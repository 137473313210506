import SalesBenefitCreate from './SalesBenefitCreate';
import SalesBenefitEdit from './SalesBenefitEdit';
import SalesBenefitList from './SalesBenefitList';


export default {
    create: SalesBenefitCreate,
    edit: SalesBenefitEdit,
    list: SalesBenefitList,
};
