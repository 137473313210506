import * as React from "react";
import {FC} from 'react';

import {
    EditButton,
    List,
    ListProps,
    Datagrid,
    TextField,
    FilterProps,
    Filter,
    TextInput,
    usePermissions
} from 'react-admin';

const IndustriesFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <TextInput source="name" label="Industry" />
    </Filter>
);

const IndustryList: FC<ListProps> = props => {
    const { loaded, permissions } = usePermissions();

    if (!loaded) {
      return null; // Đợi quyền truy cập được tải
    }
  
    const isAdmin = permissions.includes('ROLE_ADMIN');
    const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
    const isSurvey = permissions.includes('ROLE_SURVEY');
    return (
        <List title="All Industries" {...props} filters={<IndustriesFilter/>} hasCreate = {isAdmin||isSaleAdmin||isSurvey}> 
            <Datagrid>
                <TextField source="id"/>
                <TextField source="name"/>
                {(isAdmin||isSaleAdmin||isSurvey) && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default IndustryList;