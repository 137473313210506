import HawScoreCardCreate from './HawScoreCardCreate';
import HawScoreCardEdit from './HawScoreCardEdit';
import HawScoreCardList from './HawScoreCardList';
import HawScoreCardShow from './HawScoreCardShow';


export default {
    create: HawScoreCardCreate,
    edit: HawScoreCardEdit,
    list: HawScoreCardList,
    show: HawScoreCardShow
};
