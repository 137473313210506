import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScoreCardInfoBlock from "./SurveyInfoBlock/ScoreCardInfoBlock";
import HawScoreCardBlock from "./ScoreCardBlock/HawScoreCardBlock";
import SurveyTitle from "./ScoreCardBlock/SurveyTitle";
import Chart from "./ScoreCardBlock/HAWWorkForceChart";
import LegalCopyRight from "./ScoreCardBlock/LegalCopyRight";
interface Props {
	props: any;
}

const useStyles = makeStyles(theme => ({
	main: (props: Props) => ({
		overflow: 'inherit',
		display: 'flex',
	}),
	title: {},
}));
const WorkForceAllocation = ({ props }) => {
	const classes = useStyles(props);
	return (<div style={{ paddingTop: "30px" }}>
		<div className={classes.main}>
			<ScoreCardInfoBlock value={props} />
			<HawScoreCardBlock {...props}>
				<SurveyTitle {...props}/>
				<Chart {...props}/>
				<LegalCopyRight {...props}/>
			</HawScoreCardBlock>
		</div>
	</div>
	)
};

export default WorkForceAllocation;