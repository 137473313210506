import React, {
    FC,
} from 'react';
import {makeStyles} from "@material-ui/styles";

interface Props {
    language: string;
    survey_tag: string;
}

const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "100%",
        fontSize: "9pt",
        color: "#005670",
        paddingTop: "5px"
    }),
    text: () => ({
        marginLeft: "9px",
        marginRight: "9px",
        lineHeight: "18px"
    })
}));
const infoText = {
    en: {
        "haw": {
            contactInfo: {
                part1: "For more information about Employer Brand Health Check & Happiness At Work survey, please contact:"
            }
        }
    },
    vn: {
        "haw": {
            contactInfo: {
                part1: "Để Đo lường sức khỏe THNTD chuyên sâu & các khảo sát về Nguồn Nhân Lực, vui lòng liên hệ:"
            }
        }
    }
};
const SurveyContact: FC<Props> = ({language = "vn", survey_tag = "haw"}) => {
    const classes = useStyles();
    return (<div className={classes.main}>
            <div className={classes.text}>{infoText[language][survey_tag].contactInfo.part1}
            </div>
            <div className={classes.text}>
                ● (+84 28) 6268 2222 <br/>
                ● clientsolution@anphabe.com
            </div>
        </div>
    )
};

export default SurveyContact;