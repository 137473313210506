import * as React from 'react';
import {FC} from 'react';

import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';
import {Award} from "../types";


export const MediaCategory: FC<FieldProps<Award>> = ({record}) => {
    return <span> Award {record ? `"${record.name}"` : ''}</span>
}
const MediaCatEdit: FC<EditProps> = props => (
    <Edit title={<MediaCategory/>} {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput source="category_title"  label = "Category" validate={[required()]}  style={{width:"100%"}}/>
        </SimpleForm>
    </Edit>
)

export default MediaCatEdit;
