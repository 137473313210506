import * as React from 'react';
import { FC } from 'react';

import {
    EditButton,
    List,
    ListProps,
    FilterProps,
    Datagrid,
    TextField,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    required,
    ShowButton,
    usePermissions
} from 'react-admin';

const CompanyScoreCardFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <ReferenceInput
            label="Company"
            source="company_id"
            reference="companies"
            filterToQuery={searchText => ({ commercialName: searchText })}
            validate={[required()]}>
            <AutocompleteInput optionText="commercialName" alwaysOn />
        </ReferenceInput>
        {/* <TextInput source="year" /> */}
    </Filter>
);


const ScoreCardTemplateList: FC<ListProps> = props => {
    const { loaded, permissions } = usePermissions();

  if (!loaded) {
    return null; // Đợi quyền truy cập được tải
  }

  const isAdmin = permissions.includes('ROLE_ADMIN');
  const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
  const isSurvey = permissions.includes('ROLE_SURVEY');
    return (
        <List title="All Awards" {...props} filters={<CompanyScoreCardFilter />} hasCreate = {isAdmin||isSurvey}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="companyname" />
                <TextField source="year" />
                <TextField source="survey_name" />
                <TextField source="companyindustry" label="Ngành"/>
                <TextField source="language" />
                {(isAdmin||isSurvey) && <EditButton />}
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default ScoreCardTemplateList;