import * as React from 'react';
import {FC} from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    PasswordInput,
    SelectArrayInput,
    required
} from 'react-admin';



const UserCreate: FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm redirect={"list"}>
            <TextInput source="name" validate={[required()]}/>
            <TextInput label="Email Address" source="email" type="email" validate={[required()]}/>
            <PasswordInput source="password" validate={[required()]}/>
            <SelectArrayInput label="ROLES" validate={[required()]} source="roles" choices={[
                {id: 'ROLE_USER', name: 'ROLE_USER'},
                {id: 'ROLE_SALES', name: 'ROLE_SALES'},
                {id: 'ROLE_SURVEY', name: 'ROLE_SURVEY'},
                {id: 'ROLE_ADMIN', name: 'ROLE_ADMIN'},
                {id: 'ROLE_SALES_ADMIN', name: 'ROLE_SALES_ADMIN'}

            ]}/>
        </SimpleForm>
    </Create>
);

export default UserCreate;