import * as React from 'react';
import {FC} from 'react';

import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    SelectInput,
    NumberInput
} from 'react-admin';
import {Award} from "../types";


export const ScoreCard: FC<FieldProps<Award>> = ({record}) => {
    return <span> Award {record ? `"${record.name}"` : ''}</span>
}
const HawScoreCardEdit: FC<EditProps> = props => (
    <Edit title={<ScoreCard/>} {...props}>
        <SimpleForm>

            <ReferenceInput label="Template" source="template_id" reference="scorecardtemplate">
                <SelectInput optionText="report_name"/>
            </ReferenceInput>

            <ReferenceInput label="Company" source="company_id" reference="companies">
                <SelectInput optionText="commercialName"/>
            </ReferenceInput>

            <TextInput source="respondents_conducted_date" validate={[required()]} label="Thoi gian tham gia khao sat"/>

            <TextInput source="company_industry" validate={[required()]} label="Company Industry" fullWidth/>
            <NumberInput source="respondents_amount" validate={[required()]}/>

            <NumberInput source="company_index" validate={[required()]}/>
            <NumberInput source="industry_index" validate={[required()]}/>
            <NumberInput source="vietnam_index" validate={[required()]}/>


            <NumberInput source="rational_company" validate={[required()]}/>
            <NumberInput source="rational_industry" validate={[required()]}/>
            <NumberInput source="rational_vietnam" validate={[required()]}/>

            <NumberInput source="emotional_company" validate={[required()]}/>
            <NumberInput source="emotional_industry" validate={[required()]}/>
            <NumberInput source="emotional_vietnam" validate={[required()]}/>

            <NumberInput source="motivational_company" validate={[required()]}/>
            <NumberInput source="motivational_industry" validate={[required()]}/>
            <NumberInput source="motivational_vietnam" validate={[required()]}/>

            <NumberInput source="motivational_company" validate={[required()]}/>
            <NumberInput source="motivational_industry" validate={[required()]}/>
            <NumberInput source="motivational_vietnam" validate={[required()]}/>

            <NumberInput source="effort_company" validate={[required()]}/>
            <NumberInput source="effort_industry" validate={[required()]}/>
            <NumberInput source="effort_vietnam" validate={[required()]}/>

            <NumberInput source="commit_company" validate={[required()]}/>
            <NumberInput source="commit_industry" validate={[required()]}/>
            <NumberInput source="commit_vietnam" validate={[required()]}/>


            <NumberInput source="vietnam_core" validate={[required()]}/>
            <NumberInput source="vietnam_index" validate={[required()]}/>
            <NumberInput source="vietnam_index" validate={[required()]}/>
            <NumberInput source="vietnam_quitter" validate={[required()]}/>
            <NumberInput source="vietnam_zombie" validate={[required()]}/>

            <NumberInput source="company_core" validate={[required()]}/>
            <NumberInput source="company_index" validate={[required()]}/>
            <NumberInput source="company_index" validate={[required()]}/>
            <NumberInput source="company_quitter" validate={[required()]}/>
            <NumberInput source="company_zombie" validate={[required()]}/>

        </SimpleForm>
    </Edit>
)

export default HawScoreCardEdit;
