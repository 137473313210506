import * as React from 'react';
import {FC} from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required,
    SelectInput,
    NumberInput,
    ImageInput,
    ImageField
} from 'react-admin';

const ScoreCardTemplateCreate: FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm redirect={"list"}>
            <TextInput source="survey_tag"  label = "Survey Tag" validate={[required()]}/>
            <NumberInput source="survey_year"  label = "Survey Year"  validate={[required()]}/>
            <NumberInput source="total_respondent"  label = "Total Respondent" validate={[required()]}/>
            <SelectInput source="report_language" choices={[
                { id: 'vn', name: 'Tiếng Việt' },
                { id: 'en', name: 'English' },
            ]} />
            <ImageInput source="logo_url" label="Survey Logo" accept="image/*">
                <ImageField source="medium"/>
            </ImageInput>
            <TextInput source="report_name"  label = "Report Name" validate={[required()]}  style={{width:"100%"}}/>
            <TextInput source="scorecard_title"  label = "ScoreCard Title" validate={[required()]}  style={{width:"100%"}}/>
            <TextInput source="survey_title"  label = "Survey Title" validate={[required()]}  style={{width:"100%"}}/>
        </SimpleForm>
    </Create>
);

export default ScoreCardTemplateCreate;