import * as React from "react";
import {FC, useState} from 'react';
import {onHandleTextInput} from "../utils/textHandling";

import {
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    ReferenceArrayInput,
    required,
    AutocompleteArrayInput
} from 'react-admin';


const SalesPackageCreate: FC<any> = (props) => {
    const [benefits, setBenefits] = useState('');
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput
                    source="survey_id"
                    reference="surveys"
                >
                    <SelectInput source="name" initialValue={props.surveyid}/>
                </ReferenceInput>
                <TextInput source="name" validate={[required()]}/>
                <ReferenceArrayInput label="Benefits" source="benefits" reference="salesbenefits"
                                     filter={{benefits: benefits}}>
                    <AutocompleteArrayInput optionText="name" source="benefits" onChange={(event) => {
                        setBenefits(onHandleTextInput(event))
                    }}/>
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
}

export default SalesPackageCreate;