import React, {
    FC,
} from 'react';
import {makeStyles} from "@material-ui/styles";

interface Props {
    language: string;
    survey_tag: string;
}

const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "100%",
        textAlign: "left",
        paddingTop: "10px",
        fontSize: "25px",
        fontWeight: "bold",
        color: "#7f7f7f"

    }),
    title: {},
}));
const infoText = {
    en: {
        "haw": {
            title: {
                part1: "HAPPINESS AT WORK Survey"
            }
        }
    },
    vn: {
        "haw": {
            title: {
                part1: "Khảo sát NGUỒN NHÂN LỰC HẠNH PHÚC"
            }
        }
    }
};
const SurveyTitle: FC<Props> = ({language = "vn", survey_tag = "haw"}) => {
    const classes = useStyles();
    return (<div className={classes.main}>
            {infoText[language][survey_tag].title.part1}
        </div>
    )
};

export default SurveyTitle;