import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HawIndex from "./HawIndex";
import WorkForceAllocation from "./WorkForceAllocation";

interface Props {
    props: any;
}

const useStyles = makeStyles(theme => ({
    main: (props: Props) => ({
        overflow: 'inherit',
    }),
    hawIndex: ()=>({

    }),
    workforceAllocation: ()=>({

    })
}));
const HawScoreCard = ({props}) => {
    const classes = useStyles(props);
    return (<div className={classes.main}>
        <HawIndex props={props}/>
        <WorkForceAllocation props={props}/>
    </div>)
};

export default HawScoreCard;