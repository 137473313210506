import DaiIchiLifeVietnamLogo from "./logo/dai-ichi-life-vietnam.png";
import FptCorporationLogo from "./logo/fpt-corporation.png";
import FptInformationSystemLogo from "./logo/defaultCompanyLogo.png";
import FptOnlineLogo from "./logo/defaultCompanyLogo.png";
import FptTelecomLogo from "./logo/fpt-telecom.png";
import FptSoftwareLogo from "./logo/fpt-software.png";
import NipponPaintVietnamLogo from "./logo/nippon-paint-vietnam.jpg";
import RedBullLogo from "./logo/red-bull.png";
import SabmillerLogo from "./logo/defaultCompanyLogo.png";
import TriumphVietNamLogo from "./logo/triumph-viet-nam.png";
import VinacapitalLogo from "./logo/vinacapital.png";
import WallStreetEnglishLogo from "./logo/wall-street-english.png";
import CbreVietnamLogo from "./logo/cbre-vietnam.png";
import ColgatePalmoliveVietnamLogo from "./logo/colgate-palmolive-vietnam.png";
import KfcVietnamLogo from "./logo/kfc-vietnam.jpg";
import FujiXeroxLogo from "./logo/fuji-xerox.png";
import NutifoodLogo from "./logo/nutifood.png";
import MatBaoLogo from "./logo/mat-bao.png";
import ChubbLifeLogo from "./logo/chubb-life.jpg";
import CoficoLogo from "./logo/cofico.png";
import DkEngineeringLogo from "./logo/dk-engineering.png";
import EvolableLogo from "./logo/evolable-asia.png";
import GeneraliVietnamLogo from "./logo/generali-vietnam.png";
import McdonaldsVietNamLogo from "./logo/mcdonalds-viet-nam.png";
import HafeleVietnamLlcLogo from "./logo/hafele-vietnam-llc.png";
import VietjetAirLogo from "./logo/vietjet-air.png";
import HdBankLogo from "./logo/hdbank.jpg";
import InternationalSosVietNamLogo from "./logo/international-sos-viet-nam.png";
import LotteriaLogo from "./logo/lotteria.png";
import LotteMartLogo from "./logo/lotte-mart.jpg";
import LotteLegendLogo from "./logo/defaultCompanyLogo.png";
import LotteCinemaLogo from "./logo/lotte-cinema.png";
import MekongCapitalLogo from "./logo/mekong-capital.png";
import MillwardBrownLogo from "./logo/millward-brown.png";
import MonsantoVietnamLogo from "./logo/monsanto-vietnam.png";
import NsBluescopeSteelLogo from "./logo/ns-bluescope-steel.png";
import ParksonLogo from "./logo/defaultCompanyLogo.png";
import SchaefflerVietNamLogo from "./logo/schaeffler.png";
import SchenkerLogo from "./logo/schenker.png";
import ShinhanBankLogo from "./logo/shinhan-bank.png";
import SquareGroupLogo from "./logo/square-group.png";
import NganHangAChauLogo from "./logo/ngan-hang-a-chau.png";
import AustdoorLogo from "./logo/defaultCompanyLogo.png";
import ChoTotLogo from "./logo/cho-tot.png";
import CircleKLogo from "./logo/circle-k.png";
import DongwhaLogo from "./logo/defaultCompanyLogo.png";
import EsteeLauderLogo from "./logo/estee-lauder2.png";
import ExoTravelLogo from "./logo/exo-travel.png";
import GoldenGateGroupLogo from "./logo/golden-gate-group.png";
import HabitatForHumanityInternationalInVietnamLogo from "./logo/habitat-for-humanity-international-in-vietnam.png";
import NghiaNippersLogo from "./logo/nghia-nippers.png";
import VietLotusLogo from "./logo/viet-lotus.png";
import BayerVietnamLogo from "./logo/bayer-vietnam.png";
import VmgMediaLogo from "./logo/vmg-media.png";
import BelVietnamLogo from "./logo/bel-vietnam.png";
import XoneFmLogo from "./logo/defaultCompanyLogo.png";
import MasanNutriScienceLogo from "./logo/masan-nutri-science.jpg";
import GoldenCommunicationGroupLogo from "./logo/golden-communication-group.png";
import MinhDatFoodLogo from "./logo/defaultCompanyLogo.png";
import IsuzuLogo from "./logo/isuzu.png";
import SantaFeReloLogo from "./logo/santa-fe-relo.png";
import BridgestoneTireSalesVietnamLogo from "./logo/bridgestone-tire-sales-vietnam.png";
import MjGroupLogo from "./logo/defaultCompanyLogo.png";
import CacCorporationLogo from "./logo/defaultCompanyLogo.png";
import OoclLogo from "./logo/oocl.png";
import UsgBoralVietNamLogo from "./logo/usg-boral-viet-nam.png";
import AbbottLaboratoriesGmbhLogo from "./logo/abbott-laboratories-sa.jpg";
import AiaVietnamLogo from "./logo/aia-vietnam.jpg";
import AmwayVietnamLogo from "./logo/Amway.png";
import AnovaCorpLogo from "./logo/anova-corp.png";
import AveryDennisonLogo from "./logo/avery-dennison.png";
import HeinekenHanoiLogo from "./logo/heineken-hanoi.png";
import BatVietnamLogo from "./logo/bat-vietnam.png";
import BeiersdorfVietnamLogo from "./logo/beiersdorf-vietnam.png";
import CmcCorporationLogo from "./logo/cmc-corporation.png";
import CocaColaVietnamLogo from "./logo/cocacola2023.png";
import ToHopDatvietvacLogo from "./logo/to-hop-datvietvac.png";
import InseeVietnamLogo from "./logo/insee-vietnam.png";
import GeVietnamLogo from "./logo/ge-vietnam.png";
import IntelVietnamLogo from "./logo/intel-vietnam.png";
import JohnsonJohnsonLogo from "./logo/johnson-johnson.png";
import KimberlyClarkVietnamLogo from "./logo/kimberly-clark-vietnam.png";
import MondelezKinhDoLogo from "./logo/mondelez-vietnam.jpg";
import LorealVietnamLogo from "./logo/loreal-vietnam.png";
import MasanGroupLogo from "./logo/masan-group.png";
import MmMegaMarketVietnamLogo from "./logo/mm-mega-market-vietnam.jpg";
import NestleVietnamLogo from "./logo/nestle-vietnam.png";
import NguyenKimLogo from "./logo/nguyen-kim.png";
import NovalandGroupLogo from "./logo/novaland-group.jpg";
import PepsicoFoodsVietnamCompanyLogo from "./logo/pepsico-foods-vietnam-company.jpg";
import PizzaVietnamLogo from "./logo/pizza-vietnam.png";
import PrudentialFinanceLogo from "./logo/prudential-finance.png";
import PrudentialVietnamLogo from "./logo/prudential-vietnam.png";
import NumberOneLogo from "./logo/defaultCompanyLogo.png";
import ThMilkLogo from "./logo/th-milk.png";
import SamsungLogo from "./logo/samsung.png";
import SanofiVietnamLogo from "./logo/sanofi-vietnam.png";
import SuntoryPepsicoVietnamLogo from "./logo/suntory-pepsico-viet-nam.png";
import UnileverLogo from "./logo/unilever.png";
import VinhomesLogo from "./logo/vinhomes.png";
import VinatabaPhilipMorrisLogo from "./logo/vinataba-philip-morris.png";
import HeinekenVietnamLogo from "./logo/heineken-vietnam.png";
import VinamilkLogo from "./logo/vinamilk.png";
import VngLogo from "./logo/vng.png";
import PaceEducationGroupLogo from "./logo/pace-education-group.png";
import BiominLogo from "./logo/biomin.png";
import GameloftLogo from "./logo/gameloft.png";
import GlaxosmithklineGskLogo from "./logo/glaxosmithkline-gsk.png";
import GroupmLogo from "./logo/groupm.png";
import HtcLogo from "./logo/htc.png";
import MobifoneLogo from "./logo/mobifone.png";
import MsMSDMerckSharpDohmeAsiaLtdLogo from "./logo/msd-vietnam.png";
import MicrosoftVietnamLogo from "./logo/microsoft-vietnam.png";
import PrimeGroupLogo from "./logo/prime-group.png";
import RocheVietnamLogo from "./logo/roche-vietnam.png";
import SsiLogo from "./logo/ssi.png";
import SunGroupLogo from "./logo/sun-group.png";
import UndpVietnamLogo from "./logo/undp-vietnam.png";
import VietnamobileLogo from "./logo/vietnamobile.png";
import ViglaceraLogo from "./logo/defaultCompanyLogo.png";
import VinaphoneLogo from "./logo/vinaphone.png";
import YamahaLogo from "./logo/yamaha.png";
import KmsTechnologyLogo from "./logo/kms-technology.png";
import H24hLogo from "./logo/defaultCompanyLogo.png";
import AbInbevVietNamLogo from "./logo/ab-inbev-viet-nam.png";
import AprotrainLogo from "./logo/aprotrain.jpg";
import AsiafoodsLogo from "./logo/asiafoods.png";
import BbraunVietnamLogo from "./logo/bbraun-vietnam.png";
import BbdoVietnamLogo from "./logo/bbdo-vietnam.png";
import BhdstarLogo from "./logo/bhdstar.png";
import CapgeminiVietnamLogo from "./logo/capgemini-vietnam.png";
import CarlsbergVietnamLogo from "./logo/carlsberg-vietnam.png";
import DellLogo from "./logo/dell.png";
import DojiGroupLogo from "./logo/doji-group.png";
import DoosanLogo from "./logo/defaultCompanyLogo.png";
import ErcInternationalLogo from "./logo/erc-international.png";
import GalaxyCinemaLogo from "./logo/galaxy-cinema.png";
import GmVietnamLogo from "./logo/gm-vietnam.png";
import HoneywellInternationalLogo from "./logo/honeywell-international.png";
import LanguageLinkLogo from "./logo/language-link.png";
import NokiaSiemensNetworksLogo from "./logo/defaultCompanyLogo.png";
import OracleVietnamLogo from "./logo/oracle-vietnam.png";
import QualcommInternationalLogo from "./logo/qualcomm-international.png";
import TotoLogo from "./logo/defaultCompanyLogo.png";
import VsipLogo from "./logo/vsip.png";
import HoaBinhConstructionGroupLogo from "./logo/hoa-binh-construction-group.png";
import ErnstYoungVietnamLimitedLogo from "./logo/ernst-young-vietnam-limited.png";
import BritishCouncilLogo from "./logo/british-council.png";
import CanonVietnamLogo from "./logo/canon-vietnam.jpg";
import CheilVietnamLogo from "./logo/defaultCompanyLogo.png";
import DwpLogo from "./logo/defaultCompanyLogo.png";
import EximbankLogo from "./logo/eximbank.jpg";
import DkshLogo from "./logo/dksh.png";
import GedeonRichterLogo from "./logo/gedeon-richter.png";
import OppoVietnamLogo from "./logo/oppo-vietnam.png";
import SiemensLogo from "./logo/siemens.png";
import ElectroluxVietNamLogo from "./logo/electrolux-viet-nam.jpg";
import SonionLogo from "./logo/sonion.png";
import AirLiquideVietnamLogo from "./logo/air-liquide-vietnam.png";
import StandardCharteredBankLogo from "./logo/standard-chartered-bank.png";
import FedexLogo from "./logo/fedex.png";
import GrantThorntonLogo from "./logo/grant-thornton.png";
import ZamilSteelLogo from "./logo/zamil-steel.png";
import TraphacoLogo from "./logo/traphaco.png";
import LgVinaCosmeticsLogo from "./logo/lg-vina-cosmetics.png";
import EricssonVietnamLogo from "./logo/ericsson-vietnam.png";
import DetMayThygesenVietnamLogo from "./logo/det-may-thygesen-vietnam.png";
import DicentralVietnamLogo from "./logo/dicentral-vietnam.png";
import JotunVietnamLogo from "./logo/jotun-vietnam.png";
import TmaSolutionsLogo from "./logo/tma-solutions.png";
import RmitLogo from "./logo/rmit.png";
import SerenityHoldingVietnamLogo from "./logo/defaultCompanyLogo.png";
import TikiLogo from "./logo/tiki.png";
import TribecoLogo from "./logo/tribeco.png";
import ThepVinaKyoeiLogo from "./logo/defaultCompanyLogo.png";
import ArchetypeVietnamLogo from "./logo/archetype-vietnam.png";
import AristonLogo from "./logo/ariston.png";
import DaikinVietnamLogo from "./logo/daikin-vietnam.png";
import DhgPharmaLogo from "./logo/dhg-pharma.jpg";
import PeacesoftLogo from "./logo/peacesoft.jpg";
import OgilvyGroupLogo from "./logo/ogilvy-group.png";
import VinafcoLogo from "./logo/vinafco.png";
import TascoLogo from "./logo/cong-ty-co-phan-tasco.png";
import ThanhCongMobileLogo from "./logo/defaultCompanyLogo.png";
import BdsVanPhatHungLogo from "./logo/bds-van-phat-hung.png";
import ExxonmobilLogo from "./logo/exxonmobil.png";
import DinhQuocCorpLogo from "./logo/dinh-quoc-corp.png";
import VcliLogo from "./logo/vcli.png";
import VinabookLogo from "./logo/vinabook.png";
import ElisoftLogo from "./logo/defaultCompanyLogo.png";
import SovigazLogo from "./logo/sovigaz.png";
import AirWaterVietnamLogo from "./logo/air-water-vietnam.gif";
import MesserVietnamLogo from "./logo/messer-vietnam.jpg";
import AkzonobelVietnamLogo from "./logo/defaultCompanyLogo.png";
import AmericanStandardVietnamLogo from "./logo/defaultCompanyLogo.png";
import LixilVietNamLogo from "./logo/lixil-viet-nam.jpg";
import AnzBankVietnamLogo from "./logo/defaultCompanyLogo.png";
import BrgGroupLogo from "./logo/brg-group.png";
import CgvVietnamLogo from "./logo/cgv-vietnam.png";
import GamudaLandVietnamLogo from "./logo/gamuda-land-vietnam.png";
import NashtechLogo from "./logo/nashtech.png";
import HellaVietnamLogo from "./logo/hella-vietnam.png";
import IbmVietnamLogo from "./logo/ibm-vietnam.png";
import IlaVietnamLogo from "./logo/ila-vietnam.jpg";
import IndochinaCapitalVietnamLogo from "./logo/indochina-capital-vietnam.png";
import IntercontinentalSaigonHotelLogo from "./logo/intercontinental-saigon-hotel.png";
import KeppelLandVietnamLogo from "./logo/keppel-land-vietnam.jpg";
import KirbySoutheastAsiaVietnamLogo from "./logo/kirby-southeast-asia-vietnam.png";
import LibertyInsuranceVietnamLogo from "./logo/liberty-insurance-vietnam.png";
import MaiLinhGroupLogo from "./logo/mai-linh-group.png";
import MeadJohnsonNutritionVietnamLogo from "./logo/mead-johnson-nutrition-vietnam.png";
import PricewaterhousecoopersPwcLogo from "./logo/pricewaterhousecoopers-pwc.png";
import SherwinWilliamsVietnamLogo from "./logo/sherwin-williams-vietnam.png";
import ThienMinhGroupLogo from "./logo/thien-minh-group.png";
import TmsGroupLogo from "./logo/tms-group.png";
import VcCorpLogo from "./logo/vc-corp.png";
import VibBankLogo from "./logo/vib-bank.png";
import VienThongALogo from "./logo/vien-thong-a.png";
import VilubeLogo from "./logo/motul-vietnam.jpg";
import YanTvLogo from "./logo/yan-tv.png";
import CtGroupLogo from "./logo/ct-group.png";
import DiageoVietnamLogo from "./logo/diageo-vietnam.png";
import FosterVietnamLogo from "./logo/foster-vietnam.png";
import HanhPhucHospitalLogo from "./logo/hanh-phuc-hospital.png";
import HscInvestmentGroupLogo from "./logo/hsc-investment-group.jpg";
import LuatMayerBrownJsmVietnamLogo from "./logo/luat-mayer-brown-jsm-vietnam.png";
import MsbLogo from "./logo/msb.png";
import PullmanLogo from "./logo/pullman.jpg";
import PumaLogo from "./logo/puma.png";
import VpBankLogo from "./logo/vp-bank.png";
import AbbLtdLogo from "./logo/abb-ltd.png";
import NikeVietnamLogo from "./logo/nike-vietnam.png";
import CargillVietnamLogo from "./logo/cargill-vietnam.png";
import GrabLogo from "./logo/grab.png";
import NielsenVietnamLogo from "./logo/nielsen-vietnam.png";
import SonkimLandLogo from "./logo/sonkim-land.png";
import FvHospitalLogo from "./logo/fv-hospital.png";
import TruongHaiAutoLogo from "./logo/truong-hai-auto.png";
import AcecookVietNamLogo from "./logo/acecook-vietnam.png";
import SchindlerVietnamLogo from "./logo/schindler-vietnam.png";
import HoaSenGroupLogo from "./logo/hoa-sen-group.png";
import OnSemiconductorVietnamLogo from "./logo/defaultCompanyLogo.png";
import MaricoSouthEastAsiaLogo from "./logo/marico-south-east-asia.png";
import PnjLogo from "./logo/PNJ2023.png";
import TechcombankLogo from "./logo/techcombank.png";
import LazadaVietnamLogo from "./logo/lazada-vietnam.png";
import PiaggioVietnamLogo from "./logo/piaggio-vietnam.png";
import PanasonicLogo from "./logo/panasonic.jpg";
import FrieslandcampinaVietnamLogo from "./logo/frieslandcampina-vietnam.png";
import MercedesBenzVietnamLogo from "./logo/mercedes-benz-vietnam.png";
import CongTyHondaVietNamLogo from "./logo/honda-vietnam.png";
import BoschVietnamLogo from "./logo/bosch-vietnam.png";
import TheGioiDiDongLogo from "./logo/the-gioi-di-dong.png";
import NewWorldSaigonHotelLogo from "./logo/new-world-saigon-hotel.png";
import SaintGobainVietnamLogo from "./logo/saint-gobain-vietnam.png";
import SapporoLogo from "./logo/sapporo.png";
import EdelmanLogo from "./logo/edelman.png";
import ToyotaMotorVietnamLogo from "./logo/toyota-motor-vietnam.jpg";
import ManulifeLogo from "./logo/manulife.png";
import PycoGroupLogo from "./logo/pyco-group.png";
import BasfVietnamLogo from "./logo/basf-vietnam.png";
import ViettelGroupLogo from "./logo/viettel-group.png";
import SacombankLogo from "./logo/sacombank.png";
import BidvLogo from "./logo/BIDV2023.png";
import PviLogo from "./logo/pvi.png";
import VietravelLogo from "./logo/vietravel.png";
import UnicharmLogo from "./logo/unicharm.jpg";
import KantarTnsLogo from "./logo/kantar-tns.png";
import AnnamGroupLogo from "./logo/annam-group.png";
import SaveTheChildrenLogo from "./logo/save-the-children.png";
import VndirectLogo from "./logo/vndirect.png";
import CambridgeUniversityLogo from "./logo/cambridge-university.png";
import VusLogo from "./logo/vus.jpg";
import ApolloLogo from "./logo/apollo.png";
import WhitePalaceLogo from "./logo/white-palace.png";
import VietnamFanLogo from "./logo/vietnam-fan.png";
import JollibeeLogo from "./logo/jollibee.png";
import HanwhaLifeVietNamLogo from "./logo/hanwha-life.png";
import AplLogo from "./logo/apl.png";
import MolLogisticLogo from "./logo/mol-logistic.png";
import EquatorialHcmLogo from "./logo/defaultCompanyLogo.png";
import LevisLogo from "./logo/levis.png";
import BrotherVietnamLogo from "./logo/brother-vietnam.png";
import DanieliLogo from "./logo/danieli.png";
import JonesLangLasalleLogo from "./logo/jones-lang-lasalle.png";
import RingierLogo from "./logo/ringier.png";
import CanifaLogo from "./logo/canifa.png";
import IqviaVietnamLogo from "./logo/iqvia-vietnam.png";
import AccorHotelsLogo from "./logo/accor-hotels.png";
import BigCVietnamLogo from "./logo/big-c-vietnam.png";
import DeloitteVietNamLogo from "./logo/deloitte-viet-nam.png";
import VinhTuongLogo from "./logo/defaultCompanyLogo.png";
import AstrazenecaVietnamLogo from "./logo/astrazeneca.png";
import ShellLogo from "./logo/shell.png";
import FordVietNamLogo from "./logo/ford-viet-nam.png";
import CpGroupLogo from "./logo/cp-group.png";
import CapitalandVietnamLogo from "./logo/CapitaLand Development.jpg";
import M3mVietNamLogo from "./logo/3m-viet-nam.png";
import WiproConsumerCareVietNamLogo from "./logo/wipro.jpg";
import DhlLogo from "./logo/dhl-vnpt-express.png";
import DhlSupplyChainLogo from "./logo/defaultCompanyLogo.png";
import SmartTrainLogo from "./logo/smart-train.png";
import IdgVenturesVietnamLogo from "./logo/idg-ventures-vietnam.png";
import SonyElectronicsVietNamLogo from "./logo/sony-electronics-viet-nam.png";
import ToshibaLogo from "./logo/toshiba.png";
import BachySoletancheVietnamLogo from "./logo/bachy-soletanche-vietnam.png";
import DecathlonLogo from "./logo/decathlon.png";
import GlobalCybersoftAHitachiConsultingCompanyLogo from "./logo/global-cybersoft---a-hitachi-consulting-company.png";
import ImperialTobaccoLogo from "./logo/defaultCompanyLogo.png";
import ThaiTuanTextileGarmentLogo from "./logo/thai-tuan-textile-garment.png";
import TntExpressLogo from "./logo/defaultCompanyLogo.png";
import TotalVietnamLogo from "./logo/total-vietnam.jpg";
import TrgInternationalLogo from "./logo/trg-international.png";
import TrungNguyenLogo from "./logo/trung-nguyen.jpg";
import CaloficLogo from "./logo/calofic.png";
import NuskinLogo from "./logo/defaultCompanyLogo.png";
import SyngentaVietnamLogo from "./logo/syngenta-vietnam.png";
import BtmGlobalConsultingLogo from "./logo/defaultCompanyLogo.png";
import DdbLogo from "./logo/defaultCompanyLogo.png";
import AmccAppliedMicroCircuitsCorporationLogo from "./logo/amcc-applied-micro-circuits-corporation.png";
import SeabankLogo from "./logo/seabank.png";
import GreenfeedVietnamCorporationLogo from "./logo/greenfeed-vietnam-corporation.png";
import CimigoVietNamLogo from "./logo/cimigo-viet-nam.png";
import DentsuVietnamLogo from "./logo/dentsu-vietnam.png";
import PebSteelLogo from "./logo/peb-steel.png";
import ChicilonMediaLogo from "./logo/chicilon-media.png";
import KumhoEngineeringConstructionLogo from "./logo/defaultCompanyLogo.png";
import LeoBurnettLogo from "./logo/leo-burnett.png";
import TheAscottLogo from "./logo/the-ascott.png";
import GlobalInsuranceJscLogo from "./logo/global-insurance-jsc.png";
import ChinfonLogo from "./logo/defaultCompanyLogo.png";
import UnibenLogo from "./logo/uniben.jpg";
import ServierVietnamLogo from "./logo/servier-vietnam.png";
import JetstarPacificLogo from "./logo/jetstar-pacific.png";
import CiscoVietnamLogo from "./logo/cisco-vietnam.png";
import ChevronVietnamLogo from "./logo/chevron-vietnam.png";
import YusenVietnamLogo from "./logo/yusen-vietnam.png";
import HabecoLogo from "./logo/habeco.jpg";
import BitisLogo from "./logo/bitis.png";
import EgameLogo from "./logo/defaultCompanyLogo.png";
import CaliforniaFitnessYogaLogo from "./logo/california-fitness-yoga.png";
import HsbcVietnamLogo from "./logo/hsbc-vietnam.png";
import NovartisVietnamLogo from "./logo/novartis-vietnam.jpg";
import AbbvieVietnamLogo from "./logo/abbvie-vietnam.png";
import AigVietnamLogo from "./logo/aig-vietnam.png";
import VietnamnetGroupLogo from "./logo/vietnamnet-group.png";
import VietinbankLogo from "./logo/vietinbank.png";
import AvivaVietnamLogo from "./logo/aviva-vietnam.png";
import VietCapitalSecuritiesLogo from "./logo/vietcapital-securities.png";
import PurpleAsiaLogo from "./logo/purple-asia.png";
import KpmgInVietnamLogo from "./logo/kpmg.png";
import KirinVietnamLogo from "./logo/defaultCompanyLogo.png";
import GfkAsiaLogo from "./logo/gfk-asia.png";
import AdidasVietnamLogo from "./logo/adidas-vietnam.png";
import AonVietnamLogo from "./logo/aon-vietnam.png";
import CastrolBpPetcoLogo from "./logo/castrol-bp-petco.png";
import CocCocLogo from "./logo/coc-coc.png";
import AaaInsuranceLogo from "./logo/aaa-insurance.jpg";
import VietcapitalBankLogo from "./logo/vietcapital-bank.png";
import VifonLogo from "./logo/vifon.png";
import PhuMyHungLogo from "./logo/phu-my-hung.png";
import ChungKhoanBaoVietBvscLogo from "./logo/chung-khoan-bao-viet-bvsc.png";
import CitibankVietnamLogo from "./logo/citibank-vietnam.png";
import EdgeAsiaLogo from "./logo/defaultCompanyLogo.png";
import GoldsunFocusMediaLogo from "./logo/goldsun-focus-media.png";
import HappyCookLogo from "./logo/happy-cook.png";
import HoanMyLogo from "./logo/y-khoa-hoan-my.png";
import IndovinaBankLogo from "./logo/indovina-bank.png";
import IntageVietnamLogo from "./logo/defaultCompanyLogo.png";
import KaoVietnamLogo from "./logo/kao-vietnam.png";
import OperationSmileVietnamLogo from "./logo/operation-smile-vietnam.png";
import ParkHyattSaigonLogo from "./logo/park-hyatt-saigon.png";
import SanMiguelBreweryVietnamLogo from "./logo/san-miguel-brewery-vietnam.png";
import SharpVietNamLogo from "./logo/sharp-viet-nam.png";
import UpsLogo from "./logo/ups.png";
import FujifilmVietNamLogo from "./logo/fujifilm-viet-nam.png";
import LindeGasVietnamLogo from "./logo/linde-gas-vietnam.png";
import CleverlearnLogo from "./logo/cleverlearn.png";
import CscVietnamLogo from "./logo/csc-vietnam.png";
import CotecGroupLogo from "./logo/defaultCompanyLogo.png";
import ItdGroupLogo from "./logo/itd-group.png";
import ImvLogo from "./logo/imv.png";
import ConnellBrosCompanyLogo from "./logo/defaultCompanyLogo.png";
import CungMuaLogo from "./logo/cung-mua.png";
import FwdVietnamLogo from "./logo/fwd-vietnam.png";
import AaCorporationLogo from "./logo/defaultCompanyLogo.png";
import MikCorporationLogo from "./logo/mik-corporation.png";
import AnovaMilkLogo from "./logo/defaultCompanyLogo.png";
import EcoPharmaLogo from "./logo/eco-pharma.png";
import EliteFitnessLogo from "./logo/elite-fitness.png";
import SheratonHotelLogo from "./logo/sheraton-hotel.png";
import BaoHiemBaoVietLogo from "./logo/bao-hiem-bao-viet.jpg";
import LingoLogo from "./logo/defaultCompanyLogo.png";
import TopicaLogo from "./logo/topica.png";
import JwalterThompsonJwtLogo from "./logo/jwalter-thompson-jwt.png";
import SabecoLogo from "./logo/sabeco.png";
import SaoBacDauLogo from "./logo/sao-bac-dau.png";
import SunFlowerLogo from "./logo/sun-flower.png";
import VietnamStarAutomobileLogo from "./logo/vietnam-star-automobile.png";
import BitexcoGroupLogo from "./logo/bitexco-group.png";
import SsgGroupLogo from "./logo/ssg-group.png";
import RussinVecchiLogo from "./logo/russin-vecchi.png";
import ZuelligPharmaLogo from "./logo/zuellig-pharma.png";
import MitsubishiMotorsVietNamLogo from "./logo/defaultCompanyLogo.png";
import UnitedInternationalPharmaUipLogo from "./logo/united-international-pharma-uip.png";
import UniPresidentLogo from "./logo/uni-president.png";
import ProcterGambleVietnamPgLogo from "./logo/procter-gamble-vietnam-pg.png";
import VietcombankLogo from "./logo/vietcombank.png";
import AnphabeLogo from "./logo/anphabe.png";
import StarwoodLogo from "./logo/defaultCompanyLogo.png";
import CongTyNhuaDuyTanLogo from "./logo/nhua-duy-tan.png";
import NctCorporationLogo from "./logo/nct-corporation.png";
import AldoLogo from "./logo/aldo.png";
import PhucLongCoffeeTeaLogo from "./logo/phuc-long-coffee-tea.png";
import RichfieldLogo from "./logo/defaultCompanyLogo.png";
import AureconGroupLogo from "./logo/defaultCompanyLogo.png";
import CjVinaAgriLogo from "./logo/cj-vina-agri.jpg";
import DaleCarnegieLogo from "./logo/dale-carnegie.png";
import DamcoLogo from "./logo/damco.png";
import MisaLogo from "./logo/misa.png";
import HomeCreditLogo from "./logo/home-credit.png";
import SonkimFashionLogo from "./logo/sonkim-fashion.png";
import EverpiaVietnamLogo from "./logo/everpia-vietnam.png";
import CotecconsGroupLogo from "./logo/coteccons-group.png";
import AmericanRedCrossLogo from "./logo/defaultCompanyLogo.png";
import FahasaLogo from "./logo/defaultCompanyLogo.png";
import ScancomLogo from "./logo/scancom.png";
import SaigonBankLogo from "./logo/saigon-bank.png";
import DalatHasfarmLogo from "./logo/dalat-hasfarm.png";
import NewBalanceVietnamLogo from "./logo/new-balance-vietnam.png";
import NamkwangEngineeringAndConstructionLogo from "./logo/defaultCompanyLogo.png";
import HoangAnhLogo from "./logo/hoang-anh.png";
import SunLifeVietNamLogo from "./logo/sun-life-viet-nam.png";
import PetroVietnamLogo from "./logo/petro-vietnam.png";
import BonfiglioliVietnamLogo from "./logo/bonfiglioli-vietnam.png";
import DaiQuangMinhLogo from "./logo/dai-quang-minh.png";
import NshapeFitnessLogo from "./logo/nshape-fitness.png";
import Fit24Logo from "./logo/fit-24.png";
import GetFitLogo from "./logo/get-fit.png";
import TpbankLogo from "./logo/tpbank.png";
import MbbankLogo from "./logo/mb-bank.png";
import SchneiderElectricVietNamLogo from "./logo/schneider-electric-viet-nam.png";
import AnovaFeedLogo from "./logo/anova-feed.png";
import VfCorporationLogo from "./logo/vf-corporation.png";
import MitsubishiElectricVietnamLogo from "./logo/mitsubishi-electric-vietnam.png";
import LgElectricLogo from "./logo/lg-innotek-viet-nam-hai-phong.png";
import CarrierLogo from "./logo/carrier.png";
import UnderArmourLogo from "./logo/under-armour.png";
import FptTradingLogo from "./logo/defaultCompanyLogo.png";
import TheBodyShopLogo from "./logo/the-body-shop.png";
import LaureltonDiamondsLogo from "./logo/laurelton-diamonds.png";
import LaVieLogo from "./logo/la-vie.png";
import AcfcLogo from "./logo/defaultCompanyLogo.png";
import AdcGroupLogo from "./logo/adc-group.png";
import AeonVietnamLogo from "./logo/AEON2023.png";
import AeonMallLogo from "./logo/aeon-mall.png";
import ApaxEnglishLogo from "./logo/apax-english.png";
import AsiaShineLogo from "./logo/defaultCompanyLogo.png";
import AsusLogo from "./logo/asus.png";
import AudiLogo from "./logo/audi.png";
import BcgLogo from "./logo/bcg.png";
import BehnMeyerAgricareLogo from "./logo/behn-meyer-agricare.png";
import BenThanhGroupLogo from "./logo/ben-thanh-group.png";
import BhsLogo from "./logo/defaultCompanyLogo.png";
import BimGroupLogo from "./logo/tap-doan-bim-group.png";
import BitasLogo from "./logo/bita-s.png";
import BluescopeBuildingsVietnamLogo from "./logo/bluescope-buildings-vietnam.png";
import BmwEuroAutoLogo from "./logo/bmw-euro-auto.png";
import BolloreLogo from "./logo/bollore.png";
import BureauVeritasLogo from "./logo/bureau-veritas.png";
import CaravelleHotelLogo from "./logo/caravelle-hotel.png";
import CbiIpsosLogo from "./logo/cbi-ipsos.png";
import CongTyTaiChinhCoPhanTinVietVietcreditLogo from "./logo/vietcredit2023.png";
import CihLogo from "./logo/cih.png";
import CiticomLogo from "./logo/citicom.png";
import CmcTelecomLogo from "./logo/defaultCompanyLogo.png"; 
import CoatsPhongPhuLogo from "./logo/coats-phong-phu.png";
import IndoTransLogisticsLogo from "./logo/indo-trans-logistics.png";
import ControlUnionVietnamLogo from "./logo/defaultCompanyLogo.png";
import CroweHorwathLogo from "./logo/crowe-horwath.png";
import DatXanhGroupLogo from "./logo/dat-xanh-group.png";
import DatalogicLogo from "./logo/defaultCompanyLogo.png";
import DeHeusLogo from "./logo/de-heus2023.png";
import DekTechnologiesLogo from "./logo/dek-technologies.png";
import DesconLogo from "./logo/descon.png";
import DongABankLogo from "./logo/dong-a-bank.png";
import DongTamLogo from "./logo/dong-tam.png";
import DuocHoaThienPhuLogo from "./logo/duoc-hoa-thien-phu.png";
import ElcomLogo from "./logo/elcom.png";
import EMartVietNamLogo from "./logo/e-mart-viet-nam.png";
import EsprintaVietNamLogo from "./logo/esprinta-viet-nam.png";
import EurowindowLogo from "./logo/eurowindow.png";
import FeCreditLogo from "./logo/fe-credit.png";
import FlcGroupLogo from "./logo/flc-group.png";
import GinkgoLogo from "./logo/ginkgo.png";
import GsEcLogo from "./logo/defaultCompanyLogo.png";
import HanesbrandsLogo from "./logo/hanesbrands.png";
import HansaeVnLogo from "./logo/hansae-vn.png";
import HerbalifeLogo from "./logo/herbalife.png";
import HoaSenUniversityLogo from "./logo/hoa-sen-university.png";
import HoangQuanGroupLogo from "./logo/hoang-quan-group.png";
import HuaweiLogo from "./logo/huawei.png";
import HungVuongGroupLogo from "./logo/hung-vuong-group.png";
import HuyndaiKeficoLogo from "./logo/huyndai-kefico.png";
import IiViVietNamLogo from "./logo/defaultCompanyLogo.png";
import IkeaVietNamLogo from "./logo/ikea.png";
import ImgLogo from "./logo/img.png";
import ImmGroupLogo from "./logo/imm-group.png";
import JxNipponOilEnergyLogo from "./logo/jx-nippon-oil-energy.png";
import KarcherLogo from "./logo/defaultCompanyLogo.png";
import KuehnenagelLogo from "./logo/kuehnenagel.png";
import KumhoTireVnLogo from "./logo/kumho-tire-vn.png";
import LeMeridienHotelLogo from "./logo/defaultCompanyLogo.png";
import LeflairLogo from "./logo/leflair.png";
import LiksinLogo from "./logo/liksin.png";
import LinVietNamLogo from "./logo/lin-viet-nam.png";
import LotusGroupLogo from "./logo/lotus-group.png";
import LuxoftLogo from "./logo/luxoft.png";
import MaisonLogo from "./logo/maison.png";
import JwMarriottHotelLogo from "./logo/defaultCompanyLogo.png";
import MetanLogo from "./logo/defaultCompanyLogo.png";
import MekongEnergyLogo from "./logo/mekong-energy.png";
import MinhHoangGarmentLogo from "./logo/defaultCompanyLogo.png";
import MundipharmaVietnamLogo from "./logo/mundipharma.png";
import NganHangBaoVietLogo from "./logo/ngan-hang-bao-viet.png";
import NganHangVietALogo from "./logo/ngan-hang-viet-a.png";
import NhuaRangDongLogo from "./logo/nhua-rang-dong.png";
import NovoNordiskLogo from "./logo/novo-nordisk.png";
import OlamVietnamLogo from "./logo/olam-vietnam.png";
import OngOngGroupLogo from "./logo/defaultCompanyLogo.png";
import OrionLogo from "./logo/orion.png";
import OristarLogo from "./logo/defaultCompanyLogo.png";
import OtranGroupLogo from "./logo/otran-group.png";
import PacificElevatorLogo from "./logo/defaultCompanyLogo.png";
import ParagonSaiGonLogo from "./logo/defaultCompanyLogo.png";
import PepperlfuchsVietnamLogo from "./logo/pepperlfuchs-vietnam.png";
import PerfettiVanMelleLogo from "./logo/perfetti-van-melle.png";
import DuLichHoanMyLogo from "./logo/du-lich-hoan-my.png";
import PominaLogo from "./logo/pomina.png";
import PppClinicLogo from "./logo/ppp-clinic.jpg";
import PqcConventionLogo from "./logo/pqc-convention.png";
import RcrVietNamLogo from "./logo/defaultCompanyLogo.png";
import ReeCorporationLogo from "./logo/ree-corporation.png";
import RiconsGroupLogo from "./logo/ricons.png";
import RitaVoLogo from "./logo/rita-vo.png";
import SavillsLogo from "./logo/savills.png";
import SavistaLogo from "./logo/defaultCompanyLogo.png";
import SeareficoLogo from "./logo/searefico.png";
import SendoLogo from "./logo/sendo.png";
import ShbLogo from "./logo/shb.png";
import SjcLogo from "./logo/sjc.png";
import SnbLogo from "./logo/snb.png";
import SodexoLogo from "./logo/sodexo.png";
import SuzukiLogo from "./logo/suzuki.png";
import SymLogo from "./logo/sym.png";
import SyrenaLogo from "./logo/syrena.png";
import TtGroupLogo from "./logo/tt-group.png";
import TapDoanLocTroiLogo from "./logo/tap-doan-loc-troi.png";
import ThaigroupLogo from "./logo/thaigroup.png";
import TheCliffResortResidencesLogo from "./logo/the-cliff-resort-residences.png";
import TheodoreAlexanderLogo from "./logo/theodore-alexander.png";
import ThienHoaLogo from "./logo/thien-hoa.jpg";
import ToyotaBoshokuHanoiLogo from "./logo/toyota-boshoku-hanoi.png";
import TrangTienPlazaLogo from "./logo/defaultCompanyLogo.png";
import TrungTamIcsLogo from "./logo/trung-tam-ics.png";
import TrungThuyGroupLogo from "./logo/trung-thuy-group.png";
import TstTouristLogo from "./logo/tst-tourist.png";
import TapDoanThanhThanhCongTtcGroupLogo from "./logo/tap-doan-thanh-thanh-cong-ttc-group.png";
import ThanhThanhCongBienHoaLogo from "./logo/thanh-thanh-cong---bien-hoa.jpg";
import UberLogo from "./logo/uber.png";
import VnpGroupLogo from "./logo/vnp-group.png";
import VietUcVasLogo from "./logo/viet-uc-vas.png";
import VietnamEsportsLogo from "./logo/vietnam-esports.png";
import VinasoyLogo from "./logo/vinasoy.png";
import VinasunLogo from "./logo/vinasun.png";
import ViphavetAsialandLogo from "./logo/viphavet-asialand.png";
import VpddJcbVietNamLogo from "./logo/vpdd-jcb-viet-nam.png";
import VnpayLogo from "./logo/vnpay2023.png";
import VstvKLogo from "./logo/vstv-k.png";
import WrigleyLogo from "./logo/defaultCompanyLogo.png";
import YchLogo from "./logo/ych.png";
import TtcLandLogo from "./logo/ttc-land.jpg";
import HdSaisonLogo from "./logo/hd-saison.png";
import TapDoanBaoVietLogo from "./logo/tap-doan-bao-viet.png";
import ChungKhoanRongVietLogo from "./logo/chung-khoan-rong-viet.png";
import DuocSohacoLogo from "./logo/duoc-sohaco.png";
import BdoLogo from "./logo/defaultCompanyLogo.png";
import YrIndochinaLogo from "./logo/defaultCompanyLogo.png";
import PhilipsVietnamLogo from "./logo/philips-vietnam.png";
import PacificCrossLogo from "./logo/pacific-cross.png";
import AdayroiLogo from "./logo/a-day-roi.png";
import AegLogo from "./logo/aeg.png";
import BaoTinMinhChauLogo from "./logo/defaultCompanyLogo.png";
import ImtSolutionsLogo from "./logo/defaultCompanyLogo.png";
import VietcharmTravelLogo from "./logo/defaultCompanyLogo.png";
import FocusAsiaTravelLogo from "./logo/defaultCompanyLogo.png";
import FamilymartLogo from "./logo/familymart.png";
import IntimexGroupLogo from "./logo/defaultCompanyLogo.png";
import FiveStarInternationalGroupLogo from "./logo/defaultCompanyLogo.png";
import PhucSinhCorporationLogo from "./logo/defaultCompanyLogo.png";
import VtcDigicomLogo from "./logo/vtc-digicom.png";
import AdivaLogo from "./logo/adiva.png";
import SascoLogo from "./logo/sasco.png";
import DiethelmTravelLogo from "./logo/diethelm-travel.png";
import MasteriseGroupLogo from "./logo/Masterise-Homes2023.png";
import TapDoanBuuChinhVienThongVietNamVnptLogo from "./logo/vnpt.png";
import ScJohnsonSonLogo from "./logo/sc-johnson-son.jpg";
import PharmacityLogo from "./logo/pharmacity.png";
import ThangLoiGroupLogo from "./logo/thang-loi-group.png";
import SixthGearStudiosLogo from "./logo/defaultCompanyLogo.png";
import VoTrongNghiaLogo from "./logo/defaultCompanyLogo.png";
import VinschoolLogo from "./logo/vinschool.jpg";
import VinmecLogo from "./logo/vinmec.jpg";
import VietinBankScLogo from "./logo/vietin-bank-sc.jpg";
import OnsemiLogo from "./logo/defaultCompanyLogo.png";
import MeliaHanoiLogo from "./logo/melia-hanoi.png";
import KangarooVietNamLogo from "./logo/kangaroo-viet-nam.png";
import BlueOceanLogo from "./logo/blue-ocean.png";
import TbsGroupLogo from "./logo/tbs-group.png";
import OmgVietNamLogo from "./logo/omg-viet-nam.jpg";
import RsmVietNamLogo from "./logo/rsm-viet-nam.png";
import CapitalHouseLogo from "./logo/capital-house.png";
import AiTLogo from "./logo/defaultCompanyLogo.png";
import TinNghiaLogo from "./logo/tin-nghia.jpg";
import IdsMedicalLogo from "./logo/ids-medical.jpg";
import KhaiToanGroupLogo from "./logo/khai-toan-group.jpg";
import CapellaHoldingsLogo from "./logo/capella-holdings.png";
import VietThaiQuocTeLogo from "./logo/defaultCompanyLogo.png";
import FoodEmpireLogo from "./logo/food-empire.png";
import Pecc3Logo from "./logo/defaultCompanyLogo.png";
import HongHaFeedLogo from "./logo/hong-ha-feed.png";
import BidvMetlifeLogo from "./logo/bidv-metlife.png";
import CathayPacificLogo from "./logo/cathay-pacific.png";
import CathayLifeLogo from "./logo/cathay-life.png";
import PrevoirLogo from "./logo/prevoir.png";
import SaiGonFoodLogo from "./logo/saigon-food.png";
import FeconLogo from "./logo/fecon.jpg";
import AdpLogo from "./logo/adpgroup2023.png";
import TanCuongMinhLogo from "./logo/tan-cuong-minh.png";
import VietTienGarmentLogo from "./logo/viet-tien-garment.png";
import ChungKhoanBidvLogo from "./logo/chung-khoan-bidv.jpg";
import BidvInsuranceLogo from "./logo/bidv-insurance.jpg";
import ThuanPhatGroupLogo from "./logo/thuan-phat-group.png";
import VissanLogo from "./logo/defaultCompanyLogo.png";
import LouisVuittonLogo from "./logo/louis-vuitton.png";
import DongTienBakeryLogo from "./logo/defaultCompanyLogo.png";
import BaoBiKienVietLogo from "./logo/bao-bi-kien-viet.png";
import SmilevietLogo from "./logo/smileviet.png";
import AmegaTravelLogo from "./logo/amega-travel.jpg";
import SaigontravelLogo from "./logo/saigontravel.jpg";
import RichLogo from "./logo/defaultCompanyLogo.png";
import LenovoLogo from "./logo/lenovo.png";
import CangBienCaiMepCmitLogo from "./logo/cang-bien-cai-mep-cmit.png";
import DetMayThanhCongLogo from "./logo/det-may-thanh-cong.png";
import AnsaradaLogo from "./logo/ansarada.png";
import PierreFabreLogo from "./logo/pierre-fabre.png";
import KidoGroupLogo from "./logo/kido-group.png";
import CentralRetailLogo from "./logo/central-retail.jpg";
import DomescoLogo from "./logo/domesco.jpg";
import StickleyFurnitureLogo from "./logo/stickley-furniture.jpg";
import KienLongBankLogo from "./logo/kien-long-bank.jpg";
import TonDongALogo from "./logo/ton-dong-a.jpg";
import NabatiLogo from "./logo/defaultCompanyLogo.png";
import FarosLogo from "./logo/defaultCompanyLogo.png";
import TheBostonConsultingGroupLogo from "./logo/the-boston-consulting-group.png";
import AsiaDmcHgTravelLogo from "./logo/asia-dmc-hg-travel.jpg";
import PivotalMarketingLogo from "./logo/defaultCompanyLogo.png";
import ToChucGiaoDucYolaLogo from "./logo/yola.png";
import VatGiaLogo from "./logo/defaultCompanyLogo.png";
import AcacyLogo from "./logo/defaultCompanyLogo.png";
import NewVietDairyLogo from "./logo/new-viet-dairy.png";
import AbBankLogo from "./logo/ab-bank.png";
import MappleTreeLogo from "./logo/defaultCompanyLogo.png";
import BaoVietTokioMarineLogo from "./logo/defaultCompanyLogo.png";
import CauTreLogo from "./logo/cau-tre.png";
import EdgeMarketingLogo from "./logo/defaultCompanyLogo.png";
import OcbNganHangPhuongDongLogo from "./logo/ocb-bank.png";
import TttCorporationLogo from "./logo/ttt-corporation.png";
import VictoriaHealthcareLogo from "./logo/victoria-healthcare.png";
import BetrimexLogo from "./logo/defaultCompanyLogo.png";
import CreatioLogo from "./logo/defaultCompanyLogo.png";
import DiroxLogo from "./logo/defaultCompanyLogo.png";
import GeneralMotorsVnLogo from "./logo/defaultCompanyLogo.png";
import PanpagesVnLogo from "./logo/defaultCompanyLogo.png";
import QsrVietnamLogo from "./logo/qsr-vietnam.png";
import SaiGonRdcLogo from "./logo/sai-gon-rdc.png";
import SolidIntenriorLogo from "./logo/defaultCompanyLogo.png";
import TractusLogo from "./logo/tractus.png";
import TvHubLogo from "./logo/defaultCompanyLogo.png";
import SolarbkLogo from "./logo/solarbk.png";
import TienPhuocLogo from "./logo/tien-phuoc.png";
import DetTuongLongLogo from "./logo/det-tuong-long.gif";
import AbsoluteLogo from "./logo/absolute.png";
import GemadeptLogo from "./logo/gemadept.jpg";
import EdoctorLogo from "./logo/edoctor.png";
import UkAcademicLogo from "./logo/uk-academic.jpg";
import BakerMckenzieLogo from "./logo/baker-mckenzie.png";
import HrNaviLogo from "./logo/defaultCompanyLogo.png";
import AahLogo from "./logo/defaultCompanyLogo.png";
import LazadaExpressLogo from "./logo/lazada-express.png";
import NgoHanLogo from "./logo/defaultCompanyLogo.png";
import PjicoLogo from "./logo/pjico.png";
import SikaLogo from "./logo/sika.png";
import NamLongGroupLogo from "./logo/nam-long.png";
import PhatDatRealEstateDevelopmentLogo from "./logo/phat-dat-real-estate-development.png";
import PfizerVietnamLimitedLogo from "./logo/pfizer-vietnam-limited.png";
import ThePanGroupLogo from "./logo/the-pan-group.png";
import EwayLogo from "./logo/eway.png";
import AdtWineArtLogo from "./logo/adt-wine-art.png";
import CmnVietNamLogo from "./logo/defaultCompanyLogo.png";
import BipVietNamLogo from "./logo/defaultCompanyLogo.png";
import AnhQuanStrongLogo from "./logo/defaultCompanyLogo.png";
import AmvGroupLogo from "./logo/defaultCompanyLogo.png";
import TapDoanPhenikaaLogo from "./logo/tap-doan-phenikaa.png";
import NcbLogo from "./logo/ncb.png";
import TecaproLogo from "./logo/tecapro.jpg";
import VnptEpayLogo from "./logo/vnpt-epay.png";
import MbLandLogo from "./logo/mb-land.png";
import FonterraBrandsLogo from "./logo/fonterra-brands.jpg";
import KhangDienLogo from "./logo/khang-dien.jpg";
import CoachLogo from "./logo/coach.png";
import VnttLogo from "./logo/vntt.jpg";
import ImexpharmLogo from "./logo/imexpharm2023.png";
import EmpireCityLogo from "./logo/empire-city.png";
import BaoHiemVietinbankLogo from "./logo/bao-hiem-vietinabank.png";
import NecVietnamLogo from "./logo/nec-vietnam.png";
import TheCoffeeBeanTeaLeafVietnamLogo from "./logo/the-coffee-bean-tea-leaf-vietnam.png";
import TecomenLogo from "./logo/tecomen.png";
import StarbucksCoffeeLogo from "./logo/starbucks-coffee.png";
import MoricoModernJapaneseRestaurantCafeLogo from "./logo/morico-modern-japanese-restaurant-cafe.png";
import CaffeBeneLogo from "./logo/defaultCompanyLogo.png";
import TapDoanThienLongLogo from "./logo/tap-doan-thien-long.png";
import UrgoLogo from "./logo/Urgo2023.jpg";
import DaiVietGroupLogo from "./logo/dai-viet-group.jpg";
import TapDoanHoaPhatLogo from "./logo/tap-doan-hoa-phat.jpg";
import GiayJunoLogo from "./logo/giay-juno.png";
import ScbLogo from "./logo/scb.png";
import BitexLogo from "./logo/bitex.png";
import ScommerceLogo from "./logo/scommerce.png";
import McreditLogo from "./logo/mcredit.png";
import MosantoVietnamLogo from "./logo/defaultCompanyLogo.png";
import VascaraLogo from "./logo/vascara.jpg";
import PayooLogo from "./logo/payoo.jpg";
import ZodiacLogo from "./logo/zodiac.png";
import KtgLogo from "./logo/ktg.jpg";
import NgLogo from "./logo/ng.png";
import FubonLifeInsuranceLogo from "./logo/fubon-life-insurance.png";
import SilaUrbanLivingLogo from "./logo/sila-urban-living.png";
import HighlandsCoffeeLogo from "./logo/highlands-coffee.png";
import SapLabsVietnamLogo from "./logo/sap.png";
import Pcc1Logo from "./logo/pcc1.png";
import BenthanhTouristLogo from "./logo/benthanh-tourist.png";
import SaigonCoopLogo from "./logo/saigon-coop.png";
import SatraLogo from "./logo/satra.jpg";
import VietnamAirlinesLogo from "./logo/vietnam-airlines.png";
import NKidGroupLogo from "./logo/n-kid-group.png";
import AuchanRetailVietnamLogo from "./logo/auchan-retail-vietnam.png";
import EmpireGroupLogo from "./logo/empire-group.png";
import VimedimexLogo from "./logo/vimedimex.jpg";
import HewlettPackardEnterpriseVietnamLogo from "./logo/hewlett-packard-enterprise-vietnam.png";
import AzbLogo from "./logo/azb.png";
import PhamNguyenLogo from "./logo/pham-nguyen.jpg";
import AnGiaLogo from "./logo/an-gia.png";
import IdpLogo from "./logo/idp.jpg";
import SaigontouristLogo from "./logo/saigontourist.jpg";
import CareplusLogo from "./logo/careplus.jpg";
import EurofinsSacKyHaiDangLogo from "./logo/eurofins-sac-ky-hai-dang.png";
import ShsLogo from "./logo/shs.png";
import AntoreeLogo from "./logo/antoree.png";
import EmeraldaGroupLogo from "./logo/emeralda-group.png";
import ImperialGroupLogo from "./logo/imperial-group.png";
import VincommerceLogo from "./logo/vincommerce.png";
import VinecoLogo from "./logo/vineco.png";
import VinpearlLogo from "./logo/vinpearl.png";
import HongLeongBankLogo from "./logo/hong-leong-bank.png";
import HimLamLandLogo from "./logo/him-lam-land.png";
import AmpharcoUsaLogo from "./logo/ampharco-usa.jpg";
import VinfastLogo from "./logo/vinfast.jpg";
import CimbLogo from "./logo/cimb.png";
import TruemoneyLogo from "./logo/truemoney.png";
import MedigroupVietNamLogo from "./logo/medigroup-viet-nam.png";
import BenLineAgenciesLogo from "./logo/ben-line-agencies.jpg";
import NovaonLogo from "./logo/novaon.png";
import CfldLogo from "./logo/cfld.jpg";
import HopTriLogo from "./logo/hop-tri.png";
import DuocTakedaLogo from "./logo/duoc-takeda.png";
import DuocPhamCuuLongLogo from "./logo/duoc-pham-cuu-long.jpg";
import DienLanhBinhMinhLogo from "./logo/dien-lanh-binh-minh.jpg";
import PromanLogo from "./logo/defaultCompanyLogo.png";
import GrundfosLogo from "./logo/grundfos.jpg";
import SeaAirFreightInternationalLogo from "./logo/sea-air-freight-international.png";
import CholimexLogo from "./logo/cholimex.jpg";
import VigilantVideoLogo from "./logo/vigilant-video.png";
import NorfolkMansionLogo from "./logo/norfolk-mansion.jpg";
import GlnEnglishCenterLogo from "./logo/gln-english-center.jpg";
import PentalogLogo from "./logo/pentalog.jpg";
import YTeVietGiaLogo from "./logo/y-te-viet-gia.jpg";
import OrientalLogisticsLogo from "./logo/oriental-logistics.png";
import VietnamAirlinesCaterersLogo from "./logo/vietnam-airlines-caterers.jpg";
import CoduphaLogo from "./logo/codupha.jpg";
import LukesPharmacyLogo from "./logo/lukes-pharmacy.jpg";
import GiayDaMyPhongLogo from "./logo/giay-da-my-phong.png";
import MayVietNamLogo from "./logo/may-viet-nam.jpg";
import VietstarExpressLogo from "./logo/vietstar-express.png";
import NangLuongTaiTaoChauALogo from "./logo/nang-luong-tai-tao-chau-a.jpg";
import M10MayLogo from "./logo/may-10.jpg";
import MayMinhHoangLogo from "./logo/may-minh-hoang.jpg";
import AllerganLogo from "./logo/allergan.png";
import JupiterFoodsVietnamLogo from "./logo/jupiter-foods-vietnam.png";
import PnjpLogo from "./logo/pnjp.jpg";
import RedWokCuisineLogo from "./logo/red-wok-cuisine.png";
import GotecVietNamLogo from "./logo/gotec-viet-nam.png";
import HungThinhCorporationLogo from "./logo/hung-thinh-corporation.jpg";
import MedtronicVietnamLogo from "./logo/medtronic-vietnam.jpg";
import AnPhatPlasticsLogo from "./logo/an-phat-plastics.jpg";
import OpecPlasticsLogo from "./logo/opec-plastics.jpg";
import MasanResourcesLogo from "./logo/masan-resources.jpg";
import Yeah1GroupLogo from "./logo/yeah1-group.jpg";
import TheCoffeeHouseLogo from "./logo/the-coffee-house.png";
import TniCorporationLogo from "./logo/tni-corporation.jpg";
import ShopeeVietNamLogo from "./logo/shopee-viet-nam.jpg";
import AlphaKingLogo from "./logo/alpha-king.jpg";
import ScgLogo from "./logo/scg.png";
import OnTimeWorldwideLogisticsLogo from "./logo/on-time-worldwide-logistics.png";
import TransimexLogo from "./logo/transimex.png";
import SotransLogo from "./logo/sotrans.png";
import DankoLogo from "./logo/danko.jpg";
import TanCangSaiGonSnpLogo from "./logo/tan-cang-sai-gon---snp.png";
import VinalinkLogo from "./logo/vinalink.jpg";
import DacoLogisticsLogo from "./logo/daco-logistics.png";
import MaerskLogo from "./logo/maersk.png";
import OscoInternationalLogo from "./logo/osco-international.png";
import KomatsuVietNamLogo from "./logo/komatsu-viet-nam.png";
import KubotaVietnamLogo from "./logo/kubota-vietnam.png";
import BestariVietNamLogo from "./logo/bestari-viet-nam.png";
import BuhlerVietNamLogo from "./logo/buhler-viet-nam.jpg";
import YamazenVietNamLogo from "./logo/yamazen-viet-nam.jpg";
import CadCamVietNamLogo from "./logo/cad-cam-viet-nam.png";
import NidecVietNamLogo from "./logo/nidec-viet-nam.jpg";
import O4OrangesLogo from "./logo/4-oranges.jpg";
import KovaPaintLogo from "./logo/kova-paint.jpg";
import DaiDongTienLogo from "./logo/dai-dong-tien.png";
import AccAsiaChemicalLogo from "./logo/acc---asia-chemical.png";
import DatVietTourLogo from "./logo/dat-viet-tour.png";
import FiditourLogo from "./logo/fiditour.png";
import AgodaLogo from "./logo/agoda.jpg";
import TravelokaVietNamLogo from "./logo/traveloka-viet-nam.png";
import EnkeiVietNamLogo from "./logo/enkei-viet-nam.jpg";
import SchlemmerVietNamLogo from "./logo/schlemmer-viet-nam.png";
import HaradaIndustriesVietNamLogo from "./logo/harada-industries-viet-nam.png";
import CosmosVietNamLogo from "./logo/cosmos-viet-nam.png";
import CitigymLogo from "./logo/citigym.png";
import MonacoHealthcareLogo from "./logo/monaco-healthcare.jpg";
import FujinetLogo from "./logo/fujinet.png";
import CovisoftLogo from "./logo/covisoft.png";
import IsbVietnamLogo from "./logo/isb-vietnam.jpg";
import LogigearVietNamLogo from "./logo/logigear-viet-nam.png";
import BkavCorporationLogo from "./logo/bkav-corporation.png";
import MpTelecomLogo from "./logo/mp-telecom.jpg";
import KddiVietNamLogo from "./logo/kddi-viet-nam.jpg";
import FujitsuVietNamLogo from "./logo/fujitsu-viet-nam.jpg";
import SaigontelLogo from "./logo/saigontel.jpg";
import GtelLogo from "./logo/gtel.gif";
import TalentnetCorporationLogo from "./logo/talentnet-corporation.jpg";
import NavigosGroupLogo from "./logo/navigos-group.png";
import HiltiLogo from "./logo/hilti.jpg";
import CushmanWakefieldLogo from "./logo/cushman-wakefield.png";
import SapuwaLogo from "./logo/sapuwa.jpg";
import CbBankLogo from "./logo/cb-bank.jpg";
import TetraPakVietnamLogo from "./logo/tetra-pak-vietnam.jpg";
import NewToyoVietnamLogo from "./logo/new-toyo-vietnam.png";
import DxcVietnamLogo from "./logo/dxc-vietnam.png";
import TngLogo from "./logo/tng.png";
import PhanoPharmacityLogo from "./logo/phano-pharmacity.png";
import ApecGroupLogo from "./logo/apec-group.png";
import HyosungVietnamLogo from "./logo/hyosung-vietnam.jpg";
import CognitaVietnamInternationalSchoolHoChiMinhCityLogo from "./logo/cognita-vietnam-international-school-ho-chi-minh-city.jpg";
import CeoGroupLogo from "./logo/ceo-group.png";
import UniconsLogo from "./logo/unicons.png";
import DeltaGroupLogo from "./logo/delta.png";
import NhaThepTriVietLogo from "./logo/thep-tri-viet.png";
import JtiJapanTobaccoInternationalLogo from "./logo/JTI.png";
import DatXanhMienTrungLogo from "./logo/dat-xanh-mien-trung.png";
import EsquelVietNamLogo from "./logo/esquel-viet-nam.png";
import ConCungLogo from "./logo/con-cung.png";
import UrcVietnamLogo from "./logo/urc-vietnam.png";
import UplVietnamLogo from "./logo/upl-vietnam.png";
import NamABankLogo from "./logo/nam-a-bank.png";
import ThpGroupLogo from "./logo/thp-group.png";
import BaoHiemQuanDoiMicLogo from "./logo/bao-hiem-quan-doi---mic.png";
import LuxstayLogo from "./logo/luxstay.png";
import NhaKhoaKimLogo from "./logo/nha-khoa-kim.png";
import ChungKhoanVpsLogo from "./logo/chung-khoan-vps.jpg";
import ElcaVietnamLogo from "./logo/elca-vietnam.png";
import MbAgeasLifeLogo from "./logo/mb-ageas-life.png";
import DkraVietnamLogo from "./logo/dkra-vietnam.png";
import HunghauHoldingsLogo from "./logo/hunghau-holdings.png";
import RenesasDesignVietnamLogo from "./logo/renesas-design-vietnam.png";
import SunAsteriskLogo from "./logo/sun-asterisk.jpg";
import SunhouseLogo from "./logo/sunhouse.png";
import EccoVietnamLogo from "./logo/ecco-vietnam.png";
import PhuongDongLogo from "./logo/y-te-phuong-dong.png";
import AbcBakeryLogo from "./logo/abc-bakery.png";
import Gs25Logo from "./logo/gs25.png";
import BambooAirwaysLogo from "./logo/bamboo-airways.png";
import CitigoVietnamLogo from "./logo/citigo-vietnam.jpg";
import CenGroupJscLogo from "./logo/cen-group.jpg";
import VmedGroupLogo from "./logo/vmed-group.png";
import ViDienTuMomoLogo from "./logo/vi-dien-tu-momo.jpg";
import BaeminVietnamLogo from "./logo/baemin-vietnam.png";
import NganHangSoTimoLogo from "./logo/ngan-hang-so-timo.png";
import FrasersPropertyVietnamFpvLogo from "./logo/frasers-property-vietnam-fpv.jpg";
import LifullTechLogo from "./logo/lifull-tech.png";
import VinsmartLogo from "./logo/vinsmart.jpg";
import FashionGarmentsCoLtdLogo from "./logo/fashion-garments-co-ltd.png";
import NemLienALogo from "./logo/nem-lien-a.png";
import VtcAcademyLogo from "./logo/vtc-academy.png";
import BangkokBankLogo from "./logo/bangkok-bank.jpg";
import TekExpertsLogo from "./logo/tek-experts.png";
import AmanotesLogo from "./logo/amanotes.png";
import JtExpressLogo from "./logo/jt-express.png";
import VingroupLogo from "./logo/vingroup.png";
import NewteconsLogo from "./logo/newtecons.png";
import DavipharmLogo from "./logo/davipharm.jpg";
import ChungKhoanVietcombankVcbsLogo from "./logo/chung-khoan-vietcombank---vcbs.jpg";
import TapDoanKimTinLogo from "./logo/kim-tin-investment-corp.png";
import AstellasPharmaVietnamLogo from "./logo/astellas-pharma-vietnam.jpg";
import DkshSmollanLogo from "./logo/dksh-smollan.png";
import GojekVietnamLogo from "./logo/gojek-vietnam.png";
import OneMountGroupLogo from "./logo/one-mount-group.png";
import HungThinhLandLogo from "./logo/hung-thinh-land.png";
import HungThinhInconsLogo from "./logo/hung-thinh-incons.png";
import SailunVietNamLogo from "./logo/sailun-viet-nam.png";
import HandongEcLogo from "./logo/handong-ec.png";
import StellaLogo from "./logo/stella.png";
import JabilLogo from "./logo/jabil-vietnam.png";
import KimOanhGroupLogo from "./logo/kim-oanh.jpg";
import SpartronicsLogo from "./logo/spartronics.png";
import CatTuongGroupLogo from "./logo/cat-tuong-group.jpg";
import PopsworldwideLogo from "./logo/popsworldwide.png";
import VtvLogo from "./logo/vtv.png";
import LienvietpostbankLogo from "./logo/lienvietpostbank.png";
import CjFoodsVietnamLogo from "./logo/cj-foods-vietnam.png";
import DienQuangLogo from "./logo/dien-quang.jpg";
import ChungKhoanThienVietTvsLogo from "./logo/chung-khoan-thien-viet-tvs.png";
import SovicoGroupLogo from "./logo/sovico-group.png";
import SapoLogo from "./logo/sapo.png";
import SmartoscLogo from "./logo/smartosc.png";
import VtiLogo from "./logo/vti.jpg";
import PhanBonDauKhiCaMauPvcfcLogo from "./logo/phan-bon-dau-khi-ca-mau-pvcfc.png";
import LocHoaDauBinhSonBsrLogo from "./logo/loc-hoa-dau-binh-son-bsr.jpg";
import TerumoVietNamLogo from "./logo/terumo-viet-nam.jpg";
import VnvcLogo from "./logo/vnvc.png";
import DiaOcPhuLongLogo from "./logo/dia-oc-phu-long.png";
import BwIndustrialLogo from "./logo/bw-industrial.png";
import GiaoHangTietKiemLogo from "./logo/giao-hang-tiet-kiem.png";
import GumacLogo from "./logo/gumac.jpg";
import SavicoLogo from "./logo/savico.png";
import SunshineGroupLogo from "./logo/sunshine-group.jpg";
import DnpCorpLogo from "./logo/dnp-corp.png";
import SonToaVietNamLogo from "./logo/son-toa-viet-nam.png";
import ApolloSiliconeLogo from "./logo/apollo-silicone.png";
import CmcTileLogo from "./logo/cmc-tile.jpg";
import NhaThuocLongChauLogo from "./logo/nha-thuoc-long-chau.png";
import NhaThuocAnKhangLogo from "./logo/nha-thuoc-an-khang.png";
import TapDoanEcoparkLogo from "./logo/tap-doan-ecopark.png";
import VinamitLogo from "./logo/vinamit.jpg";
import VieonLogo from "./logo/vieon.jpg";
import NhuaDongNaiLogo from "./logo/nhua-dong-nai.png";
import EquestEducationGroupLogo from "./logo/equest-education-group.png";
import NguyenHoangGroupLogo from "./logo/nguyen-hoang-group.png";
import TracodiLogo from "./logo/tracodi.png";
import BambooCapitalGroupBcgLogo from "./logo/bamboo-capital-group-bcg.png";
import EisaiVietNamLogo from "./logo/eisai-viet-nam.png";
import F88Logo from "./logo/f88.png";
import HenkelAdhesiveTechnologiesLogo from "./logo/henkel.png";
/// New

import DuocThaiMinhLogo from "./logo/duoc-pham-thai-minh.png";
import GoertekVietnamLogo from "./logo/goertek-vietnam.png";
import NovaEvergreenLogo from "./logo/nova-evergreen.png";
import PernodRicardVietnamLogo from "./logo/pernod-ricard-vietnam.png";
import TapDoanDanhKhoiLogo from "./logo/tap-doan-danh-khoi.png";
import VanPhuInvestVpiLogo from "./logo/van-phu-invest-vpi.png";
import ViettelConstructionLogo from "./logo/viettel-construction.png";
import VuaNemLogo from "./logo/vua-nem.png";
import CentralconsLogo from "./logo/centralcons.png";
import GuardianVietnamLogo from "./logo/guardian-vietnam.jpg";
import NovaGroupLogo from "./logo/novagroup.png";
import GGroupLogo from "./logo/tap-doan-cong-nghe-g-group.png";
import OpenasiaGroupLogo from "./logo/OneOpenasia2023.png";
import TheColorClubLogo from "./logo/the-color-club.png";
import PROFECTUSVIETNAMLogo from "./logo/Profectus.png";
import SunHospitalityGroupLogo from "./logo/Sun-Hospitality-Group.png";
import SunWorldLogo from "./logo/Sun-World.png";
import BVDHYDUOOCLogo from "./logo/benh-vien-dai-hoc-y-duoc.jpg";
import ViettelCommerceLogo from "./logo/ViettelCommerce.png";
import TNGHoldingLogo from "./logo/tng-holdings.png";
import AOSmithLogo from "./logo/aosmith.jpg";
import VASGroupLogo from "./logo/VASGROUP.png";
import BMBSteelLogo from "./logo/bmbsteel.png";
import GoTruongThanhLogo from "./logo/gotruongthanh.png";
import NamDuocLogo from "./logo/nam-duoc.png";
import HaleonLogo from "./logo/haleon2023.png";
import NhatTinLogo from "./logo/nhattin2023.png";


let companyLogoList = {};
companyLogoList[1] = DaiIchiLifeVietnamLogo;

companyLogoList[2] = FptCorporationLogo;

companyLogoList[3] = FptInformationSystemLogo;

companyLogoList[4] = FptOnlineLogo;

companyLogoList[5] = FptTelecomLogo;

companyLogoList[6] = FptSoftwareLogo;

companyLogoList[7] = NipponPaintVietnamLogo;

companyLogoList[8] = RedBullLogo;

companyLogoList[9] = SabmillerLogo;

companyLogoList[10] = TriumphVietNamLogo;

companyLogoList[11] = VinacapitalLogo;

companyLogoList[12] = WallStreetEnglishLogo;

companyLogoList[13] = CbreVietnamLogo;

companyLogoList[14] = ColgatePalmoliveVietnamLogo;

companyLogoList[15] = KfcVietnamLogo;

companyLogoList[16] = FujiXeroxLogo;

companyLogoList[17] = NutifoodLogo;

companyLogoList[18] = MatBaoLogo;

companyLogoList[19] = ChubbLifeLogo;

companyLogoList[20] = CoficoLogo;

// companyLogoList[21] = DanoneDumexVietnamLogo;

companyLogoList[22] = DkEngineeringLogo;

companyLogoList[23] = EvolableLogo;

companyLogoList[24] = GeneraliVietnamLogo;

companyLogoList[25] = McdonaldsVietNamLogo;

companyLogoList[26] = HafeleVietnamLlcLogo;

companyLogoList[27] = VietjetAirLogo;

companyLogoList[28] = HdBankLogo;

companyLogoList[29] = InternationalSosVietNamLogo;

companyLogoList[30] = LotteriaLogo;

companyLogoList[31] = LotteMartLogo;

companyLogoList[32] = LotteLegendLogo;

companyLogoList[33] = LotteCinemaLogo;

companyLogoList[34] = MekongCapitalLogo;

companyLogoList[35] = MillwardBrownLogo;

companyLogoList[36] = MonsantoVietnamLogo;

companyLogoList[37] = NsBluescopeSteelLogo;

companyLogoList[38] = ParksonLogo;

companyLogoList[39] = SchaefflerVietNamLogo;

companyLogoList[40] = SchenkerLogo;

companyLogoList[41] = ShinhanBankLogo;

companyLogoList[42] = SquareGroupLogo;

companyLogoList[43] = NganHangAChauLogo;

companyLogoList[44] = AustdoorLogo;

companyLogoList[45] = ChoTotLogo;

companyLogoList[46] = CircleKLogo;

companyLogoList[47] = DongwhaLogo;

companyLogoList[48] = EsteeLauderLogo;

companyLogoList[49] = ExoTravelLogo;

companyLogoList[50] = GoldenGateGroupLogo;

companyLogoList[51] = HabitatForHumanityInternationalInVietnamLogo;

companyLogoList[52] = NghiaNippersLogo;

companyLogoList[53] = VietLotusLogo;

companyLogoList[54] = BayerVietnamLogo;

companyLogoList[55] = VmgMediaLogo;

companyLogoList[56] = BelVietnamLogo;

companyLogoList[57] = XoneFmLogo;

companyLogoList[58] = MasanNutriScienceLogo;

companyLogoList[59] = GoldenCommunicationGroupLogo;

companyLogoList[60] = MinhDatFoodLogo;

companyLogoList[61] = IsuzuLogo;

companyLogoList[62] = SantaFeReloLogo;

companyLogoList[63] = BridgestoneTireSalesVietnamLogo;

companyLogoList[64] = MjGroupLogo;

companyLogoList[65] = CacCorporationLogo;

companyLogoList[66] = OoclLogo;

companyLogoList[67] = UsgBoralVietNamLogo;

companyLogoList[68] = AbbottLaboratoriesGmbhLogo;

companyLogoList[69] = AiaVietnamLogo;

companyLogoList[70] = AmwayVietnamLogo;

companyLogoList[71] = AnovaCorpLogo;

companyLogoList[72] = AveryDennisonLogo;

companyLogoList[73] = HeinekenHanoiLogo;

companyLogoList[74] = BatVietnamLogo;

companyLogoList[75] = BeiersdorfVietnamLogo;

companyLogoList[76] = CmcCorporationLogo;

companyLogoList[77] = CocaColaVietnamLogo;

companyLogoList[78] = ToHopDatvietvacLogo;

companyLogoList[79] = InseeVietnamLogo;

companyLogoList[80] = GeVietnamLogo;

companyLogoList[81] = IntelVietnamLogo;

companyLogoList[82] = JohnsonJohnsonLogo;

companyLogoList[83] = KimberlyClarkVietnamLogo;

companyLogoList[84] = MondelezKinhDoLogo;

companyLogoList[85] = LorealVietnamLogo;

companyLogoList[86] = MasanGroupLogo;

companyLogoList[87] = MmMegaMarketVietnamLogo;

companyLogoList[88] = NestleVietnamLogo;

companyLogoList[89] = NguyenKimLogo;

companyLogoList[90] = NovalandGroupLogo;

companyLogoList[91] = PepsicoFoodsVietnamCompanyLogo;

companyLogoList[92] = PizzaVietnamLogo;

companyLogoList[93] = PrudentialFinanceLogo;

companyLogoList[94] = PrudentialVietnamLogo;

companyLogoList[95] = NumberOneLogo;

companyLogoList[96] = ThMilkLogo;

companyLogoList[97] = SamsungLogo;

companyLogoList[98] = SanofiVietnamLogo;

companyLogoList[99] = SuntoryPepsicoVietnamLogo;

companyLogoList[100] = UnileverLogo;

companyLogoList[101] = VinhomesLogo;

companyLogoList[102] = VinatabaPhilipMorrisLogo;

companyLogoList[103] = HeinekenVietnamLogo;

companyLogoList[104] = VinamilkLogo;

companyLogoList[105] = VngLogo;

companyLogoList[106] = PaceEducationGroupLogo;

companyLogoList[107] = BiominLogo;

companyLogoList[108] = GameloftLogo;

companyLogoList[109] = GlaxosmithklineGskLogo;

companyLogoList[110] = GroupmLogo;

companyLogoList[111] = HtcLogo;

companyLogoList[112] = MobifoneLogo;

companyLogoList[113] = MsMSDMerckSharpDohmeAsiaLtdLogo;

companyLogoList[114] = MicrosoftVietnamLogo;

companyLogoList[115] = PrimeGroupLogo;

companyLogoList[116] = RocheVietnamLogo;

companyLogoList[117] = SsiLogo;

companyLogoList[118] = SunGroupLogo;

companyLogoList[119] = UndpVietnamLogo;

companyLogoList[120] = VietnamobileLogo;

companyLogoList[121] = ViglaceraLogo;

companyLogoList[122] = VinaphoneLogo;

companyLogoList[123] = YamahaLogo;

companyLogoList[124] = KmsTechnologyLogo;

companyLogoList[125] = H24hLogo;

companyLogoList[126] = AbInbevVietNamLogo;

companyLogoList[127] = AprotrainLogo;

companyLogoList[128] = AsiafoodsLogo;

companyLogoList[129] = BbraunVietnamLogo;

companyLogoList[130] = BbdoVietnamLogo;

companyLogoList[131] = BhdstarLogo;

companyLogoList[132] = CapgeminiVietnamLogo;

companyLogoList[133] = CarlsbergVietnamLogo;

companyLogoList[134] = DellLogo;

companyLogoList[135] = DojiGroupLogo;

companyLogoList[136] = DoosanLogo;

companyLogoList[137] = ErcInternationalLogo;

companyLogoList[138] = GalaxyCinemaLogo;

companyLogoList[139] = GmVietnamLogo;

companyLogoList[140] = HoneywellInternationalLogo;

companyLogoList[141] = LanguageLinkLogo;

companyLogoList[142] = NokiaSiemensNetworksLogo;

companyLogoList[143] = OracleVietnamLogo;

companyLogoList[144] = QualcommInternationalLogo;

companyLogoList[145] = TotoLogo;

companyLogoList[146] = VsipLogo;

companyLogoList[147] = HoaBinhConstructionGroupLogo;

companyLogoList[148] = ErnstYoungVietnamLimitedLogo;

companyLogoList[149] = BritishCouncilLogo;

companyLogoList[150] = CanonVietnamLogo;

companyLogoList[151] = CheilVietnamLogo;

companyLogoList[152] = DwpLogo;

companyLogoList[153] = EximbankLogo;

companyLogoList[154] = DkshLogo;

companyLogoList[155] = GedeonRichterLogo;

companyLogoList[156] = OppoVietnamLogo;

companyLogoList[157] = SiemensLogo;

companyLogoList[158] = ElectroluxVietNamLogo;

companyLogoList[159] = SonionLogo;

companyLogoList[160] = AirLiquideVietnamLogo;

companyLogoList[161] = StandardCharteredBankLogo;

companyLogoList[162] = FedexLogo;

companyLogoList[163] = GrantThorntonLogo;

companyLogoList[164] = ZamilSteelLogo;

companyLogoList[165] = TraphacoLogo;

companyLogoList[166] = LgVinaCosmeticsLogo;

companyLogoList[167] = EricssonVietnamLogo;

companyLogoList[168] = DetMayThygesenVietnamLogo;

companyLogoList[169] = DicentralVietnamLogo;

companyLogoList[170] = JotunVietnamLogo;

companyLogoList[171] = TmaSolutionsLogo;

companyLogoList[172] = RmitLogo;

companyLogoList[173] = SerenityHoldingVietnamLogo;

companyLogoList[174] = TikiLogo;

companyLogoList[175] = TribecoLogo;

companyLogoList[176] = ThepVinaKyoeiLogo;

companyLogoList[177] = ArchetypeVietnamLogo;

companyLogoList[178] = AristonLogo;

companyLogoList[179] = DaikinVietnamLogo;

companyLogoList[180] = DhgPharmaLogo;

companyLogoList[181] = PeacesoftLogo;

companyLogoList[182] = OgilvyGroupLogo;

companyLogoList[183] = VinafcoLogo;

companyLogoList[184] = TascoLogo;

companyLogoList[185] = ThanhCongMobileLogo;

companyLogoList[186] = BdsVanPhatHungLogo;

companyLogoList[187] = ExxonmobilLogo;

companyLogoList[188] = DinhQuocCorpLogo;

companyLogoList[189] = VcliLogo;

companyLogoList[190] = VinabookLogo;

companyLogoList[191] = ElisoftLogo;

companyLogoList[192] = SovigazLogo;

companyLogoList[193] = AirWaterVietnamLogo;

companyLogoList[194] = MesserVietnamLogo;

companyLogoList[195] = AkzonobelVietnamLogo;

companyLogoList[196] = AmericanStandardVietnamLogo;

companyLogoList[197] = LixilVietNamLogo;

companyLogoList[198] = AnzBankVietnamLogo;

companyLogoList[199] = BrgGroupLogo;

companyLogoList[200] = CgvVietnamLogo;

companyLogoList[201] = GamudaLandVietnamLogo;

companyLogoList[202] = NashtechLogo;

companyLogoList[203] = HellaVietnamLogo;

companyLogoList[204] = IbmVietnamLogo;

companyLogoList[205] = IlaVietnamLogo;

companyLogoList[206] = IndochinaCapitalVietnamLogo;

companyLogoList[207] = IntercontinentalSaigonHotelLogo;

companyLogoList[208] = KeppelLandVietnamLogo;

companyLogoList[209] = KirbySoutheastAsiaVietnamLogo;

companyLogoList[210] = LibertyInsuranceVietnamLogo;

companyLogoList[211] = MaiLinhGroupLogo;

companyLogoList[212] = MeadJohnsonNutritionVietnamLogo;

companyLogoList[213] = PricewaterhousecoopersPwcLogo;

companyLogoList[214] = SherwinWilliamsVietnamLogo;

companyLogoList[215] = ThienMinhGroupLogo;

companyLogoList[216] = TmsGroupLogo;

companyLogoList[217] = VcCorpLogo;

companyLogoList[218] = VibBankLogo;

companyLogoList[219] = VienThongALogo;

companyLogoList[220] = VilubeLogo;

companyLogoList[221] = YanTvLogo;

companyLogoList[222] = CtGroupLogo;

companyLogoList[223] = DiageoVietnamLogo;

companyLogoList[224] = FosterVietnamLogo;

companyLogoList[225] = HanhPhucHospitalLogo;

companyLogoList[226] = HscInvestmentGroupLogo;

companyLogoList[227] = LuatMayerBrownJsmVietnamLogo;

companyLogoList[228] = MsbLogo;

companyLogoList[229] = PullmanLogo;

companyLogoList[230] = PumaLogo;

companyLogoList[231] = VpBankLogo;

companyLogoList[232] = AbbLtdLogo;

companyLogoList[233] = NikeVietnamLogo;

companyLogoList[234] = CargillVietnamLogo;

companyLogoList[235] = GrabLogo;

companyLogoList[236] = NielsenVietnamLogo;

companyLogoList[237] = SonkimLandLogo;

companyLogoList[238] = FvHospitalLogo;

companyLogoList[239] = TruongHaiAutoLogo;

companyLogoList[240] = AcecookVietNamLogo;

companyLogoList[241] = SchindlerVietnamLogo;

companyLogoList[242] = HoaSenGroupLogo;

companyLogoList[243] = OnSemiconductorVietnamLogo;

companyLogoList[244] = MaricoSouthEastAsiaLogo;

companyLogoList[245] = PnjLogo;

companyLogoList[246] = TechcombankLogo;

companyLogoList[247] = LazadaVietnamLogo;

companyLogoList[248] = PiaggioVietnamLogo;

companyLogoList[249] = PanasonicLogo;

companyLogoList[250] = FrieslandcampinaVietnamLogo;

companyLogoList[251] = MercedesBenzVietnamLogo;

companyLogoList[252] = CongTyHondaVietNamLogo;

companyLogoList[253] = BoschVietnamLogo;

companyLogoList[254] = TheGioiDiDongLogo;

companyLogoList[255] = NewWorldSaigonHotelLogo;

companyLogoList[256] = SaintGobainVietnamLogo;

companyLogoList[257] = SapporoLogo;

companyLogoList[258] = EdelmanLogo;

companyLogoList[259] = ToyotaMotorVietnamLogo;

companyLogoList[260] = ManulifeLogo;

companyLogoList[261] = PycoGroupLogo;

companyLogoList[262] = BasfVietnamLogo;

companyLogoList[263] = ViettelGroupLogo;

companyLogoList[264] = SacombankLogo;

companyLogoList[265] = BidvLogo;

companyLogoList[266] = PviLogo;

companyLogoList[267] = VietravelLogo;

companyLogoList[268] = UnicharmLogo;

companyLogoList[269] = KantarTnsLogo;

companyLogoList[270] = AnnamGroupLogo;

companyLogoList[271] = SaveTheChildrenLogo;

companyLogoList[272] = VndirectLogo;

companyLogoList[273] = CambridgeUniversityLogo;

companyLogoList[274] = VusLogo;

companyLogoList[275] = ApolloLogo;

companyLogoList[276] = WhitePalaceLogo;

companyLogoList[277] = VietnamFanLogo;

companyLogoList[278] = JollibeeLogo;

companyLogoList[279] = HanwhaLifeVietNamLogo;

companyLogoList[280] = AplLogo;

companyLogoList[281] = MolLogisticLogo;

companyLogoList[282] = EquatorialHcmLogo;

companyLogoList[283] = LevisLogo;

companyLogoList[284] = BrotherVietnamLogo;

companyLogoList[285] = DanieliLogo;

companyLogoList[286] = JonesLangLasalleLogo;

companyLogoList[287] = RingierLogo;

companyLogoList[288] = CanifaLogo;

companyLogoList[289] = IqviaVietnamLogo;

companyLogoList[290] = AccorHotelsLogo;

companyLogoList[291] = BigCVietnamLogo;

companyLogoList[292] = DeloitteVietNamLogo;

companyLogoList[293] = VinhTuongLogo;

companyLogoList[294] = AstrazenecaVietnamLogo;

companyLogoList[295] = ShellLogo;

companyLogoList[296] = FordVietNamLogo;

companyLogoList[297] = CpGroupLogo;

companyLogoList[298] = CapitalandVietnamLogo;

companyLogoList[299] = M3mVietNamLogo;

companyLogoList[300] = WiproConsumerCareVietNamLogo;

companyLogoList[301] = DhlLogo;

companyLogoList[302] = DhlSupplyChainLogo;

companyLogoList[303] = SmartTrainLogo;

companyLogoList[304] = IdgVenturesVietnamLogo;

companyLogoList[305] = SonyElectronicsVietNamLogo;

companyLogoList[306] = ToshibaLogo;

companyLogoList[307] = BachySoletancheVietnamLogo;

companyLogoList[308] = DecathlonLogo;

companyLogoList[309] = GlobalCybersoftAHitachiConsultingCompanyLogo;

companyLogoList[310] = ImperialTobaccoLogo;

companyLogoList[311] = ThaiTuanTextileGarmentLogo;

companyLogoList[312] = TntExpressLogo;

companyLogoList[313] = TotalVietnamLogo;

companyLogoList[314] = TrgInternationalLogo;

companyLogoList[315] = TrungNguyenLogo;

companyLogoList[316] = CaloficLogo;

companyLogoList[317] = NuskinLogo;

companyLogoList[318] = SyngentaVietnamLogo;

companyLogoList[319] = BtmGlobalConsultingLogo;

companyLogoList[320] = DdbLogo;

companyLogoList[321] = AmccAppliedMicroCircuitsCorporationLogo;

companyLogoList[322] = SeabankLogo;

companyLogoList[323] = GreenfeedVietnamCorporationLogo;

companyLogoList[324] = CimigoVietNamLogo;

companyLogoList[325] = DentsuVietnamLogo;

companyLogoList[326] = PebSteelLogo;

companyLogoList[327] = ChicilonMediaLogo;

companyLogoList[328] = KumhoEngineeringConstructionLogo;

companyLogoList[329] = LeoBurnettLogo;

companyLogoList[330] = TheAscottLogo;

companyLogoList[331] = GlobalInsuranceJscLogo;

companyLogoList[332] = ChinfonLogo;

companyLogoList[333] = UnibenLogo;

companyLogoList[334] = ServierVietnamLogo;

companyLogoList[335] = JetstarPacificLogo;

companyLogoList[336] = CiscoVietnamLogo;

companyLogoList[337] = ChevronVietnamLogo;

companyLogoList[338] = YusenVietnamLogo;

companyLogoList[339] = HabecoLogo;

companyLogoList[340] = BitisLogo;

companyLogoList[341] = EgameLogo;

companyLogoList[342] = CaliforniaFitnessYogaLogo;

companyLogoList[343] = HsbcVietnamLogo;

companyLogoList[344] = NovartisVietnamLogo;

companyLogoList[345] = AbbvieVietnamLogo;

companyLogoList[346] = AigVietnamLogo;

companyLogoList[347] = VietnamnetGroupLogo;

companyLogoList[348] = VietinbankLogo;

companyLogoList[349] = AvivaVietnamLogo;

companyLogoList[350] = VietCapitalSecuritiesLogo;

companyLogoList[351] = PurpleAsiaLogo;

companyLogoList[352] = KpmgInVietnamLogo;

companyLogoList[353] = KirinVietnamLogo;

companyLogoList[354] = GfkAsiaLogo;

companyLogoList[355] = AdidasVietnamLogo;

companyLogoList[356] = AonVietnamLogo;

companyLogoList[357] = CastrolBpPetcoLogo;

companyLogoList[358] = CocCocLogo;

companyLogoList[359] = AaaInsuranceLogo;

companyLogoList[360] = VietcapitalBankLogo;

companyLogoList[361] = VifonLogo;

companyLogoList[362] = PhuMyHungLogo;

companyLogoList[363] = ChungKhoanBaoVietBvscLogo;

companyLogoList[364] = CitibankVietnamLogo;

companyLogoList[365] = EdgeAsiaLogo;

companyLogoList[366] = GoldsunFocusMediaLogo;

companyLogoList[367] = HappyCookLogo;

companyLogoList[368] = HoanMyLogo;

companyLogoList[369] = IndovinaBankLogo;

companyLogoList[370] = IntageVietnamLogo;

companyLogoList[371] = KaoVietnamLogo;

companyLogoList[372] = OperationSmileVietnamLogo;

companyLogoList[373] = ParkHyattSaigonLogo;

companyLogoList[374] = SanMiguelBreweryVietnamLogo;

companyLogoList[375] = SharpVietNamLogo;

companyLogoList[376] = UpsLogo;

companyLogoList[377] = FujifilmVietNamLogo;

companyLogoList[378] = LindeGasVietnamLogo;

companyLogoList[379] = CleverlearnLogo;

companyLogoList[380] = CscVietnamLogo;

companyLogoList[381] = CotecGroupLogo;

companyLogoList[382] = ItdGroupLogo;

companyLogoList[383] = ImvLogo;

companyLogoList[384] = ConnellBrosCompanyLogo;

companyLogoList[385] = CungMuaLogo;

companyLogoList[386] = FwdVietnamLogo;

companyLogoList[387] = AaCorporationLogo;

companyLogoList[388] = MikCorporationLogo;

companyLogoList[389] = AnovaMilkLogo;

companyLogoList[390] = EcoPharmaLogo;

companyLogoList[391] = EliteFitnessLogo;

companyLogoList[392] = SheratonHotelLogo;

companyLogoList[393] = BaoHiemBaoVietLogo;

companyLogoList[394] = LingoLogo;

companyLogoList[395] = TopicaLogo;

companyLogoList[396] = JwalterThompsonJwtLogo;

companyLogoList[397] = SabecoLogo;

companyLogoList[398] = SaoBacDauLogo;

companyLogoList[399] = SunFlowerLogo;

companyLogoList[400] = VietnamStarAutomobileLogo;

companyLogoList[401] = BitexcoGroupLogo;

companyLogoList[402] = SsgGroupLogo;

companyLogoList[403] = RussinVecchiLogo;

companyLogoList[404] = ZuelligPharmaLogo;

companyLogoList[405] = MitsubishiMotorsVietNamLogo;

companyLogoList[406] = UnitedInternationalPharmaUipLogo;

companyLogoList[407] = UniPresidentLogo;

companyLogoList[408] = ProcterGambleVietnamPgLogo;

companyLogoList[409] = VietcombankLogo;

companyLogoList[410] = AnphabeLogo;

companyLogoList[411] = StarwoodLogo;

companyLogoList[412] = CongTyNhuaDuyTanLogo;

companyLogoList[413] = NctCorporationLogo;

companyLogoList[414] = AldoLogo;

companyLogoList[415] = PhucLongCoffeeTeaLogo;

companyLogoList[416] = RichfieldLogo;

companyLogoList[417] = AureconGroupLogo;

companyLogoList[418] = CjVinaAgriLogo;

companyLogoList[419] = DaleCarnegieLogo;

companyLogoList[420] = DamcoLogo;

companyLogoList[421] = MisaLogo;

companyLogoList[422] = HomeCreditLogo;

companyLogoList[423] = SonkimFashionLogo;

companyLogoList[424] = EverpiaVietnamLogo;

companyLogoList[425] = CotecconsGroupLogo;

companyLogoList[426] = AmericanRedCrossLogo;

companyLogoList[427] = FahasaLogo;

companyLogoList[428] = ScancomLogo;

companyLogoList[429] = SaigonBankLogo;

companyLogoList[430] = DalatHasfarmLogo;

companyLogoList[431] = NewBalanceVietnamLogo;

companyLogoList[432] = NamkwangEngineeringAndConstructionLogo;

companyLogoList[433] = HoangAnhLogo;

companyLogoList[434] = SunLifeVietNamLogo;

companyLogoList[435] = PetroVietnamLogo;

companyLogoList[436] = BonfiglioliVietnamLogo;

companyLogoList[437] = DaiQuangMinhLogo;

companyLogoList[438] = NshapeFitnessLogo;

companyLogoList[439] = Fit24Logo;

companyLogoList[440] = GetFitLogo;

companyLogoList[441] = TpbankLogo;

companyLogoList[442] = MbbankLogo;

companyLogoList[443] = SchneiderElectricVietNamLogo;

companyLogoList[444] = AnovaFeedLogo;

companyLogoList[445] = VfCorporationLogo;

companyLogoList[446] = MitsubishiElectricVietnamLogo;

companyLogoList[447] = LgElectricLogo;

companyLogoList[448] = CarrierLogo;

companyLogoList[449] = UnderArmourLogo;

companyLogoList[451] = FptTradingLogo;

companyLogoList[452] = TheBodyShopLogo;

companyLogoList[453] = LaureltonDiamondsLogo;

companyLogoList[454] = LaVieLogo;

companyLogoList[455] = AcfcLogo;

companyLogoList[456] = AdcGroupLogo;

companyLogoList[457] = AeonVietnamLogo;

companyLogoList[458] = AeonMallLogo;

companyLogoList[459] = ApaxEnglishLogo;

companyLogoList[460] = AsiaShineLogo;

companyLogoList[461] = AsusLogo;

companyLogoList[462] = AudiLogo;

companyLogoList[463] = BcgLogo;

companyLogoList[464] = BehnMeyerAgricareLogo;

companyLogoList[465] = BenThanhGroupLogo;

companyLogoList[466] = BhsLogo;

companyLogoList[467] = BimGroupLogo;

companyLogoList[468] = BitasLogo;

companyLogoList[469] = BluescopeBuildingsVietnamLogo;

companyLogoList[470] = BmwEuroAutoLogo;

companyLogoList[471] = BolloreLogo;

companyLogoList[472] = BureauVeritasLogo;

companyLogoList[473] = CaravelleHotelLogo;

companyLogoList[474] = CbiIpsosLogo;

companyLogoList[475] = CongTyTaiChinhCoPhanTinVietVietcreditLogo;

companyLogoList[476] = CihLogo;

companyLogoList[477] = CiticomLogo;

companyLogoList[479] = CmcTelecomLogo;

companyLogoList[480] = CoatsPhongPhuLogo;

companyLogoList[481] = IndoTransLogisticsLogo;

companyLogoList[482] = ControlUnionVietnamLogo;

companyLogoList[483] = CroweHorwathLogo;

companyLogoList[484] = DatXanhGroupLogo;

companyLogoList[485] = DatalogicLogo;

companyLogoList[486] = DeHeusLogo;

companyLogoList[487] = DekTechnologiesLogo;

companyLogoList[488] = DesconLogo;

companyLogoList[489] = DongABankLogo;

companyLogoList[490] = DongTamLogo;

companyLogoList[491] = DuocHoaThienPhuLogo;

companyLogoList[492] = ElcomLogo;

companyLogoList[493] = EMartVietNamLogo;

companyLogoList[495] = EsprintaVietNamLogo;

companyLogoList[496] = EurowindowLogo;

companyLogoList[497] = FeCreditLogo;

companyLogoList[498] = FlcGroupLogo;

companyLogoList[499] = GinkgoLogo;

companyLogoList[500] = GsEcLogo;

companyLogoList[501] = HanesbrandsLogo;

companyLogoList[502] = HansaeVnLogo;

companyLogoList[503] = HerbalifeLogo;

companyLogoList[504] = HoaSenUniversityLogo;

companyLogoList[505] = HoangQuanGroupLogo;

companyLogoList[506] = HuaweiLogo;

companyLogoList[507] = HungVuongGroupLogo;

companyLogoList[508] = HuyndaiKeficoLogo;

companyLogoList[509] = IiViVietNamLogo;

companyLogoList[510] = IkeaVietNamLogo;

companyLogoList[511] = ImgLogo;

companyLogoList[512] = ImmGroupLogo;

companyLogoList[513] = JxNipponOilEnergyLogo;

companyLogoList[514] = KarcherLogo;

companyLogoList[515] = KuehnenagelLogo;

companyLogoList[516] = KumhoTireVnLogo;

companyLogoList[517] = LeMeridienHotelLogo;

companyLogoList[518] = LeflairLogo;

companyLogoList[519] = LiksinLogo;

companyLogoList[520] = LinVietNamLogo;

companyLogoList[521] = LotusGroupLogo;

companyLogoList[522] = LuxoftLogo;

companyLogoList[523] = MaisonLogo;

companyLogoList[524] = JwMarriottHotelLogo;

companyLogoList[525] = MetanLogo;

companyLogoList[526] = MekongEnergyLogo;

companyLogoList[527] = MinhHoangGarmentLogo;

companyLogoList[528] = MundipharmaVietnamLogo;

companyLogoList[529] = NganHangBaoVietLogo;

companyLogoList[530] = NganHangVietALogo;

companyLogoList[531] = NhuaRangDongLogo;

companyLogoList[532] = NovoNordiskLogo;

companyLogoList[533] = OlamVietnamLogo;

companyLogoList[534] = OngOngGroupLogo;

companyLogoList[535] = OrionLogo;

companyLogoList[536] = OristarLogo;

companyLogoList[537] = OtranGroupLogo;

companyLogoList[538] = PacificElevatorLogo;

companyLogoList[539] = ParagonSaiGonLogo;

companyLogoList[540] = PepperlfuchsVietnamLogo;

companyLogoList[541] = PerfettiVanMelleLogo;

companyLogoList[542] = DuLichHoanMyLogo;

companyLogoList[543] = PominaLogo;

companyLogoList[544] = PppClinicLogo;

companyLogoList[545] = PqcConventionLogo;

companyLogoList[546] = RcrVietNamLogo;

companyLogoList[547] = ReeCorporationLogo;

companyLogoList[548] = RiconsGroupLogo;

companyLogoList[549] = RitaVoLogo;

companyLogoList[550] = SavillsLogo;

companyLogoList[551] = SavistaLogo;

companyLogoList[552] = SeareficoLogo;

companyLogoList[553] = SendoLogo;

companyLogoList[554] = ShbLogo;

companyLogoList[555] = SjcLogo;

companyLogoList[556] = SnbLogo;

companyLogoList[557] = SodexoLogo;

companyLogoList[558] = SuzukiLogo;

companyLogoList[559] = SymLogo;

companyLogoList[560] = SyrenaLogo;

companyLogoList[561] = TtGroupLogo;

companyLogoList[562] = TapDoanLocTroiLogo;

companyLogoList[563] = ThaigroupLogo;

companyLogoList[564] = TheCliffResortResidencesLogo;

companyLogoList[565] = TheodoreAlexanderLogo;

companyLogoList[566] = ThienHoaLogo;

companyLogoList[567] = ToyotaBoshokuHanoiLogo;

companyLogoList[568] = TrangTienPlazaLogo;

companyLogoList[569] = TrungTamIcsLogo;

companyLogoList[570] = TrungThuyGroupLogo;

companyLogoList[571] = TstTouristLogo;

companyLogoList[572] = TapDoanThanhThanhCongTtcGroupLogo;

companyLogoList[573] = ThanhThanhCongBienHoaLogo;

companyLogoList[574] = UberLogo;

companyLogoList[575] = VnpGroupLogo;

companyLogoList[576] = VietUcVasLogo;

companyLogoList[577] = VietnamEsportsLogo;

companyLogoList[578] = VinasoyLogo;

companyLogoList[579] = VinasunLogo;

companyLogoList[580] = ViphavetAsialandLogo;

companyLogoList[581] = VpddJcbVietNamLogo;

companyLogoList[582] = VnpayLogo;

companyLogoList[583] = VstvKLogo;

companyLogoList[584] = WrigleyLogo;

companyLogoList[585] = YchLogo;

companyLogoList[586] = TtcLandLogo;

companyLogoList[587] = HdSaisonLogo;

companyLogoList[588] = TapDoanBaoVietLogo;

companyLogoList[589] = ChungKhoanRongVietLogo;

companyLogoList[590] = DuocSohacoLogo;

companyLogoList[591] = BdoLogo;

companyLogoList[592] = YrIndochinaLogo;

companyLogoList[593] = PhilipsVietnamLogo;

companyLogoList[594] = PacificCrossLogo;

companyLogoList[595] = AdayroiLogo;

companyLogoList[596] = AegLogo;

companyLogoList[597] = BaoTinMinhChauLogo;

companyLogoList[598] = ImtSolutionsLogo;

companyLogoList[599] = VietcharmTravelLogo;

companyLogoList[600] = FocusAsiaTravelLogo;

companyLogoList[601] = FamilymartLogo;

companyLogoList[602] = IntimexGroupLogo;

companyLogoList[603] = FiveStarInternationalGroupLogo;

companyLogoList[604] = PhucSinhCorporationLogo;

companyLogoList[605] = VtcDigicomLogo;

companyLogoList[606] = AdivaLogo;

companyLogoList[608] = SascoLogo;

companyLogoList[609] = DiethelmTravelLogo;

companyLogoList[610] = MasteriseGroupLogo;

companyLogoList[611] = TapDoanBuuChinhVienThongVietNamVnptLogo

companyLogoList[612] = ScJohnsonSonLogo;

companyLogoList[613] = PharmacityLogo;

companyLogoList[614] = ThangLoiGroupLogo;

companyLogoList[615] = SixthGearStudiosLogo;

companyLogoList[616] = VoTrongNghiaLogo;

companyLogoList[617] = VinschoolLogo;

companyLogoList[618] = VinmecLogo;

companyLogoList[619] = VietinBankScLogo;

companyLogoList[620] = OnsemiLogo;

companyLogoList[621] = MeliaHanoiLogo;

companyLogoList[622] = KangarooVietNamLogo;

companyLogoList[623] = BlueOceanLogo;

companyLogoList[624] = TbsGroupLogo;

companyLogoList[625] = OmgVietNamLogo;

companyLogoList[626] = RsmVietNamLogo;

companyLogoList[627] = CapitalHouseLogo;

companyLogoList[628] = AiTLogo;

companyLogoList[629] = TinNghiaLogo;

companyLogoList[630] = IdsMedicalLogo;

companyLogoList[631] = KhaiToanGroupLogo;

companyLogoList[632] = CapellaHoldingsLogo;

companyLogoList[633] = VietThaiQuocTeLogo;

companyLogoList[634] = FoodEmpireLogo;

companyLogoList[635] = Pecc3Logo;

companyLogoList[636] = HongHaFeedLogo;

companyLogoList[637] = BidvMetlifeLogo;

companyLogoList[638] = CathayPacificLogo;

companyLogoList[639] = CathayLifeLogo;

companyLogoList[640] = PrevoirLogo;

companyLogoList[641] = SaiGonFoodLogo;

companyLogoList[642] = FeconLogo;

companyLogoList[643] = AdpLogo;

companyLogoList[644] = TanCuongMinhLogo;

companyLogoList[645] = VietTienGarmentLogo;

companyLogoList[646] = ChungKhoanBidvLogo;

companyLogoList[647] = BidvInsuranceLogo;

companyLogoList[648] = ThuanPhatGroupLogo;

companyLogoList[649] = VissanLogo;

companyLogoList[650] = LouisVuittonLogo;

companyLogoList[651] = DongTienBakeryLogo;

companyLogoList[652] = BaoBiKienVietLogo;

companyLogoList[653] = SmilevietLogo;

companyLogoList[654] = AmegaTravelLogo;

companyLogoList[655] = SaigontravelLogo;

companyLogoList[656] = RichLogo;

companyLogoList[657] = LenovoLogo;

companyLogoList[658] = CangBienCaiMepCmitLogo;

companyLogoList[659] = DetMayThanhCongLogo;

companyLogoList[660] = AnsaradaLogo;

companyLogoList[661] = PierreFabreLogo;

companyLogoList[662] = KidoGroupLogo;

companyLogoList[663] = CentralRetailLogo;

companyLogoList[664] = DomescoLogo;

companyLogoList[665] = StickleyFurnitureLogo;

companyLogoList[666] = KienLongBankLogo;

companyLogoList[667] = TonDongALogo;

companyLogoList[668] = NabatiLogo;

companyLogoList[669] = FarosLogo;

companyLogoList[670] = TheBostonConsultingGroupLogo;

companyLogoList[671] = AsiaDmcHgTravelLogo;

companyLogoList[672] = PivotalMarketingLogo;

companyLogoList[673] = ToChucGiaoDucYolaLogo;

companyLogoList[674] = VatGiaLogo;

companyLogoList[675] = AcacyLogo;

companyLogoList[676] = NewVietDairyLogo;

companyLogoList[677] = AbBankLogo;

companyLogoList[678] = MappleTreeLogo;

companyLogoList[679] = BaoVietTokioMarineLogo;

companyLogoList[680] = CauTreLogo;

companyLogoList[681] = EdgeMarketingLogo;

companyLogoList[682] = OcbNganHangPhuongDongLogo;

companyLogoList[683] = TttCorporationLogo;

companyLogoList[684] = VictoriaHealthcareLogo;

companyLogoList[685] = BetrimexLogo;

companyLogoList[686] = CreatioLogo;

companyLogoList[687] = DiroxLogo;

companyLogoList[688] = GeneralMotorsVnLogo;

companyLogoList[689] = PanpagesVnLogo;

companyLogoList[690] = QsrVietnamLogo;

companyLogoList[691] = SaiGonRdcLogo;

companyLogoList[692] = SolidIntenriorLogo;

companyLogoList[693] = TractusLogo;

companyLogoList[694] = TvHubLogo;

companyLogoList[695] = SolarbkLogo;

companyLogoList[696] = TienPhuocLogo;

companyLogoList[697] = DetTuongLongLogo;

companyLogoList[698] = AbsoluteLogo;

companyLogoList[699] = GemadeptLogo;

companyLogoList[700] = EdoctorLogo;

companyLogoList[701] = UkAcademicLogo;

companyLogoList[702] = BakerMckenzieLogo;

companyLogoList[703] = HrNaviLogo;

companyLogoList[704] = AahLogo;

companyLogoList[705] = LazadaExpressLogo;

companyLogoList[706] = NgoHanLogo;

companyLogoList[707] = PjicoLogo;

companyLogoList[708] = SikaLogo;

companyLogoList[709] = NamLongGroupLogo;

companyLogoList[710] = PhatDatRealEstateDevelopmentLogo;

companyLogoList[711] = PfizerVietnamLimitedLogo;

companyLogoList[712] = ThePanGroupLogo;

companyLogoList[713] = EwayLogo;

companyLogoList[714] = AdtWineArtLogo;

companyLogoList[715] = CmnVietNamLogo;

companyLogoList[716] = BipVietNamLogo;

companyLogoList[717] = AnhQuanStrongLogo;

companyLogoList[718] = AmvGroupLogo;

companyLogoList[719] = TapDoanPhenikaaLogo;

companyLogoList[720] = NcbLogo;

companyLogoList[721] = TecaproLogo;

companyLogoList[722] = VnptEpayLogo;

companyLogoList[723] = MbLandLogo;

companyLogoList[724] = FonterraBrandsLogo;

companyLogoList[725] = KhangDienLogo;

companyLogoList[726] = CoachLogo;

companyLogoList[727] = VnttLogo;

companyLogoList[728] = ImexpharmLogo;

companyLogoList[729] = EmpireCityLogo;

companyLogoList[730] = BaoHiemVietinbankLogo;

companyLogoList[731] = NecVietnamLogo;

companyLogoList[732] = TheCoffeeBeanTeaLeafVietnamLogo;

companyLogoList[733] = TecomenLogo;

companyLogoList[734] = StarbucksCoffeeLogo;

companyLogoList[736] = MoricoModernJapaneseRestaurantCafeLogo;

companyLogoList[737] = CaffeBeneLogo;

companyLogoList[738] = TapDoanThienLongLogo;

companyLogoList[739] = UrgoLogo;

companyLogoList[740] = DaiVietGroupLogo;

companyLogoList[741] = TapDoanHoaPhatLogo;

companyLogoList[742] = GiayJunoLogo;

companyLogoList[743] = ScbLogo;

companyLogoList[744] = BitexLogo;

companyLogoList[745] = ScommerceLogo;

companyLogoList[746] = McreditLogo;

companyLogoList[747] = MosantoVietnamLogo;

companyLogoList[748] = VascaraLogo;

companyLogoList[749] = PayooLogo;

companyLogoList[750] = ZodiacLogo;

companyLogoList[751] = KtgLogo;

companyLogoList[752] = NgLogo;

companyLogoList[753] = FubonLifeInsuranceLogo;

companyLogoList[754] = SilaUrbanLivingLogo;

companyLogoList[755] = HighlandsCoffeeLogo;

companyLogoList[756] = SapLabsVietnamLogo;

companyLogoList[757] = Pcc1Logo;

companyLogoList[758] = BenthanhTouristLogo;

companyLogoList[759] = SaigonCoopLogo;

companyLogoList[760] = SatraLogo;

companyLogoList[761] = VietnamAirlinesLogo;

companyLogoList[762] = NKidGroupLogo;

companyLogoList[763] = AuchanRetailVietnamLogo;

companyLogoList[764] = EmpireGroupLogo;

companyLogoList[765] = VimedimexLogo;

companyLogoList[766] = HewlettPackardEnterpriseVietnamLogo;

companyLogoList[767] = AzbLogo;

companyLogoList[768] = PhamNguyenLogo;

companyLogoList[769] = AnGiaLogo;

companyLogoList[770] = IdpLogo;

companyLogoList[772] = SaigontouristLogo;

companyLogoList[773] = CareplusLogo;

companyLogoList[774] = EurofinsSacKyHaiDangLogo;

companyLogoList[775] = ShsLogo;

companyLogoList[776] = AntoreeLogo;

companyLogoList[777] = EmeraldaGroupLogo;

companyLogoList[778] = ImperialGroupLogo;

companyLogoList[779] = VincommerceLogo;

companyLogoList[780] = VinecoLogo;

companyLogoList[781] = VinpearlLogo;

companyLogoList[782] = HongLeongBankLogo;

companyLogoList[783] = HimLamLandLogo;

companyLogoList[784] = AmpharcoUsaLogo;

companyLogoList[786] = VinfastLogo;

companyLogoList[787] = CimbLogo;

companyLogoList[788] = TruemoneyLogo;

companyLogoList[789] = MedigroupVietNamLogo;

companyLogoList[790] = BenLineAgenciesLogo;

companyLogoList[791] = NovaonLogo;

companyLogoList[792] = CfldLogo;

companyLogoList[793] = HopTriLogo;

companyLogoList[794] = DuocTakedaLogo;

companyLogoList[795] = DuocPhamCuuLongLogo;

companyLogoList[796] = DienLanhBinhMinhLogo;

companyLogoList[797] = PromanLogo;

companyLogoList[798] = GrundfosLogo;

companyLogoList[799] = SeaAirFreightInternationalLogo;

companyLogoList[800] = CholimexLogo;

companyLogoList[801] = VigilantVideoLogo;

companyLogoList[802] = NorfolkMansionLogo;

companyLogoList[803] = GlnEnglishCenterLogo;

companyLogoList[804] = PentalogLogo;

companyLogoList[805] = YTeVietGiaLogo;

companyLogoList[806] = OrientalLogisticsLogo;

companyLogoList[807] = VietnamAirlinesCaterersLogo;

companyLogoList[808] = CoduphaLogo;

companyLogoList[809] = LukesPharmacyLogo;

companyLogoList[810] = GiayDaMyPhongLogo;

companyLogoList[811] = MayVietNamLogo;

companyLogoList[812] = VietstarExpressLogo;

companyLogoList[813] = NangLuongTaiTaoChauALogo;

companyLogoList[814] = M10MayLogo;

companyLogoList[815] = MayMinhHoangLogo;

companyLogoList[816] = AllerganLogo;

companyLogoList[1313] = JupiterFoodsVietnamLogo;

companyLogoList[1314] = PnjpLogo;

companyLogoList[1315] = RedWokCuisineLogo;

companyLogoList[1316] = GotecVietNamLogo;

companyLogoList[1317] = HungThinhCorporationLogo;

companyLogoList[1318] = MedtronicVietnamLogo;

companyLogoList[1319] = AnPhatPlasticsLogo;

companyLogoList[1320] = OpecPlasticsLogo;

companyLogoList[1321] = MasanResourcesLogo;

companyLogoList[1323] = Yeah1GroupLogo;

companyLogoList[1324] = TheCoffeeHouseLogo;

companyLogoList[1325] = TniCorporationLogo;

companyLogoList[1326] = ShopeeVietNamLogo;

companyLogoList[1327] = AlphaKingLogo;

companyLogoList[1328] = ScgLogo;

companyLogoList[1329] = OnTimeWorldwideLogisticsLogo;

companyLogoList[1330] = TransimexLogo;

companyLogoList[1331] = SotransLogo;

companyLogoList[1332] = DankoLogo;

companyLogoList[1333] = TanCangSaiGonSnpLogo;

companyLogoList[1334] = VinalinkLogo;

companyLogoList[1335] = DacoLogisticsLogo;

companyLogoList[1336] = MaerskLogo;

companyLogoList[1337] = OscoInternationalLogo;

companyLogoList[1338] = KomatsuVietNamLogo;

companyLogoList[1339] = KubotaVietnamLogo;

companyLogoList[1340] = BestariVietNamLogo;

companyLogoList[1341] = BuhlerVietNamLogo;

companyLogoList[1342] = YamazenVietNamLogo;

companyLogoList[1343] = CadCamVietNamLogo;

companyLogoList[1344] = NidecVietNamLogo;

companyLogoList[1345] = O4OrangesLogo;

companyLogoList[1346] = KovaPaintLogo;

companyLogoList[1347] = DaiDongTienLogo;

companyLogoList[1348] = AccAsiaChemicalLogo;

companyLogoList[1349] = DatVietTourLogo;

companyLogoList[1350] = FiditourLogo;

companyLogoList[1351] = AgodaLogo;

companyLogoList[1352] = TravelokaVietNamLogo;

companyLogoList[1353] = EnkeiVietNamLogo;

companyLogoList[1354] = SchlemmerVietNamLogo;

companyLogoList[1355] = HaradaIndustriesVietNamLogo;

companyLogoList[1356] = CosmosVietNamLogo;

companyLogoList[1357] = CitigymLogo;

companyLogoList[1358] = MonacoHealthcareLogo;

companyLogoList[1359] = FujinetLogo;

companyLogoList[1360] = CovisoftLogo;

companyLogoList[1361] = IsbVietnamLogo;

companyLogoList[1362] = LogigearVietNamLogo;

companyLogoList[1363] = BkavCorporationLogo;

companyLogoList[1364] = MpTelecomLogo;

companyLogoList[1365] = KddiVietNamLogo;

companyLogoList[1366] = FujitsuVietNamLogo;

companyLogoList[1367] = SaigontelLogo;

companyLogoList[1368] = GtelLogo;

companyLogoList[1369] = TalentnetCorporationLogo;

companyLogoList[1370] = NavigosGroupLogo;

companyLogoList[1371] = HiltiLogo;

companyLogoList[1372] = CushmanWakefieldLogo;

companyLogoList[1373] = SapuwaLogo;

companyLogoList[1374] = CbBankLogo;

companyLogoList[1375] = TetraPakVietnamLogo;

companyLogoList[1376] = NewToyoVietnamLogo;

companyLogoList[1377] = DxcVietnamLogo;

companyLogoList[1378] = TngLogo;

companyLogoList[1379] = PhanoPharmacityLogo;

companyLogoList[1380] = ApecGroupLogo;

companyLogoList[1381] = HyosungVietnamLogo;

companyLogoList[1383] = CognitaVietnamInternationalSchoolHoChiMinhCityLogo;

companyLogoList[1384] = CeoGroupLogo;

companyLogoList[1385] = UniconsLogo;

companyLogoList[1386] = DeltaGroupLogo;

companyLogoList[1387] = NhaThepTriVietLogo;

companyLogoList[1388] = JtiJapanTobaccoInternationalLogo;

companyLogoList[1389] = DatXanhMienTrungLogo;

companyLogoList[1390] = EsquelVietNamLogo;

companyLogoList[1391] = ConCungLogo;

companyLogoList[1392] = UrcVietnamLogo;

companyLogoList[1393] = UplVietnamLogo;

companyLogoList[1394] = NamABankLogo;

companyLogoList[1395] = ThpGroupLogo;

companyLogoList[1396] = BaoHiemQuanDoiMicLogo;

companyLogoList[1397] = LuxstayLogo;

companyLogoList[1398] = NhaKhoaKimLogo;

companyLogoList[1399] = ChungKhoanVpsLogo;

companyLogoList[1400] = ElcaVietnamLogo;

companyLogoList[1401] = MbAgeasLifeLogo;

companyLogoList[1402] = DkraVietnamLogo;

companyLogoList[1403] = HunghauHoldingsLogo;

companyLogoList[1404] = RenesasDesignVietnamLogo;

companyLogoList[1405] = SunAsteriskLogo;

companyLogoList[1406] = SunhouseLogo;

companyLogoList[1407] = EccoVietnamLogo;

companyLogoList[1408] = PhuongDongLogo;

companyLogoList[1409] = AbcBakeryLogo;

companyLogoList[1410] = Gs25Logo;

companyLogoList[1411] = BambooAirwaysLogo;

companyLogoList[1412] = CitigoVietnamLogo;

companyLogoList[1413] = CenGroupJscLogo;

companyLogoList[1414] = VmedGroupLogo;

companyLogoList[1415] = ViDienTuMomoLogo;

companyLogoList[1416] = BaeminVietnamLogo;

companyLogoList[1417] = NganHangSoTimoLogo;

companyLogoList[1418] = FrasersPropertyVietnamFpvLogo;

companyLogoList[1419] = LifullTechLogo;

companyLogoList[1420] = VinsmartLogo;

companyLogoList[1421] = FashionGarmentsCoLtdLogo;

companyLogoList[1422] = NemLienALogo;

companyLogoList[1423] = VtcAcademyLogo;

companyLogoList[1424] = BangkokBankLogo;

companyLogoList[1425] = TekExpertsLogo;

companyLogoList[1426] = AmanotesLogo;

companyLogoList[1427] = JtExpressLogo;

companyLogoList[1428] = VingroupLogo;

companyLogoList[1429] = NewteconsLogo;

companyLogoList[1430] = DavipharmLogo;

companyLogoList[1431] = ChungKhoanVietcombankVcbsLogo;

companyLogoList[1432] = TapDoanKimTinLogo;

companyLogoList[1433] = AstellasPharmaVietnamLogo;

companyLogoList[1434] = DkshSmollanLogo;

companyLogoList[1435] = GojekVietnamLogo;

companyLogoList[1436] = OneMountGroupLogo;

companyLogoList[1437] = HungThinhLandLogo;

companyLogoList[1438] = HungThinhInconsLogo;

companyLogoList[1439] = SailunVietNamLogo;

companyLogoList[1440] = HandongEcLogo;

companyLogoList[1441] = StellaLogo;

companyLogoList[1442] = JabilLogo;

companyLogoList[1443] = KimOanhGroupLogo;

companyLogoList[1444] = SpartronicsLogo;

companyLogoList[1445] = CatTuongGroupLogo;

companyLogoList[1446] = PopsworldwideLogo;

companyLogoList[1447] = VtvLogo;

companyLogoList[1448] = LienvietpostbankLogo;

companyLogoList[1449] = CjFoodsVietnamLogo;

companyLogoList[1450] = DienQuangLogo;

companyLogoList[1451] = ChungKhoanThienVietTvsLogo;

companyLogoList[1452] = SovicoGroupLogo;

companyLogoList[1453] = SapoLogo;

companyLogoList[1454] = SmartoscLogo;

companyLogoList[1455] = VtiLogo;

companyLogoList[1456] = PhanBonDauKhiCaMauPvcfcLogo;

companyLogoList[1457] = LocHoaDauBinhSonBsrLogo;

companyLogoList[1458] = TerumoVietNamLogo;

companyLogoList[1459] = VnvcLogo;

companyLogoList[1460] = DiaOcPhuLongLogo;

companyLogoList[1461] = BwIndustrialLogo;

companyLogoList[1462] = GiaoHangTietKiemLogo;

companyLogoList[1463] = GumacLogo;

companyLogoList[1464] = SavicoLogo;

companyLogoList[1465] = SunshineGroupLogo;

companyLogoList[1466] = DnpCorpLogo;

companyLogoList[1467] = SonToaVietNamLogo;

companyLogoList[1468] = ApolloSiliconeLogo;

companyLogoList[1469] = CmcTileLogo;

companyLogoList[1470] = NhaThuocLongChauLogo;

companyLogoList[1471] = NhaThuocAnKhangLogo;

companyLogoList[1472] = TapDoanEcoparkLogo;

companyLogoList[1473] = VinamitLogo;

companyLogoList[1474] = VieonLogo;

companyLogoList[1475] = NhuaDongNaiLogo;

companyLogoList[1476] = EquestEducationGroupLogo;

companyLogoList[1477] = NguyenHoangGroupLogo;

companyLogoList[1478] = TracodiLogo;

companyLogoList[1479] = BambooCapitalGroupBcgLogo;
companyLogoList[1488] = DuocThaiMinhLogo;
companyLogoList[1484] = GoertekVietnamLogo;
companyLogoList[1491] = NovaEvergreenLogo;
companyLogoList[1482] = PernodRicardVietnamLogo;
companyLogoList[1494] = TapDoanDanhKhoiLogo;
companyLogoList[1486] = VanPhuInvestVpiLogo;
companyLogoList[1492] = ViettelConstructionLogo;
companyLogoList[1481] = VuaNemLogo;
companyLogoList[1483] = CentralconsLogo;
companyLogoList[1493] = EisaiVietNamLogo;
companyLogoList[1485] = F88Logo;
companyLogoList[1487] = GuardianVietnamLogo;
companyLogoList[1496] = NovaGroupLogo;
companyLogoList[1489] = GGroupLogo;
companyLogoList[1609] = HenkelAdhesiveTechnologiesLogo;
companyLogoList[1551] = OpenasiaGroupLogo;
companyLogoList[1610] = TheColorClubLogo;
companyLogoList[1555] = PROFECTUSVIETNAMLogo;
companyLogoList[1607] = SunHospitalityGroupLogo;
companyLogoList[1608] = SunWorldLogo;
companyLogoList[1611] = BVDHYDUOOCLogo;
companyLogoList[1622] = ViettelCommerceLogo;
companyLogoList[1568] = TNGHoldingLogo;
companyLogoList[1614] = AOSmithLogo;
companyLogoList[1566] = VASGroupLogo;
companyLogoList[1606] = BMBSteelLogo;
companyLogoList[1615] = GoTruongThanhLogo;
companyLogoList[1547] = NamDuocLogo;
companyLogoList[1623] = HaleonLogo;
companyLogoList[1549] = NhatTinLogo;



export default companyLogoList;
