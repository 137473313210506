import * as React from 'react';
import { FC } from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required,
    SelectInput,
    NumberInput,
} from 'react-admin';

const InternalScoreCard2022Create: FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm redirect={"list"}>
            <SelectInput source="report_language" choices={[
                { id: 'vn', name: 'Tiếng Việt' },
                { id: 'en', name: 'English' },
            ]} />
            <TextInput source="survey_name" label="Survey_name" validate={[required()]} style={{ width: "100%" }} />

            <NumberInput source="year" label="Year" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="surveyperiod" label="Surveyperiod" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="benchmark" label="Benchmark" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="companyid" label="Companyid" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="industryid" label="Industryid" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="comindid" label="Comindid" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="language" label="Language" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="companyname" label="Companyname" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="companyindustry" label="Companyindustry" validate={[required()]} style={{ width: "100%" }} />

            <NumberInput source="totalrespondents" label="Totalrespondents" validate={[required()]} style={{ width: "100%" }} />

            <NumberInput source="industryrespondents" label="Industryrespondents" validate={[required()]} style={{ width: "100%" }} />

            <NumberInput source="companyrespondents" label="Companyrespondents" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="firstchoiceindexvalue" label="Firstchoiceindexvalue" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="desireindexvalue" label="Desireindexvalue" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="actionindexvalue" label="Actionindexvalue" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="interestindexvalue" label="Interestindexvalue" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="attentionindexvalue" label="Attentionindexvalue" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="rejectionindexvalue" label="Rejectionindexvalue" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ebaindexindexvalue" label="Ebaindexindexvalue" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="firstchoicebenchmark" label="Firstchoicebenchmark" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="desirebenchmark" label="Desirebenchmark" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="actionbenchmark" label="Actionbenchmark" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="interestbenchmark" label="Interestbenchmark" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="attentionbenchmark" label="Attentionbenchmark" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="rejectionbenchmark" label="Rejectionbenchmark" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ebaindexbenchmark" label="Ebaindexbenchmark" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="industryranking" label="Industryranking" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="totalranking" label="Totalranking" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="top50VN" label="Top50vn" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="attentiontointerest" label="Attentiontointerest" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="interesttoaction" label="Interesttoaction" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="interesttodesire" label="Interesttodesire" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="desireto1stchoice" label="Desireto1stchoice" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage1" label="Externalimage1" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage2" label="Externalimage2" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage3" label="Externalimage3" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage4" label="Externalimage4" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage5" label="Externalimage5" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage6" label="Externalimage6" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage7" label="Externalimage7" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage8" label="Externalimage8" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage9" label="Externalimage9" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="externalimage10" label="Externalimage10" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage1" label="Internalimage1" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage2" label="Internalimage2" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage3" label="Internalimage3" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage4" label="Internalimage4" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage5" label="Internalimage5" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage6" label="Internalimage6" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage7" label="Internalimage7" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage8" label="Internalimage8" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage9" label="Internalimage9" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="internalimage10" label="Internalimage10" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat1" label="Ctynvnhonhat1" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat2" label="Ctynvnhonhat2" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat3" label="Ctynvnhonhat3" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat4" label="Ctynvnhonhat4" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat5" label="Ctynvnhonhat5" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat6" label="Ctynvnhonhat6" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat7" label="Ctynvnhonhat7" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat8" label="Ctynvnhonhat8" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat9" label="Ctynvnhonhat9" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ctynvnhonhat10" label="Ctynvnhonhat10" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat1" label="Percentctynvnhonhat1" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat2" label="Percentctynvnhonhat2" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat3" label="Percentctynvnhonhat3" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat4" label="Percentctynvnhonhat4" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat5" label="Percentctynvnhonhat5" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat6" label="Percentctynvnhonhat6" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat7" label="Percentctynvnhonhat7" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat8" label="Percentctynvnhonhat8" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat9" label="Percentctynvnhonhat9" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percentctynvnhonhat10" label="Percentctynvnhonhat10" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider1" label="Talentconsider1" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider2" label="Talentconsider2" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider3" label="Talentconsider3" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider4" label="Talentconsider4" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider5" label="Talentconsider5" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider6" label="Talentconsider6" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider7" label="Talentconsider7" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider8" label="Talentconsider8" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider9" label="Talentconsider9" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="talentconsider10" label="Talentconsider10" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider1" label="Percenttalentconsider1" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider2" label="Percenttalentconsider2" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider3" label="Percenttalentconsider3" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider4" label="Percenttalentconsider4" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider5" label="Percenttalentconsider5" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider6" label="Percenttalentconsider6" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider7" label="Percenttalentconsider7" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider8" label="Percenttalentconsider8" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider9" label="Percenttalentconsider9" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="percenttalentconsider10" label="Percenttalentconsider10" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="rationalcompany" label="Rationalcompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="emotinalcompany" label="Emotinalcompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="motivationcompany" label="Motivationcompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="effortcompany" label="Effortcompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="commitcompany" label="Commitcompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="rationalindustry" label="Rationalindustry" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="emotinalindustry" label="Emotinalindustry" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="motivationindustry" label="Motivationindustry" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="effortindustry" label="Effortindustry" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="commitindustry" label="Commitindustry" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="rationalmarket" label="Rationalmarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="emotinalmarket" label="Emotinalmarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="motivationmarket" label="Motivationmarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="effortmarket" label="Effortmarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="commitmarket" label="Commitmarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="hawindexcompany" label="Hawindexcompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="hawindexindustry" label="Hawindexindustry" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="hawindexmarket" label="Hawindexmarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="corecompany" label="Corecompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="regretcompany" label="Regretcompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="zombiecompany" label="Zombiecompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="quittercompany" label="Quittercompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="coremarket" label="Coremarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="regretmarket" label="Regretmarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="zombiemarket" label="Zombiemarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="quittermarket" label="Quittermarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="sscore" label="Sscore" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ssregret" label="Ssregret" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="sszombie" label="Sszombie" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="ssquitter" label="Ssquitter" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="turnovercompany" label="Turnovercompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="turnovermarket" label="Turnovermarket" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="employeeagreegoodcompany" label="Employeeagreegoodcompany" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="sharingwithfriend" label="Sharingwithfriend" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="cvquantity" label="Cvquantity" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="timehire" label="Timehire" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="hrsupport" label="Hrsupport" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="hirequality" label="Hirequality" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="interestedworkhere" label="Interestedworkhere" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="recruitmentprocess" label="Recruitmentprocess" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="onboarding" label="Onboarding" validate={[required()]} style={{ width: "100%" }} />

            <TextInput source="leadershipinspiration" label="Leadershipinspiration" validate={[required()]} style={{ width: "100%" }} />
        </SimpleForm>
    </Create>
);

export default InternalScoreCard2022Create;