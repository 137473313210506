import React, {
    FC
} from 'react';
import { makeStyles } from "@material-ui/styles";
import pptxgen from "pptxgenjs";
import awardframe from "./img/award-frame-blank.png";
import surveylogo2023 from "./img/logovnbptw2023.png";
import surveyeoclogo2023 from "./img/eoc2023.png";
import surveylogo2022 from "./img/logovnbptw2022_2.png";
import surveylogo2021 from "./img/logovnbptw2021.png";
import surveylogo2020 from "./img/logovnbptw2020.jpg";
import surveylogo from "./img/logovnbptwngeneral.jpg";
import transformArrow from "./img/transform_arrows.png";
import industryAwardFrame from "./img/industry_reward.png";
import totalAwardFrame from "./img/total_reward.png";
import rejectLine from "./img/rejectline.png";
import companyLogoList from '../../companies/CompanyLogo';
import { numberWithCommas } from '../../utils/textHandling';

const COLOR_BLUELAGOON = "005670";
const COLOR_BLACK = "000000";
const COLOR_WHITE = "FFFFFF";
const COLOR_DARKGRAY = "2F2F2F";
const COLOR_LIGHTGRAY = "d9d9d9";
const COLOR_RED = "FF0000";
const COLOR_FIRSTCHOICE = "7c41a9";
const COLOR_DESIRE = "a26840";
const COLOR_ACTION = "99ca3c";
const COLOR_INTEREST = "ffc000";
const COLOR_ATTENTION = "005670";
const COLOR_REJECTION = "ed7d31";
// const COLOR_GRAY = "595959";

interface Props {
    action_benchmark: string,
    action_border_calculator: string,
    action_index_value: string,
    attention_benchmark: string,
    attention_index_value: string,
    attention_reject_box: string,
    attention_to_interest: string,
    brandimages_detail_1: string,
    brandimages_detail_2: string,
    brandimages_detail_3: string,
    brandimages_detail_4: string,
    brandimages_detail_5: string,
    company_id: string,
    company_industry: string,
    company_logo: string,
    company_name: string,
    desire_benchmark: string,
    desire_border_calculator: string,
    desire_index_value: string,
    desire_to_1stchoice: string,
    ebaindex_benchmark: string,
    ebaindex_index_value: string,
    first_choice_border_calculator: string,
    firstchoice_benchmark: string,
    firstchoice_index_value: string,
    id: string,
    externalimage1: string,
    externalimage2: string,
    externalimage3: string,
    externalimage4: string,
    externalimage5: string,
    externalimage6: string,
    externalimage7: string,
    externalimage8: string,
    externalimage9: string,
    externalimage10: string,
    jobimportance1: string,
    jobimportance2: string,
    jobimportance3: string,
    jobimportance4: string,
    jobimportance5: string,
    jobimportance6: string,
    jobimportance7: string,
    jobimportance8: string,
    jobimportance9: string,
    jobimportance10: string,
    industry_ranking: number,
    interest_benchmark: string,
    interest_border_calculator: string,
    interest_index_value: string,
    interest_to_action: string,
    interest_to_desire: string,
    reject_box: string,
    reject_decor_value: string,
    rejection_benchmark: string,
    rejection_index_value: string,
    report_lg: string,
    respondents_amount: string,
    respondents_conducted_date: string,
    scorecard_title: string,
    survey_tag: string,
    survey_title: string,
    template_id: string,
    template_logo: string,
    total_ranking: number,
    total_respondent: number,
    top50VN: string,
    year: number
}

const useStyles = makeStyles(theme => ({
    main: () => ({
        font: "bold",
        textAlign: "center",
        paddingTop: "10px",
    }),
    dlbutton: () => ({
        width: "160px",
        textDecoration: "none",
        fontWeight: "bold"
    }),
    title: () => ({
        padding: "10px"
    })
}));

const VNBPTWScoreCardExport: FC<Props> =
    ({
        action_benchmark = "",
        action_index_value = "",
        attention_benchmark = "",
        attention_index_value = "",
        attention_to_interest = "",
        brandimages_detail_1 = "",
        brandimages_detail_2 = "",
        brandimages_detail_3 = "",
        brandimages_detail_4 = "",
        brandimages_detail_5 = "",
        company_id = "",
        company_industry = "",
        company_logo = "",
        company_name = "",
        desire_benchmark = "",
        desire_index_value = "",
        desire_to_1stchoice = "",
        ebaindex_benchmark = "",
        ebaindex_index_value = "",
        firstchoice_benchmark = "",
        firstchoice_index_value = "",
        id = "",
        externalimage1 = "",
        externalimage2 = "",
        externalimage3 = "",
        externalimage4 = "",
        externalimage5 = "",
        externalimage6 = "",
        externalimage7 = "",
        externalimage8 = "",
        externalimage9 = "",
        externalimage10 = "",
        jobimportance1 = "",
        jobimportance2 = "",
        jobimportance3 = "",
        jobimportance4 = "",
        jobimportance5 = "",
        jobimportance6 = "",
        jobimportance7 = "",
        jobimportance8 = "",
        jobimportance9 = "",
        jobimportance10 = "",
        industry_ranking = 0,
        interest_benchmark = "",
        interest_index_value = "",
        interest_to_action = "",
        interest_to_desire = "",
        rejection_benchmark = "",
        rejection_index_value = "",
        report_lg = "VN",
        respondents_amount = 0,
        respondents_conducted_date = "",
        scorecard_title = "",
        survey_tag = "",
        survey_title = "",
        template_id = "",
        template_logo = "",
        total_ranking = 0,
        total_respondent = 0,
        top50VN = "",
        year = 0
    }) => {
        const data_action_benchmark = action_benchmark;
        const data_action_index_value = action_index_value;
        const data_attention_benchmark = attention_benchmark;
        const data_attention_index_value = attention_index_value;
        const data_attention_to_interest = attention_to_interest;
        const data_brandimages_detail_1 = brandimages_detail_1;
        const data_brandimages_detail_2 = brandimages_detail_2;
        const data_brandimages_detail_3 = brandimages_detail_3;
        const data_brandimages_detail_4 = brandimages_detail_4;
        const data_brandimages_detail_5 = brandimages_detail_5;
        const data_company_id = company_id;
        const data_company_industry = company_industry;
        // const data_company_logo = company_logo;
        const data_company_name = company_name;
        const data_desire_benchmark = desire_benchmark;
        const data_desire_index_value = desire_index_value;
        const data_desire_to_1stchoice = desire_to_1stchoice;
        const data_ebaindex_benchmark = ebaindex_benchmark;
        const data_ebaindex_index_value = ebaindex_index_value;
        const data_firstchoice_benchmark = firstchoice_benchmark;
        const data_firstchoice_index_value = firstchoice_index_value;
        // const data_id = id;
        const data_industry_ranking = industry_ranking;
        const data_interest_benchmark = interest_benchmark;
        const data_interest_index_value = interest_index_value;
        const data_interest_to_action = interest_to_action;
        const data_interest_to_desire = interest_to_desire;
        const data_rejection_benchmark = rejection_benchmark;
        const data_rejection_index_value = rejection_index_value;
        const data_report_lg = report_lg.toUpperCase();
        const data_respondents_amount = respondents_amount;
        const data_respondents_conducted_date = respondents_conducted_date;

        const data_externalimage1 = externalimage1 != '#N/A' ? externalimage1 : '';
        const data_externalimage2 = externalimage2 != '#N/A' ? externalimage2 : '';
        const data_externalimage3 = externalimage3 != '#N/A' ? externalimage3 : '';
        const data_externalimage4 = externalimage4 != '#N/A' ? externalimage4 : '';
        const data_externalimage5 = externalimage5 != '#N/A' ? externalimage5 : '';
        const data_externalimage6 = externalimage6 != '#N/A' ? externalimage6 : '';
        const data_externalimage7 = externalimage7 != '#N/A' ? externalimage7 : '';
        const data_externalimage8 = externalimage8 != '#N/A' ? externalimage8 : '';
        const data_externalimage9 = externalimage9 != '#N/A' ? externalimage9 : '';
        const data_externalimage10 = externalimage10 != '#N/A' ? externalimage10 : '';
        const data_jobimportance1 = jobimportance1 != '#N/A' ? jobimportance1 : '';
        const data_jobimportance2 = jobimportance2 != '#N/A' ? jobimportance2 : '';
        const data_jobimportance3 = jobimportance3 != '#N/A' ? jobimportance3 : '';
        const data_jobimportance4 = jobimportance4 != '#N/A' ? jobimportance4 : '';
        const data_jobimportance5 = jobimportance5 != '#N/A' ? jobimportance5 : '';
        const data_jobimportance6 = jobimportance6 != '#N/A' ? jobimportance6 : '';
        const data_jobimportance7 = jobimportance7 != '#N/A' ? jobimportance7 : '';
        const data_jobimportance8 = jobimportance8 != '#N/A' ? jobimportance8 : '';
        const data_jobimportance9 = jobimportance9 != '#N/A' ? jobimportance9 : '';
        const data_jobimportance10 = jobimportance10 != '#N/A' ? jobimportance10 : '';

        // const data_scorecard_title = scorecard_title;
        // const data_survey_title = survey_title;
        const data_total_ranking = total_ranking;
        const data_total_respondent = total_respondent;
        const data_year = year;
        const scLanguage = data_report_lg;
        const cologo = companyLogoList[parseInt(data_company_id)];
        const classes = useStyles();
        const doExport = () => {

            let pres = new pptxgen();


            pres.defineLayout({ name: 'ScoreCard', width: 14.611, height: 8.5 });
            pres.layout = 'ScoreCard';

            let slide = pres.addSlide();
            let sc_subTitle = {};
            sc_subTitle["VN"] = "BẢNG ĐÁNH GIÁ NHANH";
            sc_subTitle["EN"] = "SCORECARD";
            const companyName = data_company_name;
            const companyIndustry = data_company_industry;
            const industryrespondents = data_respondents_amount;
            const surveyperiod = data_respondents_conducted_date;
            //// PAGE 1
            // ########## Left Side Bar  START ############
            slide.addText("", {
                x: 0,
                y: 0,
                h: 8.5,
                w: 2.75,
                fill: { color: "f2f2f2" },
            });


            let logoWidth = 0;
            let logoHeight = 0;

            let myImg = document.getElementById("companylogo") as HTMLImageElement;
            if (myImg) {
                logoWidth = myImg.naturalWidth;
                logoHeight = myImg.naturalHeight;
            }
            const baseLogoX = 0.2;
            const baseLogoY = 0.2;
            const baseLogoW = 2.4;
            const baseLogoH = 1.5;
            const baseWHRatio = 1.6;
            const baseHWRatio = 0.625;
            let logoX = baseLogoX;
            let logoY = baseLogoY;
            let logoW = baseLogoW;
            let logoH = baseLogoH;
            if (logoHeight >= logoWidth || logoWidth < logoHeight * baseWHRatio) {
                logoW = (logoWidth / logoHeight) * baseLogoW * baseHWRatio;
                logoX = baseLogoX + (baseLogoW / 2 - logoW / 2);
            } else {
                logoH = (logoHeight / logoWidth) * baseLogoH * baseWHRatio;
                logoY = baseLogoY + (baseLogoH / 2 - logoH / 2);
            }
            slide.addImage({ path: cologo, x: logoX, y: logoY, h: logoH, w: logoW, sizing: { type: "contain", h: logoH, w: logoW } });
            slide.addText([{ text: companyName, options: { fontSize: 18, breakLine: true, bold: true } }, { text: companyIndustry, options: { fontSize: 14 } }], {
                x: 0,
                y: 2,
                h: 1.2,
                w: 2.6,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            let sideBarInfo_1 = {};
            sideBarInfo_1["VN"] = "Kết quả được đánh giá bởi ";
            sideBarInfo_1["EN"] = "Final results are entirely based on ";
            let sideBarInfo_2 = {};
            switch (survey_tag) {
                case "eoc":
                    sideBarInfo_2["VN"] = " Sinh viên trong Ngành";
                    sideBarInfo_2["EN"] = " Student in related Majors";
                    break;
                case "vnbptw":
                default:
                    sideBarInfo_2["VN"] = " nhân sự Ngành";
                    sideBarInfo_2["EN"] = " industry respondents";
                    break;
            }

            let sideBarInfo_conducted = {};
            sideBarInfo_conducted["VN"] = "Thời gian: ";
            sideBarInfo_conducted["EN"] = "Conducted: ";
            let sideBarInfo_4 = {};
            sideBarInfo_4["VN"] = "Điểm chuẩn so sánh dựa trên kết quả Khảo sát ";
            sideBarInfo_4["EN"] = "The benchmark is extracted from ";
            let sideBarInfo_surveyName = {};
            sideBarInfo_surveyName["VN"] = survey_title;//"Nơi làm việc tốt nhất Việt Nam " + data_year;
            sideBarInfo_surveyName["EN"] = survey_title;//"Vietnam Best Places To Work Survey " + data_year;
            const displayindustryrespondents = numberWithCommas(industryrespondents);
            slide.addText([{ text: sideBarInfo_1[scLanguage] },
            { text: " " + displayindustryrespondents + " ", options: { color: "FF0000" } },
            { text: sideBarInfo_2[scLanguage], options: { breakLine: true } },
            { text: sideBarInfo_conducted[scLanguage] + surveyperiod, options: { breakLine: true, bullet: { indent: 10 } } },
            { text: sideBarInfo_4[scLanguage], options: { bullet: { indent: 10 } } },
            { text: sideBarInfo_surveyName[scLanguage], options: { breakLine: true, bold: true } },
            ]
                , {
                    x: 0,
                    y: 3.4,
                    h: 1.3,
                    w: 2.6,
                    color: "000000",
                    fontSize: 12,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            let sideBarInfo_contact = {};
            sideBarInfo_contact["VN"] = "Để Đo lường sức khỏe THNTD chuyên sâu & các khảo sát về Nguồn Nhân Lực, vui lòng liên hệ: ";
            sideBarInfo_contact["EN"] = "For deeper understand Employer Brand Health Check & Happiness At Work survey, please contact: ";
            slide.addText(sideBarInfo_contact[scLanguage], {
                x: 0,
                y: 4.8,
                h: 0.8,
                w: 2.6,
                color: COLOR_BLUELAGOON,
                fontSize: 11,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.justify,
            });
            slide.addText([{ text: "(+84 28) 6268 2222", options: { breakLine: true, bullet: { indent: 10 } } },
            { text: "clientsolution@anphabe.com", options: { bullet: { indent: 10 } } },
            ]
                , {
                    x: 0,
                    y: 5.3,
                    h: 0.8,
                    w: 2.6,
                    color: COLOR_BLUELAGOON,
                    fontSize: 12,
                    bold: true,
                    italic: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            let survey_logo;
            switch (data_year) {
                case 2020:
                    survey_logo = surveylogo2020;
                    break;
                case 2021:
                    survey_logo = surveylogo2021;
                    break;
                case 2022:
                    survey_logo = surveylogo2022;
                    break;
                case 2023:
                    survey_logo = surveylogo2023;
                    if (survey_tag == 'eoc') {
                        survey_logo = surveyeoclogo2023;
                    }
                    break;
                default:
                    survey_logo = surveylogo;
                    break;
            }
            slide.addImage({ path: survey_logo, x: 0.1, y: 6.3, h: 1.6, w: 2.5, sizing: { type: "contain", h: 1.6, w: 2.5 } });
            //  ########### Side Bar END #############
            // BODY PAGE 1
            // ######### HEADER START #############
            slide.addText(sc_subTitle[scLanguage], {
                x: 2.9,
                y: 0.05,
                h: 0.2,
                color: "363636",
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            let scTitle = {};
            scTitle["VN"] = scorecard_title;//"SỨC KHỎE THƯƠNG HIỆU NHÀ TUYỂN DỤNG (THNTD) 2021";
            scTitle["EN"] = scorecard_title;//"EMPLOYER BRAND HEALTH CHECK 2021 ";

            slide.addText(scTitle[scLanguage], {
                x: 2.9,
                y: 0.25,
                h: 0.6,
                color: "363636",
                fontSize: 28,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            // ######### HEADER END #############
            //////////// BODY //////////////////
            let attractiveIndex = {};
            attractiveIndex["VN"] = "CHỈ SỐ SỨC HẤP DẪN THƯƠNG HIỆU NHÀ TUYỂN DỤNG";
            attractiveIndex["EN"] = "EMPLOYER BRAND ATTRACTIVENESS INDEX";
            slide.addText(attractiveIndex[scLanguage], {
                x: 2.85,
                y: 0.95,
                h: 0.5,
                w: 6.4,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontSize: 16,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: COLOR_BLUELAGOON }
            });

            slide.addText("", {
                x: 2.85,
                y: 1.45,
                h: 5.2,
                w: 6.4,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            let conversionRate = {};
            conversionRate["VN"] = "Tỉ lệ chuyển đổi";
            conversionRate["EN"] = "Conversion rate";
            slide.addText(conversionRate[scLanguage], {
                x: 2.85,
                y: 1.45,
                h: 0.3,
                w: 6.4,
                color: "000000",
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
            });
            slide.addText("", {
                x: 4.4,
                y: 1.8,
                h: 4.8,
                w: 0.05,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_BLACK }
            });


            // Tiêu đề
            // EBA
            /////////////////// ƯU TIÊN
            const maxIndexValueBar = 1.75;
            const firstchoiceindexvalue = parseFloat(data_firstchoice_index_value).toFixed(1);
            const desireto1stchoice = parseFloat(data_desire_to_1stchoice).toFixed(1);
            let firstChoice_title_1 = {};
            firstChoice_title_1["VN"] = "ƯU TIÊN";
            firstChoice_title_1["EN"] = "FIRST CHOICE";
            let firstChoice_title_2 = {};
            firstChoice_title_2["VN"] = "CHỌN";
            firstChoice_title_2["EN"] = "";
            slide.addText([{ text: firstChoice_title_1[scLanguage], options: { breakLine: true } },
            { text: firstChoice_title_2[scLanguage] },
            ]
                , {
                    x: 2.85,
                    y: 1.8,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 1.9,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 1.9,
                h: 0.7,
                w: maxIndexValueBar * parseInt(firstchoiceindexvalue) / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_FIRSTCHOICE },
            });
            slide.addText(firstchoiceindexvalue.toString() + "%", {
                x: 4.8,
                y: 2.1,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            slide.addText(desireto1stchoice.toString() + "%", {
                x: 5.75,
                y: 2.25,
                h: 0.35,
                w: 1,
                color: COLOR_REJECTION,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
                fontSize: 16
            });
            let firstChoice_desc_1 = {};
            if (survey_tag == "eoc") {
                firstChoice_desc_1["VN"] = "% Sinh viên ngành ";
                firstChoice_desc_1["EN"] = "% Student ";
            } else {
                firstChoice_desc_1["VN"] = "% Nhân sự ngành ";
                firstChoice_desc_1["EN"] = "% Industry talents give ";
            }

            let firstChoice_desc_2 = {};
            firstChoice_desc_2["VN"] = "ưu tiên chọn ";
            firstChoice_desc_2["EN"] = "priority to accept ";
            let firstChoice_desc_3 = {};
            firstChoice_desc_3["VN"] = "Công ty trong tất cả các lời mời làm việc.";
            firstChoice_desc_3["EN"] = "Company's offer despite similar offers";

            slide.addText([{ text: firstChoice_desc_1[scLanguage] }, { text: firstChoice_desc_2[scLanguage], options: { bold: true } }, { text: firstChoice_desc_3[scLanguage] }], {
                x: 6.25,
                y: 1.9,
                h: 0.7,
                w: 2.9,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            ////////////////// KHÁT KHAO
            const desireindexvalue = parseFloat(data_desire_index_value).toFixed(1);
            const interesttodesire = parseFloat(data_interest_to_desire).toFixed(1);
            let desire_title = {};
            desire_title["VN"] = "KHÁT KHAO";
            desire_title["EN"] = "DESIRE";
            slide.addText([{ text: desire_title[scLanguage] },
            ]
                , {
                    x: 2.85,
                    y: 2.6,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 2.7,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 2.7,
                h: 0.7,
                w: maxIndexValueBar * parseInt(desireindexvalue) / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_DESIRE },
            });
            slide.addText(desireindexvalue + "%", {
                x: 4.9,
                y: 2.9,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            slide.addText(interesttodesire + "%", {
                x: 6,
                y: 3,
                h: 0.35,
                w: 1,
                color: COLOR_REJECTION,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
                fontSize: 16
            });
            let desire_desc_1 = {};
            if (survey_tag == "eoc") {
                desire_desc_1["VN"] = "% Sinh viên ngành xem Công ty là ";
                desire_desc_1["EN"] = "% Students who perceive company as an ";
            } else {
                desire_desc_1["VN"] = "% Nhân sự ngành mục tiêu xem Công ty là ";
                desire_desc_1["EN"] = "% Industry talents who perceive company as an ";;
            }
            let desire_desc_2 = {};
            desire_desc_2["VN"] = "“Nơi Làm Việc Lý Tưởng”";
            desire_desc_2["EN"] = "“Ideal Place To Work”";

            slide.addText([{ text: desire_desc_1[scLanguage] }, { text: desire_desc_2[scLanguage], options: { bold: true } }], {
                x: 6.25,
                y: 2.7,
                h: 0.7,
                w: 2.9,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            ///////////////////// ỨNG TUYỂN
            const actionindexvalue = parseFloat(data_action_index_value).toFixed(1);
            const interesttoaction = parseFloat(data_interest_to_action).toFixed(1);
            let action_title = {};
            action_title["VN"] = "ỨNG TUYỂN";
            action_title["EN"] = "ACTION";
            slide.addText([{ text: action_title[scLanguage] },
            ]
                , {
                    x: 2.85,
                    y: 3.4,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 3.5,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 3.5,
                h: 0.7,
                w: maxIndexValueBar * parseInt(actionindexvalue) / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_ACTION },
            });
            slide.addText(actionindexvalue + "%", {
                x: 5.1,
                y: 3.6,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            slide.addText(interesttoaction + "%", {
                x: 6.27,
                y: 3.8,
                h: 0.35,
                w: 1,
                color: COLOR_REJECTION,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
                fontSize: 16
            });
            let action_desc_1 = {};
            if (survey_tag == "eoc") {
                action_desc_1["VN"] = "% Sinh viên ngành ";
                action_desc_1["EN"] = "% Student who are ";
            } else {
                action_desc_1["VN"] = "% Nhân sự ngành mục tiêu ";
                action_desc_1["EN"] = "% Industry talents who are ";
            }
            let action_desc_2 = {};
            action_desc_2["VN"] = "sẵn sàng ứng tuyển ";
            action_desc_2["EN"] = "willing to apply ";
            let action_desc_3 = {};
            action_desc_3["VN"] = "khi Công ty có vị trí phù hợp";
            action_desc_3["EN"] = "for relevant job openings at company";

            slide.addText([{ text: action_desc_1[scLanguage] }, { text: action_desc_2[scLanguage], options: { bold: true } }, { text: action_desc_3[scLanguage] }], {
                x: 6.7,
                y: 3.45,
                h: 0.7,
                w: 2.4,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            //////////////// QUAN TÂM
            const interestindexvalue = parseFloat(data_interest_index_value).toFixed(1);
            const attentiontointerest = parseFloat(data_attention_to_interest).toFixed(1);
            const rejectionindexvalue = parseFloat(data_rejection_index_value).toFixed(1);
            let interest_title = {};
            interest_title["VN"] = "QUAN TÂM";
            interest_title["EN"] = "INTEREST";
            slide.addText([{ text: interest_title[scLanguage] }
            ]
                , {
                    x: 2.85,
                    y: 4.2,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 4.3,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 4.3,
                h: 0.7,
                w: maxIndexValueBar * parseInt(interestindexvalue) / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_INTEREST },
            });
            slide.addText(interestindexvalue + "%", {
                x: 5.3,
                y: 4.5,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            slide.addText(attentiontointerest + "%", {
                x: 6.55,
                y: 4.55,
                h: 0.35,
                w: 1,
                color: COLOR_REJECTION,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
                fontSize: 16
            });
            let interest_desc_1 = {};
            if (survey_tag == "eoc") {
                interest_desc_1["VN"] = "% Sinh viên ngành ";
                interest_desc_1["EN"] = "% Students who ";
            } else {
                interest_desc_1["VN"] = "% Nhân sự ngành ";
                interest_desc_1["EN"] = "% Industry talents who ";
            }

            let interest_desc_2 = {};
            interest_desc_2["VN"] = "cân nhắc làm việc cho Công ty trong tương lai";
            interest_desc_2["EN"] = "consider to work for company in the future";

            slide.addText([{ text: interest_desc_1[scLanguage] }, { text: interest_desc_2[scLanguage], options: { bold: true } }], {
                x: 6.9,
                y: 4.3,
                h: 0.7,
                w: 2.15,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            //////////////// TỔNG
            const attentionindexvalue = parseFloat(data_attention_index_value).toFixed(1);
            let attention_title_1 = {};
            attention_title_1["VN"] = "TỔNG";
            attention_title_1["EN"] = "ATTENTION";
            let attention_title_2 = {};
            attention_title_2["VN"] = "NHẬN BIẾT";
            attention_title_2["EN"] = "";
            slide.addText([{ text: attention_title_1[scLanguage], options: { breakLine: true } },
            { text: attention_title_2[scLanguage] }]
                , {
                    x: 2.85,
                    y: 5,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 5.1,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 5.1,
                h: 0.7,
                w: maxIndexValueBar,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_ATTENTION },
            });
            slide.addText(attentionindexvalue + "%", {
                x: 4.5,
                y: 5.22,
                h: 0.35,
                w: ((100 - parseInt(rejectionindexvalue)) / 100) * 1.8,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });

            slide.addImage({ path: rejectLine, x: 4.5 + ((100 - parseInt(rejectionindexvalue)) / 100) * maxIndexValueBar - 0.03, y: 5.1, h: 0.7, w: 0.02, sizing: { type: "contain", h: 0.7, w: 0.02 } });
            let attention_desc_1 = {};
            if (survey_tag == "eoc") {
                attention_desc_1["VN"] = "% Sinh viên ngành ";
                attention_desc_1["EN"] = "% Students who have ";
            } else {
                attention_desc_1["VN"] = "% Nhân sự ngành ";
                attention_desc_1["EN"] = "% Industry talents who have ";
            }
            let attention_desc_2 = {};
            attention_desc_2["VN"] = "từng biết đến  ";
            attention_desc_2["EN"] = "know ";
            let attention_desc_3 = {};
            attention_desc_3["VN"] = "hoặc ";
            attention_desc_3["EN"] = "or ";
            let attention_desc_4 = {};
            attention_desc_4["VN"] = "nghe nói  ";
            attention_desc_4["EN"] = "heard ";
            let attention_desc_5 = {};
            attention_desc_5["VN"] = "về Công ty.";
            attention_desc_5["EN"] = "of company.";

            slide.addText([{ text: attention_desc_1[scLanguage] },
            { text: attention_desc_2[scLanguage], options: { bold: true } },
            { text: attention_desc_3[scLanguage] },
            { text: attention_desc_4[scLanguage], options: { bold: true } },
            { text: attention_desc_5[scLanguage] }], {
                x: 6.55,
                y: 5.1,
                h: 0.7,
                w: 2.6,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            ///////////////////// NHẬN BIẾT TIÊU CỰC

            let reject_title_1 = {};
            reject_title_1["VN"] = "NHẬN BIẾT";
            reject_title_1["EN"] = "REJCTION";
            let reject_title_2 = {};
            reject_title_2["VN"] = "TIÊU CỰC";
            reject_title_2["EN"] = "";
            slide.addText([{ text: reject_title_1[scLanguage], options: { breakLine: true } },
            { text: reject_title_2[scLanguage] }]
                , {
                    x: 2.85,
                    y: 5.8,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 5.9,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5 + maxIndexValueBar * (100 - parseInt(rejectionindexvalue)) / 100,
                y: 5.9,
                h: 0.7,
                w: maxIndexValueBar * parseInt(rejectionindexvalue) / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_REJECTION },
            });
            slide.addText(rejectionindexvalue + "%", {
                x: 4 + maxIndexValueBar * (100 - parseInt(rejectionindexvalue)) / 100,
                y: 6,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            let reject_desc_1 = {};
            if (survey_tag == "eoc") {
                reject_desc_1["VN"] = "% Sinh viên ngành ";
                reject_desc_1["EN"] = "% Students who ";
            } else {
                reject_desc_1["VN"] = "% Nhân sự ngành ";
                reject_desc_1["EN"] = "% Industry talents who ";
            }
            
            let reject_desc_2 = {};
            reject_desc_2["VN"] = "không thích ";
            reject_desc_2["EN"] = "don’t like ";
            let reject_desc_3 = {};
            reject_desc_3["VN"] = "Công ty";
            reject_desc_3["EN"] = "company";
            slide.addText([{ text: reject_desc_1[scLanguage] },
            { text: reject_desc_2[scLanguage], options: { bold: true } },
            { text: reject_desc_3[scLanguage] }], {
                x: 6.55,
                y: 5.9,
                h: 0.7,
                w: 2.55,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });

            slide.addImage({ path: transformArrow, x: 5.45, y: 2, w: 1.25, h: 3.3854166667 });

            const ebaindexindexvalue = parseFloat(data_ebaindex_index_value).toFixed(1);
            let ebaIndex_title = {};
            ebaIndex_title["VN"] = "CHỈ SỐ EBA:";
            ebaIndex_title["EN"] = "EBA INDEX";
            slide.addText(ebaIndex_title[scLanguage], {
                x: 2.85,
                y: 6.8,
                h: 0.9,
                w: 6.4,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            slide.addText(ebaindexindexvalue, {
                x: 7.4,
                y: 6.8,
                h: 0.9,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
            });
            // EBA benkmark
            let ebaBenkmark_title = {};
            ebaBenkmark_title["VN"] = "NGÀNH";
            ebaBenkmark_title["EN"] = "INDUSTRY";
            slide.addText(ebaBenkmark_title[scLanguage], {
                x: 9.35,
                y: 0.95,
                h: 0.5,
                w: 0.95,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontSize: 12,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: COLOR_BLUELAGOON }
            });
            const firstchoicebenchmark = parseFloat(data_firstchoice_benchmark).toFixed(1);
            const desirebenchmark = parseFloat(data_desire_benchmark).toFixed(1);
            const actionbenchmark = parseFloat(data_action_benchmark).toFixed(1);
            const interestbenchmark = parseFloat(data_interest_benchmark).toFixed(1);
            const attentionbenchmark = parseFloat(data_attention_benchmark).toFixed(1);
            const rejectionbenchmark = parseFloat(data_rejection_benchmark).toFixed(1);
            const ebaindexbenchmark = parseFloat(data_ebaindex_benchmark).toFixed(1);
            slide.addText("", {
                x: 9.35,
                y: 1.45,
                h: 6.25,
                w: 0.95,
                color: "000000",
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            slide.addText("", {

                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            const benmark_x = 9.35;
            const benmark_w = 0.9;

            slide.addText(firstchoicebenchmark + "%", {
                x: benmark_x,
                y: 2.05,
                h: 0.3,
                w: benmark_w,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(desirebenchmark + "%", {
                x: benmark_x,
                y: 2.88,
                h: 0.3,
                w: benmark_w,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(actionbenchmark + "%", {
                x: benmark_x,
                y: 3.61,
                h: 0.3,
                w: benmark_w,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(interestbenchmark + "%", {
                x: benmark_x,
                y: 4.5,
                h: 0.3,
                w: benmark_w,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(attentionbenchmark + "%", {
                x: benmark_x,
                y: 5.4,
                h: 0.3,
                w: benmark_w,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(rejectionbenchmark + "%", {
                x: benmark_x,
                y: 6.1,
                h: 0.3,
                w: benmark_w,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(ebaindexbenchmark, {
                x: benmark_x,
                y: 6.8,
                h: 0.9,
                w: benmark_w,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            // Ranking 2023
            if (data_year == 2023) {
                const totalRespondents_int = new Intl.NumberFormat().format(data_total_respondent);
                // Ranking
                let awardTitle = {};
                awardTitle["VN"] = "NƠI LÀM VIỆC TỐT NHẤT VIỆT NAM"
                awardTitle["EN"] = "VIETNAM BEST PLACES TO WORK";
                let awardSubTitle = {};
                const category = top50VN;
                awardSubTitle["VN"] = "KHỐI DOANH NGHIỆP " + category;
                awardSubTitle["EN"] = category + " ENTERPRISES";

                if (survey_tag == "eoc") {
                    awardTitle["VN"] = "THNTD HẤP DẪN NHẤT"
                    awardTitle["EN"] = "BEST EMPLOYER OF CHOICE ™ 2023";
                    awardSubTitle["VN"] = "VỚI SINH VIÊN VIỆT NAM";
                    awardSubTitle["EN"] = "RANKING";
                    slide.addText([
                        { text: awardTitle[scLanguage], options: { fontSize: 14, breakLine: true, bold: true, fontFace: "Arial" } },
                        { text: awardSubTitle[scLanguage], options: { fontSize: 14, fontFace: "Arial" } }], {
                        x: 10.5,
                        y: 0.95,
                        h: 0.5,
                        w: 3.8,
                        color: "FFFFFF",
                        fill: { color: COLOR_BLUELAGOON },
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                } else {
                    slide.addText([
                        { text: awardTitle[scLanguage], options: { fontSize: 14, breakLine: true, bold: true, } },
                        { text: awardSubTitle[scLanguage], options: { fontSize: 10.5, fontFace: "Arial" } }], {
                        x: 10.5,
                        y: 0.95,
                        h: 0.5,
                        w: 3.8,
                        color: "FFFFFF",
                        fill: { color: COLOR_BLUELAGOON },
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                }


                const industryRanking_int = industry_ranking;
                const totalRanking_int = total_ranking;

                const top50VNAward = parseInt(top50VN);
                const award_1_y = 3;
                const award_2_y = 4.5;
                const award_3_y = 6;

                slide.addText("", {
                    x: 10.5,
                    y: 1.45,
                    h: 6.35,
                    w: 3.8,
                    color: "000000",
                    fontFace: 'Calibri',
                    fontSize: 16,
                    bold: true,
                    align: pres.AlignH.center,
                    line: { width: 1, color: COLOR_BLACK },
                    fill: { color: COLOR_BLACK }
                });
                slide.addImage({ path: awardframe, x: 10.5, y: 1.45, h: 2.5, w: 3.8, sizing: { type: "contain", h: 2.5, w: 3.8, } });
                let displayIndustryRanking = industryRanking_int;
                if (industryRanking_int > 0 && totalRanking_int > 0) {
                    slide.addImage({ path: industryAwardFrame, x: 11, y: award_1_y, h: 0.95, w: 2.7 });
                    let awardIndustryTitle = {};
                    awardIndustryTitle["VN"] = " NGÀNH";
                    awardIndustryTitle["EN"] = " INDUSTRY";
                    let awardEmployeeIndustryTitle = {};
                    if (survey_tag == "eoc") {
                        awardEmployeeIndustryTitle["VN"] = " sinh viên ngành";
                        awardEmployeeIndustryTitle["EN"] = " Major's student";
                    } else {
                        awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
                        awardEmployeeIndustryTitle["EN"] = " Industry Talents";
                    }


                    slide.addText([
                        { text: "#", options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                        { text: displayIndustryRanking.toString(), options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                        { text: awardIndustryTitle[scLanguage], options: { fontSize: 17.5, breakLine: true, bold: true, color: COLOR_WHITE } },
                        { text: displayindustryrespondents.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                        { text: awardEmployeeIndustryTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                        x: 11.3,
                        y: award_1_y + 0.2,
                        h: 0.5,
                        w: 2.1,
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });

                    slide.addImage({ path: totalAwardFrame, x: 11, y: award_2_y, h: 0.95, w: 2.7 });
                    let awardMarketTitle = {};
                    awardMarketTitle["VN"] = "TOÀN THỊ TRƯỜNG";
                    awardMarketTitle["EN"] = "TOTAL VIETNAM";
                    let awardEmployeeMarketTitle = {};

                    if (survey_tag == "eoc") {
                        awardEmployeeMarketTitle["VN"] = " sinh viên Việt Nam";
                        awardEmployeeMarketTitle["EN"] = " Vietnamese students";
                        slide.addText([
                            { text: "TOP ", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
                            { text: totalRanking_int.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
                            { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
                            { text: totalRespondents_int.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                            { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                            x: 11.2,
                            y: award_2_y + 0.2,
                            h: 0.5,
                            w: 2.3,
                            color: "FFFFFF",
                            fontFace: 'Calibri',
                            align: pres.AlignH.center,
                        });
                    } else {
                        awardEmployeeMarketTitle["VN"] = " Nhân sự Việt Nam";
                        awardEmployeeMarketTitle["EN"] = " VietNam Talents";
                        slide.addText([
                            { text: "#", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
                            { text: totalRanking_int.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
                            { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
                            { text: totalRespondents_int.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                            { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                            x: 11.2,
                            y: award_2_y + 0.2,
                            h: 0.5,
                            w: 2.3,
                            color: "FFFFFF",
                            fontFace: 'Calibri',
                            align: pres.AlignH.center,
                        });
                    }


                } else if (industryRanking_int > 0) {
                    slide.addImage({ path: industryAwardFrame, x: 11, y: award_1_y, h: 0.95, w: 2.7 });
                    let awardIndustryTitle = {};
                    awardIndustryTitle["VN"] = " NGÀNH";
                    awardIndustryTitle["EN"] = " INDUSTRY";
                    let awardEmployeeIndustryTitle = {};
                    if (survey_tag == "eoc") {
                        awardEmployeeIndustryTitle["VN"] = " sinh viên Việt Nam";
                        awardEmployeeIndustryTitle["EN"] = " Vietnamese students";
                    } else {
                        awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
                        awardEmployeeIndustryTitle["EN"] = " Industry Talents";
                    }
                    slide.addText([
                        { text: "#", options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                        { text: displayIndustryRanking.toString(), options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                        { text: awardIndustryTitle[scLanguage], options: { fontSize: 17.5, breakLine: true, bold: true, color: COLOR_WHITE } },
                        { text: displayindustryrespondents.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                        { text: awardEmployeeIndustryTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                        x: 11.3,
                        y: award_1_y + 0.2,
                        h: 0.5,
                        w: 2.1,
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });

                } else if (totalRanking_int > 0) {
                    slide.addImage({ path: totalAwardFrame, x: 11, y: award_1_y, h: 0.95, w: 2.7 });
                    let awardMarketTitle = {};
                    awardMarketTitle["VN"] = "TOÀN THỊ TRƯỜNG";
                    awardMarketTitle["EN"] = "TOTAL VIETNAM";
                    let awardEmployeeMarketTitle = {};
                    if (survey_tag == "eoc") {
                        awardEmployeeMarketTitle["VN"] = " sinh viên Việt Nam";
                        awardEmployeeMarketTitle["EN"] = " Vietnamese students";
                        slide.addText([
                            { text: "TOP ", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
                            { text: totalRanking_int.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
                            { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
                            { text: totalRespondents_int.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                            { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                            x: 11.2,
                            y: award_1_y + 0.2,
                            h: 0.5,
                            w: 2.3,
                            color: "FFFFFF",
                            fontFace: 'Calibri',
                            align: pres.AlignH.center,
                        });
                    }
                    else {
                        awardEmployeeMarketTitle["VN"] = " Nhân sự Việt Nam";
                        awardEmployeeMarketTitle["EN"] = " VietNam Talents";
                        slide.addText([
                            { text: "#", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
                            { text: totalRanking_int.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
                            { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
                            { text: totalRespondents_int.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                            { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                            x: 11.2,
                            y: award_1_y + 0.2,
                            h: 0.5,
                            w: 2.3,
                            color: "FFFFFF",
                            fontFace: 'Calibri',
                            align: pres.AlignH.center,
                        });
                    }


                }
                /////////////////////////////////////////END 2023////////////////////////////////////////////////////////////////
            } else {

                // Ranking + Image before 2023

                let awardTitle = {};
                awardTitle["VN"] = "NƠI LÀM VIỆC TỐT NHẤT VIỆT NAM"
                awardTitle["EN"] = "VIETNAM BEST PLACES TO WORK";
                let awardSubTitle = {};
                awardSubTitle["VN"] = "BẢNG XẾP HẠNG";
                awardSubTitle["EN"] = "RANKING";

                slide.addText([
                    { text: awardTitle[scLanguage], options: { fontSize: 14, breakLine: true, bold: true, } },
                    { text: awardSubTitle[scLanguage], options: { fontSize: 10.5 } }], {
                    x: 10.5,
                    y: 0.95,
                    h: 0.5,
                    w: 3.8,
                    color: "FFFFFF",
                    fill: { color: COLOR_BLUELAGOON },
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });

                const industryranking = data_industry_ranking;
                const totalRanking = data_total_ranking;
                const totalRespondents = data_total_respondent;
                const displayTotalRespondents = numberWithCommas(totalRespondents);
                const top50VNAward = parseInt(top50VN);
                slide.addImage({ path: awardframe, x: 10.5, y: 1.45, h: 2.5, w: 3.8, sizing: { type: "contain", h: 2.5, w: 3.8, } });

                const firstFrameY = 1.9;
                const singleFrameY = 2.3;
                const secondFrameY = 3;
                const firstFrameInfoY = 2.1;
                const secondFrameInfoY = 3.1;
                if (industryranking > 0 && totalRanking > 0) {
                    slide.addImage({ path: industryAwardFrame, x: 11, y: firstFrameY, h: 0.95, w: 2.7 });
                    slide.addImage({ path: totalAwardFrame, x: 11, y: secondFrameY, h: 0.95, w: 2.7 });
                    let awardIndustryTitle = {};
                    awardIndustryTitle["VN"] = " NGÀNH";
                    awardIndustryTitle["EN"] = " INDUSTRY";
                    let awardEmployeeIndustryTitle = {};
                    if (survey_tag == "eoc") {
                        awardEmployeeIndustryTitle["VN"] = " sinh viên Việt Nam";
                        awardEmployeeIndustryTitle["EN"] = " Vietnamese students";
                    } else {
                        awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
                        awardEmployeeIndustryTitle["EN"] = " Industry Talents";
                    }
                    // awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
                    // awardEmployeeIndustryTitle["EN"] = " Industry Talents";
                    slide.addText([
                        { text: "#", options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                        { text: industryranking.toString(), options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                        { text: awardIndustryTitle[scLanguage], options: { fontSize: 17.5, breakLine: true, bold: true, color: COLOR_WHITE } },
                        { text: displayindustryrespondents, options: { fontSize: 10.5, color: COLOR_RED } },
                        { text: awardEmployeeIndustryTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                        x: 11.3,
                        y: firstFrameInfoY,
                        h: 0.5,
                        w: 2.1,
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });

                    let awardMarketTitle = {};
                    awardMarketTitle["VN"] = "TOÀN THỊ TRƯỜNG";
                    awardMarketTitle["EN"] = "TOTAL VIETNAM";
                    let awardEmployeeMarketTitle = {};
                    awardEmployeeMarketTitle["VN"] = " Nhân sự Việt Nam";
                    awardEmployeeMarketTitle["EN"] = " VietNam Talents";
                    slide.addText([
                        { text: "#", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
                        { text: totalRanking.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
                        { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
                        { text: displayTotalRespondents, options: { fontSize: 10.5, color: COLOR_RED } },
                        { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                        x: 11.2,
                        y: secondFrameInfoY,
                        h: 0.5,
                        w: 2.3,
                        color: "FFFFFF",
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                } else if (totalRanking > 0) {
                    let totalRanking_Y = singleFrameY;
                    if (top50VNAward > 0) {
                        totalRanking_Y = firstFrameY;
                        slide.addImage({ path: industryAwardFrame, x: 11, y: secondFrameY, h: 0.95, w: 2.7 });
                        let top50Text_1 = {};
                        top50Text_1["VN"] = "TOP 50";
                        top50Text_1["EN"] = "TOP 50";
                        let top50Text_2 = {};
                        top50Text_2["VN"] = "Doanh nghiệp Việt";
                        top50Text_2["EN"] = "Vietnamese organizations";
                        let top50Text_3 = {};
                        top50Text_3["VN"] = " có THNTD hấp dẫn";
                        top50Text_3["EN"] = " with attractive EB";
                        slide.addText([
                            { text: top50Text_1[scLanguage], options: { fontSize: 17, color: COLOR_WHITE, bold: true, breakLine: true } },
                            { text: top50Text_2[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } },
                            { text: top50Text_3[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } }], {
                            x: 11.2,
                            y: secondFrameY + 0.2,
                            h: 0.5,
                            w: 2.3,
                            color: "FFFFFF",
                            fontFace: 'Calibri',
                            align: pres.AlignH.center,
                        });
                    }
                    slide.addImage({ path: totalAwardFrame, x: 11, y: totalRanking_Y, h: 0.95, w: 2.7 });
                    let awardMarketTitle = {};
                    awardMarketTitle["VN"] = "TOÀN THỊ TRƯỜNG";
                    awardMarketTitle["EN"] = "TOTAL VIETNAM";
                    let awardEmployeeMarketTitle = {};
                    awardEmployeeMarketTitle["VN"] = " Nhân sự Việt Nam";
                    awardEmployeeMarketTitle["EN"] = " VietNam Talents";
                    slide.addText([
                        { text: "#", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
                        { text: totalRanking.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
                        { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
                        { text: displayTotalRespondents, options: { fontSize: 10.5, color: COLOR_RED } },
                        { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                        x: 11.2,
                        y: totalRanking_Y + 0.2,
                        h: 0.5,
                        w: 2.3,
                        color: "FFFFFF",
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                    if (top50VNAward > 0) {
                        slide.addImage({ path: industryAwardFrame, x: 11, y: secondFrameY, h: 0.95, w: 2.7 });
                        let top50Text_1 = {};
                        top50Text_1["VN"] = "TOP 50";
                        top50Text_1["EN"] = "TOP 50";
                        let top50Text_2 = {};
                        top50Text_2["VN"] = "Doanh nghiệp Việt";
                        top50Text_2["EN"] = "Vietnamese organizations";
                        let top50Text_3 = {};
                        top50Text_3["VN"] = " có THNTD hấp dẫn";
                        top50Text_3["EN"] = " with attractive EB";
                        slide.addText([
                            { text: top50Text_1[scLanguage], options: { fontSize: 17, color: COLOR_WHITE, bold: true, breakLine: true } },
                            { text: top50Text_2[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } },
                            { text: top50Text_3[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } }], {
                            x: 11.2,
                            y: secondFrameY + 0.2,
                            h: 0.5,
                            w: 2.3,
                            color: "FFFFFF",
                            fontFace: 'Calibri',
                            align: pres.AlignH.center,
                        });
                    }
                }
                else if (industryranking > 0) {
                    let industryRanking_Y = singleFrameY;
                    if (top50VNAward > 0) {
                        industryRanking_Y = firstFrameY;
                        slide.addImage({ path: industryAwardFrame, x: 11, y: secondFrameY, h: 0.95, w: 2.7 });
                        let top50Text_1 = {};
                        top50Text_1["VN"] = "TOP 50";
                        top50Text_1["EN"] = "TOP 50";
                        let top50Text_2 = {};
                        top50Text_2["VN"] = "Doanh nghiệp Việt";
                        top50Text_2["EN"] = "Vietnamese organizations";
                        let top50Text_3 = {};
                        top50Text_3["VN"] = " có THNTD hấp dẫn";
                        top50Text_3["EN"] = " with attractive EB";
                        slide.addText([
                            { text: top50Text_1[scLanguage], options: { fontSize: 17, color: COLOR_WHITE, bold: true, breakLine: true } },
                            { text: top50Text_2[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } },
                            { text: top50Text_3[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } }], {
                            x: 11.2,
                            y: secondFrameY + 0.2,
                            h: 0.5,
                            w: 2.3,
                            color: "FFFFFF",
                            fontFace: 'Calibri',
                            align: pres.AlignH.center,
                        });
                    }
                    slide.addImage({ path: industryAwardFrame, x: 11, y: industryRanking_Y, h: 0.95, w: 2.7 });
                    let awardIndustryTitle = {};
                    awardIndustryTitle["VN"] = " NGÀNH";
                    awardIndustryTitle["EN"] = " INDUSTRY";
                    let awardEmployeeIndustryTitle = {};
                    awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
                    awardEmployeeIndustryTitle["EN"] = " Industry Talents";
                    slide.addText([
                        { text: "#", options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                        { text: industryranking.toString(), options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                        { text: awardIndustryTitle[scLanguage], options: { fontSize: 17.5, breakLine: true, bold: true, color: COLOR_WHITE } },
                        { text: displayindustryrespondents, options: { fontSize: 10.5, color: COLOR_RED } },
                        { text: awardEmployeeIndustryTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                        x: 11.3,
                        y: industryRanking_Y + 0.2,
                        h: 0.5,
                        w: 2.1,
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                } else if (top50VNAward > 0) {
                    slide.addImage({ path: industryAwardFrame, x: 11, y: firstFrameY, h: 0.95, w: 2.7 });
                    let top50Text_1 = {};
                    top50Text_1["VN"] = "TOP 50";
                    top50Text_1["EN"] = "TOP 50";
                    let top50Text_2 = {};
                    top50Text_2["VN"] = "Doanh nghiệp Việt";
                    top50Text_2["EN"] = "Vietnamese organizations";
                    let top50Text_3 = {};
                    top50Text_3["VN"] = " có THNTD hấp dẫn";
                    top50Text_3["EN"] = " with attractive EB";
                    slide.addText([
                        { text: top50Text_1[scLanguage], options: { fontSize: 17, color: COLOR_WHITE, bold: true, breakLine: true } },
                        { text: top50Text_2[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } },
                        { text: top50Text_3[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } }], {
                        x: 11.2,
                        y: firstFrameY + 0.2,
                        h: 0.5,
                        w: 2.3,
                        color: "FFFFFF",
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                }
                // End Ranking
                // Images
                slide.addText("", {
                    x: 10.5,
                    y: 4.1,
                    h: 3.6,
                    w: 3.8,
                    color: "000000",
                    fontFace: 'Calibri',
                    fontSize: 16,
                    bold: true,
                    align: pres.AlignH.center,
                    line: { width: 1, color: 'A9A9A9' },
                });
                let companyImageTitle_1 = {};
                companyImageTitle_1["VN"] = "TOP 5";
                companyImageTitle_1["EN"] = "TOP 5 MOST PERCEIVED";
                let companyImageTitle_2 = {};
                companyImageTitle_2["VN"] = "HÌNH ẢNH THNTD NỔI BẬT";
                companyImageTitle_2["EN"] = "EMPLOYER BRAND IMAGES";
                slide.addText([{ text: companyImageTitle_1[scLanguage], options: { breakLine: true } }, { text: companyImageTitle_2[scLanguage] }], {
                    x: 10.5,
                    y: 4.1,
                    h: 0.6,
                    w: 3.8,
                    color: COLOR_WHITE,
                    fontFace: 'Calibri',
                    fontSize: 20,
                    bold: true,
                    align: pres.AlignH.center,
                    line: { width: 1, color: COLOR_BLUELAGOON },
                    fill: { color: COLOR_BLUELAGOON }
                });
                slide.addText("1", {
                    x: 10.55,
                    y: 4.75,
                    h: 0.5,
                    w: 0.3,
                    color: COLOR_WHITE,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.center,
                    line: { width: 1, color: COLOR_DARKGRAY },
                    fill: { color: COLOR_DARKGRAY }
                });
                slide.addText(data_brandimages_detail_1, {
                    x: 10.9,
                    y: 4.75,
                    h: 0.5,
                    w: 3.3,
                    color: COLOR_BLACK,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.left,
                    line: { width: 1, color: COLOR_LIGHTGRAY },
                    fill: { color: COLOR_LIGHTGRAY }
                });
                slide.addText("2", {
                    x: 10.55,
                    y: 5.3,
                    h: 0.5,
                    w: 0.3,
                    color: COLOR_WHITE,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.center,
                    line: { width: 1, color: COLOR_DARKGRAY },
                    fill: { color: COLOR_DARKGRAY }
                });
                slide.addText(data_brandimages_detail_2, {
                    x: 10.9,
                    y: 5.3,
                    h: 0.5,
                    w: 3.3,
                    color: COLOR_BLACK,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.left,
                    line: { width: 1, color: COLOR_LIGHTGRAY },
                    fill: { color: COLOR_LIGHTGRAY }
                });
                slide.addText("3", {
                    x: 10.55,
                    y: 5.85,
                    h: 0.5,
                    w: 0.3,
                    color: COLOR_WHITE,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.center,
                    line: { width: 1, color: COLOR_DARKGRAY },
                    fill: { color: COLOR_DARKGRAY }
                });
                slide.addText(data_brandimages_detail_3, {
                    x: 10.9,
                    y: 5.85,
                    h: 0.5,
                    w: 3.3,
                    color: COLOR_BLACK,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.left,
                    line: { width: 1, color: COLOR_LIGHTGRAY },
                    fill: { color: COLOR_LIGHTGRAY }
                });
                slide.addText("4", {
                    x: 10.55,
                    y: 6.4,
                    h: 0.5,
                    w: 0.3,
                    color: COLOR_WHITE,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.center,
                    line: { width: 1, color: COLOR_DARKGRAY },
                    fill: { color: COLOR_DARKGRAY }
                });
                slide.addText(data_brandimages_detail_4, {
                    x: 10.9,
                    y: 6.4,
                    h: 0.5,
                    w: 3.3,
                    color: COLOR_BLACK,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.left,
                    line: { width: 1, color: COLOR_LIGHTGRAY },
                    fill: { color: COLOR_LIGHTGRAY }
                });
                slide.addText("5", {
                    x: 10.55,
                    y: 6.95,
                    h: 0.5,
                    w: 0.3,
                    color: COLOR_WHITE,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.center,
                    line: { width: 1, color: COLOR_DARKGRAY },
                    fill: { color: COLOR_DARKGRAY }
                });
                slide.addText(data_brandimages_detail_5, {
                    x: 10.9,
                    y: 6.95,
                    h: 0.5,
                    w: 3.3,
                    color: COLOR_BLACK,
                    fontFace: 'Calibri',
                    fontSize: 15,
                    align: pres.AlignH.left,
                    line: { width: 1, color: COLOR_LIGHTGRAY },
                    fill: { color: COLOR_LIGHTGRAY }
                });
            }


            // let awardTitle = {};
            // awardTitle["VN"] = "NƠI LÀM VIỆC TỐT NHẤT VIỆT NAM"
            // awardTitle["EN"] = "VIETNAM BEST PLACES TO WORK";
            // let awardSubTitle = {};
            // awardSubTitle["VN"] = "BẢNG XẾP HẠNG";
            // awardSubTitle["EN"] = "RANKING";
            // slide.addText([
            //     { text: awardTitle[scLanguage], options: { fontSize: 14, breakLine: true, bold: true, } },
            //     { text: awardSubTitle[scLanguage], options: { fontSize: 10.5 } }], {
            //     x: 10.5,
            //     y: 0.95,
            //     h: 0.5,
            //     w: 3.8,
            //     color: "FFFFFF",
            //     fill: { color: COLOR_BLUELAGOON },
            //     fontFace: 'Calibri',
            //     align: pres.AlignH.center,
            // });
            // slide.addText("", {
            //     x: 10.5,
            //     y: 1.45,
            //     h: 6.35,
            //     w: 3.8,
            //     color: "000000",
            //     fontFace: 'Calibri',
            //     fontSize: 16,
            //     bold: true,
            //     align: pres.AlignH.center,
            //     fill: { color: COLOR_BLACK }
            // });
            // const industryranking = data_industry_ranking;
            // const totalRanking = data_total_ranking;
            // const totalRespondents = data_total_respondent;
            // const displayTotalRespondent = numberWithCommas(totalRespondents);

            // slide.addImage({ path: awardframe, x: 10.5, y: 1.45, h: 2.5, w: 3.8, sizing: { type: "contain", h: 2.5, w: 3.8, } });
            // if (industryranking > 0 && totalRanking > 0) {
            //     slide.addImage({ path: industryAwardFrame, x: 11, y: 4, h: 0.95, w: 2.7 });
            //     slide.addImage({ path: totalAwardFrame, x: 11, y: 6, h: 0.95, w: 2.7 });
            //     let awardIndustryTitle = {};
            //     awardIndustryTitle["VN"] = " NGÀNH";
            //     awardIndustryTitle["EN"] = " INDUSTRY";
            //     let awardEmployeeIndustryTitle = {};
            //     awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
            //     awardEmployeeIndustryTitle["EN"] = " Industry Talents";
            //     slide.addText([
            //         { text: "#", options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
            //         { text: industryranking.toString(), options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
            //         { text: awardIndustryTitle[scLanguage], options: { fontSize: 17.5, breakLine: true, bold: true, color: COLOR_WHITE } },
            //         { text: displayindustryrespondents, options: { fontSize: 10.5, color: COLOR_RED } },
            //         { text: awardEmployeeIndustryTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
            //         x: 11.3,
            //         y: 4.2,
            //         h: 0.5,
            //         w: 2.1,
            //         fontFace: 'Calibri',
            //         align: pres.AlignH.center,
            //     });

            //     let awardMarketTitle = {};
            //     awardMarketTitle["VN"] = "TOÀN THỊ TRƯỜNG";
            //     awardMarketTitle["EN"] = "TOTAL VIETNAM";
            //     let awardEmployeeMarketTitle = {};
            //     awardEmployeeMarketTitle["VN"] = " Nhân sự Việt Nam";
            //     awardEmployeeMarketTitle["EN"] = " VietNam Talents";
            //     slide.addText([
            //         { text: "#", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
            //         { text: totalRanking.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
            //         { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
            //         { text: displayTotalRespondent, options: { fontSize: 10.5, color: COLOR_RED } },
            //         { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
            //         x: 11.2,
            //         y: 6.2,
            //         h: 0.5,
            //         w: 2.3,
            //         color: "FFFFFF",
            //         fontFace: 'Calibri',
            //         align: pres.AlignH.center,
            //     });
            // } else {
            //     if (totalRanking > 0) {
            //         slide.addImage({ path: totalAwardFrame, x: 11, y: 4, h: 0.95, w: 2.7 });
            //         let awardMarketTitle = {};
            //         awardMarketTitle["VN"] = "TOÀN THỊ TRƯỜNG";
            //         awardMarketTitle["EN"] = "TOTAL VIETNAM";
            //         let awardEmployeeMarketTitle = {};
            //         awardEmployeeMarketTitle["VN"] = " Nhân sự Việt Nam";
            //         awardEmployeeMarketTitle["EN"] = " VietNam Talents";
            //         slide.addText([
            //             { text: "#", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
            //             { text: totalRanking.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
            //             { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
            //             { text: displayTotalRespondent, options: { fontSize: 10.5, color: COLOR_RED } },
            //             { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
            //             x: 11.2,
            //             y: 4.2,
            //             h: 0.5,
            //             w: 2.3,
            //             color: "FFFFFF",
            //             fontFace: 'Calibri',
            //             align: pres.AlignH.center,
            //         });
            //     }
            //     if (industryranking > 0) {
            //         slide.addImage({ path: industryAwardFrame, x: 11, y: 4, h: 0.95, w: 2.7 });
            //         let awardIndustryTitle = {};
            //         awardIndustryTitle["VN"] = " NGÀNH";
            //         awardIndustryTitle["EN"] = " INDUSTRY";
            //         let awardEmployeeIndustryTitle = {};
            //         awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
            //         awardEmployeeIndustryTitle["EN"] = " Industry Talents";
            //         slide.addText([
            //             { text: "#", options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
            //             { text: industryranking.toString(), options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
            //             { text: awardIndustryTitle[scLanguage], options: { fontSize: 17.5, breakLine: true, bold: true, color: COLOR_WHITE } },
            //             { text: displayindustryrespondents, options: { fontSize: 10.5, color: COLOR_RED } },
            //             { text: awardEmployeeIndustryTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
            //             x: 11.3,
            //             y: 4.2,
            //             h: 0.5,
            //             w: 2.1,
            //             fontFace: 'Calibri',
            //             align: pres.AlignH.center,
            //         });

            //     }
            // }

            let copyRightText = {};
            copyRightText["VN"] = "* Tài liệu này được thực hiện độc quyền cho khách hàng của Anphabe. Mọi hình thức tái xuất bản hoặc chia sẻ đều phải có sự đồng ý bằng văn bản của Anphabe.";
            copyRightText["EN"] = "* This document is proprietary and conﬁdential and exclusively share to Anphabe clients only. All rights reserved. No part of this document may be reproduced or shared to other company, without prior written consent of Anphabe.";
            slide.addText(copyRightText[scLanguage], {
                x: 2.85,
                y: 7.9,
                h: 0.35,
                w: 11.45,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 11,
                italic: true
            });
            // slide.addText("", {
            //     x: 10.5,
            //     y: 4.1,
            //     h: 3.6,
            //     w: 3.8,
            //     color: "000000",
            //     fontFace: 'Calibri',
            //     fontSize: 16,
            //     bold: true,
            //     align: pres.AlignH.center,
            //     line: { width: 1, color: 'A9A9A9' },
            // });

            // PART II - 2023
            if (externalimage1.length > 1) {
                /// ################ PAGE 2  START  ###################### ///
                slide = pres.addSlide();
                // ########## Left Side Bar  START PAGE 2 ############
                slide.addText("", {
                    x: 0,
                    y: 0,
                    h: 8.5,
                    w: 2.75,
                    fill: { color: "f2f2f2" },
                });
                slide.addImage({ path: cologo, x: logoX, y: logoY, h: logoH, w: logoW, sizing: { type: "contain", h: logoH, w: logoW } });
                slide.addText([{ text: companyName, options: { fontSize: 18, breakLine: true, bold: true } }, { text: companyIndustry, options: { fontSize: 14 } }], {
                    x: 0,
                    y: 2,
                    h: 1.2,
                    w: 2.6,
                    color: "FFFFFF",
                    fill: { color: COLOR_BLUELAGOON },
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                let awardEmployeeIndustryTitle = [];
                if (survey_tag == "eoc") {
                    awardEmployeeIndustryTitle["VN"] = "  Sinh viên trong Ngành";
                    awardEmployeeIndustryTitle["EN"] = " Student in related Majors";
                } else {
                    awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
                    awardEmployeeIndustryTitle["EN"] = " Industry Talents";
                }

                let sideBarInfo_1_2 = {};
                sideBarInfo_1_2["VN"] = "Kết quả được đánh giá bởi:";
                sideBarInfo_1_2["EN"] = "Final results are entirely based on:";
                // let nIndustry = 3000;
                // let nCompanyTitle = [];
                // nCompanyTitle["VN"] = " công ty trong ngành";
                // nCompanyTitle["EN"] = " Industry Talents";//FF0000
                // const displaynIndustry = numberWithCommas(nIndustry);
                slide.addText([{ text: sideBarInfo_1_2[scLanguage], options: { breakLine: true } },
                { text: displayindustryrespondents + " ", options: { bullet: { indent: 10 }, color: 'FF0000' } },
                { text: awardEmployeeIndustryTitle[scLanguage], options: { breakLine: true } },
                // { text: displaynIndustry + " ", options: { bullet: { indent: 10 }, color: 'FF0000' } },
                // { text: nCompanyTitle[scLanguage], options: { breakLine: true } },
                { text: sideBarInfo_conducted[scLanguage] + surveyperiod, options: { breakLine: true, bullet: { indent: 10 } } },
                ]
                    , {
                        x: 0,
                        y: 3.3,
                        h: 0.5,
                        w: 2.6,
                        color: "000000",
                        fontSize: 12,
                        fontFace: 'Calibri',
                        align: pres.AlignH.left,
                    });
                // slide.addText([{ text: sideBarInfo_1[scLanguage], options: { breakLine: true } },
                // { text: displayindustryrespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
                // { text: sideBarInfo_2[scLanguage], options: { breakLine: true } },
                // { text: companyRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
                // { text: companyRespondentText[scLanguage], options: { breakLine: true } },
                // { text: sideBarInfo_conducted[scLanguage] + surveyperiod, options: { bullet: { indent: 10 } } },
                // ]
                //     , {
                //         x: 0,
                //         y: 3.3,
                //         h: 1.3,
                //         w: 2.6,
                //         color: "000000",
                //         fontSize: 12,
                //         fontFace: 'Calibri',
                //         align: pres.AlignH.left,
                //     });
                slide.addText(sideBarInfo_contact[scLanguage], {
                    x: 0,
                    y: 4.8,
                    h: 0.8,
                    w: 2.6,
                    color: COLOR_BLUELAGOON,
                    fontSize: 11,
                    fontFace: 'Calibri',
                    italic: true,
                    align: pres.AlignH.justify,
                });
                slide.addText([{ text: "(+84 28) 6268 2222", options: { breakLine: true, bullet: { indent: 10 } } },
                { text: "clientsolution@anphabe.com", options: { bullet: { indent: 10 } } },
                ]
                    , {
                        x: 0,
                        y: 5.3,
                        h: 0.8,
                        w: 2.6,
                        color: COLOR_BLUELAGOON,
                        fontSize: 12,
                        bold: true,
                        italic: true,
                        fontFace: 'Calibri',
                        align: pres.AlignH.left,
                    });
                slide.addImage({ path: survey_logo, x: 0.1, y: 6.3, h: 1.6, w: 2.5, sizing: { type: "contain", h: 1.6, w: 2.5 } });
                //  ########### Side Bar END #############
                // BODY PAGE 2
                ///// TITLE 
                let page2_sc_title = {};
                page2_sc_title["VN"] = scorecard_title;
                page2_sc_title["EN"] = scorecard_title;
                // ######### HEADER START #############
                slide.addText(sc_subTitle[scLanguage], {
                    x: 2.9,
                    y: 0.05,
                    h: 0.2,
                    color: "363636",
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
                slide.addText(page2_sc_title[scLanguage], {
                    x: 2.9,
                    y: 0.25,
                    h: 0.6,
                    bold: true,
                    color: "363636",
                    fontSize: 28,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
                /////////////////// SLIDE 2 BODY
                let slide2ExternalTalents_1 = {};
                let slide2ExternalTalents_2 = {};
                slide2ExternalTalents_1["VN"] = "TOP 10 HÌNH ẢNH THNTD ĐƯỢC GHI NHẬN";
                slide2ExternalTalents_1["EN"] = "TOP 10 MOST PERCEIVED EMPLOYER BRAND IMAGES";

                if (survey_tag == "eoc") {
                    slide2ExternalTalents_2["VN"] = "Đánh giá bởi sinh viên trong ngành";
                    slide2ExternalTalents_2["EN"] = "By Industry Students";
                } else {
                    slide2ExternalTalents_2["VN"] = "Đánh giá bởi Nhân tài tiềm năng bên ngoài";
                    slide2ExternalTalents_2["EN"] = "By External talents";
                }

                slide.addText([{ text: slide2ExternalTalents_1[scLanguage], options: { bold: true, breakLine: true } },
                { text: slide2ExternalTalents_2[scLanguage] }], {
                    x: 3.05,
                    y: 1.2,
                    h: 0.5,
                    w: 5.1,
                    color: COLOR_WHITE,
                    fill: { color: COLOR_BLUELAGOON },
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                slide.addText("", {
                    x: 3.05,
                    y: 1.7,
                    h: 5.19,
                    w: 5.1,
                    color: "FFFFFF",
                    line: { width: 1, color: "A9A9A9" }
                });
                let externalImage: string[] = [];
                if (data_externalimage1.length > 0) {
                    externalImage.push(data_externalimage1);
                }
                if (data_externalimage2.length > 0) {
                    externalImage.push(data_externalimage2);
                }
                if (data_externalimage3.length > 0) {
                    externalImage.push(data_externalimage3);
                }
                if (data_externalimage4.length > 0) {
                    externalImage.push(data_externalimage4);
                }
                if (data_externalimage5.length > 0) {
                    externalImage.push(data_externalimage5);
                }
                if (data_externalimage6.length > 0) {
                    externalImage.push(data_externalimage6);
                }
                if (data_externalimage7.length > 0) {
                    externalImage.push(data_externalimage7);
                }
                if (data_externalimage8.length > 0) {
                    externalImage.push(data_externalimage8);
                }
                if (data_externalimage9.length > 0) {
                    externalImage.push(data_externalimage9);
                }
                if (data_externalimage10.length > 0) {
                    externalImage.push(data_externalimage10);
                }
                let baseExternal_Y = 1.8;
                let baseExternal_Count = 1;
                for (let i = 0; i < externalImage.length; i++) {
                    slide.addText(baseExternal_Count.toString(), {
                        x: 3.1,
                        y: baseExternal_Y,
                        h: 0.45,
                        w: 0.45,
                        color: COLOR_WHITE,
                        fill: { color: COLOR_DARKGRAY },
                        fontSize: 14,
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                    baseExternal_Count++;
                    slide.addText(externalImage[i], {
                        x: 3.6,
                        y: baseExternal_Y,
                        h: 0.45,
                        w: 4.4,
                        color: COLOR_BLACK,
                        fill: { color: COLOR_LIGHTGRAY },
                        fontSize: 14,
                        fontFace: 'Calibri',
                        align: pres.AlignH.left,
                    });
                    baseExternal_Y += 0.5;
                }

                let jobImportance: string[] = [];
                if (data_jobimportance1.length > 0) {
                    jobImportance.push(data_jobimportance1);
                }
                if (data_jobimportance2.length > 0) {
                    jobImportance.push(data_jobimportance2);
                }
                if (data_jobimportance3.length > 0) {
                    jobImportance.push(data_jobimportance3);
                }
                if (data_jobimportance4.length > 0) {
                    jobImportance.push(data_jobimportance4);
                }
                if (data_jobimportance5.length > 0) {
                    jobImportance.push(data_jobimportance5);
                }
                if (data_jobimportance6.length > 0) {
                    jobImportance.push(data_jobimportance6);
                }
                if (data_jobimportance7.length > 0) {
                    jobImportance.push(data_jobimportance7);
                }
                if (data_jobimportance8.length > 0) {
                    jobImportance.push(data_jobimportance8);
                }
                if (data_jobimportance9.length > 0) {
                    jobImportance.push(data_jobimportance9);
                }
                if (data_jobimportance10.length > 0) {
                    jobImportance.push(data_jobimportance10);
                }
                let slide2CompanyTalents_1 = {};
                slide2CompanyTalents_1["VN"] = "TOP 10 TIÊU CHÍ TẠI NƠI LÀM VIỆC LÝ TƯỞNG";
                slide2CompanyTalents_1["EN"] = "TOP 10 IDEAL CRITERIA OF A WORKPLACE";
                let slide2CompanyTalents_2 = {};
                if (survey_tag == "eoc") {
                    slide2CompanyTalents_2["VN"] = "Đánh giá bởi sinh viên trong ngành";
                    slide2CompanyTalents_2["EN"] = "By Industry Students";
                }
                else {
                    slide2CompanyTalents_2["VN"] = "Đánh giá bởi Nhân tài Ngành";
                    slide2CompanyTalents_2["EN"] = "By Industry Talents";
                }

                slide.addText([{ text: slide2CompanyTalents_1[scLanguage], options: { bold: true, breakLine: true } },
                { text: slide2CompanyTalents_2[scLanguage] }], {
                    x: 8.6,
                    y: 1.2,
                    h: 0.5,
                    w: 5.1,
                    color: COLOR_WHITE,
                    fill: { color: COLOR_BLUELAGOON },
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                slide.addText("", {
                    x: 8.6,
                    y: 1.7,
                    h: 5.19,
                    w: 5.1,
                    color: "FFFFFF",
                    line: { width: 1, color: "A9A9A9" }
                });
                let baseInternal_Y = 1.8;
                let baseInternal_Count = 1;
                for (let i = 0; i < jobImportance.length; i++) {
                    slide.addText(baseInternal_Count.toString(), {
                        x: 8.65,
                        y: baseInternal_Y,
                        h: 0.45,
                        w: 0.45,
                        color: COLOR_WHITE,
                        fill: { color: COLOR_DARKGRAY },
                        fontSize: 14,
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                    slide.addText(jobImportance[i], {
                        x: 9.15,
                        y: baseInternal_Y,
                        h: 0.45,
                        w: 4.4,
                        color: COLOR_BLACK,
                        fill: { color: COLOR_LIGHTGRAY },
                        fontSize: 14,
                        fontFace: 'Calibri',
                        align: pres.AlignH.left,
                    });
                    baseInternal_Count++;
                    baseInternal_Y += 0.5;
                }
                slide.addText(copyRightText[scLanguage], {
                    x: 2.85,
                    y: 7.9,
                    h: 0.35,
                    w: 11.45,
                    color: COLOR_DARKGRAY,
                    fontFace: 'Calibri',
                    fontSize: 11,
                    italic: true
                });
                // ############ END SLIDE 2 #############
            }

            ///// Export /////
            const timeStamp = Date.now();
            let objectDate = new Date();
            let day = objectDate.getDate();
            let month = objectDate.getMonth() + 1;
            let year = objectDate.getFullYear();
            let displaytime = year + "" + month + "" + day;
            pres.writeFile({ fileName: companyName + "_" + companyIndustry + "_" + survey_tag + "_" + displaytime + ".pptx" });
        };
        return (
            <div className={classes.main}>
                <div>
                    <img src={cologo} id={"companylogo"} width={"150px"} alt={""} />
                </div>
                <div className={classes.title}>{company_name}</div>
                <div className={classes.title}>Báo cáo: {survey_title}</div>
                <div className={classes.title}>Năm: {year}</div>
                <div className={classes.title}>Ngôn ngữ : {scLanguage}</div>
                <div className={classes.title}>
                    <button onClick={() => doExport()}> Export PPTX</button>
                </div>
            </div>
        )
    };

export default VNBPTWScoreCardExport;