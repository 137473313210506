import React, {
    FC,
} from 'react';
import {makeStyles} from "@material-ui/styles";

interface Props {
    report_lg: string;
    survey_tag: string;
}

const infoText = {
    en: {
        "vnbptw": {
            legalCopyRight: {
                part1: "(*) This document is proprietary and conﬁdential and exclusively share to Anphabe clients only. All rights reserved. No part of this document may be reproduced or shared to other company, without prior written consent of Anphabe.",
            },
        }
    },
    vn: {
        "vnbptw": {
            legalCopyRight: {
                part1: "(*) This document is proprietary and conﬁdential and exclusively share to Anphabe clients only. All rights reserved. No part of this document may be reproduced or shared to other company, without prior written consent of Anphabe.",
            },
        }
    }
};
const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "100%",
        fontSize: "10pt",
        paddingTop: "12px"
    }),
}));

const LegalCopyRight: FC<Props> = ({report_lg = "vn", survey_tag = "vnbptw"}) => {
    const classes = useStyles();
    return (<div className={classes.main}>
            {infoText[report_lg][survey_tag].legalCopyRight.part1}
        </div>
    )
};

export default LegalCopyRight;