import * as React from 'react';
import {FC} from 'react';
import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    TextInput,
    PasswordInput,
    SelectArrayInput
} from 'react-admin';
import {User} from "../types";

export const UserTitle: FC<FieldProps<User>> = ({record}) => {
    return <span> User {record ? `"${record.name}"` : ''}</span>
}
const UserEdit: FC<EditProps> = props => (
    <Edit title={<UserTitle/>} {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput source="name"/>
            <TextInput label="Email Address" source="email" type="email"/>
            <PasswordInput source="password"/>
            <SelectArrayInput label="ROLES" source="roles" choices={[
                {id: 'ROLE_USER', name: 'ROLE_USER'},
                {id: 'ROLE_SALES', name: 'ROLE_SALES'},
                {id: 'ROLE_SURVEY', name: 'ROLE_SURVEY'},
                {id: 'ROLE_ADMIN', name: 'ROLE_ADMIN'},
                {id: 'ROLE_SALES_ADMIN', name: 'ROLE_SALES_ADMIN'}
            ]}/>
        </SimpleForm>
    </Edit>
)
export default UserEdit;