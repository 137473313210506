import InternalScoreCard2022Create from './InternalScoreCard2022Create';
import InternalScoreCard2022Edit from './InternalScoreCard2022Edit';
import InternalScoreCard2022List from './InternalScoreCard2022List';
import InternalScoreCard2022Show from './InternalScoreCard2022Show';


export default {
    create: InternalScoreCard2022Create,
    edit: InternalScoreCard2022Edit,
    list: InternalScoreCard2022List,
    show: InternalScoreCard2022Show
};
