import * as React from 'react';
import {FC} from 'react';

import {
    EditButton,
    List,
    ListProps,
    FilterProps,
    Datagrid,
    TextField,
    Filter,
    ReferenceInput,
    ReferenceField,
    AutocompleteInput,
    required,
    TextInput,
    usePermissions
} from 'react-admin';

const CompanyAwardFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <ReferenceInput
            label="Award"
            source="award_id"
            reference="awardscategory"
            filterToQuery={searchText => ({ name: searchText })}
            validate={[required()]}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            label="Company"
            source="company_id"
            reference="companies"
            filterToQuery={searchText => ({ commercialName: searchText })}
            validate={[required()]}>
            <AutocompleteInput optionText="commercialName" alwaysOn/>
        </ReferenceInput>
        <TextInput source="year"  />
    </Filter>
);


const CompanyAwardList: FC<ListProps> = props => {
    const { loaded, permissions } = usePermissions();

    if (!loaded) {
      return null; // Đợi quyền truy cập được tải
    }
  
    const isAdmin = permissions.includes('ROLE_ADMIN');
    const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
    const isSurvey = permissions.includes('ROLE_SURVEY');
    return (
        <List title="All Awards" {...props} filters={<CompanyAwardFilter/>} hasCreate = {isAdmin||isSurvey}>
            <Datagrid>
                <TextField source="id"/>
                <ReferenceField label="Company"
                                reference="companies"
                                source="company_id"
                >
                    <TextField source="commercialName"/>
                </ReferenceField>
                <ReferenceField label="Award"
                                reference="awardscategory"
                                source="award_id"
                >
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="year"/>
                <TextField source="ranking"/>
                {(isAdmin||isSurvey) && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default CompanyAwardList;