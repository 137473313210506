import * as React from 'react';
import {FC} from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';

const AwardCreate: FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm redirect={"list"}>
            <TextInput source="name"  validate={[required()]} fullWidth/>
            <TextInput source="codeName"  validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export default AwardCreate;