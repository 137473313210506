import * as React from 'react';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { usePermissions } from 'react-admin';

const UserEditToolbar = (props) => {
  const { loaded, permissions } = usePermissions();

  if (!loaded) {
    return null; // Đợi quyền truy cập được tải
  }

  const isAdmin = permissions.includes('ROLE_ADMIN');

  return (
    <Toolbar {...props}>
      {isAdmin && <SaveButton />}
      {isAdmin && <DeleteButton />}
    </Toolbar>
  );
};
export default UserEditToolbar;