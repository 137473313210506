import * as React from 'react';
import { FC } from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    AutocompleteInput,
    NumberInput
} from 'react-admin';

const HawScoreCardCreate: FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm redirect={"list"}>
            <ReferenceInput
                label="Award"
                source="template_id"
                reference="scorecardtemplate"
                filterToQuery={searchText => ({ name: searchText })}
                validate={[required()]}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput
                label="Company"
                source="company_id"
                reference="companies"
                filterToQuery={searchText => ({ commercialName: searchText })}
                validate={[required()]}>
                <AutocompleteInput optionText="commercialName" />
            </ReferenceInput>

            <ReferenceInput
                label="Industry"
                source="company_industry"
                reference="industries"
                filterToQuery={searchText => ({ name: searchText })}
                validate={[required()]}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <TextInput source="respondents_conducted_date" validate={[required()]} />

            <TextInput source="company_industry" validate={[required()]} label="Company Industry" fullWidth/>
            <NumberInput source="respondents_amount" validate={[required()]} />

            <NumberInput source="company_index" validate={[required()]} />
            <NumberInput source="industry_index" validate={[required()]} />
            <NumberInput source="vietnam_index" validate={[required()]} />


            <NumberInput source="rational_company" validate={[required()]} />
            <NumberInput source="rational_industry" validate={[required()]} />
            <NumberInput source="rational_vietnam" validate={[required()]} />

            <NumberInput source="emotional_company" validate={[required()]} />
            <NumberInput source="emotional_industry" validate={[required()]} />
            <NumberInput source="emotional_vietnam" validate={[required()]} />

            <NumberInput source="motivational_company" validate={[required()]} />
            <NumberInput source="motivational_industry" validate={[required()]} />
            <NumberInput source="motivational_vietnam" validate={[required()]} />

            <NumberInput source="motivational_company" validate={[required()]} />
            <NumberInput source="motivational_industry" validate={[required()]} />
            <NumberInput source="motivational_vietnam" validate={[required()]} />

            <NumberInput source="effort_company" validate={[required()]} />
            <NumberInput source="effort_industry" validate={[required()]} />
            <NumberInput source="effort_vietnam" validate={[required()]} />

            <NumberInput source="commit_company" validate={[required()]} />
            <NumberInput source="commit_industry" validate={[required()]} />
            <NumberInput source="commit_vietnam" validate={[required()]} />


            <NumberInput source="vietnam_core" validate={[required()]} />
            <NumberInput source="vietnam_index" validate={[required()]} />
            <NumberInput source="vietnam_index" validate={[required()]} />
            <NumberInput source="vietnam_quitter" validate={[required()]} />
            <NumberInput source="vietnam_zombie" validate={[required()]} />

            <NumberInput source="company_core" validate={[required()]} />
            <NumberInput source="company_index" validate={[required()]} />
            <NumberInput source="company_index" validate={[required()]} />
            <NumberInput source="company_quitter" validate={[required()]} />
            <NumberInput source="company_zombie" validate={[required()]} />


        </SimpleForm>
    </Create>
);

export default HawScoreCardCreate;