import InternalScoreCard2023Create from './InternalScoreCard2023Create';
import InternalScoreCard2023Edit from './InternalScoreCard2023Edit';
import InternalScoreCard2023List from './InternalScoreCard2023List';
import InternalScoreCard2023Show from './InternalScoreCard2023Show';


export default {
    create: InternalScoreCard2023Create,
    edit: InternalScoreCard2023Edit,
    list: InternalScoreCard2023List,
    show: InternalScoreCard2023Show
};
