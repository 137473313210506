import * as React from 'react';
import { FC} from 'react';
import InternalScoreCard2021 from "./InternalScoreCard2021";
import {
    ShowProps,
    useShowController,
} from 'react-admin';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "1600px",
    })
}));
const ScoreCard = props => {
    const {
        record, // record fetched via dataProvider.getOne() based on the id from the location
    } = useShowController(props);
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <InternalScoreCard2021
                props={record}
            />
        </div>
    );
}


const InternalScoreCard2021Show: FC<ShowProps> = props => (
    <ScoreCard {...props}>
    </ScoreCard>
);

export default InternalScoreCard2021Show