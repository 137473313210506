import InternalScoreCard2021Create from './InternalScoreCard2021Create';
import InternalScoreCard2021Edit from './InternalScoreCard2021Edit';
import InternalScoreCard2021List from './InternalScoreCard2021List';
import InternalScoreCard2021Show from './InternalScoreCard2021Show';


export default {
    create: InternalScoreCard2021Create,
    edit: InternalScoreCard2021Edit,
    list: InternalScoreCard2021List,
    show: InternalScoreCard2021Show
};
