import * as React from 'react';
import {FC} from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    AutocompleteInput,
    NumberInput
} from 'react-admin';

const CompanyScoreCardCreate: FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm redirect={"list"}>
            <ReferenceInput
                label="Award"
                source="template_id"
                reference="scorecardtemplate"
                filterToQuery={searchText => ({name: searchText})}
                validate={[required()]}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>

            <ReferenceInput
                label="Company"
                source="company_id"
                reference="companies"
                filterToQuery={searchText => ({commercialName: searchText})}
                validate={[required()]}>
                <AutocompleteInput optionText="commercialName"/>
            </ReferenceInput>

            <ReferenceInput
                label="Industry"
                source="company_industry"
                reference="industries"
                filterToQuery={searchText => ({name: searchText})}
                validate={[required()]}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>

            <TextInput source="company_industry" validate={[required()]} label="Company Industry" fullWidth/>

            <TextInput source="respondents_conducted_date" validate={[required()]}/>

            <NumberInput source="respondents_amount" validate={[required()]}/>

            <NumberInput source="firstchoice_index_value" validate={[required()]}/>

            <NumberInput source="firstchoice_benchmark" validate={[required()]}/>

            <NumberInput source="desire_index_value" validate={[required()]}/>

            <NumberInput source="desire_benchmark" validate={[required()]}/>

            <NumberInput source="action_index_value" validate={[required()]}/>

            <NumberInput source="action_benchmark" validate={[required()]}/>

            <NumberInput source="interest_index_value" validate={[required()]}/>

            <NumberInput source="interest_benchmark" validate={[required()]}/>

            <NumberInput source="attention_index_value" validate={[required()]}/>

            <NumberInput source="attention_benchmark" validate={[required()]}/>

            <NumberInput source="rejection_index_value" validate={[required()]}/>

            <NumberInput source="rejection_benchmark" validate={[required()]}/>

            <NumberInput source="ebaindex_index_value" validate={[required()]}/>

            <NumberInput source="ebaindex_benchmark" validate={[required()]}/>

            <NumberInput source="total_ranking" validate={[required()]}/>

            <NumberInput source="industry_ranking" validate={[required()]}/>

            <TextInput source="brandimages_detail_1" validate={[required()]}/>

            <TextInput source="brandimages_detail_2" validate={[required()]}/>

            <TextInput source="brandimages_detail_3" validate={[required()]}/>

            <TextInput source="brandimages_detail_4" validate={[required()]}/>

            <TextInput source="brandimages_detail_5" validate={[required()]}/>

            <NumberInput source="attention_to_interest" validate={[required()]}/>

            <NumberInput source="interest_to_action" validate={[required()]}/>

            <NumberInput source="desire_to_1stchoice" validate={[required()]}/>

            <NumberInput source="total_respondent" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export default CompanyScoreCardCreate;