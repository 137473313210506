import MediaCatCreate from './MediaCatCreate';
import MediaCatEdit from './MediaCatEdit';
import MediaCatList from './MediaCatList';


export default {
    create: MediaCatCreate,
    edit: MediaCatEdit,
    list: MediaCatList,
};
