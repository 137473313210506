import React, {
    FC,
} from 'react';
import {makeStyles} from "@material-ui/styles";

interface Props {
    respondents_amount: number;
    respondents_conducted_date: string;
    survey_title: any;
    language: any;
    survey_tag: any;
    year: number;
}

const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "100%",
        fontSize: "10pt",
        paddingTop: "10px",
    }),
    text: () => ({
        marginLeft: "9px",
        marginRight: "9px",
        lineHeight: "18px"
    })
}));

const infoText = {
    en: {
        "haw": {
            respondentInfo: {
                part1: "Final results are entirely based on :",
                part2: " professionals."
            },
            conductDate: {
                part1: "● Time:"
            },
            surveyInfo: {
                part1: "● Benchmark used for comparison was taken from surveys Anphabe conducted in "
            }
        }
    },
    vn: {
        "haw": {
            respondentInfo: {
                part1: "Kết quả được đánh giá bởi: ",
                part2: " người đi làm trong ngành "
            },
            conductDate: {
                part1: "● Thời gian:"
            },
            surveyInfo: {
                part1: "●  Điểm chuẩn so sánh dựa trên kết quả các khảo sát Anphabe thực hiện năm "
            }
        }
    }
};
const numberWithCommas = (x) =>{
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};
const SurveyInfo: FC<Props> = ({respondents_amount='', respondents_conducted_date='', survey_title='', language="vn", survey_tag="haw", year= 0}) => {
    const industry_respondent_amount = numberWithCommas((respondents_amount) ? respondents_amount : 0);
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className={classes.text}>
                {infoText[language][survey_tag].respondentInfo.part1}<b> {industry_respondent_amount} </b>{infoText[language][survey_tag].respondentInfo.part2}
            </div>
            <div className={classes.text} style={{marginLeft:"5px"}}>
                <b> {infoText[language][survey_tag].conductDate.part1} {respondents_conducted_date} </b><br/>
                {infoText[language][survey_tag].surveyInfo.part1} <b>{year}</b>
            </div>
        </div>
    )
};

export default SurveyInfo;