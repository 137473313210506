import * as React from "react";
import {FC} from 'react';

import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    TextInput,
    required,
    ArrayInput,
    SimpleFormIterator
} from 'react-admin';
import {SalesBenefit} from "../types";

export const IndustryTitle: FC<FieldProps<SalesBenefit>> = ({record}) => {
    return <span> Industry {record ? `"${record.name}"` : ''}</span>
}

const SalesBenefitEdit: FC<EditProps> = props => {
    return (
        <Edit title={<IndustryTitle/>} {...props}>
            <SimpleForm>
                <TextInput disabled source="id"/>
                <TextInput source="name" fullWidth validate={[required()]}/>
                <TextInput source="setting_keys" fullWidth />
            </SimpleForm>
        </Edit>
    )
}
export default SalesBenefitEdit;