import * as React from 'react';
import { useMediaQuery, Theme } from '@material-ui/core';
import { FC } from 'react';

import {
    EditButton,
    SimpleList,
    List,
    ListProps,
    Datagrid,
    EmailField,
    TextField,
    usePermissions,   
    
} from 'react-admin';


const UserList: FC<ListProps> = props => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const { loaded, permissions } = usePermissions();

    if (!loaded) {
      return null; // Đợi quyền truy cập được tải
    }
  
    const isAdmin = permissions.includes('ROLE_ADMIN');
    return (
        <List title="All users" {...props} hasCreate = {isAdmin}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.username}
                    tertiaryText={record => record.email}
                />
            ) : (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <EmailField source="email" />
                    {isAdmin && <EditButton />}
                </Datagrid>
            )}
        </List>
        
    );
};

export default UserList;