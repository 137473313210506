import React, {
    FC,
} from 'react';
import {makeStyles} from "@material-ui/styles";
import brainIcon from "./images/brain_150.png";
import flameIcon from "./images/flame_150.png";
import gymIcon from "./images/gym_150.png";
import handIcon from "./images/hand_150.png";
import heartIcon from "./images/heart_150.png";

interface Props {
    language: string,
    survey_tag: string,
    company_short_name: string,
    rational_company: number,
    rational_industry: number,
    rational_vietnam: number,
    emotional_company: number,
    emotional_industry: number,
    emotional_vietnam: number,
    motivational_company: number,
    motivational_industry: number,
    motivational_vietnam: number,
    effort_company: number,
    effort_industry: number,
    effort_vietnam: number,
    commit_company: number,
    commit_industry: number,
    commit_vietnam: number
}

const useStyles = makeStyles(theme => ({
        main: () => ({
            width: "100%",
            font: "bold",
            paddingTop: "10px",
            display: 'flex',
        }),
        engagementMotivation: () => ({
            width: "50%",
            paddingRight: "20px"
        }),
        performanceLoyalty: () => ({
            paddingLeft: "12px",
            width: "48%",
            paddingTop: "50px"
        }),
        subTitle: () => ({
            backgroundColor: "#595959",
            color: "#ffffff",
            fontWeight: "bold",
            lineHeight: "30px",
            textAlign: "center"
        }),
        scoreBox: () => ({
            borderLeft: "#1b1e21 dashed 1px",
            borderRight: "#1b1e21 dashed 1px",
            borderBottom: "#1b1e21 dashed 1px",
            display: "flex",
            paddingTop: "5px",
            paddingBottom: "5px"
        }),
        scoreBoxTitle: () => ({
            display: "flex",
            paddingTop: "5px",
            paddingBottom: "5px",
            textAlign: "center",

        }),
        pentagonArrowSmall: () => ({
            height: "134px",
            position: "relative",
            background: "#a6a6a6",
            margin: "auto",
            width: "60px",
            textAlign: "center"
        }),
        pentagonArrowSmallBefore: () => ({
            content: "",
            position: "relative",
            bottom: 0,
            borderLeft: "20px solid #a6a6a6",
            borderTop: "68px solid transparent",
            borderBottom: "68px solid transparent"
        }),
        chartTitle: () => ({
            color: "#ffffff",
            fontWeight: "bold",
            fontSize: "15px",
            textAlign: "center"
        }),
        chartBoxTitle: () => ({
            flex: "0 0 11%",
            maxWidth: "11%",
        }),
        chartBoxTitleSpan: () => ({
            display: "inline-block",
            transform: "rotate(-90deg)",
            color: "#a6a6a6",
            fontSize: "17px",
            fontWeight: "bold",
            textAlign: "center",
            margin: "auto"
        }),
        chartBox: () => ({
            borderRight: "1px solid #a6a6a6",
            height: "130px",
            textAlign: "right",
            paddingRight: "15px",
            paddingTop: "10px",
            width: "123px"
        }),
        chartBoxChart: () => ({
            height: "130px",
            paddingTop: "10px"
        }),
        chartLine: () => ({
            height: "45px",
        }),
        chartLineSpan: () => ({
            display: "inline-block",
            height: "30px",
            lineHeight: "30px",
            verticalAlign: "top",
            paddingLeft: "5px"
        }),
        pentagonArrowLargeBox: () => ({
            position: "relative",
        }),
        pentagonArrowLarge: () => ({
            position: "absolute",
            background: " #a6a6a6",
            width: "65px",
            height: "196px",
            top: "123px",
            left: "-26px",
            textAlign: "center"

        }),
        pentagonArrowLargeBefore: () => ({
            content: "",
            position: "absolute",
            top: "121px",
            left: "39px",
            bottom: 0,
            width: 0,
            height: 0,
            borderLeft: "20px solid #a6a6a6",
            borderTop: "100px solid transparent",
            borderBottom: "100px solid transparent"
        }),
        notice: {
            color: "#7f7f7f",
            textAlign: "right",
            fontStyle: "italic",
            fontSize: "80%",
            fontWeight: 400,
            paddingRight:"20px",
            paddingTop: "5px"
        }

    }))
;
const infoText = {
    en: {
        "haw": {
            engagementMotivation: {
                title: "ENGAGEMENT & MOTIVATION",
                rational: {
                    think: "THINK",
                    engagement: "RATIONAL<br/> ENGAGEMENT"
                },
                emotional: {
                    feel: "FEEL",
                    engagement: "EMOTIONAL<br/> ENGAGEMENT",

                },
                motivational: {
                    motivation: "MOTIVA<br/>TION",
                    engagement: "MOTIVATIONAL<br/> ENABLEMENT",
                }
            },
            performanceLoyalty: {
                title: "PERFORMANCE & LOYALTY",
                action: "ACTION",
                discretionaryEffort: "DISCRETIONARY<br/> EFFORT",
                commitmentToStay: "COMMITMENT<br/>TO STAY"
            },
            industry: "INDUSTRY",
            notice: "(% employees choosing “Totally agree” or “Agree” for<br/>" +
                "each happiness behavior.)"
        }
    },
    vn: {
        "haw": {
            engagementMotivation: {
                title: "MỨC ĐỘ GẮN KẾT",
                rational: {
                    think: "NGHĨ",
                    engagement: "GẮN KẾT <br/> LÝ TRÍ",
                },
                emotional: {
                    feel: "CẢM",
                    engagement: "GẮN KẾT <br/> TÌNH CẢM",
                },
                motivational: {
                    motivation: "ĐỘNG <br/> LỰC",
                    engagement: "NÂNG TẦNG <br/> ĐỘNG LỰC",
                }
            },
            performanceLoyalty: {
                title: "HIỆU QUẢ LÀM VIỆC & TRUNG THÀNH",
                action: "HÀNH<br/> ĐỘNG",
                discretionaryEffort: "NỖ LỰC <br/> TỰ NGUYỆN",
                commitmentToStay: "CAM KẾT <br/> GẮN BÓ"
            },
            industry: "NGÀNH",
            notice: "(% nhân viên chọn “Hoàn toàn đồng ý” hoặc “Đồng<br/>ý” cho các biểu hiện Hạnh Phúc)"
        }
    }
};
const HAWIndexMainChart: FC<Props> = ({
                                          language = "vn", survey_tag = "haw", company_short_name = "",
                                          rational_company = 50,
                                          rational_industry = 50,
                                          rational_vietnam = 50,
                                          emotional_company = 50,
                                          emotional_industry = 50,
                                          emotional_vietnam = 50,
                                          motivational_company = 50,
                                          motivational_industry = 50,
                                          motivational_vietnam = 50,
                                          effort_company = 50,
                                          effort_industry = 50,
                                          effort_vietnam = 50,
                                          commit_company = 50,
                                          commit_industry = 50,
                                          commit_vietnam = 50
                                      }) => {
    const classes = useStyles();
    return (<div className={classes.main}>
            <div className={classes.engagementMotivation}>
                <div className={classes.subTitle}>
                    {infoText[language][survey_tag].engagementMotivation.title}
                </div>
                <div className={classes.scoreBox}>
                    <div className={classes.scoreBoxTitle}>
                        <div className={classes.pentagonArrowSmall}>
                            <div style={{paddingTop: "25px"}}>
                                <img src={brainIcon} width={"50px"} alt="Brain"/>
                            </div>
                            <div className={classes.chartTitle}>
                                {infoText[language][survey_tag].engagementMotivation.rational.think}
                            </div>
                        </div>
                        <div className={classes.pentagonArrowSmallBefore}></div>
                    </div>
                    <div style={{position: "relative"}}>
					<span className={classes.chartBoxTitleSpan}
                          style={{
                              position: "absolute",
                              left: "-45px",
                              top: "55px",
                              width: "150px",
                          }}
                          dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].engagementMotivation.rational.engagement}}>
					</span>
                    </div>
                    <div className={classes.chartBox} style={{paddingLeft: "67px"}}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{company_short_name}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{infoText[language][survey_tag].industry}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							VN
                            </span>
                        </div>
                    </div>
                    <div className={classes.chartBoxChart}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#1183a9",
                            color: "#ffffff",
                            width: rational_company * 1.9 + "px",
                            border: "1px solid #1183a9"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{rational_company}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#dcebf6",
                            color: "#ffffff",
                            width: rational_industry * 1.9 + "px",
                            border: "1px solid #1183a9"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{rational_industry}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            color: "#ffffff",
                            width: rational_vietnam * 1.9 + "px",
                            border: "1px solid #1183a9"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{rational_vietnam}%
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.scoreBox}>
                    <div className={classes.scoreBoxTitle}>
                        <div className={classes.pentagonArrowSmall}>
                            <div style={{paddingTop: "25px"}}>
                                <img src={heartIcon} width={"50px"} alt="Heart"/>
                            </div>
                            <div className={classes.chartTitle}>
                                {infoText[language][survey_tag].engagementMotivation.emotional.feel}
                            </div>
                        </div>
                        <div className={classes.pentagonArrowSmallBefore}></div>
                    </div>
                    <div style={{position: "relative"}}>
					<span className={classes.chartBoxTitleSpan}
                          style={{
                              position: "absolute",
                              left: "-45px",
                              top: "55px",
                              width: "150px",
                          }}
                          dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].engagementMotivation.emotional.engagement}}>
					</span>
                    </div>
                    <div className={classes.chartBox} style={{paddingLeft: "67px"}}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{company_short_name}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{infoText[language][survey_tag].industry}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							VN
                            </span>
                        </div>
                    </div>
                    <div className={classes.chartBoxChart}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#e41e5b",
                            color: "#ffffff",
                            width: emotional_company * 1.9 + "px",
                            border: "1px solid #e41e5b"
                        }}>

						</span>
                            <span className={classes.chartLineSpan}>
							{emotional_company}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#ffcccc",
                            color: "#ffffff",
                            width: emotional_industry * 1.9 + "px",
                            border: "1px solid #e41e5b"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{emotional_industry}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            color: "#ffffff",
                            width: emotional_vietnam * 1.9 + "px",
                            border: "1px solid #e41e5b"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{emotional_vietnam}%
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.scoreBox}>
                    <div className={classes.scoreBoxTitle}>
                        <div className={classes.pentagonArrowSmall}>
                            <div style={{paddingTop: "25px"}}>
                                <img src={flameIcon} width={"50px"} alt = "icon"/>
                            </div>
                            <div className={classes.chartTitle}
                                 dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].engagementMotivation.motivational.motivation}}>
                            </div>
                        </div>
                        <div className={classes.pentagonArrowSmallBefore}></div>
                    </div>
                    <div style={{position: "relative"}}>
					<span className={classes.chartBoxTitleSpan}
                          style={{
                              position: "absolute",
                              left: "-45px",
                              top: "55px",
                              width: "150px",
                          }}
                          dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].engagementMotivation.motivational.engagement}}>
					</span>
                    </div>
                    <div className={classes.chartBox} style={{paddingLeft: "67px"}}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{company_short_name}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{infoText[language][survey_tag].industry}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							VN
                            </span>
                        </div>
                    </div>
                    <div className={classes.chartBoxChart}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#80327c",
                            color: "#ffffff",
                            width: motivational_company * 1.9 + "px",
                            border: "1px solid #80327c"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{motivational_company}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#d8e3f2",
                            color: "#ffffff",
                            width: motivational_industry * 1.9 + "px",
                            border: "1px solid #80327c"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{motivational_industry}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            color: "#ffffff",
                            width: motivational_vietnam * 1.9 + "px",
                            border: "1px solid #80327c"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{motivational_vietnam}%
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.performanceLoyalty}>
                <div style={{position: "relative"}}>
                    <div className={classes.pentagonArrowLargeBox}>
                        <div className={classes.pentagonArrowLarge}>
                            <div style={{paddingTop: "25px"}}>
                                <img src={gymIcon} width={"50px"} alt ="icon"/>
                            </div>
                            <div className={classes.chartTitle} style={{paddingTop:"5px", paddingBottom:"5px"}}
                                 dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].performanceLoyalty.action}}>
                            </div>
                            <div>
                                <img src={handIcon} width={"50px"} alt ="icon"/>
                            </div>
                        </div>
                        <div className={classes.pentagonArrowLargeBefore}>

                        </div>
                    </div>
                </div>


                <div className={classes.subTitle}>
                    {infoText[language][survey_tag].performanceLoyalty.title}
                </div>

                <div className={classes.scoreBox} style={{paddingTop: "25px", paddingBottom: "25px"}}>
                    <div style={{position: "relative"}}>
					<span className={classes.chartBoxTitleSpan}
                          style={{
                              position: "absolute",
                              left: "17px",
                              top: "55px",
                              width: "150px",
                          }}
                          dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].performanceLoyalty.discretionaryEffort}}>
					</span>
                    </div>
                    <div className={classes.chartBox} style={{paddingLeft: "135px"}}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{company_short_name}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{infoText[language][survey_tag].industry}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							VN
                            </span>
                        </div>
                    </div>
                    <div className={classes.chartBoxChart}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#fab93b",
                            color: "#ffffff",
                            width: effort_company * 1.9 + "px",
                            border: "1px solid #fab93b"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{effort_company}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#ededed",
                            color: "#ffffff",
                            width: effort_industry * 1.9 + "px",
                            border: "1px solid #fab93b"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{effort_industry}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            color: "#ffffff",
                            width: effort_vietnam * 1.9 + "px",
                            border: "1px solid #fab93b"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{effort_vietnam}%
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.scoreBox} style={{paddingTop: "25px", paddingBottom: "25px"}}>
                    <div style={{position: "relative"}}>
					<span className={classes.chartBoxTitleSpan}
                          style={{
                              position: "absolute",
                              left: "17px",
                              top: "55px",
                              width: "150px",
                          }}
                          dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].performanceLoyalty.commitmentToStay}}>
					</span>
                    </div>
                    <div className={classes.chartBox} style={{paddingLeft: "135px"}}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{company_short_name}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							{infoText[language][survey_tag].industry}
						</span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan}>
							VN
                            </span>
                        </div>
                    </div>
                    <div className={classes.chartBoxChart}>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#96c94f",
                            color: "#ffffff",
                            width: commit_company * 1.9 + "px",
                            border: "1px solid #96c94f"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{commit_company}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            backgroundColor: "#fdcbb0",
                            color: "#ffffff",
                            width: commit_industry * 1.9 + "px",
                            border: "1px solid #96c94f"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{commit_industry}%
                            </span>
                        </div>
                        <div className={classes.chartLine}>
						<span className={classes.chartLineSpan} style={{
                            color: "#ffffff",
                            width: commit_vietnam * 1.9 + "px",
                            border: "1px solid #96c94f"
                        }}>
						</span>
                            <span className={classes.chartLineSpan}>
							{commit_vietnam}%
                            </span>
                        </div>
                    </div>

                </div>
                <div className={classes.notice}
                     dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].notice}}>
                </div>
            </div>
        </div>
    )
};

export default HAWIndexMainChart;