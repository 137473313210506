import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserList from './UserList';


export default {
    create: UserCreate,
    edit: UserEdit,
    list: UserList,
};
