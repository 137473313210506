import CompanyAwardCreate from './CompanyAwardCreate';
import CompanyAwardEdit from './CompanyAwardEdit';
import CompanyAwardList from './CompanyAwardList';


export default {
    create: CompanyAwardCreate,
    edit: CompanyAwardEdit,
    list: CompanyAwardList
};
