import * as React from 'react';
import {FC, useState} from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    BooleanInput,
    TextInput,
    required,
    ReferenceArrayInput,
    AutocompleteArrayInput
} from 'react-admin';
import {onHandleTextInput} from "../utils/textHandling";

export const CompanyIndustry = () => {
    const [filterIndustry, setFilterIndustry] = useState('');
    return <ReferenceArrayInput label="Industries" source="industries" reference="industries"
                                filter={{'q': filterIndustry}}>
        <AutocompleteArrayInput optionText="name" source="industries" onChange={(event) => {
            setFilterIndustry(onHandleTextInput(event))
        }}/>
    </ReferenceArrayInput>
}

const CompanyCreate: FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm redirect={"list"}>
            <TextInput source="commercialName" validate={[required()]}/>
            <TextInput source="legalNameVn"/>
            <TextInput source="legalNameEn"/>
            <TextInput source="employees"/>
            <TextInput source="shortDescription" multiline/>
            <CompanyIndustry/>
            <TextInput source="description" multiline/>
            <TextInput source="companyUrl"/>
            <TextInput source="portalUrl"/>
            <BooleanInput source="valid"/>
        </SimpleForm>
    </Create>
);

export default CompanyCreate;