import * as React from 'react';
import {FC} from 'react';

import {
    EditButton,
    List,
    ListProps,
    FilterProps,
    Datagrid,
    TextField,
    Filter,
    SearchInput,usePermissions
} from 'react-admin';

const AwardFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <SearchInput source="report_name" alwaysOn/>
    </Filter>
);


const ScoreCardTemplateList: FC<ListProps> = props => {
    const { loaded, permissions } = usePermissions();

    if (!loaded) {
      return null; // Đợi quyền truy cập được tải
    }
  
    const isAdmin = permissions.includes('ROLE_ADMIN');
    const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
    const isSurvey = permissions.includes('ROLE_SURVEY');
    return (
        <List title="All Awards" {...props} filters={<AwardFilter/>} hasCreate = {isAdmin||isSurvey}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="survey_tag"/>
                <TextField source="survey_year"/>
                <TextField source="report_language"/>
                <TextField source="report_name"/>
                {(isAdmin||isSurvey) && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default ScoreCardTemplateList;