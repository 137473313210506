import React, {
    FC,
} from 'react';
import {makeStyles} from "@material-ui/styles";
import CompanyTitle from "./CompanyTitle";
import SurveyInfo from "./SurveyInfo";
import SurveyContact from "./SurveyContact";
import ScoreCardInfoHeader from "./ScoreCardInfoHeader";
interface Props {
    value: any;
}

const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "250px",
    }),
    title: () => ({

    })
}));

const ScoreCardInfoBlock: FC<Props> = props => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <ScoreCardInfoHeader {...props.value}/>
            <CompanyTitle {...props.value}/>
            <SurveyInfo {...props.value}/>
            <SurveyContact {...props.value}/>
        </div>)
};

export default ScoreCardInfoBlock;