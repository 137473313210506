import * as React from 'react';
import {FC} from 'react';

import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    SelectInput,
    ImageInput,
    ImageField,
} from 'react-admin';
import {Award} from "../types";


export const ScoreCardTemplate: FC<FieldProps<Award>> = ({record}) => {
    return <span> Award {record ? `"${record.name}"` : ''}</span>
}
const HawScoreCardTemplateEdit: FC<EditProps> = props => (
    <Edit title={<ScoreCardTemplate/>} {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput source="survey_tag"  label = "Survey Tag" validate={[required()]}/>
            <NumberInput source="survey_year"  label = "Survey Year"  validate={[required()]}/>
            <NumberInput source="total_respondent"  label = "Total Respondent" validate={[required()]}/>
            <SelectInput source="report_language" choices={[
                { id: 'vn', name: 'Tiếng Việt' },
                { id: 'en', name: 'English' },
            ]} />
            <span>Current Logo</span>
            <img  src={props['logo']} alt = "logo"/>
            <ImageInput source="logo_url" label="Survey Logo" accept="image/*">
                <ImageField source="medium"/>
            </ImageInput>
            <TextInput source="report_name"  label = "Report Name" validate={[required()]} fullWidth/>
            <TextInput source="scorecard_title"  label = "ScoreCard Title" validate={[required()]} fullWidth/>
            <TextInput source="survey_title"  label = "Survey Title" validate={[required()]} fullWidth/>
        </SimpleForm>
    </Edit>
)

export default HawScoreCardTemplateEdit;
