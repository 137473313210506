import React from 'react';
import ScoreCardInfoBlock from "./SurveyInfoBlock/ScoreCardInfoBlock";
import ScoreCardStatisticBlock from "./ScoreCardBlock/ScoreCardStatisticBlock";
import VNBPTWScoreCardExport from "./VNBPTWScoreCardPPTExport/VNBPTWScoreCardExport";
import { makeStyles } from '@material-ui/core/styles';

interface Props {
    props: any;
}

const useStyles = makeStyles(theme => ({
    main: (props: Props) => ({
        overflow: 'inherit',
        display: 'flex',
    }),
    title: {},
}));


const VNBPTWScoreCard = ({ props }) => {
    const classes = useStyles(props);
    return (<div className={classes.main}>
        {/* <ScoreCardInfoBlock value={props} />
        <ScoreCardStatisticBlock value={props} /> */}
        <VNBPTWScoreCardExport {...props} />
    </div>)
};

export default VNBPTWScoreCard;