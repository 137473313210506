import IndustryCreate from './IndustryCreate';
import IndustryEdit from './IndustryEdit';
import IndustryList from './IndustryList';


export default {
    create: IndustryCreate,
    edit: IndustryEdit,
    list: IndustryList,
};
