import * as React from 'react';
import {Route} from 'react-router-dom';
import SalesPackageCreate from './salespackage/SalesPackageCreate';
import ParticipantsCreate from "./participants/ParticipantsCreate";
import BenefitSettingCreate from './benefitsetting/BenefitSettingCreate';
export default [
    <Route exact path="/surveys/:survey_id/add-salespackages"    render={(routeProps) => <SalesPackageCreate surveyid={decodeURIComponent((routeProps.match).params.survey_id)}  hasShow basePath={routeProps.match.url} resource="salespackages" {...routeProps} />}   />,
    <Route exact path="/surveys/:survey_id/add-participant"    render={(routeProps) => <ParticipantsCreate surveyid={decodeURIComponent((routeProps.match).params.survey_id)}  hasShow basePath={routeProps.match.url} resource="participants" {...routeProps} />}   />,
    <Route exact path="/surveys/:survey_id/add-benefit"  render={(routeProps) => <BenefitSettingCreate surveyid={decodeURIComponent((routeProps.match).params.survey_id)}  hasShow basePath={routeProps.match.url} resource="benefitsetting" {...routeProps} />}   />
    ];

