import HawScoreCardTemplateCreate from './HawScoreCardTemplateCreate';
import HawScoreCardTemplateEdit from './HawScoreCardTemplateEdit';
import HawScoreCardTemplateList from './HawScoreCardTemplateList';


export default {
    create: HawScoreCardTemplateCreate,
    edit: HawScoreCardTemplateEdit,
    list: HawScoreCardTemplateList,
};
