import AwardCreate from './AwardCreate';
import AwardEdit from './AwardEdit';
import AwardList from './AwardList';


export default {
    create: AwardCreate,
    edit: AwardEdit,
    list: AwardList,
};
