import CompanyCreate from './CompanyCreate';
import CompanyEdit from './CompanyEdit';
import CompanyList from './CompanyList';


export default {
    create: CompanyCreate,
    edit: CompanyEdit,
    list: CompanyList,
};
