import React, {
    FC,
} from 'react';
import {makeStyles} from '@material-ui/core/styles';

interface Props {
    props: any;
}

const useStyles = makeStyles(theme => ({
    main: (props: Props) => ({
        overflow: 'inherit',
        width: "100%",
        paddingLeft: "15px"
    }),
    title: {},
}));
const HawScoreCardBlock: FC<Props> = ({children, props}) => {
    const classes = useStyles(props);
    return (<div className={classes.main}>
        {children}
    </div>)
};

export default HawScoreCardBlock;