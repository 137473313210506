import * as React from 'react';
import {FC} from 'react';

import {
    EditButton,
    List,
    ListProps,
    FilterProps,
    Datagrid,
    TextField,
    Filter,
    ReferenceInput,
    ReferenceField,
    AutocompleteInput,
    required,
    ShowButton,
    usePermissions
} from 'react-admin';

const CompanyScoreCardFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <ReferenceInput
            label="Template"
            source="template_id"
            reference="scorecardtemplate"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}>
            <AutocompleteInput optionText="report_name"/>
        </ReferenceInput>
        <ReferenceInput
            label="Company"
            source="company_id"
            reference="companies"
            filterToQuery={searchText => ({commercialName: searchText})}
            validate={[required()]}>
            <AutocompleteInput optionText="commercialName" alwaysOn/>
        </ReferenceInput>
        {/* <TextInput source="year"/> */}
    </Filter>
);


const CompanyScoreCardList: FC<ListProps> = props => {
    const { loaded, permissions } = usePermissions();

    if (!loaded) {
      return null; // Đợi quyền truy cập được tải
    }
  
    const isAdmin = permissions.includes('ROLE_ADMIN');
    const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
    const isSurvey = permissions.includes('ROLE_SURVEY');
    return (
        <List title="All ScoreCard" {...props} filters={<CompanyScoreCardFilter/>} hasCreate = {isAdmin||isSurvey}>
            <Datagrid>
                <TextField source="id"/>

                <ReferenceField label="Company"
                                reference="companies"
                                source="company_id"
                >
                    <TextField source="commercialName"/>
                </ReferenceField>

                <ReferenceField label="Template"
                                reference="scorecardtemplate"
                                source="template_id"
                >
                    <TextField source="report_name"/>
                </ReferenceField>

                <TextField source="company_industry"/>
                <TextField source="id">{<div></div>}</TextField>
                <ReferenceField label="Year"
                                reference="scorecardtemplate"
                                source="template_id"
                >
                    <TextField source="survey_year"/>
                </ReferenceField>

                {(isAdmin||isSurvey) && <EditButton />}
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default CompanyScoreCardList;