import React from 'react';
import InternalScoreCard2021Export from "./VNBPTWScoreCardPPTExport/InternalScoreCard2021Export";
import { makeStyles } from '@material-ui/core/styles';

interface Props {
    props: any;
}

const useStyles = makeStyles(theme => ({
    main: (props: Props) => ({
        overflow: 'inherit',
        display: 'flex',
    }),
    title: {},
}));


const InternalScoreCard2021 = ({ props }) => {
    const classes = useStyles(props);
    return (<div className={classes.main}>
        <InternalScoreCard2021Export {...props} />
    </div>)
};

export default InternalScoreCard2021;