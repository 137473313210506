import React, {
    FC,
} from 'react';
import {makeStyles} from "@material-ui/styles";
interface Props {
    company_name: string;
    company_industry: string;
    company_logo: string;
}

const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "100%",
    }),
    title: () => ({
        background: "#005670",
        color: "#ffffff",
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
    }),
    logo: () => ({
        width: "100%",
        paddingBottom: "3px",
        "& img": {
            display: "inline-block",
            maxWidth: "100%",
            maxHeight: "150px"
        },
        verticalAlign: "middle",
        textAlign: "center",
        margin: "auto"

    }),
    image: () => ({
        width:"100%",

    }),
    companyName: () => ({
        fontWeight: "bold",
    }),
    industry: () => ({
        // fontSize: "11pt"
    })
}));

const CompanyTitle: FC<Props> = ({company_name, company_industry, company_logo}) => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className={classes.logo}>
                <img src={company_logo} alt="Logo"/>
            </div>
            <div className={classes.title}>
                <div>
                    <span className={classes.companyName}>{company_name}</span>
                </div>
                <div className={classes.industry}>
                    <span>{company_industry}</span>
                </div>

            </div>

        </div>
    )
};

export default CompanyTitle;