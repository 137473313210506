import { fetchUtils } from 'ra-core';

const httpClient = (url:string, options:any= {}) => {
    let apiUrl = process.env.REACT_APP_API_ENTRYPOINT;
    if(!options.headers){
        options.headers = new Headers({ Accept: 'application/json'});
    }
    let user_info:any = localStorage.getItem('auth')?localStorage.getItem('auth'):'';
    const {token} = JSON.parse(user_info);
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetchUtils.fetchJson(apiUrl+"/"+url, options).then(({ status, headers, body, json }) => {
        return Promise.resolve(json);
    });
};

export default httpClient;



