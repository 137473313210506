import * as React from 'react';
import {FC} from 'react';

import {
    EditButton,
    List,
    ListProps,
    FilterProps,
    Datagrid,
    TextField,
    Filter,
    SearchInput,
    usePermissions
} from 'react-admin';

const CategoryFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <SearchInput source="category_title" alwaysOn/>
    </Filter>
);


const MediaCatList: FC<ListProps> = props => {
    const { loaded, permissions } = usePermissions();

  if (!loaded) {
    return null; // Đợi quyền truy cập được tải
  }

  const isAdmin = permissions.includes('ROLE_ADMIN');
    return (
        <List title="All Category" {...props} filters={<CategoryFilter/>} hasCreate = {isAdmin}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="category_title"/>
                {isAdmin && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default MediaCatList;