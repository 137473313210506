import * as React from "react";
import { FC } from 'react';

import {
    EditButton,
    List,
    ListProps,
    Datagrid,
    TextField,
    usePermissions
} from 'react-admin';


const SalesBenefitList: FC<ListProps> = props => {

    const { loaded, permissions } = usePermissions();

    if (!loaded) {
        return null; // Đợi quyền truy cập được tải
    }

    const isAdmin = permissions.includes('ROLE_ADMIN');
    const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
    const isSurvey = permissions.includes('ROLE_SURVEY');
    return (
        <List title="All Sales Benefits" {...props} hasCreate={isAdmin || isSaleAdmin}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="setting_keys" />
                {(isAdmin || isSaleAdmin) && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default SalesBenefitList;