import * as React from "react";
import {
    Edit,
    BooleanInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    AutocompleteInput,
    AutocompleteArrayInput,
    FieldProps
} from "react-admin";
import {FC, useState} from "react";
import {onHandleTextInput} from "../utils/textHandling";
import {ParticipantCompany} from "../types";

interface Props {
    survey: number;
}

export const ParticipantSalesPackages: FC<FieldProps<ParticipantCompany>> = ({record}) => {
    const [filterPackage, setFilterPackage] = useState('');
    if (record) {
        return <ReferenceArrayInput label="Sales Packages" source="packages" reference="salespackages"
                                    filter={{q: filterPackage, survey_id: record.survey}} style={{width: "255px"}}>
            <AutocompleteArrayInput optionText="name" source="packages" onChange={(event) => {
                setFilterPackage(onHandleTextInput(event))
            }}/>
        </ReferenceArrayInput>
    }
    return <div> </div>
}

const redirect = (basePath, id, data) => `/surveys/${data.survey}/participants`;

const ParticipantsEdit: FC = (props) => {
    const [filterExCompetitor, setFilterExCompetitor] = useState('');
    const [filterInCompetitor, setFilterInCompetitor] = useState('');
    const [filterIndustry, setFilterIndustry] = useState('');
    const [filterMajors, setFilterMajors] = useState('');
    const [filterUser, setFilterUser] = useState('');

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect}>

                <h2>Main Info</h2>
                <ReferenceInput
                    source="survey"
                    reference="surveys"
                >
                    <SelectInput source="name" disabled/>
                </ReferenceInput>

                <ReferenceInput label="Company" source="company" reference="companies" disabled
                >
                    <AutocompleteInput optionText="commercialName" source="company_id"
                    />
                </ReferenceInput>
                <BooleanInput source="participated" label="Participated"/>
                <ReferenceArrayInput label="Industries" source="industries" reference="industries"
                                     filter={{'q': filterIndustry}}>
                    <AutocompleteArrayInput optionText="name" source="industries" onChange={(event) => {
                        setFilterIndustry(onHandleTextInput(event))
                    }}/>
                </ReferenceArrayInput>
                <ReferenceArrayInput label="Majors" source="majors" reference="industries" filter={{'q': filterMajors}}>
                    <AutocompleteArrayInput optionText="name" source="majors" onChange={(event) => {
                        setFilterMajors(onHandleTextInput(event))
                    }}/>
                </ReferenceArrayInput>
                <ReferenceArrayInput label="External Competitors" source="external_competitor" reference="companies"
                                     filter={{
                                         'q': filterExCompetitor,
                                         commercialName: filterExCompetitor,
                                     }}>
                    <AutocompleteArrayInput optionText="commercialName" source="external_competitor" onChange={(event) => {
                        setFilterExCompetitor(onHandleTextInput(event))
                    }}/>
                </ReferenceArrayInput>
                <ReferenceArrayInput label="Internal Competitors" source="internal_competitor" reference="companies"
                                     filter={{
                                         'q': filterInCompetitor,
                                         commercialName: filterInCompetitor,
                                     }}>
                    <AutocompleteArrayInput optionText="commercialName" source="internal_competitor" onChange={(event) => {
                        setFilterInCompetitor(onHandleTextInput(event))
                    }}/>
                </ReferenceArrayInput>
                <h2>Verification</h2>
                <BooleanInput source="sales_verified" label="Sales Verified"/>
                <BooleanInput source="info_verified" label="Info Verified"/>
                <BooleanInput source="logo_verified" label="Logo Verified"/>
                <h2>SALES</h2>
                <ParticipantSalesPackages/>
                <BooleanInput source="potential" label="Potential"/>
                <BooleanInput source="client" label="Client"/>
                <ReferenceInput
                    source="sales"
                    reference="users"
                    filter={{'q': filterUser}}
                >
                    <AutocompleteInput source="users" onChange={(event) => {
                        setFilterUser(onHandleTextInput(event))
                    }}/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
}
export default ParticipantsEdit;