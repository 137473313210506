import * as React from 'react';
import {FC} from 'react';

import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    SelectInput,
    NumberInput
} from 'react-admin';
import {Award} from "../types";


export const ScoreCard: FC<FieldProps<Award>> = ({record}) => {
    return <span> Award {record ? `"${record.name}"` : ''}</span>
}
const CompanyScoreCardEdit: FC<EditProps> = props => (
    <Edit title={<ScoreCard/>} {...props}>
        <SimpleForm>

            <ReferenceInput label="Template" source="template_id" reference="scorecardtemplate">
                <SelectInput optionText="report_name" />
            </ReferenceInput>

            <ReferenceInput label="Company" source="company_id" reference="companies">
                <SelectInput optionText="commercialName"/>
            </ReferenceInput>
            <TextInput source="company_industry" validate={[required()]} label="Company Industry" fullWidth/>
            <TextInput source="respondents_conducted_date" validate={[required()]}/>

            <NumberInput source="respondents_amount" validate={[required()]}/>

            <NumberInput source="firstchoice_index_value" validate={[required()]}/>

            <NumberInput source="firstchoice_benchmark" validate={[required()]}/>

            <NumberInput source="desire_index_value" validate={[required()]}/>

            <NumberInput source="desire_benchmark" validate={[required()]}/>

            <NumberInput source="action_index_value" validate={[required()]}/>

            <NumberInput source="action_benchmark" validate={[required()]}/>

            <NumberInput source="interest_index_value" validate={[required()]}/>

            <NumberInput source="interest_benchmark" validate={[required()]}/>

            <NumberInput source="attention_index_value" validate={[required()]}/>

            <NumberInput source="attention_benchmark" validate={[required()]}/>

            <NumberInput source="rejection_index_value" validate={[required()]}/>

            <NumberInput source="rejection_benchmark" validate={[required()]}/>

            <NumberInput source="ebaindex_index_value" validate={[required()]}/>

            <NumberInput source="ebaindex_benchmark" validate={[required()]}/>

            <NumberInput source="total_ranking" validate={[required()]}/>

            <NumberInput source="industry_ranking" validate={[required()]}/>

            <TextInput source="brandimages_detail_1"  fullWidth/>

            <TextInput source="brandimages_detail_2"  fullWidth/>

            <TextInput source="brandimages_detail_3"  fullWidth/>

            <TextInput source="brandimages_detail_4"  fullWidth/>

            <TextInput source="brandimages_detail_5"  fullWidth/>

            <NumberInput source="attention_to_interest" validate={[required()]}/>

            <NumberInput source="interest_to_action" validate={[required()]}/>

            <NumberInput source="desire_to_1stchoice" validate={[required()]}/>

            <NumberInput source="total_respondent" validate={[required()]}/>

            <TextInput source="externalimage1"  fullWidth/>
            <TextInput source="externalimage2"  fullWidth/>
            <TextInput source="externalimage3"  fullWidth/>
            <TextInput source="externalimage4"  fullWidth/>
            <TextInput source="externalimage5"  fullWidth/>
            <TextInput source="externalimage6"  fullWidth/>
            <TextInput source="externalimage7"  fullWidth/>
            <TextInput source="externalimage8"  fullWidth/>
            <TextInput source="externalimage9"  fullWidth/>
            <TextInput source="externalimage10"  fullWidth/>

            <TextInput source="jobimportance1"  fullWidth/>
            <TextInput source="jobimportance2"  fullWidth/>
            <TextInput source="jobimportance3"  fullWidth/>
            <TextInput source="jobimportance4"  fullWidth/>
            <TextInput source="jobimportance5"  fullWidth/>
            <TextInput source="jobimportance6"  fullWidth/>
            <TextInput source="jobimportance7"  fullWidth/>
            <TextInput source="jobimportance8"  fullWidth/>
            <TextInput source="jobimportance9"  fullWidth/>
            <TextInput source="jobimportance10"  fullWidth/>

        </SimpleForm>
    </Edit>
)

export default CompanyScoreCardEdit;
