import React, {
    FC
} from 'react';
import { makeStyles } from "@material-ui/styles";
import pptxgen from "pptxgenjs";
import awardframe from "./img/award-frame-blank.png";
import surveylogo2021 from "./img/logovnbptw2021.png";
import surveylogo2020 from "./img/logovnbptw2020.jpg";
import surveylogo from "./img/logovnbptwngeneral.jpg";
import transformArrow from "./img/transform_arrows.png";
import industryAwardFrame from "./img/industry_reward.png";
import totalAwardFrame from "./img/total_reward.png";
import engamentArrowVN from "./img/3factor_vn.png";
import engamentArrowEN from "./img/3factor_en.png";
import actionArrowVN from "./img/actionarrow_vn.png";
import actionArrowEN from "./img/actionarrow_en.png";
import whiteCircle from "./img/whitecircle.png";
import redCircle from "./img/redcircle.png";
import greenCircle from "./img/greencircle.png";
import orangeCircle from "./img/orangecircle.png";
import coreBaloon from "./img/core_baloon.png";
import regretBaloon from "./img/regret_baloon.png";
import zoombieBaloon from "./img/zoombie_baloon.png";
import quitBaloon from "./img/quit_baloon.png";
import notStayArrow from "./img/notstayarrow.png";
import stayEmployee from "./img/stay_employee.png";
import leaveEmployee from "./img/leave_employee.png";
import classificationArrowUp from "./img/white-triangle-up.png";
import classificationArrowDown from "./img/white-triangle-down.png";
import classificationArrowRight from "./img/white-triangle-right.png";
import classificationArrowLeft from "./img/white-triangle-left.png";
import classificationArrowLine from "./img/white-triangle-line.png";
import rejectLine from "./img/rejectline.png";
// import AcecookVietnamlogo from "./img/logo/acecook-vietnam.png";
// import CenGrouplogo from "./img/logo/cen-group.jpg";
// import ChungKhoanBaoVietlogo from "./img/logo/chung-khoan-bao-viet-bvsc.jpg";
// import VietCreditlogo from "./img/logo/cong-ty-tai-chinh-co-phan-tin-viet-vietcredit.png";
// import DaikinVietnamlogo from "./img/logo/daikin-vietnam.png";
// import Deltalogo from "./img/logo/delta.png";
// import EsteeLauderlogo from "./img/logo/estee-lauder.png";
// import FashionGarmentsColtdlogo from "./img/logo/fashion-garments-co-ltd.png";
// import Feconlogo from "./img/logo/fecon.jpg";
// import FWDVNlogo from "./img/logo/fwd-vietnam.png";
// import GojekVNlogo from "./img/logo/gojek-vietnam.png";
// import GreenFeedVNlogo from "./img/logo/greenfeed-vietnam-corporation.png";
// import HoaBinhConlogo from "./img/logo/hoa-binh-construction-group.png";
// import JotunVNlogo from "./img/logo/jotun-vietnam.png";
// import KimOanhGrouplogo from "./img/logo/kim-oanh-group.jpg";
// import KuehneNagellogo from "./img/logo/kuehnenagel.png";
// import MaricoSEAlogo from "./img/logo/marico-south-east-asia.png";
// import MMVNlogo from "./img/logo/mm-mega-market-vietnam.jpg";
// import MondelezVNlogo from "./img/logo/mondelez-vietnam.jpg";
// import Novalandlogo from "./img/logo/novaland-group.jpg";
// import PepsicoFoodsVNlogo from "./img/logo/pepsico-foods-vietnam-company.jpg";
// import PerfettiVanMellelogo from "./img/logo/perfetti-van-melle.png";
// import SailunVNlogo from "./img/logo/sailun-viet-nam.png";
// import SaintGobainVNlogo from "./img/logo/saint-gobain-vietnam.png";
// import SCBlogo from "./img/logo/scb.png";
// import KimTinCorplogo from "./img/logo/tap-doan-kim-tin.jpg";
// import PhenikaaCorplogo from "./img/logo/tap-doan-phenikaa.png";
// import TPBanklogo from "./img/logo/tpbank.png";
// import Unibenlogo from "./img/logo/uniben.jpg";
import companyLogoList from '../../companies/CompanyLogo';


const COLOR_BLUELAGOON = "005670";
const COLOR_BLACK = "000000";
const COLOR_WHITE = "FFFFFF";
const COLOR_DARKGRAY = "2F2F2F";
const COLOR_LIGHTGRAY = "d9d9d9";
const COLOR_RED = "FF0000";
const COLOR_FIRSTCHOICE = "7c41a9";
const COLOR_DESIRE = "a26840";
const COLOR_ACTION = "99ca3c";
const COLOR_INTEREST = "ffc000";
const COLOR_ATTENTION = "005670";
const COLOR_REJECTION = "ed7d31";
const COLOR_GRAY = "595959";

interface Props {
    survey_name: string,
    year: string,
    surveyperiod: string,
    benchmark: string,
    companyid: string,
    industryid: string,
    comindid: string,
    language: string,
    companyname: string,
    companyindustry: string,
    totalrespondents: string,
    industryrespondents: string,
    companyrespondents: string,
    firstchoiceindexvalue: string,
    desireindexvalue: string,
    actionindexvalue: string,
    interestindexvalue: string,
    attentionindexvalue: string,
    rejectionindexvalue: string,
    ebaindexindexvalue: string,
    firstchoicebenchmark: string,
    desirebenchmark: string,
    actionbenchmark: string,
    interestbenchmark: string,
    attentionbenchmark: string,
    rejectionbenchmark: string,
    ebaindexbenchmark: string,
    industryranking: string,
    totalranking: string,
    top50VN: string,
    brandimagesdetail1: string,
    brandimagesdetail2: string,
    brandimagesdetail3: string,
    brandimagesdetail4: string,
    brandimagesdetail5: string,
    attentiontointerest: string,
    interesttoaction: string,
    interesttodesire: string,
    desireto1stchoice: string,
    externalimage1: string,
    externalimage2: string,
    externalimage3: string,
    externalimage4: string,
    externalimage5: string,
    externalimage6: string,
    externalimage7: string,
    externalimage8: string,
    externalimage9: string,
    externalimage10: string,
    internalimage1: string,
    internalimage2: string,
    internalimage3: string,
    internalimage4: string,
    internalimage5: string,
    internalimage6: string,
    internalimage7: string,
    internalimage8: string,
    internalimage9: string,
    internalimage10: string,
    ctynvnhonhat1: string,
    ctynvnhonhat2: string,
    ctynvnhonhat3: string,
    ctynvnhonhat4: string,
    ctynvnhonhat5: string,
    ctynvnhonhat6: string,
    ctynvnhonhat7: string,
    ctynvnhonhat8: string,
    ctynvnhonhat9: string,
    ctynvnhonhat10: string,
    percentctynvnhonhat1: string,
    percentctynvnhonhat2: string,
    percentctynvnhonhat3: string,
    percentctynvnhonhat4: string,
    percentctynvnhonhat5: string,
    percentctynvnhonhat6: string,
    percentctynvnhonhat7: string,
    percentctynvnhonhat8: string,
    percentctynvnhonhat9: string,
    percentctynvnhonhat10: string,
    talentconsider1: string,
    talentconsider2: string,
    talentconsider3: string,
    talentconsider4: string,
    talentconsider5: string,
    talentconsider6: string,
    talentconsider7: string,
    talentconsider8: string,
    talentconsider9: string,
    talentconsider10: string,
    percenttalentconsider1: string,
    percenttalentconsider2: string,
    percenttalentconsider3: string,
    percenttalentconsider4: string,
    percenttalentconsider5: string,
    percenttalentconsider6: string,
    percenttalentconsider7: string,
    percenttalentconsider8: string,
    percenttalentconsider9: string,
    percenttalentconsider10: string,
    rationalcompany: string,
    emotinalcompany: string,
    motivationcompany: string,
    effortcompany: string,
    commitcompany: string,
    rationalindustry: string,
    emotinalindustry: string,
    motivationindustry: string,
    effortindustry: string,
    commitindustry: string,
    rationalmarket: string,
    emotinalmarket: string,
    motivationmarket: string,
    effortmarket: string,
    commitmarket: string,
    hawindexcompany: string,
    hawindexindustry: string,
    hawindexmarket: string,
    corecompany: string,
    regretcompany: string,
    zombiecompany: string,
    quittercompany: string,
    coremarket: string,
    regretmarket: string,
    zombiemarket: string,
    quittermarket: string,
    sscore: string,
    ssregret: string,
    sszombie: string,
    ssquitter: string,
    turnovercompany: string,
    turnovermarket: string,
    employeeagreegoodcompany: string,
    sharingwithfriend: string,
    cvquantity: string,
    timehire: string,
    hrsupport: string,
    hirequality: string,
    interestedworkhere: string,
    recruitmentprocess: string,
    onboarding: string,
    leadershipinspiration: string,
}

const useStyles = makeStyles(theme => ({
    main: () => ({
        font: "bold",
        textAlign: "center",
        paddingTop: "10px",
    }),
    dlbutton: () => ({
        width: "160px",
        textDecoration: "none",
        fontWeight: "bold"
    }),
    title: () => ({
        padding: "10px"
    })
}));
// const getBase64FromUrl = async (url) => {
//     const data = await fetch(url);
//     const blob = await data.blob();
//     return new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(blob);
//         reader.onloadend = () => {
//             const base64data = reader.result;
//             resolve(base64data);
//         }
//     });
// }
// function getBase64Image(img) {
//     let canvas = document.createElement("canvas");
//     canvas.width = img.width;
//     canvas.height = img.height;
//     let ctx = canvas.getContext("2d");
//     if (ctx) {
//         ctx.drawImage(img, 0, 0);
//     }
//     let dataURL = canvas.toDataURL("image/png");
//     return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
// }

// var base64 = getBase64Image(document.getElementById("ok"));
// let logoList_tamthoi = {};
// logoList_tamthoi[340] = AcecookVietnamlogo;
// logoList_tamthoi[1513] = CenGrouplogo;
// logoList_tamthoi[463] = ChungKhoanBaoVietlogo;
// logoList_tamthoi[575] = VietCreditlogo;
// logoList_tamthoi[279] = DaikinVietnamlogo;
// logoList_tamthoi[1486] = Deltalogo;
// logoList_tamthoi[148] = EsteeLauderlogo;
// logoList_tamthoi[1521] = FashionGarmentsColtdlogo;
// logoList_tamthoi[742] = Feconlogo;
// logoList_tamthoi[486] = FWDVNlogo;
// logoList_tamthoi[1535] = GojekVNlogo;
// logoList_tamthoi[423] = GreenFeedVNlogo;
// logoList_tamthoi[247] = HoaBinhConlogo;
// logoList_tamthoi[270] = JotunVNlogo;
// logoList_tamthoi[1543] = KimOanhGrouplogo;
// logoList_tamthoi[615] = KuehneNagellogo;
// logoList_tamthoi[344] = MaricoSEAlogo;
// logoList_tamthoi[187] = MMVNlogo;
// logoList_tamthoi[184] = MondelezVNlogo;
// logoList_tamthoi[190] = Novalandlogo;
// logoList_tamthoi[191] = PepsicoFoodsVNlogo;
// logoList_tamthoi[641] = PerfettiVanMellelogo;
// logoList_tamthoi[1539] = SailunVNlogo;
// logoList_tamthoi[356] = SaintGobainVNlogo;
// logoList_tamthoi[843] = SCBlogo;
// logoList_tamthoi[1532] = KimTinCorplogo;
// logoList_tamthoi[819] = PhenikaaCorplogo;
// logoList_tamthoi[541] = TPBanklogo;
// logoList_tamthoi[433] = Unibenlogo;


const VNBPTWScoreCardExport: FC<Props> =
    ({
        survey_name = "",
        year = "",
        surveyperiod = "",
        benchmark = "",
        companyid = "",
        industryid = "",
        comindid = "",
        language = "",
        companyname = "",
        companyindustry = "",
        totalrespondents = "",
        industryrespondents = "",
        companyrespondents = "",
        firstchoiceindexvalue = "",
        desireindexvalue = "",
        actionindexvalue = "",
        interestindexvalue = "",
        attentionindexvalue = "",
        rejectionindexvalue = "",
        ebaindexindexvalue = "",
        firstchoicebenchmark = "",
        desirebenchmark = "",
        actionbenchmark = "",
        interestbenchmark = "",
        attentionbenchmark = "",
        rejectionbenchmark = "",
        ebaindexbenchmark = "",
        industryranking = "",
        totalranking = "",
        top50VN = "",
        brandimagesdetail1 = "",
        brandimagesdetail2 = "",
        brandimagesdetail3 = "",
        brandimagesdetail4 = "",
        brandimagesdetail5 = "",
        attentiontointerest = "",
        interesttoaction = "",
        interesttodesire = "",
        desireto1stchoice = "",
        externalimage1 = "",
        externalimage2 = "",
        externalimage3 = "",
        externalimage4 = "",
        externalimage5 = "",
        externalimage6 = "",
        externalimage7 = "",
        externalimage8 = "",
        externalimage9 = "",
        externalimage10 = "",
        internalimage1 = "",
        internalimage2 = "",
        internalimage3 = "",
        internalimage4 = "",
        internalimage5 = "",
        internalimage6 = "",
        internalimage7 = "",
        internalimage8 = "",
        internalimage9 = "",
        internalimage10 = "",
        ctynvnhonhat1 = "",
        ctynvnhonhat2 = "",
        ctynvnhonhat3 = "",
        ctynvnhonhat4 = "",
        ctynvnhonhat5 = "",
        ctynvnhonhat6 = "",
        ctynvnhonhat7 = "",
        ctynvnhonhat8 = "",
        ctynvnhonhat9 = "",
        ctynvnhonhat10 = "",
        percentctynvnhonhat1 = "",
        percentctynvnhonhat2 = "",
        percentctynvnhonhat3 = "",
        percentctynvnhonhat4 = "",
        percentctynvnhonhat5 = "",
        percentctynvnhonhat6 = "",
        percentctynvnhonhat7 = "",
        percentctynvnhonhat8 = "",
        percentctynvnhonhat9 = "",
        percentctynvnhonhat10 = "",
        talentconsider1 = "",
        talentconsider2 = "",
        talentconsider3 = "",
        talentconsider4 = "",
        talentconsider5 = "",
        talentconsider6 = "",
        talentconsider7 = "",
        talentconsider8 = "",
        talentconsider9 = "",
        talentconsider10 = "",
        percenttalentconsider1 = "",
        percenttalentconsider2 = "",
        percenttalentconsider3 = "",
        percenttalentconsider4 = "",
        percenttalentconsider5 = "",
        percenttalentconsider6 = "",
        percenttalentconsider7 = "",
        percenttalentconsider8 = "",
        percenttalentconsider9 = "",
        percenttalentconsider10 = "",
        rationalcompany = "",
        emotinalcompany = "",
        motivationcompany = "",
        effortcompany = "",
        commitcompany = "",
        rationalindustry = "",
        emotinalindustry = "",
        motivationindustry = "",
        effortindustry = "",
        commitindustry = "",
        rationalmarket = "",
        emotinalmarket = "",
        motivationmarket = "",
        effortmarket = "",
        commitmarket = "",
        hawindexcompany = "",
        hawindexindustry = "",
        hawindexmarket = "",
        corecompany = "",
        regretcompany = "",
        zombiecompany = "",
        quittercompany = "",
        coremarket = "",
        regretmarket = "",
        zombiemarket = "",
        quittermarket = "",
        sscore = "",
        ssregret = "",
        sszombie = "",
        ssquitter = "",
        turnovercompany = "",
        turnovermarket = "",
        employeeagreegoodcompany = "",
        sharingwithfriend = "",
        cvquantity = "",
        timehire = "",
        hrsupport = "",
        hirequality = "",
        interestedworkhere = "",
        recruitmentprocess = "",
        onboarding = "",
        leadershipinspiration = "",
    }) => {
        const data_survey_name = survey_name;
        const data_year = parseInt(year);
        const data_surveyperiod = surveyperiod;
        // const data_benchmark = benchmark;
        const data_companyid = companyid;
        // const data_industryid = industryid;
        // const data_comindid = comindid;
        const data_language = language;
        const data_companyname = companyname;
        const data_companyindustry = companyindustry;
        const data_totalrespondents = totalrespondents;
        const data_industryrespondents = industryrespondents;
        const data_companyrespondents = companyrespondents;
        const data_firstchoiceindexvalue = parseFloat(firstchoiceindexvalue);
        const data_desireindexvalue = parseFloat(desireindexvalue);
        const data_actionindexvalue = parseFloat(actionindexvalue);
        const data_interestindexvalue = parseFloat(interestindexvalue);
        const data_attentionindexvalue = parseFloat(attentionindexvalue);
        const data_rejectionindexvalue = parseFloat(rejectionindexvalue);
        const data_ebaindexindexvalue = ebaindexindexvalue;
        const data_firstchoicebenchmark = firstchoicebenchmark;
        const data_desirebenchmark = desirebenchmark;
        const data_actionbenchmark = actionbenchmark;
        const data_interestbenchmark = interestbenchmark;
        const data_attentionbenchmark = attentionbenchmark;
        const data_rejectionbenchmark = rejectionbenchmark;
        const data_ebaindexbenchmark = ebaindexbenchmark;
        const data_industryranking = industryranking;
        const data_totalranking = totalranking;
        const data_top50VN = top50VN;
        // const data_brandimagesdetail1 = brandimagesdetail1;
        // const data_brandimagesdetail2 = brandimagesdetail2;
        // const data_brandimagesdetail3 = brandimagesdetail3;
        // const data_brandimagesdetail4 = brandimagesdetail4;
        // const data_brandimagesdetail5 = brandimagesdetail5;
        const data_attentiontointerest = attentiontointerest;
        const data_interesttoaction = interesttoaction;
        const data_interesttodesire = interesttodesire;
        const data_desireto1stchoice = desireto1stchoice;
        const data_externalimage1 = externalimage1;
        const data_externalimage2 = externalimage2;
        const data_externalimage3 = externalimage3;
        const data_externalimage4 = externalimage4;
        const data_externalimage5 = externalimage5;
        const data_externalimage6 = externalimage6;
        const data_externalimage7 = externalimage7;
        const data_externalimage8 = externalimage8;
        const data_externalimage9 = externalimage9;
        const data_externalimage10 = externalimage10;
        const data_internalimage1 = internalimage1;
        const data_internalimage2 = internalimage2;
        const data_internalimage3 = internalimage3;
        const data_internalimage4 = internalimage4;
        const data_internalimage5 = internalimage5;
        const data_internalimage6 = internalimage6;
        const data_internalimage7 = internalimage7;
        const data_internalimage8 = internalimage8;
        const data_internalimage9 = internalimage9;
        const data_internalimage10 = internalimage10;
        const data_ctynvnhonhat1 = ctynvnhonhat1;
        const data_ctynvnhonhat2 = ctynvnhonhat2;
        const data_ctynvnhonhat3 = ctynvnhonhat3;
        const data_ctynvnhonhat4 = ctynvnhonhat4;
        const data_ctynvnhonhat5 = ctynvnhonhat5;
        const data_ctynvnhonhat6 = ctynvnhonhat6;
        const data_ctynvnhonhat7 = ctynvnhonhat7;
        const data_ctynvnhonhat8 = ctynvnhonhat8;
        const data_ctynvnhonhat9 = ctynvnhonhat9;
        const data_ctynvnhonhat10 = ctynvnhonhat10;
        const data_percentctynvnhonhat1 = percentctynvnhonhat1;
        const data_percentctynvnhonhat2 = percentctynvnhonhat2;
        const data_percentctynvnhonhat3 = percentctynvnhonhat3;
        const data_percentctynvnhonhat4 = percentctynvnhonhat4;
        const data_percentctynvnhonhat5 = percentctynvnhonhat5;
        const data_percentctynvnhonhat6 = percentctynvnhonhat6;
        const data_percentctynvnhonhat7 = percentctynvnhonhat7;
        const data_percentctynvnhonhat8 = percentctynvnhonhat8;
        const data_percentctynvnhonhat9 = percentctynvnhonhat9;
        const data_percentctynvnhonhat10 = percentctynvnhonhat10;
        const data_talentconsider1 = talentconsider1;
        const data_talentconsider2 = talentconsider2;
        const data_talentconsider3 = talentconsider3;
        const data_talentconsider4 = talentconsider4;
        const data_talentconsider5 = talentconsider5;
        const data_talentconsider6 = talentconsider6;
        const data_talentconsider7 = talentconsider7;
        const data_talentconsider8 = talentconsider8;
        const data_talentconsider9 = talentconsider9;
        const data_talentconsider10 = talentconsider10;
        const data_percenttalentconsider1 = percenttalentconsider1;
        const data_percenttalentconsider2 = percenttalentconsider2;
        const data_percenttalentconsider3 = percenttalentconsider3;
        const data_percenttalentconsider4 = percenttalentconsider4;
        const data_percenttalentconsider5 = percenttalentconsider5;
        const data_percenttalentconsider6 = percenttalentconsider6;
        const data_percenttalentconsider7 = percenttalentconsider7;
        const data_percenttalentconsider8 = percenttalentconsider8;
        const data_percenttalentconsider9 = percenttalentconsider9;
        const data_percenttalentconsider10 = percenttalentconsider10;
        const data_rationalcompany = rationalcompany;
        const data_emotinalcompany = emotinalcompany;
        const data_motivationcompany = motivationcompany;
        const data_effortcompany = effortcompany;
        const data_commitcompany = commitcompany;
        const data_rationalindustry = rationalindustry;
        const data_emotinalindustry = emotinalindustry;
        const data_motivationindustry = motivationindustry;
        const data_effortindustry = effortindustry;
        const data_commitindustry = commitindustry;
        const data_rationalmarket = rationalmarket;
        const data_emotinalmarket = emotinalmarket;
        const data_motivationmarket = motivationmarket;
        const data_effortmarket = effortmarket;
        const data_commitmarket = commitmarket;
        const data_hawindexcompany = hawindexcompany;
        const data_hawindexindustry = hawindexindustry;
        const data_hawindexmarket = hawindexmarket;
        const data_corecompany = corecompany;
        const data_regretcompany = regretcompany;
        const data_zombiecompany = zombiecompany;
        const data_quittercompany = quittercompany;
        const data_coremarket = coremarket;
        const data_regretmarket = regretmarket;
        const data_zombiemarket = zombiemarket;
        const data_quittermarket = quittermarket;
        const data_sscore = sscore;
        const data_ssregret = ssregret;
        const data_sszombie = sszombie;
        const data_ssquitter = ssquitter;
        const data_turnovercompany = turnovercompany;
        const data_turnovermarket = turnovermarket;
        const data_employeeagreegoodcompany = employeeagreegoodcompany;
        const data_sharingwithfriend = sharingwithfriend;
        const data_cvquantity = cvquantity;
        const data_timehire = timehire;
        const data_hrsupport = hrsupport;
        const data_hirequality = hirequality;
        const data_interestedworkhere = interestedworkhere;
        const data_recruitmentprocess = recruitmentprocess;
        const data_onboarding = onboarding;
        const data_leadershipinspiration = leadershipinspiration;
        const scLanguage = data_language;
        const cologo = companyLogoList[parseInt(data_companyid)];//companyLogoList[parseInt(data_companyid) + 100];
        const classes = useStyles();
        const doExport = () => {
            //const base64 = getBase64Image(document.getElementById("ok"));

            let pres = new pptxgen();


            pres.defineLayout({ name: 'ScoreCard', width: 14.611, height: 8.5 });
            pres.layout = 'ScoreCard';

            // const company_logo = "http://csbe.vietnambestplacestowork.com/logo/novaland-group.jpg?p=rectMedium&v=1588563595";
            // const base64 = getBase64FromUrl(company_logo);

            // let imagePath = null;
            // let company_logo_base;
            // RNFS.readFile(company_logo, 'base64')
            //     .then(res => {
            //         console.log(res);
            //     });
            let slide = pres.addSlide();
            let sc_subTitle = {};
            sc_subTitle["VN"] = "BẢNG ĐÁNH GIÁ NHANH";
            sc_subTitle["EN"] = "SCORECARD";
            const companyRespondents = data_companyrespondents;
            const companyName = data_companyname;
            const companyIndustry = data_companyindustry;
            const industryrespondents = data_industryrespondents;
            const industryRespondents = data_industryrespondents;
            const surveyperiod = data_surveyperiod;
            //// PAGE 1
            // ########## Left Side Bar  START ############
            slide.addText("", {
                x: 0,
                y: 0,
                h: 8.5,
                w: 2.75,
                fill: { color: "f2f2f2" },
            });

            // const data_company_logo = 'data:image/jpeg;base64,';
            // let data_company_logo;
            // const printAddress = () => {
            //     base64.then((a) => {
            //         data_company_logo = a;
            //         // console.log ( data_company_logo);
            //         // slide.addImage({ data: data_company_logo, x: 0.2, y: 0.2, h: 1.5, w: 2.4, sizing: { type: "contain", h: 1.5, w: 2.4 } });
            //     });
            // };
            // printAddress();

            let logoWidth = 0;
            let logoHeight = 0;

            let myImg = document.getElementById("companylogo") as HTMLImageElement;
            if (myImg) {
                logoWidth = myImg.naturalWidth;
                logoHeight = myImg.naturalHeight;
            }
            const baseLogoX = 0.2;
            const baseLogoY = 0.2;
            const baseLogoW = 2.4;
            const baseLogoH = 1.5;
            const baseWHRatio = 1.6;
            const baseHWRatio = 0.625;
            let logoX = baseLogoX;
            let logoY = baseLogoY;
            let logoW = baseLogoW;
            let logoH = baseLogoH;
            if (logoHeight >= logoWidth || logoWidth < logoHeight * baseWHRatio) {
                logoW = (logoWidth / logoHeight) * baseLogoW * baseHWRatio;
                logoX = baseLogoX + (baseLogoW / 2 - logoW / 2);
                console.log(2);
            } else {
                logoH = (logoHeight / logoWidth) * baseLogoH * baseWHRatio;
                logoY = baseLogoY + (baseLogoH / 2 - logoH / 2);
                console.log(1);
            }
            console.log(logoWidth, logoHeight, logoH, logoW);
            slide.addImage({ path: cologo, x: logoX, y: logoY, h: logoH, w: logoW, sizing: { type: "contain", h: logoH, w: logoW } });
            slide.addText([{ text: companyName, options: { fontSize: 18, breakLine: true, bold: true } }, { text: companyIndustry, options: { fontSize: 14 } }], {
                x: 0,
                y: 2,
                h: 1.2,
                w: 2.6,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            let sideBarInfo_1 = {};
            sideBarInfo_1["VN"] = "Kết quả được đánh giá bởi ";
            sideBarInfo_1["EN"] = "Final results are entirely based on ";
            let sideBarInfo_2 = {};
            sideBarInfo_2["VN"] = " nhân sự Ngành";
            sideBarInfo_2["EN"] = " industry respondents";
            let sideBarInfo_conducted = {};
            sideBarInfo_conducted["VN"] = "Thời gian: ";
            sideBarInfo_conducted["EN"] = "Conducted: ";
            let sideBarInfo_4 = {};
            sideBarInfo_4["VN"] = "Điểm chuẩn so sánh dựa trên kết quả Khảo sát ";
            sideBarInfo_4["EN"] = "The benchmark is extracted from ";
            let sideBarInfo_surveyName = {};
            sideBarInfo_surveyName["VN"] = "Nơi làm việc tốt nhất Việt Nam 2021";
            sideBarInfo_surveyName["EN"] = "Vietnam Best Places To Work Survey 2021";
            slide.addText([{ text: sideBarInfo_1[scLanguage] },
            { text: " " + industryrespondents + " ", options: { color: "FF0000" } },
            { text: sideBarInfo_2[scLanguage], options: { breakLine: true } },
            { text: sideBarInfo_conducted[scLanguage] + surveyperiod, options: { breakLine: true, bullet: { indent: 10 } } },
            { text: sideBarInfo_4[scLanguage], options: { bullet: { indent: 10 } } },
            { text: sideBarInfo_surveyName[scLanguage], options: { breakLine: true, bold: true } },
            ]
                , {
                    x: 0,
                    y: 3.2,
                    h: 1.3,
                    w: 2.6,
                    color: "000000",
                    fontSize: 12,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            let sideBarInfo_contact = {};
            sideBarInfo_contact["VN"] = "Để Đo lường sức khỏe THNTD chuyên sâu & các khảo sát về Nguồn Nhân Lực, vui lòng liên hệ: ";
            sideBarInfo_contact["EN"] = "For deeper understand Employer Brand Health Check & Happiness At Work survey, please contact: ";
            slide.addText(sideBarInfo_contact[scLanguage], {
                x: 0,
                y: 4.5,
                h: 0.8,
                w: 2.6,
                color: COLOR_BLUELAGOON,
                fontSize: 11,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.justify,
            });
            slide.addText([{ text: "(+84 28) 6268 2222", options: { breakLine: true, bullet: { indent: 10 } } },
            { text: "clientsolution@anphabe.com", options: { bullet: { indent: 10 } } },
            ]
                , {
                    x: 0,
                    y: 5.3,
                    h: 0.8,
                    w: 2.6,
                    color: COLOR_BLUELAGOON,
                    fontSize: 12,
                    bold: true,
                    italic: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            let survey_logo;
            switch (data_year) {
                case 2020:
                    survey_logo = surveylogo2020;
                    break;
                case 2021:
                    survey_logo = surveylogo2021;
                    break;
                default:
                    survey_logo = surveylogo;
                    break;
            }
            slide.addImage({ path: survey_logo, x: 0.1, y: 6.3, h: 1.6, w: 2.5, sizing: { type: "contain", h: 1.6, w: 2.5 } });
            //  ########### Side Bar END #############
            // BODY PAGE 1
            // ######### HEADER START #############
            slide.addText(sc_subTitle[scLanguage], {
                x: 2.9,
                y: 0.05,
                h: 0.2,
                color: "363636",
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            let scTitle = {};
            scTitle["VN"] = "SỨC KHỎE THƯƠNG HIỆU NHÀ TUYỂN DỤNG (THNTD) 2021";
            scTitle["EN"] = "EMPLOYER BRAND HEALTH CHECK 2021 ";

            slide.addText(scTitle[scLanguage], {
                x: 2.9,
                y: 0.25,
                h: 0.6,
                color: "363636",
                fontSize: 28,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            // ######### HEADER END #############
            //////////// BODY //////////////////
            let attractiveIndex = {};
            attractiveIndex["VN"] = "CHỈ SỐ SỨC HẤP DẪN THƯƠNG HIỆU NHÀ TUYỂN DỤNG";
            attractiveIndex["EN"] = "EMPLOYER BRAND ATTRACTIVENESS INDEX";
            slide.addText(attractiveIndex[scLanguage], {
                x: 2.85,
                y: 0.95,
                h: 0.5,
                w: 6.4,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontSize: 16,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: COLOR_BLUELAGOON }
            });

            slide.addText("", {
                x: 2.85,
                y: 1.45,
                h: 5.2,
                w: 6.4,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            let conversionRate = {};
            conversionRate["VN"] = "Tỉ lệ chuyển đổi";
            conversionRate["EN"] = "Conversion rate";
            slide.addText(conversionRate[scLanguage], {
                x: 2.85,
                y: 1.45,
                h: 0.3,
                w: 6.4,
                color: "000000",
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
            });
            slide.addText("", {
                x: 4.4,
                y: 1.8,
                h: 4.8,
                w: 0.05,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_BLACK }
            });


            // Tiêu đề
            // EBA
            /////////////////// ƯU TIÊN
            const maxIndexValueBar = 1.75;
            const firstchoiceindexvalue = data_firstchoiceindexvalue;
            const desireto1stchoice = data_desireto1stchoice;
            let firstChoice_title_1 = {};
            firstChoice_title_1["VN"] = "ƯU TIÊN";
            firstChoice_title_1["EN"] = "FIRST CHOICE";
            let firstChoice_title_2 = {};
            firstChoice_title_2["VN"] = "CHỌN";
            firstChoice_title_2["EN"] = "";
            slide.addText([{ text: firstChoice_title_1[scLanguage], options: { breakLine: true } },
            { text: firstChoice_title_2[scLanguage] },
            ]
                , {
                    x: 2.85,
                    y: 1.8,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 1.9,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 1.9,
                h: 0.7,
                w: maxIndexValueBar * data_firstchoiceindexvalue / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_FIRSTCHOICE },
            });
            slide.addText(firstchoiceindexvalue + "%", {
                x: 4.8,
                y: 2.1,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            slide.addText(desireto1stchoice, {
                x: 5.75,
                y: 2.25,
                h: 0.35,
                w: 1,
                color: COLOR_REJECTION,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
                fontSize: 16
            });
            let firstChoice_desc_1 = {};
            firstChoice_desc_1["VN"] = "% Nhân sự ngành ";
            firstChoice_desc_1["EN"] = "% Industry talents give ";
            let firstChoice_desc_2 = {};
            firstChoice_desc_2["VN"] = "ưu tiên chọn ";
            firstChoice_desc_2["EN"] = "priority to accept";
            let firstChoice_desc_3 = {};
            firstChoice_desc_3["VN"] = "Công ty trong tất cả các lời mời làm việc.";
            firstChoice_desc_3["EN"] = "Company’s offer despite similar offers";

            slide.addText([{ text: firstChoice_desc_1[scLanguage] }, { text: firstChoice_desc_2[scLanguage], options: { bold: true } }, { text: firstChoice_desc_3[scLanguage] }], {
                x: 6.25,
                y: 1.9,
                h: 0.7,
                w: 2.9,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            ////////////////// KHÁT KHAO
            const desireindexvalue = data_desireindexvalue;
            const interesttodesire = data_interesttodesire;
            let desire_title = {};
            desire_title["VN"] = "KHÁT KHAO";
            desire_title["EN"] = "DESIRE";
            slide.addText([{ text: desire_title[scLanguage] },
            ]
                , {
                    x: 2.85,
                    y: 2.6,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 2.7,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 2.7,
                h: 0.7,
                w: maxIndexValueBar * data_desireindexvalue / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_DESIRE },
            });
            slide.addText(desireindexvalue + "%", {
                x: 4.9,
                y: 2.9,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            slide.addText(interesttodesire, {
                x: 6,
                y: 3,
                h: 0.35,
                w: 1,
                color: COLOR_REJECTION,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
                fontSize: 16
            });
            let desire_desc_1 = {};
            desire_desc_1["VN"] = "% Nhân sự ngành mục tiêu xem Công ty là ";
            desire_desc_1["EN"] = "% Industry talents who perceive company as an ";
            let desire_desc_2 = {};
            desire_desc_2["VN"] = "“Nơi Làm Việc Lý Tưởng”";
            desire_desc_2["EN"] = "“Ideal Place To Work”";

            slide.addText([{ text: desire_desc_1[scLanguage] }, { text: desire_desc_2[scLanguage], options: { bold: true } }], {
                x: 6.25,
                y: 2.7,
                h: 0.7,
                w: 2.9,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            ///////////////////// ỨNG TUYỂN
            const actionindexvalue = data_actionindexvalue;
            const interesttoaction = data_interesttoaction;
            let action_title = {};
            action_title["VN"] = "ỨNG TUYỂN";
            action_title["EN"] = "ACTION";
            slide.addText([{ text: action_title[scLanguage] },
            ]
                , {
                    x: 2.85,
                    y: 3.4,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 3.5,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 3.5,
                h: 0.7,
                w: maxIndexValueBar * actionindexvalue / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_ACTION },
            });
            slide.addText(actionindexvalue + "%", {
                x: 5.1,
                y: 3.6,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            slide.addText(interesttoaction, {
                x: 6.27,
                y: 3.8,
                h: 0.35,
                w: 1,
                color: COLOR_REJECTION,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
                fontSize: 16
            });
            let action_desc_1 = {};
            action_desc_1["VN"] = "% Nhân sự ngành mục tiêu ";
            action_desc_1["EN"] = "% Industry talents who are ";
            let action_desc_2 = {};
            action_desc_2["VN"] = "sẵn sàng ứng tuyển ";
            action_desc_2["EN"] = "willing to apply ";
            let action_desc_3 = {};
            action_desc_3["VN"] = "khi Công ty có vị trí phù hợp";
            action_desc_3["EN"] = "for relevant job openings at company";

            slide.addText([{ text: action_desc_1[scLanguage] }, { text: action_desc_2[scLanguage], options: { bold: true } }, { text: action_desc_3[scLanguage] }], {
                x: 6.7,
                y: 3.45,
                h: 0.7,
                w: 2.4,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            //////////////// QUAN TÂM
            const interestindexvalue = data_interestindexvalue;
            const attentiontointerest = data_attentiontointerest;
            const rejectionindexvalue = data_rejectionindexvalue;
            let interest_title = {};
            interest_title["VN"] = "QUAN TÂM";
            interest_title["EN"] = "INTEREST";
            slide.addText([{ text: interest_title[scLanguage] }
            ]
                , {
                    x: 2.85,
                    y: 4.2,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 4.3,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 4.3,
                h: 0.7,
                w: maxIndexValueBar * interestindexvalue / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_INTEREST },
            });
            slide.addText(interestindexvalue + "%", {
                x: 5.3,
                y: 4.5,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            slide.addText(attentiontointerest, {
                x: 6.55,
                y: 4.55,
                h: 0.35,
                w: 1,
                color: COLOR_REJECTION,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
                fontSize: 16
            });
            let interest_desc_1 = {};
            interest_desc_1["VN"] = "% Nhân sự ngành ";
            interest_desc_1["EN"] = "% Industry talents who ";
            let interest_desc_2 = {};
            interest_desc_2["VN"] = "cân nhắc làm việc cho Công ty trong tương lai";
            interest_desc_2["EN"] = "consider to work for company in the future";

            slide.addText([{ text: interest_desc_1[scLanguage] }, { text: interest_desc_2[scLanguage], options: { bold: true } }], {
                x: 6.9,
                y: 4.3,
                h: 0.7,
                w: 2.15,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            //////////////// TỔNG
            const attentionindexvalue = data_attentionindexvalue;
            let attention_title_1 = {};
            attention_title_1["VN"] = "TỔNG";
            attention_title_1["EN"] = "ATTENTION";
            let attention_title_2 = {};
            attention_title_2["VN"] = "NHẬN BIẾT";
            attention_title_2["EN"] = "";
            slide.addText([{ text: attention_title_1[scLanguage], options: { breakLine: true } },
            { text: attention_title_2[scLanguage] }]
                , {
                    x: 2.85,
                    y: 5,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 5.1,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5,
                y: 5.1,
                h: 0.7,
                w: maxIndexValueBar,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_ATTENTION },
            });
            slide.addText(attentionindexvalue + "%", {
                x: 4.5,
                y: 5.22,
                h: 0.35,
                w: ((100 - rejectionindexvalue) / 100) * 1.8,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });

            slide.addImage({ path: rejectLine, x: 4.5 + ((100 - rejectionindexvalue) / 100) * maxIndexValueBar - 0.03, y: 5.1, h: 0.7, w: 0.02, sizing: { type: "contain", h: 0.7, w: 0.02 } });
            let attention_desc_1 = {};
            attention_desc_1["VN"] = "% Nhân sự ngành ";
            attention_desc_1["EN"] = "% Industry talents who have";
            let attention_desc_2 = {};
            attention_desc_2["VN"] = "từng biết đến  ";
            attention_desc_2["EN"] = "know ";
            let attention_desc_3 = {};
            attention_desc_3["VN"] = "hoặc ";
            attention_desc_3["EN"] = "or ";
            let attention_desc_4 = {};
            attention_desc_4["VN"] = "nghe nói  ";
            attention_desc_4["EN"] = "heard ";
            let attention_desc_5 = {};
            attention_desc_5["VN"] = "về Công ty.";
            attention_desc_5["EN"] = "of company.";

            slide.addText([{ text: attention_desc_1[scLanguage] },
            { text: attention_desc_2[scLanguage], options: { bold: true } },
            { text: attention_desc_3[scLanguage] },
            { text: attention_desc_4[scLanguage], options: { bold: true } },
            { text: attention_desc_5[scLanguage] }], {
                x: 6.55,
                y: 5.1,
                h: 0.7,
                w: 2.6,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });
            ///////////////////// NHẬN BIẾT TIÊU CỰC

            let reject_title_1 = {};
            reject_title_1["VN"] = "NHẬN BIẾT";
            reject_title_1["EN"] = "REJCTION";
            let reject_title_2 = {};
            reject_title_2["VN"] = "TIÊU CỰC";
            reject_title_2["EN"] = "";
            slide.addText([{ text: reject_title_1[scLanguage], options: { breakLine: true } },
            { text: reject_title_2[scLanguage] }]
                , {
                    x: 2.85,
                    y: 5.8,
                    h: 0.8,
                    w: 1.5,
                    color: COLOR_BLACK,
                    fontSize: 16,
                    bold: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.right,
                });
            slide.addText("", {
                x: 4.5,
                y: 5.9,
                h: 0.7,
                w: 4.6,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_LIGHTGRAY },
            });
            slide.addText("", {
                x: 4.5 + maxIndexValueBar * (100 - rejectionindexvalue) / 100,
                y: 5.9,
                h: 0.7,
                w: maxIndexValueBar * rejectionindexvalue / 100,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fill: { color: COLOR_REJECTION },
            });
            slide.addText(rejectionindexvalue + "%", {
                x: 4 + maxIndexValueBar * (100 - rejectionindexvalue) / 100,
                y: 6,
                h: 0.35,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                fontSize: 16
            });
            let reject_desc_1 = {};
            reject_desc_1["VN"] = "% Nhân sự ngành ";
            reject_desc_1["EN"] = "% Industry talents who";
            let reject_desc_2 = {};
            reject_desc_2["VN"] = "không thích ";
            reject_desc_2["EN"] = "don’t like ";
            let reject_desc_3 = {};
            reject_desc_3["VN"] = "Công ty";
            reject_desc_3["EN"] = "company";
            slide.addText([{ text: reject_desc_1[scLanguage] },
            { text: reject_desc_2[scLanguage], options: { bold: true } },
            { text: reject_desc_3[scLanguage] }], {
                x: 6.55,
                y: 5.9,
                h: 0.7,
                w: 2.55,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.center,
                fontSize: 12
            });

            slide.addImage({ path: transformArrow, x: 5.45, y: 2, w: 1.25, h: 3.3854166667 });

            const ebaindexindexvalue = data_ebaindexindexvalue;
            let ebaIndex_title = {};
            ebaIndex_title["VN"] = "CHỈ SỐ EBA:";
            ebaIndex_title["EN"] = "EBA INDEX";
            slide.addText(ebaIndex_title[scLanguage], {
                x: 2.85,
                y: 6.8,
                h: 0.9,
                w: 6.4,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            slide.addText(ebaindexindexvalue, {
                x: 7.4,
                y: 6.8,
                h: 0.9,
                w: 1,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
            });
            // EBA benkmark
            let ebaBenkmark_title = {};
            ebaBenkmark_title["VN"] = "NGÀNH";
            ebaBenkmark_title["EN"] = "INDUSTRY";
            slide.addText(ebaBenkmark_title[scLanguage], {
                x: 9.35,
                y: 0.95,
                h: 0.5,
                w: 0.95,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontSize: 12,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: COLOR_BLUELAGOON }
            });
            const firstchoicebenchmark = data_firstchoicebenchmark;
            const desirebenchmark = data_desirebenchmark;
            const actionbenchmark = data_actionbenchmark;
            const interestbenchmark = data_interestbenchmark;
            const attentionbenchmark = data_attentionbenchmark;
            const rejectionbenchmark = data_rejectionbenchmark;
            const ebaindexbenchmark = data_ebaindexbenchmark;
            slide.addText("", {
                x: 9.35,
                y: 1.45,
                h: 6.25,
                w: 0.95,
                color: "000000",
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            slide.addText("", {

                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            slide.addText(firstchoicebenchmark, {
                x: 9.4,
                y: 2.05,
                h: 0.3,
                w: 0.7,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(desirebenchmark, {
                x: 9.4,
                y: 2.88,
                h: 0.3,
                w: 0.7,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(actionbenchmark, {
                x: 9.4,
                y: 3.61,
                h: 0.3,
                w: 0.7,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(interestbenchmark, {
                x: 9.4,
                y: 4.5,
                h: 0.3,
                w: 0.7,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(attentionbenchmark, {
                x: 9.4,
                y: 5.4,
                h: 0.3,
                w: 0.7,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(rejectionbenchmark, {
                x: 9.4,
                y: 6.1,
                h: 0.3,
                w: 0.7,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            slide.addText(ebaindexbenchmark, {
                x: 9.4,
                y: 6.8,
                h: 0.9,
                w: 0.7,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.right,
            });
            // Ranking
            let awardTitle = {};
            awardTitle["VN"] = "NƠI LÀM VIỆC TỐT NHẤT VIỆT NAM"
            awardTitle["EN"] = "VIETNAM BEST PLACES TO WORK";
            let awardSubTitle = {};
            awardSubTitle["VN"] = "BẢNG XẾP HẠNG";
            awardSubTitle["EN"] = "RANKING";
            slide.addText([
                { text: awardTitle[scLanguage], options: { fontSize: 14, breakLine: true, bold: true, } },
                { text: awardSubTitle[scLanguage], options: { fontSize: 10.5 } }], {
                x: 10.5,
                y: 0.95,
                h: 0.5,
                w: 3.8,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            slide.addText("", {
                x: 10.5,
                y: 1.45,
                h: 6.35,
                w: 3.8,
                color: "000000",
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' },
                fill: { color: COLOR_BLACK }
            });
            const industryranking = parseInt(data_industryranking);
            const totalRanking = parseInt(data_totalranking);
            const totalRespondents = data_totalrespondents;
            const top50VNAward = parseInt(data_top50VN);
            const award_1_y = 3;
            const award_2_y = 4.5;
            const award_3_y = 6;
            slide.addImage({ path: awardframe, x: 10.5, y: 1.45, h: 2.5, w: 3.8, sizing: { type: "contain", h: 2.5, w: 3.8, } });

            if (industryranking > 0 && totalRanking > 0) {
                slide.addImage({ path: industryAwardFrame, x: 11, y: award_1_y, h: 0.95, w: 2.7 });
                let awardIndustryTitle = {};
                awardIndustryTitle["VN"] = " NGÀNH";
                awardIndustryTitle["EN"] = " INDUSTRY";
                let awardEmployeeIndustryTitle = {};
                awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
                awardEmployeeIndustryTitle["EN"] = " Industry Talents";
                slide.addText([
                    { text: "#", options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                    { text: industryranking.toString(), options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                    { text: awardIndustryTitle[scLanguage], options: { fontSize: 17.5, breakLine: true, bold: true, color: COLOR_WHITE } },
                    { text: industryrespondents.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                    { text: awardEmployeeIndustryTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                    x: 11.3,
                    y: award_1_y + 0.2,
                    h: 0.5,
                    w: 2.1,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });

                slide.addImage({ path: totalAwardFrame, x: 11, y: award_2_y, h: 0.95, w: 2.7 });
                let awardMarketTitle = {};
                awardMarketTitle["VN"] = "TOÀN THỊ TRƯỜNG";
                awardMarketTitle["EN"] = "TOTAL VIETNAM";
                let awardEmployeeMarketTitle = {};
                awardEmployeeMarketTitle["VN"] = " Nhân sự Việt Nam";
                awardEmployeeMarketTitle["EN"] = " VietNam Talents";
                slide.addText([
                    { text: "#", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
                    { text: totalRanking.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
                    { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
                    { text: totalRespondents.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                    { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                    x: 11.2,
                    y: award_2_y + 0.2,
                    h: 0.5,
                    w: 2.3,
                    color: "FFFFFF",
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                if (top50VNAward > 0) {
                    slide.addImage({ path: industryAwardFrame, x: 11, y: award_3_y, h: 0.95, w: 2.7 });
                    let top50Text_1 = {};
                    top50Text_1["VN"] = "TOP 50";
                    top50Text_1["EN"] = "TOP 50";
                    let top50Text_2 = {};
                    top50Text_2["VN"] = "Doanh nghiệp Việt";
                    top50Text_2["EN"] = "Vietnamese organizations";
                    let top50Text_3 = {};
                    top50Text_3["VN"] = " có THNTD hấp dẫn";
                    top50Text_3["EN"] = " with attractive EB";
                    slide.addText([
                        { text: top50Text_1[scLanguage], options: { fontSize: 17, color: COLOR_WHITE, bold: true, breakLine: true } },
                        { text: top50Text_2[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } },
                        { text: top50Text_3[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } }], {
                        x: 11.2,
                        y: award_3_y + 0.2,
                        h: 0.5,
                        w: 2.3,
                        color: "FFFFFF",
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                }
            } else if (industryranking > 0) {
                slide.addImage({ path: industryAwardFrame, x: 11, y: award_1_y, h: 0.95, w: 2.7 });
                let awardIndustryTitle = {};
                awardIndustryTitle["VN"] = " NGÀNH";
                awardIndustryTitle["EN"] = " INDUSTRY";
                let awardEmployeeIndustryTitle = {};
                awardEmployeeIndustryTitle["VN"] = " Nhân sự ngành";
                awardEmployeeIndustryTitle["EN"] = " Industry Talents";
                slide.addText([
                    { text: "#", options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                    { text: industryranking.toString(), options: { fontSize: 17.5, color: COLOR_RED, bold: true, } },
                    { text: awardIndustryTitle[scLanguage], options: { fontSize: 17.5, breakLine: true, bold: true, color: COLOR_WHITE } },
                    { text: industryrespondents.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                    { text: awardEmployeeIndustryTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                    x: 11.3,
                    y: award_1_y + 0.2,
                    h: 0.5,
                    w: 2.1,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                if (top50VNAward > 0) {
                    slide.addImage({ path: industryAwardFrame, x: 11, y: award_2_y, h: 0.95, w: 2.7 });
                    let top50Text_1 = {};
                    top50Text_1["VN"] = "TOP 50";
                    top50Text_1["EN"] = "TOP 50";
                    let top50Text_2 = {};
                    top50Text_2["VN"] = "Doanh nghiệp Việt";
                    top50Text_2["EN"] = "Vietnamese organizations";
                    let top50Text_3 = {};
                    top50Text_3["VN"] = " có THNTD hấp dẫn";
                    top50Text_3["EN"] = " with attractive EB";
                    slide.addText([
                        { text: top50Text_1[scLanguage], options: { fontSize: 17, color: COLOR_WHITE, bold: true, breakLine: true } },
                        { text: top50Text_2[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } },
                        { text: top50Text_3[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } }], {
                        x: 11.2,
                        y: award_2_y + 0.2,
                        h: 0.5,
                        w: 2.3,
                        color: "FFFFFF",
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                }

            } else if (totalRanking > 0) {
                slide.addImage({ path: totalAwardFrame, x: 11, y: award_1_y, h: 0.95, w: 2.7 });
                let awardMarketTitle = {};
                awardMarketTitle["VN"] = "TOÀN THỊ TRƯỜNG";
                awardMarketTitle["EN"] = "TOTAL VIETNAM";
                let awardEmployeeMarketTitle = {};
                awardEmployeeMarketTitle["VN"] = " Nhân sự Việt Nam";
                awardEmployeeMarketTitle["EN"] = " VietNam Talents";
                slide.addText([
                    { text: "#", options: { fontSize: 17, color: COLOR_INTEREST, bold: true, } },
                    { text: totalRanking.toString(), options: { fontSize: 17, color: COLOR_INTEREST, bold: true, breakLine: true } },
                    { text: awardMarketTitle[scLanguage], options: { fontSize: 17, breakLine: true, bold: true, color: COLOR_WHITE } },
                    { text: totalRespondents.toString(), options: { fontSize: 10.5, color: COLOR_RED } },
                    { text: awardEmployeeMarketTitle[scLanguage], options: { fontSize: 10.5, color: COLOR_WHITE } }], {
                    x: 11.2,
                    y: award_1_y + 0.2,
                    h: 0.5,
                    w: 2.3,
                    color: "FFFFFF",
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                if (top50VNAward > 0) {
                    slide.addImage({ path: industryAwardFrame, x: 11, y: award_2_y, h: 0.95, w: 2.7 });
                    let top50Text_1 = {};
                    top50Text_1["VN"] = "TOP 50";
                    top50Text_1["EN"] = "TOP 50";
                    let top50Text_2 = {};
                    top50Text_2["VN"] = "Doanh nghiệp Việt";
                    top50Text_2["EN"] = "Vietnamese organizations";
                    let top50Text_3 = {};
                    top50Text_3["VN"] = " có THNTD hấp dẫn";
                    top50Text_3["EN"] = " with attractive EB";
                    slide.addText([
                        { text: top50Text_1[scLanguage], options: { fontSize: 17, color: COLOR_WHITE, bold: true, breakLine: true } },
                        { text: top50Text_2[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } },
                        { text: top50Text_3[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } }], {
                        x: 11.2,
                        y: award_2_y + 0.2,
                        h: 0.5,
                        w: 2.3,
                        color: "FFFFFF",
                        fontFace: 'Calibri',
                        align: pres.AlignH.center,
                    });
                }
            } else if (top50VNAward > 0) {
                slide.addImage({ path: industryAwardFrame, x: 11, y: award_1_y, h: 0.95, w: 2.7 });
                let top50Text_1 = {};
                top50Text_1["VN"] = "TOP 50";
                top50Text_1["EN"] = "TOP 50";
                let top50Text_2 = {};
                top50Text_2["VN"] = "Doanh nghiệp Việt";
                top50Text_2["EN"] = "Vietnamese organizations";
                let top50Text_3 = {};
                top50Text_3["VN"] = " có THNTD hấp dẫn";
                top50Text_3["EN"] = " with attractive EB";
                slide.addText([
                    { text: top50Text_1[scLanguage], options: { fontSize: 17, color: COLOR_WHITE, bold: true, breakLine: true } },
                    { text: top50Text_2[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } },
                    { text: top50Text_3[scLanguage], options: { fontSize: 14, color: COLOR_WHITE, bold: true, breakLine: true } }], {
                    x: 11.2,
                    y: award_1_y + 0.2,
                    h: 0.5,
                    w: 2.3,
                    color: "FFFFFF",
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
            }



            // END RANKING



            let copyRightText = {};
            copyRightText["VN"] = "* Tài liệu này được thực hiện độc quyền cho khách hàng của Anphabe. Mọi hình thức tái xuất bản hoặc chia sẻ đều phải có sự đồng ý bằng văn bản của Anphabe.";
            copyRightText["EN"] = "* This document is proprietary and conﬁdential and exclusively share to Anphabe clients only. All rights reserved. No part of this document may be reproduced or shared to other company, without prior written consent of Anphabe.";
            slide.addText(copyRightText[scLanguage], {
                x: 2.85,
                y: 7.9,
                h: 0.35,
                w: 11.45,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 11,
                italic: true
            });
            /// ################ PAGE 2  START  ###################### ///
            slide = pres.addSlide();
            // ########## Left Side Bar  START PAGE 2 ############
            slide.addText("", {
                x: 0,
                y: 0,
                h: 8.5,
                w: 2.75,
                fill: { color: "f2f2f2" },
            });
            slide.addImage({ path: cologo, x: logoX, y: logoY, h: logoH, w: logoW, sizing: { type: "contain", h: logoH, w: logoW } });
            slide.addText([{ text: companyName, options: { fontSize: 18, breakLine: true, bold: true } }, { text: companyIndustry, options: { fontSize: 14 } }], {
                x: 0,
                y: 2,
                h: 1.2,
                w: 2.6,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            let companyRespondentText = {};
            companyRespondentText["VN"] = " nhân viên " + companyName;
            companyRespondentText["EN"] = " " + companyName + " respondents";
            slide.addText([{ text: sideBarInfo_1[scLanguage], options: { breakLine: true } },
            { text: industryRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: sideBarInfo_2[scLanguage], options: { breakLine: true } },
            { text: companyRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: companyRespondentText[scLanguage], options: { breakLine: true } },
            { text: sideBarInfo_conducted[scLanguage] + surveyperiod, options: { bullet: { indent: 10 } } },
            ]
                , {
                    x: 0,
                    y: 3.2,
                    h: 1.3,
                    w: 2.6,
                    color: "000000",
                    fontSize: 12,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addText(sideBarInfo_contact[scLanguage], {
                x: 0,
                y: 4.5,
                h: 0.8,
                w: 2.6,
                color: COLOR_BLUELAGOON,
                fontSize: 11,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.justify,
            });
            slide.addText([{ text: "(+84 28) 6268 2222", options: { breakLine: true, bullet: { indent: 10 } } },
            { text: "clientsolution@anphabe.com", options: { bullet: { indent: 10 } } },
            ]
                , {
                    x: 0,
                    y: 5.3,
                    h: 0.8,
                    w: 2.6,
                    color: COLOR_BLUELAGOON,
                    fontSize: 12,
                    bold: true,
                    italic: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addImage({ path: survey_logo, x: 0.1, y: 6.3, h: 1.6, w: 2.5, sizing: { type: "contain", h: 1.6, w: 2.5 } });
            //  ########### Side Bar END #############
            // BODY PAGE 2
            ///// TITLE 
            let page2_sc_title = {};
            page2_sc_title["VN"] = "SỨC KHỎE THƯƠNG HIỆU NHÀ TUYỂN DỤNG (THNTD) 2021";
            page2_sc_title["EN"] = "EMPLOYER BRAND HEALTH CHECK 2021";
            // ######### HEADER START #############
            slide.addText(sc_subTitle[scLanguage], {
                x: 2.9,
                y: 0.05,
                h: 0.2,
                color: "363636",
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            slide.addText(page2_sc_title[scLanguage], {
                x: 2.9,
                y: 0.25,
                h: 0.6,
                bold: true,
                color: "363636",
                fontSize: 28,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            /////////////////// SLIDE 2 BODY
            let slide2ExternalTalents_1 = {};
            slide2ExternalTalents_1["VN"] = "TOP 10 HÌNH ẢNH THNTD ĐƯỢC GHI NHẬN";
            slide2ExternalTalents_1["EN"] = "TOP 10 MOST PERCEIVED";
            let slide2ExternalTalents_2 = {};
            slide2ExternalTalents_2["VN"] = "Đánh giá bởi Nhân tài tiềm năng bên ngoài";
            slide2ExternalTalents_2["EN"] = "EMPLOYER BRAND IMAGES – By External talents";
            slide.addText([{ text: slide2ExternalTalents_1[scLanguage], options: { bold: true, breakLine: true } },
            { text: slide2ExternalTalents_2[scLanguage] }], {
                x: 3.05,
                y: 1.2,
                h: 0.5,
                w: 5.1,
                color: COLOR_WHITE,
                fill: { color: COLOR_BLUELAGOON },
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            slide.addText("", {
                x: 3.05,
                y: 1.7,
                h: 5.19,
                w: 5.1,
                color: "FFFFFF",
                line: { width: 1, color: "A9A9A9" }
            });
            let externalImage: string[] = [];
            if (data_externalimage1.length > 0) {
                externalImage.push(data_externalimage1);
            }
            if (data_externalimage2.length > 0) {
                externalImage.push(data_externalimage2);
            }
            if (data_externalimage3.length > 0) {
                externalImage.push(data_externalimage3);
            }
            if (data_externalimage4.length > 0) {
                externalImage.push(data_externalimage4);
            }
            if (data_externalimage5.length > 0) {
                externalImage.push(data_externalimage5);
            }
            if (data_externalimage6.length > 0) {
                externalImage.push(data_externalimage6);
            }
            if (data_externalimage7.length > 0) {
                externalImage.push(data_externalimage7);
            }
            if (data_externalimage8.length > 0) {
                externalImage.push(data_externalimage8);
            }
            if (data_externalimage9.length > 0) {
                externalImage.push(data_externalimage9);
            }
            if (data_externalimage10.length > 0) {
                externalImage.push(data_externalimage10);
            }
            let baseExternal_Y = 1.8;
            let baseExternal_Count = 1;
            for (let i = 0; i < externalImage.length; i++) {
                slide.addText(baseExternal_Count.toString(), {
                    x: 3.1,
                    y: baseExternal_Y,
                    h: 0.45,
                    w: 0.45,
                    color: COLOR_WHITE,
                    fill: { color: COLOR_DARKGRAY },
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                baseExternal_Count++;
                slide.addText(externalImage[i], {
                    x: 3.6,
                    y: baseExternal_Y,
                    h: 0.45,
                    w: 4.4,
                    color: COLOR_BLACK,
                    fill: { color: COLOR_LIGHTGRAY },
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
                baseExternal_Y += 0.5;
            }

            let internalImages: string[] = [];
            if (data_internalimage1.length > 0) {
                internalImages.push(data_internalimage1);
            }
            if (data_internalimage2.length > 0) {
                internalImages.push(data_internalimage2);
            }
            if (data_internalimage3.length > 0) {
                internalImages.push(data_internalimage3);
            }
            if (data_internalimage4.length > 0) {
                internalImages.push(data_internalimage4);
            }
            if (data_internalimage5.length > 0) {
                internalImages.push(data_internalimage5);
            }
            if (data_internalimage6.length > 0) {
                internalImages.push(data_internalimage6);
            }
            if (data_internalimage7.length > 0) {
                internalImages.push(data_internalimage7);
            }
            if (data_internalimage8.length > 0) {
                internalImages.push(data_internalimage8);
            }
            if (data_internalimage9.length > 0) {
                internalImages.push(data_internalimage9);
            }
            if (data_internalimage10.length > 0) {
                internalImages.push(data_internalimage10);
            }
            let slide2CompanyTalents_1 = {};
            slide2CompanyTalents_1["VN"] = "TOP 10 HÌNH ẢNH THNTD CÓ TÍNH CẠNH TRANH CAO";
            slide2CompanyTalents_1["EN"] = "TOP 10 MOST PERCEIVED";
            let slide2CompanyTalents_2 = {};
            slide2CompanyTalents_2["VN"] = "Đánh giá bởi Nhân viên " + companyName.toUpperCase();
            slide2CompanyTalents_2["EN"] = "By " + companyName.toUpperCase() + " Employees";
            slide.addText([{ text: slide2CompanyTalents_1[scLanguage], options: { bold: true, breakLine: true } },
            { text: slide2CompanyTalents_2[scLanguage] }], {
                x: 8.6,
                y: 1.2,
                h: 0.5,
                w: 5.1,
                color: COLOR_WHITE,
                fill: { color: COLOR_BLUELAGOON },
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            slide.addText("", {
                x: 8.6,
                y: 1.7,
                h: 5.19,
                w: 5.1,
                color: "FFFFFF",
                line: { width: 1, color: "A9A9A9" }
            });
            let baseInternal_Y = 1.8;
            let baseInternal_Count = 1;
            for (let i = 0; i < internalImages.length; i++) {
                slide.addText(baseInternal_Count.toString(), {
                    x: 8.65,
                    y: baseInternal_Y,
                    h: 0.45,
                    w: 0.45,
                    color: COLOR_WHITE,
                    fill: { color: COLOR_DARKGRAY },
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                slide.addText(internalImages[i], {
                    x: 9.15,
                    y: baseInternal_Y,
                    h: 0.45,
                    w: 4.4,
                    color: COLOR_BLACK,
                    fill: { color: COLOR_LIGHTGRAY },
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
                baseInternal_Count++;
                baseInternal_Y += 0.5;
            }
            slide.addText(copyRightText[scLanguage], {
                x: 2.85,
                y: 7.9,
                h: 0.35,
                w: 11.45,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 11,
                italic: true
            });
            // ############ END SLIDE 2 #############

            /// ##############  PAGE 3  START ################## ///
            slide = pres.addSlide();
            // ########## Left Side Bar  START ############
            slide.addText("", {
                x: 0,
                y: 0,
                h: 8.5,
                w: 2.75,
                fill: { color: "f2f2f2" },
            });
            slide.addImage({ path: cologo, x: logoX, y: logoY, h: logoH, w: logoW, sizing: { type: "contain", h: logoH, w: logoW } });
            slide.addText([{ text: companyName, options: { fontSize: 18, breakLine: true, bold: true } }, { text: companyIndustry, options: { fontSize: 14 } }], {
                x: 0,
                y: 2,
                h: 1.2,
                w: 2.6,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            slide.addText([{ text: sideBarInfo_1[scLanguage], options: { breakLine: true } },
            { text: industryRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: sideBarInfo_2[scLanguage], options: { breakLine: true } },
            { text: companyRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: companyRespondentText[scLanguage], options: { breakLine: true } },
            { text: sideBarInfo_conducted[scLanguage] + surveyperiod, options: { bullet: { indent: 10 } } },
            ]
                , {
                    x: 0,
                    y: 3.2,
                    h: 1.3,
                    w: 2.6,
                    color: "000000",
                    fontSize: 12,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addText(sideBarInfo_contact[scLanguage], {
                x: 0,
                y: 4.5,
                h: 0.8,
                w: 2.6,
                color: COLOR_BLUELAGOON,
                fontSize: 11,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.justify,
            });
            slide.addText([{ text: "(+84 28) 6268 2222", options: { breakLine: true, bullet: { indent: 10 } } },
            { text: "clientsolution@anphabe.com", options: { bullet: { indent: 10 } } },
            ]
                , {
                    x: 0,
                    y: 5.3,
                    h: 0.8,
                    w: 2.6,
                    color: COLOR_BLUELAGOON,
                    fontSize: 12,
                    bold: true,
                    italic: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addImage({ path: survey_logo, x: 0.1, y: 6.3, h: 1.6, w: 2.5, sizing: { type: "contain", h: 1.6, w: 2.5 } });
            ///// TITLE 
            let page3_sc_title = {}
            page3_sc_title["VN"] = "CÁC ĐỐI THỦ TIỀM NĂNG (THƯƠNG HIỆU NHÀ TUYỂN DỤNG)";
            page3_sc_title["EN"] = "POTENTIAL COMPETITORS (EMPLOYER BRAND)";
            // ######### HEADER START #############
            slide.addText(sc_subTitle[scLanguage], {
                x: 2.9,
                y: 0.05,
                h: 0.2,
                color: "363636",
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            slide.addText(page3_sc_title[scLanguage], {
                x: 2.9,
                y: 0.25,
                h: 0.6,
                bold: true,
                color: "363636",
                fontSize: 28,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            let page3MostIdealTitle_1 = {};
            let page3MostIdealTitle_2 = {};
            page3MostIdealTitle_1["VN"] = "NHỮNG NHÀ TUYỂN DỤNG";
            page3MostIdealTitle_1["EN"] = "MOST IDEAL EMPLOYERS";
            page3MostIdealTitle_2["VN"] = "ĐƯỢC NHÂN VIÊN NHỚ ĐẾN NHIỀU NHẤT";
            page3MostIdealTitle_2["EN"] = "NAMED DIRECTLY BY EMPLOYEES";

            slide.addText([{ text: page3MostIdealTitle_1[scLanguage], options: { color: "ffc000", breakLine: true } }, { text: page3MostIdealTitle_2[scLanguage], options: { color: COLOR_WHITE } }], {
                x: 3.8,
                y: 1.8,
                h: 0.8,
                w: 4.9,
                fontSize: 17,
                bold: true,
                fontFace: 'Calibri',
                fill: { color: "003468" },
                align: pres.AlignH.center,
            });
            let ctyNvNhoNhat: string[] = [];
            if (data_ctynvnhonhat1.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat1);
            }
            if (data_ctynvnhonhat2.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat2);
            }
            if (data_ctynvnhonhat3.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat3);
            }
            if (data_ctynvnhonhat4.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat4);
            }
            if (data_ctynvnhonhat5.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat5);
            }
            if (data_ctynvnhonhat6.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat6);
            }
            if (data_ctynvnhonhat7.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat7);
            }
            if (data_ctynvnhonhat8.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat8);
            }
            if (data_ctynvnhonhat9.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat9);
            }
            if (data_ctynvnhonhat10.length > 0) {
                ctyNvNhoNhat.push(data_ctynvnhonhat10);
            }
            let percentCtyNvNhoNhat: string[] = [];
            if (data_percentctynvnhonhat1.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat1);
            }
            if (data_percentctynvnhonhat2.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat2);
            }
            if (data_percentctynvnhonhat3.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat3);
            }
            if (data_percentctynvnhonhat4.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat4);
            }
            if (data_percentctynvnhonhat5.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat5);
            }
            if (data_percentctynvnhonhat6.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat6);
            }
            if (data_percentctynvnhonhat7.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat7);
            }
            if (data_percentctynvnhonhat8.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat8);
            }
            if (data_percentctynvnhonhat9.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat9);
            }
            if (data_percentctynvnhonhat10.length > 0) {
                percentCtyNvNhoNhat.push(data_percentctynvnhonhat10);
            }
            let base_ctyNvNhoNhat_Y = 2.6;
            let base_ctyNvNhoNhat_count = 1;
            for (let i = 0; i < ctyNvNhoNhat.length; i++) {
                slide.addText(base_ctyNvNhoNhat_count.toString(), {
                    x: 3.8,
                    y: base_ctyNvNhoNhat_Y,
                    h: 0.3,
                    w: 0.4,
                    color: COLOR_BLACK,
                    fontSize: 14,
                    fontFace: 'Calibri',
                    bold: base_ctyNvNhoNhat_count <= 3 ? true : false,
                    align: pres.AlignH.center,
                });
                slide.addText(ctyNvNhoNhat[i], {
                    x: 4.2,
                    y: base_ctyNvNhoNhat_Y,
                    h: 0.3,
                    w: 3.5,
                    color: COLOR_BLACK,
                    fontSize: 14,
                    fontFace: 'Calibri',
                    bold: base_ctyNvNhoNhat_count <= 3 ? true : false,
                    align: pres.AlignH.center,
                });
                slide.addText(percentCtyNvNhoNhat[i], {
                    x: 7.7,
                    y: base_ctyNvNhoNhat_Y,
                    h: 0.3,
                    w: 1,
                    color: COLOR_BLACK,
                    fontSize: 14,
                    bold: base_ctyNvNhoNhat_count <= 3 ? true : false,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                base_ctyNvNhoNhat_Y += 0.3;
                base_ctyNvNhoNhat_count++;
            }

            let page3InterestTitle_1 = {}
            let page3InterestTitle_2 = {}
            let page3InterestTitle_3 = {}
            let page3InterestTitle_4 = {}
            page3InterestTitle_1["VN"] = "NHÂN TÀI ";
            page3InterestTitle_1["EN"] = "TALENT WHO TAKE ";
            page3InterestTitle_2["VN"] = "QUAN TÂM ";
            page3InterestTitle_2["EN"] = " INTEREST ";
            page3InterestTitle_3["VN"] = companyName.toUpperCase();
            page3InterestTitle_3["EN"] = "IN " + companyName.toUpperCase();
            page3InterestTitle_4["VN"] = " ĐỒNG THỜI CŨNG QUAN TÂM ĐẾN";
            page3InterestTitle_4["EN"] = " ALSO SHOW INTEREST IN";


            slide.addText([
                { text: page3InterestTitle_1[scLanguage], options: { color: COLOR_WHITE } },
                { text: page3InterestTitle_2[scLanguage], options: { color: "ffc000" } },
                { text: page3InterestTitle_3[scLanguage], options: { color: COLOR_WHITE } },
                { text: page3InterestTitle_4[scLanguage], options: { color: "ffc000" } }], {
                x: 9.15,
                y: 1.8,
                h: 0.8,
                w: 4.9,
                fontSize: 17,
                bold: true,
                fontFace: 'Calibri',
                fill: { color: "003468" },
                align: pres.AlignH.center,
            });
            let talentConsiders: string[] = [];
            let percentTalentConsiders: string[] = [];
            if (data_talentconsider1.length > 0) {
                talentConsiders.push(data_talentconsider1);
            }
            if (data_talentconsider2.length > 0) {
                talentConsiders.push(data_talentconsider2);
            }
            if (data_talentconsider3.length > 0) {
                talentConsiders.push(data_talentconsider3);
            }
            if (data_talentconsider4.length > 0) {
                talentConsiders.push(data_talentconsider4);
            }
            if (data_talentconsider5.length > 0) {
                talentConsiders.push(data_talentconsider5);
            }
            if (data_talentconsider6.length > 0) {
                talentConsiders.push(data_talentconsider6);
            }
            if (data_talentconsider7.length > 0) {
                talentConsiders.push(data_talentconsider7);
            }
            if (data_talentconsider8.length > 0) {
                talentConsiders.push(data_talentconsider8);
            }
            if (data_talentconsider9.length > 0) {
                talentConsiders.push(data_talentconsider9);
            }
            if (data_talentconsider10.length > 0) {
                talentConsiders.push(data_talentconsider10);
            }
            if (data_percenttalentconsider1.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider1);
            }
            if (data_percenttalentconsider2.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider2);
            }
            if (data_percenttalentconsider3.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider3);
            }
            if (data_percenttalentconsider4.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider4);
            }
            if (data_percenttalentconsider5.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider5);
            }
            if (data_percenttalentconsider6.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider6);
            }
            if (data_percenttalentconsider7.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider7);
            }
            if (data_percenttalentconsider8.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider8);
            }
            if (data_percenttalentconsider9.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider9);
            }
            if (data_percenttalentconsider10.length > 0) {
                percentTalentConsiders.push(data_percenttalentconsider10);
            }

            let base_talentConsiders_Y = 2.6;
            let base_talentConsiders_Count = 1;
            for (let i = 0; i < talentConsiders.length; i++) {
                slide.addText(base_talentConsiders_Count.toString(), {
                    x: 9.15,
                    y: base_talentConsiders_Y,
                    h: 0.3,
                    w: 0.4,
                    color: COLOR_BLACK,
                    bold: base_talentConsiders_Count <= 3 ? true : false,
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                slide.addText(talentConsiders[i], {
                    x: 9.55,
                    y: base_talentConsiders_Y,
                    h: 0.3,
                    w: 3.5,
                    color: COLOR_BLACK,
                    bold: base_talentConsiders_Count <= 3 ? true : false,
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                slide.addText(percentTalentConsiders[i], {
                    x: 13.05,
                    y: base_talentConsiders_Y,
                    h: 0.3,
                    w: 1,
                    color: COLOR_BLACK,
                    bold: base_talentConsiders_Count <= 3 ? true : false,
                    fontSize: 14,
                    fontFace: 'Calibri',
                    align: pres.AlignH.center,
                });
                base_talentConsiders_Y += 0.3;
                base_talentConsiders_Count++;
            }
            slide.addText(copyRightText[scLanguage], {
                x: 2.85,
                y: 7.9,
                h: 0.35,
                w: 11.45,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 11,
                italic: true
            });
            /// ##############  PAGE 3 END ################## ///
            ///  ##############  PAGE 4 START ################## ///
            slide = pres.addSlide();
            // ########## Left Side Bar  START ############
            slide.addText("", {
                x: 0,
                y: 0,
                h: 8.5,
                w: 2.75,
                fill: { color: "f2f2f2" },
            });
            slide.addImage({ path: cologo, x: logoX, y: logoY, h: logoH, w: logoW, sizing: { type: "contain", h: logoH, w: logoW } });
            slide.addText([{ text: companyName, options: { fontSize: 18, breakLine: true, bold: true } }, { text: companyIndustry, options: { fontSize: 14 } }], {
                x: 0,
                y: 2,
                h: 1.2,
                w: 2.6,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            let page4BaseOnHAWText_1 = {};
            let page4BaseOnHAWText_2 = {};
            page4BaseOnHAWText_1["VN"] = "Điểm chuẩn so sánh dựa trên ";
            page4BaseOnHAWText_1["EN"] = "The benchmark is extracted from ";
            page4BaseOnHAWText_2["VN"] = "điểm chuẩn về Nguồn nhân lực hạnh phúc qua các năm";
            page4BaseOnHAWText_2["EN"] = "Happiness At Work Benchmark over years";

            slide.addText([{ text: sideBarInfo_1[scLanguage], options: { breakLine: true } },
            { text: industryRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: sideBarInfo_2[scLanguage], options: { breakLine: true } },
            { text: companyRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: companyRespondentText[scLanguage], options: { breakLine: true } },
            { text: sideBarInfo_conducted[scLanguage] + surveyperiod, options: { breakLine: true, bullet: { indent: 10 } } },
            { text: page4BaseOnHAWText_1[scLanguage], options: { bullet: { indent: 10 } } },
            { text: page4BaseOnHAWText_2[scLanguage], options: { bold: true } }
            ]
                , {
                    x: 0,
                    y: 3.2,
                    h: 1.55,
                    w: 2.6,
                    color: "000000",
                    fontSize: 12,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addText(sideBarInfo_contact[scLanguage], {
                x: 0,
                y: 4.7,
                h: 0.8,
                w: 2.6,
                color: COLOR_BLUELAGOON,
                fontSize: 11,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.justify,
            });
            slide.addText([{ text: "(+84 28) 6268 2222", options: { breakLine: true, bullet: { indent: 10 } } },
            { text: "clientsolution@anphabe.com", options: { bullet: { indent: 10 } } },
            ]
                , {
                    x: 0,
                    y: 5.3,
                    h: 0.8,
                    w: 2.6,
                    color: COLOR_BLUELAGOON,
                    fontSize: 12,
                    bold: true,
                    italic: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addImage({ path: survey_logo, x: 0.1, y: 6.3, h: 1.6, w: 2.5, sizing: { type: "contain", h: 1.6, w: 2.5 } });
            ///// TITLE 
            let page4_sc_title = {};
            page4_sc_title["EN"] = "OVERALL INTERNAL HAPPINESS AT WORK (HAW)";
            page4_sc_title["VN"] = "CHỈ SỐ NGUỒN NHÂN LỰC HẠNH PHÚC NỘI BỘ (HAW)";
            // ######### HEADER START #############
            slide.addText(sc_subTitle[scLanguage], {
                x: 2.9,
                y: 0.05,
                h: 0.2,
                color: "363636",
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            slide.addText(page4_sc_title[scLanguage], {
                x: 2.9,
                y: 0.25,
                h: 0.6,
                bold: true,
                color: "363636",
                fontSize: 28,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            /////////////////// BODY
            let HAWIndexTitle = {};
            HAWIndexTitle["VN"] = "CHỈ SỐ NGUỒN NHÂN LỰC HẠNH PHÚC (HAW INDEX)";
            HAWIndexTitle["EN"] = "HAPPINESS AT WORK INDEX (HAW INDEX)";
            slide.addText(HAWIndexTitle[scLanguage], {
                x: 2.85,
                y: 0.95,
                h: 0.55,
                w: 11.4,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontSize: 21,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: COLOR_BLUELAGOON }
            });
            slide.addText("", {
                x: 2.85,
                y: 1.5,
                h: 5.4,
                w: 11.4,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            let engamentTitle = {};
            engamentTitle["VN"] = "MỨC ĐỘ GẮN KẾT";
            engamentTitle["EN"] = "ENGAMENT";

            slide.addText(engamentTitle[scLanguage], {
                x: 3,
                y: 1.6,
                h: 0.4,
                w: 5.3,
                color: "FFFFFF",
                fill: { color: COLOR_GRAY },
                fontSize: 16,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: COLOR_GRAY }
            });
            slide.addText("", {
                x: 3,
                y: 2,
                h: 4.75,
                w: 5.3,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            let performLoyalTitle = {}
            performLoyalTitle["VN"] = "HIỆU QUẢ LÀM VIỆC & TRUNG THÀNH";
            performLoyalTitle["EN"] = "PERFORMANCE & LOYALTY";
            slide.addText(performLoyalTitle[scLanguage], {
                x: 8.74,
                y: 1.6,
                h: 0.4,
                w: 5.3,
                color: "FFFFFF",
                fill: { color: COLOR_GRAY },
                fontSize: 16,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: COLOR_GRAY }
            });
            let engamentArrow = {};
            engamentArrow["VN"] = engamentArrowVN;
            engamentArrow["EN"] = engamentArrowEN;

            slide.addImage({ path: engamentArrow[scLanguage], x: 3, y: 2, h: 4.803, w: 1.063 });
            slide.addText("", {
                x: 8.74,
                y: 2,
                h: 4.75,
                w: 5.3,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            slide.addText("", {
                x: 8.74,
                y: 2,
                h: 2.2,
                w: 5.3,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            const maxWidthConnectBar = 2.3;
            const rationalCompany = data_rationalcompany;
            const rationalIndustry = data_rationalindustry;
            const rationalMarket = data_rationalmarket;
            const rationalCompanyInt = parseInt(rationalCompany);
            const rationalIndustryInt = parseInt(rationalIndustry);
            const rationalMarketInt = parseInt(rationalMarket);
            let rationalTitle_1 = {}
            let rationalTitle_2 = {}
            rationalTitle_1["VN"] = "GẮN KẾT";
            rationalTitle_1["EN"] = "RATIONAL";
            rationalTitle_2["VN"] = "LÝ TRÍ";
            rationalTitle_2["EN"] = "ENGAGEMENT";
            let page4CompanyText = {};
            let page4IndustryText = {};
            let page4VN2021Text = "VN 2021";
            page4CompanyText["VN"] = "Công ty";
            page4IndustryText["VN"] = "Ngành";
            page4CompanyText["EN"] = "Company";
            page4IndustryText["EN"] = "Industry";
            slide.addText([{ text: rationalTitle_1[scLanguage], options: { breakLine: true } }, { text: rationalTitle_2[scLanguage] }], {
                x: 3.8,
                y: 2.5,
                h: 0.65,
                w: 1.5,
                color: "1183a9",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                rotate: 270
            });
            slide.addText(page4CompanyText[scLanguage], {
                x: 5.05,
                y: 2.2,
                h: 0.3,
                w: maxWidthConnectBar * rationalCompanyInt / 100,
                color: COLOR_WHITE,
                fill: { color: "0070c0" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(rationalCompany, {
                x: 5.05 + maxWidthConnectBar * rationalCompanyInt / 100,
                y: 2.2,
                h: 0.3,
                w: 0.7,
                color: COLOR_BLUELAGOON,
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4IndustryText[scLanguage], {
                x: 5.05,
                y: 2.65,
                h: 0.3,
                w: maxWidthConnectBar * rationalIndustryInt / 100,
                color: COLOR_BLACK,
                fill: { color: "8faadc" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(rationalIndustry, {
                x: 5.05 + maxWidthConnectBar * rationalIndustryInt / 100,
                y: 2.65,
                h: 0.3,
                w: 0.7,
                color: COLOR_BLUELAGOON,
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4VN2021Text, {
                x: 5.05,
                y: 3.1,
                h: 0.3,
                w: maxWidthConnectBar * rationalMarketInt / 100,
                color: COLOR_BLACK,
                fill: { color: "dae3f3" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(rationalMarket, {
                x: 5.05 + maxWidthConnectBar * rationalMarketInt / 100,
                y: 3.1,
                h: 0.3,
                w: 0.7,
                color: COLOR_BLUELAGOON,
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText("", {
                x: 3,
                y: 2,
                h: 1.625,
                w: 5.3,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            // EMOTION
            const emotinalCompany = data_emotinalcompany;
            const emotinalIndustry = data_emotinalindustry;
            const emotinalMarket = data_emotinalmarket;
            const emotinalCompanyInt = parseInt(emotinalCompany);
            const emotinalIndustryInt = parseInt(data_emotinalindustry);
            const emotinalMarketInt = parseInt(data_emotinalmarket);

            let emotionalTitle_1 = {}
            let emotionalTitle_2 = {}
            emotionalTitle_1["VN"] = "GẮN KẾT";
            emotionalTitle_1["EN"] = "EMOTIONAL";
            emotionalTitle_2["VN"] = "TÌNH CẢM";
            emotionalTitle_2["EN"] = "ENGAGEMENT";
            slide.addText([{ text: emotionalTitle_1[scLanguage], options: { breakLine: true } }, { text: emotionalTitle_2[scLanguage] }], {
                x: 3.8,
                y: 4.1,
                h: 0.65,
                w: 1.5,
                color: "da1c5c",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                rotate: 270
            });
            slide.addText(page4CompanyText[scLanguage], {
                x: 5.05,
                y: 3.8,
                h: 0.3,
                w: maxWidthConnectBar * emotinalCompanyInt / 100,
                color: COLOR_WHITE,
                fill: { color: "da1c5c" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(emotinalCompany, {
                x: 5.05 + maxWidthConnectBar * emotinalCompanyInt / 100,
                y: 3.8,
                h: 0.3,
                w: 0.7,
                color: "da1c5c",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4IndustryText[scLanguage], {
                x: 5.05,
                y: 4.22,
                h: 0.3,
                w: maxWidthConnectBar * emotinalIndustryInt / 100,
                color: COLOR_WHITE,
                fill: { color: "e95184" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(emotinalIndustry, {
                x: 5.05 + maxWidthConnectBar * emotinalIndustryInt / 100,
                y: 4.22,
                h: 0.3,
                w: 0.7,
                color: "da1c5c",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4VN2021Text, {
                x: 5.05,
                y: 4.7,
                h: 0.3,
                w: maxWidthConnectBar * emotinalMarketInt / 100,
                color: COLOR_WHITE,
                fill: { color: "f39fbb" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(emotinalMarket, {
                x: 5.05 + maxWidthConnectBar * emotinalMarketInt / 100,
                y: 4.7,
                h: 0.3,
                w: 0.7,
                color: "da1c5c",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText("", {
                x: 3,
                y: 3.625,
                h: 1.625,
                w: 5.3,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            //// MOTIVATION
            let motivationTitle_1 = {}
            let motivationTitle_2 = {}
            motivationTitle_1["VN"] = "GẮN KẾT";
            motivationTitle_1["EN"] = "MOTIVATION";
            motivationTitle_2["VN"] = "ĐỘNG LỰC";
            motivationTitle_2["EN"] = "ENGAGEMENT";
            slide.addText([{ text: motivationTitle_1[scLanguage], options: { breakLine: true } }, { text: motivationTitle_2[scLanguage] }], {
                x: 3.8,
                y: 5.7,
                h: 0.65,
                w: 1.5,
                color: "7d317f",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                rotate: 270
            });
            const motivationCompany = data_motivationcompany;
            const motivationIndustry = data_motivationindustry;
            const motivationMarket = data_motivationmarket;
            const motivationCompanyInt = parseInt(data_motivationcompany);
            const motivationIndustryInt = parseInt(data_motivationindustry);
            const motivationMarketInt = parseInt(data_motivationmarket);
            slide.addText(page4CompanyText[scLanguage], {
                x: 5.05,
                y: 5.35,
                h: 0.3,
                w: maxWidthConnectBar * motivationCompanyInt / 100,
                color: COLOR_WHITE,
                fill: { color: "7030a0" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(motivationCompany, {
                x: 5.05 + maxWidthConnectBar * motivationCompanyInt / 100,
                y: 5.35,
                h: 0.3,
                w: 0.7,
                color: "da1c5c",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4IndustryText[scLanguage], {
                x: 5.05,
                y: 5.8,
                h: 0.3,
                w: maxWidthConnectBar * motivationIndustryInt / 100,
                color: COLOR_WHITE,
                fill: { color: "9a57cd" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(motivationIndustry, {
                x: 5.05 + maxWidthConnectBar * motivationIndustryInt / 100,
                y: 5.8,
                h: 0.3,
                w: 0.7,
                color: "da1c5c",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4VN2021Text, {
                x: 5.05,
                y: 6.23,
                h: 0.3,
                w: maxWidthConnectBar * motivationMarketInt / 100,
                color: COLOR_WHITE,
                fill: { color: "d5b8ea" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(motivationMarket, {
                x: 5.05 + maxWidthConnectBar * motivationMarketInt / 100,
                y: 6.23,
                h: 0.3,
                w: 0.7,
                color: "da1c5c",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            // EFFORT
            let effortTitle_1 = {}
            let effortTitle_2 = {}
            effortTitle_1["VN"] = "NỖ LỰC";
            effortTitle_1["EN"] = "DISCRETIONARY";
            effortTitle_2["VN"] = "TỰ NGUYỆN";
            effortTitle_2["EN"] = "EFFORT";
            slide.addText([{ text: effortTitle_1[scLanguage], options: { breakLine: true } }, { text: effortTitle_1[scLanguage] }], {
                x: 9.2,
                y: 2.85,
                h: 0.65,
                w: 1.7,
                color: "f3ba19",
                fontSize: 16,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                rotate: 270
            });
            const effortCompany = data_effortcompany;
            const effortIndustry = data_effortindustry;
            const effortMarket = data_effortmarket;
            const effortCompanyInt = parseInt(data_effortcompany);
            const effortIndustryInt = parseInt(data_effortindustry);
            const effortMarketInt = parseInt(data_effortmarket);
            slide.addText(page4CompanyText[scLanguage], {
                x: 10.6,
                y: 2.4,
                h: 0.42,
                w: maxWidthConnectBar * effortCompanyInt / 100,
                color: COLOR_WHITE,
                fill: { color: "ffc000" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(effortCompany, {
                x: 10.6 + maxWidthConnectBar * effortCompanyInt / 100,
                y: 2.4,
                h: 0.42,
                w: 0.7,
                color: "ffc000",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4IndustryText[scLanguage], {
                x: 10.6,
                y: 2.9,
                h: 0.42,
                w: maxWidthConnectBar * effortIndustryInt / 100,
                color: "ffc000",
                fill: { color: "ffe699" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(effortIndustry, {
                x: 10.6 + maxWidthConnectBar * effortIndustryInt / 100,
                y: 2.9,
                h: 0.42,
                w: 0.7,
                color: "ffc000",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4VN2021Text, {
                x: 10.6,
                y: 3.4,
                h: 0.42,
                w: maxWidthConnectBar * effortMarketInt / 100,
                color: "ffc000",
                fill: { color: "fff2cc" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(effortMarket, {
                x: 10.6 + maxWidthConnectBar * effortMarketInt / 100,
                y: 3.5,
                h: 0.4,
                w: 0.7,
                color: "ffc000",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            // COMMITMENT
            let commitTitle_1 = {}
            let commitTitle_2 = {}
            commitTitle_1["VN"] = "CAM KẾT";
            commitTitle_1["EN"] = "COMMITMENT";
            commitTitle_2["VN"] = "GẮN BÓ";
            commitTitle_2["EN"] = "TO STAY";
            slide.addText([{ text: commitTitle_1[scLanguage], options: { breakLine: true } }, { text: commitTitle_2[scLanguage] }], {
                x: 9.2,
                y: 5.2,
                h: 0.65,
                w: 1.7,
                color: "98ca3d",
                fontSize: 16,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                rotate: 270
            });
            const commitCompany = data_commitcompany;
            const commitIndustry = data_commitindustry;
            const commitMarket = data_commitmarket;
            const commitCompanyInt = parseInt(data_commitcompany);
            const commitIndustryInt = parseInt(data_commitindustry);
            const commitMarketInt = parseInt(data_commitmarket);
            slide.addText(page4CompanyText[scLanguage], {
                x: 10.6,
                y: 4.7,
                h: 0.4,
                w: maxWidthConnectBar * commitCompanyInt / 100,
                color: COLOR_WHITE,
                fill: { color: "99ca3c" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(commitCompany, {
                x: 10.6 + maxWidthConnectBar * commitCompanyInt / 100,
                y: 4.7,
                h: 0.4,
                w: 0.7,
                color: "70ad47",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4IndustryText[scLanguage], {
                x: 10.6,
                y: 5.2,
                h: 0.4,
                w: maxWidthConnectBar * commitIndustryInt / 100,
                color: "70ad47",
                fill: { color: "c5e0b4" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(commitIndustry, {
                x: 10.6 + maxWidthConnectBar * commitIndustryInt / 100,
                y: 5.2,
                h: 0.3,
                w: 0.7,
                color: "70ad47",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(page4VN2021Text, {
                x: 10.6,
                y: 5.7,
                h: 0.4,
                w: maxWidthConnectBar * commitMarketInt / 100,
                color: "70ad47",
                fill: { color: "e2f0d9" },
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            slide.addText(commitMarket, {
                x: 10.6 + maxWidthConnectBar * commitMarketInt / 100,
                y: 5.8,
                h: 0.4,
                w: 0.7,
                color: "70ad47",
                fontSize: 14,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.left,
            });
            /// DOWN

            const hawIndexCompany = parseInt(data_hawindexcompany);
            let page4HawIndexText = {}
            page4HawIndexText["VN"] = "Chỉ số Nguồn nhân lực hạnh phúc - ";
            page4HawIndexText["EN"] = "COMPANY HAW INDEX ";
            slide.addText([{ text: page4HawIndexText[scLanguage] + companyName, options: { fontSize: 16, bold: true, breakLine: true } },
            { text: "(n=" + companyRespondents + ")", options: { fontSize: 14, italic: true } }], {
                x: 2.9,
                y: 7.1,
                h: 0.6,
                w: 5.9,
                color: COLOR_WHITE,
                fill: { color: COLOR_GRAY },
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            slide.addText(hawIndexCompany.toString(), {
                x: 8,
                y: 7.1,
                h: 0.6,
                w: 0.7,
                color: "fabb0b",
                fontSize: 25,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
            });

            const hawIndexIndustry = parseInt(data_hawindexindustry);
            let averageIndustryText = {};
            averageIndustryText["VN"] = "Trung bình ngành";
            averageIndustryText["EN"] = "INDUSTRY AVG";
            slide.addText([{ text: averageIndustryText[scLanguage], options: { fontSize: 16, bold: true, breakLine: true } },
            { text: "(n=" + industryRespondents + ")", options: { fontSize: 14, italic: true } }], {
                x: 8.81,
                y: 7.1,
                h: 0.6,
                w: 2.7,
                color: COLOR_WHITE,
                fill: { color: COLOR_GRAY },
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            slide.addText(hawIndexIndustry.toString(), {
                x: 10.6,
                y: 7.1,
                h: 0.6,
                w: 0.7,
                color: "fabb0b",
                fontSize: 25,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
            });
            const hawIndexMarket = parseInt(data_hawindexmarket);
            let page4VN2021BotText = {}
            page4VN2021BotText["VN"] = "Việt Nam 2021";
            page4VN2021BotText["EN"] = "VIET NAM 2021";
            slide.addText([{ text: page4VN2021BotText[scLanguage], options: { fontSize: 16, bold: true, breakLine: true } },
            { text: "(n=" + totalRespondents + ")", options: { fontSize: 14, italic: true } }], {
                x: 11.52,
                y: 7.1,
                h: 0.6,
                w: 2.71,
                color: COLOR_WHITE,
                fill: { color: COLOR_GRAY },
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            slide.addText(hawIndexMarket.toString(), {
                x: 13.2,
                y: 7.1,
                h: 0.6,
                w: 0.7,
                color: "fabb0b",
                fontSize: 25,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
            });
            let actionArrowImg = {};
            actionArrowImg["VN"] = actionArrowVN;
            actionArrowImg["EN"] = actionArrowEN;

            slide.addImage({ path: actionArrowImg[scLanguage], x: 8.3, y: 3, h: 2.396, w: 1.354 });
            slide.addText(copyRightText[scLanguage], {
                x: 2.85,
                y: 7.9,
                h: 0.35,
                w: 11.45,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 11,
                italic: true
            });
            // ######### HEADER END #############
            ///  ##############  PAGE 4 END ################## ///
            /// ##############  PAGE 5 START ##################///
            slide = pres.addSlide();
            // ########## Left Side Bar  START ############
            slide.addText("", {
                x: 0,
                y: 0,
                h: 8.5,
                w: 2.75,
                fill: { color: "f2f2f2" },
            });
            slide.addImage({ path: cologo, x: logoX, y: logoY, h: logoH, w: logoW, sizing: { type: "contain", h: logoH, w: logoW } });
            slide.addText([{ text: companyName, options: { fontSize: 18, breakLine: true, bold: true } }, { text: companyIndustry, options: { fontSize: 14 } }], {
                x: 0,
                y: 2,
                h: 1.2,
                w: 2.6,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            slide.addText([{ text: sideBarInfo_1[scLanguage], options: { breakLine: true } },
            { text: industryRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: sideBarInfo_2[scLanguage], options: { breakLine: true } },
            { text: companyRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: companyRespondentText[scLanguage], options: { breakLine: true } },
            { text: sideBarInfo_conducted[scLanguage] + surveyperiod, options: { breakLine: true, bullet: { indent: 10 } } },
            { text: page4BaseOnHAWText_1[scLanguage], options: { bullet: { indent: 10 } } },
            { text: page4BaseOnHAWText_2[scLanguage], options: { bold: true } }
            ]
                , {
                    x: 0,
                    y: 3.2,
                    h: 1.55,
                    w: 2.6,
                    color: "000000",
                    fontSize: 12,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addText(sideBarInfo_contact[scLanguage], {
                x: 0,
                y: 4.7,
                h: 0.8,
                w: 2.6,
                color: COLOR_BLUELAGOON,
                fontSize: 11,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.justify,
            });
            slide.addText([{ text: "(+84 28) 6268 2222", options: { breakLine: true, bullet: { indent: 10 } } },
            { text: "clientsolution@anphabe.com", options: { bullet: { indent: 10 } } },
            ]
                , {
                    x: 0,
                    y: 5.3,
                    h: 0.8,
                    w: 2.6,
                    color: COLOR_BLUELAGOON,
                    fontSize: 12,
                    bold: true,
                    italic: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addImage({ path: survey_logo, x: 0.1, y: 6.3, h: 1.6, w: 2.5, sizing: { type: "contain", h: 1.6, w: 2.5 } });
            /// SIDE BAR END //
            ///// TITLE 
            let page5_sc_title = {};
            page5_sc_title["VN"] = "CHỈ SỐ NGUỒN NHÂN LỰC HẠNH PHÚC NỘI BỘ (HAW)";
            page5_sc_title["EN"] = "OVERALL INTERNAL HAPPINESS AT WORK (HAW)";
            // ######### HEADER START #############
            slide.addText(sc_subTitle[scLanguage], {
                x: 2.9,
                y: 0.05,
                h: 0.2,
                color: "363636",
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            slide.addText(page5_sc_title[scLanguage], {
                x: 2.9,
                y: 0.25,
                h: 0.6,
                bold: true,
                color: "363636",
                fontSize: 28,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            let classificationTitle = {};
            classificationTitle["VN"] = "PHÂN BỔ NGUỒN NHÂN LỰC";
            classificationTitle["EN"] = "INTERNAL EMPLOYEE CLASSIFICATION";
            slide.addText(classificationTitle[scLanguage], {
                x: 2.85,
                y: 0.95,
                h: 0.55,
                w: 11.4,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontSize: 16,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: COLOR_BLUELAGOON }
            });
            slide.addText("", {
                x: 2.85,
                y: 1.5,
                h: 3.8,
                w: 11.4,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            slide.addText("", {
                x: 8.25,
                y: 1.5,
                h: 3.8,
                w: 0.65,
                color: "FFFFFF",
                fontFace: 'Calibri',
                fill: { color: "595959" },
                bold: true,
                align: pres.AlignH.center,
            });
            slide.addText("", {
                x: 2.85,
                y: 3,
                h: 0.65,
                w: 11.4,
                color: "FFFFFF",
                fontFace: 'Calibri',
                fill: { color: "595959" },
                bold: true,
                align: pres.AlignH.center,
            });
            slide.addImage({ path: whiteCircle, x: 8.25, y: 3, h: 0.65, w: 0.65 });
            slide.addImage({ path: greenCircle, x: 6.8, y: 1.6, h: 1, w: 1 });
            slide.addImage({ path: redCircle, x: 9.2, y: 1.6, h: 1, w: 1 });
            slide.addImage({ path: greenCircle, x: 9.2, y: 4.2, h: 1, w: 1 });
            slide.addImage({ path: orangeCircle, x: 6.8, y: 4.2, h: 1, w: 1 });

            slide.addImage({ path: classificationArrowUp, x: 8.55, y: 2.7, h: 0.15, w: 0.15, sizing: { type: "contain", h: 0.15, w: 0.15 } });
            slide.addImage({ path: classificationArrowDown, x: 8.55, y: 3.9, h: 0.15, w: 0.15, sizing: { type: "contain", h: 0.15, w: 0.15 } });
            slide.addImage({ path: classificationArrowRight, x: 9.2, y: 3.28, h: 0.15, w: 0.15, sizing: { type: "contain", h: 0.15, w: 0.15 } });
            slide.addImage({ path: classificationArrowLine, x: 9.25, y: 3.34, h: 0.02, w: 4.9 });
            slide.addImage({ path: classificationArrowLeft, x: 7.77, y: 3.28, h: 0.15, w: 0.15, sizing: { type: "contain", h: 0.15, w: 0.15 } });
            slide.addImage({ path: classificationArrowLine, x: 2.9, y: 3.34, h: 0.02, w: 5 });
            let page5commitQ = {};
            page5commitQ["VN"] = "CAM KẾT GẮN BÓ";
            page5commitQ["EN"] = "INTENT TO STAY";
            slide.addText(page5commitQ[scLanguage], {
                x: 3, y: 3.2, h: 0.3, w: 1.4,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                fontSize: 11,
                bold: true,
                align: pres.AlignH.left,
                fill: { color: "595959" }
            });
            let page5leaveQ = {};
            page5leaveQ["VN"] = "CÓ Ý ĐỊNH NGHỈ VIỆC";
            page5leaveQ["EN"] = "INTENT TO LEAVE";
            slide.addText(page5leaveQ[scLanguage], {
                x: 12.1, y: 3.2, h: 0.3, w: 2,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                fontSize: 11,
                bold: true,
                align: pres.AlignH.left,
                fill: { color: "595959" }
            });
            let page5highEffortQ = {};
            page5highEffortQ["VN"] = "NỖ LỰC CAO";
            page5highEffortQ["EN"] = "HIGH EFFORT";
            slide.addText(page5highEffortQ[scLanguage], {
                x: 8.05, y: 2, h: 0.3, w: 1.15,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                fontSize: 11,
                bold: true,
                align: pres.AlignH.left,
                fill: { color: "595959" },
                rotate: 270
            });
            let page5LowEffortQ = {};
            page5LowEffortQ["VN"] = "NỖ LỰC THẤP";
            page5LowEffortQ["EN"] = "LOW EFFORT";
            slide.addText(page5LowEffortQ[scLanguage], {
                x: 8.05, y: 4.6, h: 0.3, w: 1.1,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                fontSize: 11,
                bold: true,
                align: pres.AlignH.left,
                fill: { color: "595959" },
                rotate: 270
            });
            const coreCompany = data_corecompany;
            const regretCompany = data_regretcompany;
            const zombieCompany = data_zombiecompany;
            const quitterCompany = data_quittercompany;
            // CORE
            slide.addText(coreCompany, {
                x: 6.8, y: 1.6, h: 1, w: 1,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 21,
                align: pres.AlignH.center,
            });
            // REGRET
            slide.addText(regretCompany, {
                x: 9.2, y: 1.6, h: 1, w: 1,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 21,
                align: pres.AlignH.center,
            });
            // ZOombie
            slide.addText(zombieCompany, {
                x: 6.8, y: 4.2, h: 1, w: 1,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 21,
                align: pres.AlignH.center,
            });
            // QUIT
            slide.addText(quitterCompany, {
                x: 9.2, y: 4.2, h: 1, w: 1,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 21,
                align: pres.AlignH.center,
            });
            // 4 cai baloon
            slide.addImage({ path: coreBaloon, x: 4.68, y: 2.36, h: 0.646, w: 2.313 });
            slide.addImage({ path: regretBaloon, x: 10.05, y: 2.3, h: 0.688, w: 2.136 });
            slide.addImage({ path: zoombieBaloon, x: 4.56, y: 3.7, h: 0.636, w: 2.250 });
            slide.addImage({ path: quitBaloon, x: 10.3, y: 3.8, h: 0.833, w: 2.011 });

            const coreMarket = data_coremarket;
            const regretMarket = data_regretmarket;
            const zombieMarket = data_zombiemarket;
            const quitterMarket = data_quittermarket;
            const ssCore = data_sscore;
            const ssRegret = data_ssregret;
            const ssZombie = data_sszombie;
            const ssQuitter = data_ssquitter;
            // Text 4 cai baloon
            // Core
            let page5BaloonText = {};
            page5BaloonText["VN"] = " mức trung bình thị trường ";
            page5BaloonText["EN"] = " Market Avg. ";
            //Core
            slide.addText([{ text: ssCore, options: { color: COLOR_RED, bold: true } },
            { text: page5BaloonText[scLanguage], options: { color: COLOR_BLACK } },
            { text: "(" + coreMarket + ")", options: { color: COLOR_RED } }], {
                x: 4.67, y: 2.41, h: 0.5, w: 2,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 12,
                align: pres.AlignH.center,
            });
            // Regret
            slide.addText([{ text: ssRegret, options: { color: COLOR_RED, bold: true } },
            { text: page5BaloonText[scLanguage], options: { color: COLOR_BLACK } },
            { text: "(" + regretMarket + ")", options: { color: COLOR_RED } }], {
                x: 10.1, y: 2.4, h: 0.5, w: 2,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 12,
                align: pres.AlignH.center,
            });
            // Zoombie
            slide.addText([{ text: ssZombie, options: { color: COLOR_RED, bold: true } },
            { text: page5BaloonText[scLanguage], options: { color: COLOR_BLACK } },
            { text: "(" + zombieMarket + ")", options: { color: COLOR_RED } }], {
                x: 4.61, y: 3.75, h: 0.5, w: 2,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 12,
                align: pres.AlignH.center,
            });
            // Quit
            slide.addText([{ text: ssQuitter, options: { color: COLOR_RED, bold: true } },
            { text: page5BaloonText[scLanguage], options: { color: COLOR_BLACK } },
            { text: "(" + quitterMarket + ")", options: { color: COLOR_RED } }], {
                x: 10.35, y: 3.85, h: 0.5, w: 2,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 12,
                align: pres.AlignH.center,
            });
            // 4 cai Title
            let pageCore5Title = {};
            pageCore5Title["VN"] = "ĐỘI NGŨ NÒNG CỐT";
            pageCore5Title["EN"] = "CORE TEAM";
            slide.addText(pageCore5Title[scLanguage], {
                x: 2.85, y: 1.55, h: 0.4, w: 2.8,
                color: "a5d054",
                fontFace: 'Calibri',
                bold: true,
                fontSize: 20,
                align: pres.AlignH.left,
            });
            let page5RegretTitle = {};
            page5RegretTitle["VN"] = "THẤT THOÁT ĐÁNG TIẾC";
            page5RegretTitle["EN"] = "REGRET LOSS";
            slide.addText(page5RegretTitle[scLanguage], {
                x: 11.25, y: 1.55, h: 0.4, w: 3,
                color: "c00000",
                fontFace: 'Calibri',
                bold: true,
                fontSize: 20,
                align: pres.AlignH.right,
            });
            let page5ZombieTitle = {};
            page5ZombieTitle["VN"] = "NHÓM “ZOMBIE”";
            page5ZombieTitle["EN"] = "“ZOMBIE”";
            slide.addText(page5ZombieTitle[scLanguage], {
                x: 2.85, y: 4, h: 0.4, w: 1.85,
                color: "fabb0b",
                fontFace: 'Calibri',
                bold: true,
                fontSize: 20,
                align: pres.AlignH.left,
            });
            let page5QuitTitle = {};
            page5QuitTitle["VN"] = "NHÓM TỪ BỎ";
            page5QuitTitle["EN"] = "QUITTER";
            slide.addText(page5QuitTitle[scLanguage], {
                x: 12.2, y: 4.1, h: 0.4, w: 1.85,
                color: "a5d054",
                fontFace: 'Calibri',
                bold: true,
                fontSize: 20,
                align: pres.AlignH.right,
            });
            let page5CoreDesc = {};
            page5CoreDesc["VN"] = "Nhân viên NỖ LỰC và CAM KẾT GẮN BÓ";
            page5CoreDesc["EN"] = "Loyal & High Effort";
            slide.addText(page5CoreDesc[scLanguage], {
                x: 2.85, y: 1.95, h: 0.63, w: 2.45,
                color: COLOR_BLACK,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 15,
                align: pres.AlignH.left,
            });
            let page5RegretDesc = {};
            page5RegretDesc["VN"] = "Nhân viên NỖ LỰC, nhưng vẫn CÓ Ý ĐỊNH NGHỈ VIỆC";
            page5RegretDesc["EN"] = "High Effort yet still Leave";
            slide.addText(page5RegretDesc[scLanguage], {
                x: 12.2, y: 1.95, h: 0.9, w: 2.15,
                color: COLOR_BLACK,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 15,
                align: pres.AlignH.left,
            });
            let page5ZombieDesc = {};
            page5ZombieDesc["VN"] = "Nhân viên KHÔNG NỖ LỰC, nhưng KHÔNG CÓ Ý ĐỊNH NGHỈ VIỆC";
            page5ZombieDesc["EN"] = "Loyal yet Low Effort";
            slide.addText(page5ZombieDesc[scLanguage], {
                x: 2.85, y: 4.5, h: 0.9, w: 2.85,
                color: COLOR_BLACK,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 15,
                align: pres.AlignH.left,
            });
            let page5QuitDesc = {};
            page5QuitDesc["VN"] = "Nhân viên KHÔNG NỖ LỰC, và CÓ Ý ĐỊNH NGHỈ VIỆC";
            page5QuitDesc["EN"] = "Low Effort & Leave";
            slide.addText(page5QuitDesc[scLanguage], {
                x: 11.4, y: 4.5, h: 0.65, w: 2.8,
                color: COLOR_BLACK,
                fontFace: 'Calibri',
                bold: true,
                fontSize: 15,
                align: pres.AlignH.right,
            });
            // DỰ ĐOÁN TỈ LỆ NGHỈ VIỆC
            let turnOverRateText = {};
            turnOverRateText["VN"] = "DỰ ĐOÁN TỈ LỆ NGHỈ VIỆC";
            turnOverRateText["EN"] = "TURNOVER RATE FORECAST";
            slide.addText(turnOverRateText[scLanguage], {
                x: 2.85,
                y: 5.4,
                h: 0.3,
                w: 11.4,
                color: COLOR_WHITE,
                fill: { color: "c55a11" },
                fontSize: 16,
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: COLOR_BLUELAGOON }
            });
            slide.addText("", {
                x: 2.85,
                y: 5.7,
                h: 1.9,
                w: 11.4,
                color: "FFFFFF",
                fontFace: 'Calibri',
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            slide.addImage({ path: notStayArrow, x: 3.1, y: 6.74, h: 0.448, w: 4.542 });
            let turnOverPercentText = {};
            turnOverPercentText["VN"] = "% Nhân viên KHÔNG cam kết gắn bó";
            turnOverPercentText["EN"] = "% Employees DO NOT Commit to stay";
            slide.addText(turnOverPercentText[scLanguage], {
                x: 3.1,
                y: 6.75,
                h: 0.4,
                w: 4.65,
                color: COLOR_WHITE,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.left,
            });

            let totalStay = Math.ceil((parseInt(coreCompany) + parseInt(zombieCompany)) / 10);
            let totalLeave = 10 - totalStay;
            let leaveStay_x = 3.6;
            const leaveStay_y = 5.9;
            const employee_h = 0.767;
            const employee_w = 0.344;
            for (let j = 0; j < totalStay; j++) {
                slide.addImage({ path: stayEmployee, x: leaveStay_x, y: leaveStay_y, h: employee_h, w: employee_w });
                leaveStay_x = leaveStay_x + employee_w + 0.01;
            }
            for (let k = 0; k < totalLeave; k++) {
                slide.addImage({ path: leaveEmployee, x: leaveStay_x, y: leaveStay_y, h: employee_h, w: employee_w });
                leaveStay_x = leaveStay_x + employee_w + 0.01;
            }
            let turnOverPercentDescText = {};
            turnOverPercentDescText["VN"] = "(Nhân viên chọn Rất KHÔNG đồng ý/ KHÔNG Đồng ý/ Hơi KHÔNG đồng ý với các nhận định “Cam kết gắn bó”)";
            turnOverPercentDescText["EN"] = "(Employees choose Totally disagree/Disagree/Somewhat disagree toward statements representing to “Commitment to Stay”)";
            slide.addText(turnOverPercentDescText[scLanguage], {
                x: 2.92,
                y: 7.15,
                h: 0.45,
                w: 4.82,
                color: COLOR_BLACK,
                fontFace: 'Calibri',
                fontSize: 11,
                italic: true,
                align: pres.AlignH.center,
            });
            slide.addText(companyName, {
                x: 9.1,
                y: 6,
                h: 0.88,
                w: 2.2,
                color: COLOR_BLACK,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            const turnovercompany = data_turnovercompany;
            const turnovermarket = data_turnovermarket;
            slide.addText([{ text: "(n=" + companyRespondents + ")", options: { fontSize: 11, italic: true, breakLine: true, color: COLOR_GRAY } },
            { text: turnovercompany, options: { bold: true, fontSize: 21, color: COLOR_BLACK } }], {
                x: 9.8,
                y: 6.85,
                h: 0.8,
                w: 1,
                color: COLOR_BLACK,
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            let page5Vietnam = {}
            page5Vietnam["VN"] = "Việt Nam";
            page5Vietnam["EN"] = "VIET NAM";
            slide.addText(page5Vietnam[scLanguage], {
                x: 11.85,
                y: 6,
                h: 0.88,
                w: 2.2,
                color: COLOR_BLACK,
                fontFace: 'Calibri',
                fontSize: 16,
                bold: true,
                align: pres.AlignH.center,
                line: { width: 1, color: 'A9A9A9' }
            });
            slide.addText([{ text: "(n=" + totalRespondents + ")", options: { fontSize: 11, italic: true, breakLine: true, color: COLOR_GRAY } },
            { text: turnovermarket, options: { bold: true, fontSize: 21, color: COLOR_BLACK } }], {
                x: 12.5,
                y: 6.85,
                h: 0.8,
                w: 1,
                color: COLOR_BLACK,
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            slide.addText(copyRightText[scLanguage], {
                x: 2.85,
                y: 7.9,
                h: 0.35,
                w: 11.45,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 11,
                italic: true
            });
            /// ##############  PAGE 5 END ##################///
            /// ##############  PAGE 6 START ##################///
            slide = pres.addSlide();
            // ########## Left Side Bar  START ############
            slide.addText("", {
                x: 0,
                y: 0,
                h: 8.5,
                w: 2.75,
                fill: { color: "f2f2f2" },
            });
            slide.addImage({ path: cologo, x: logoX, y: logoY, h: logoH, w: logoW, sizing: { type: "contain", h: logoH, w: logoW } });
            slide.addText([{ text: companyName, options: { fontSize: 18, breakLine: true, bold: true } }, { text: companyIndustry, options: { fontSize: 14 } }], {
                x: 0,
                y: 2,
                h: 1.2,
                w: 2.6,
                color: "FFFFFF",
                fill: { color: COLOR_BLUELAGOON },
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            let page6Result_1 = {};
            page6Result_1["VN"] = "Điểm chuẩn so sánh dựa trên kết quả khảo sát ";
            page6Result_1["EN"] = "The benchmark is extracted from ";
            let page6Result_2 = {};
            page6Result_2["VN"] = "Nơi làm việc tốt nhất Việt Nam 2021";
            page6Result_2["EN"] = "Vietnam Best Places To Work Survey 2021";
            slide.addText([{ text: sideBarInfo_1[scLanguage], options: { breakLine: true } },
            { text: industryRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: sideBarInfo_2[scLanguage], options: { breakLine: true } },
            { text: companyRespondents.toString(), options: { color: "FF0000", bullet: { indent: 10 } } },
            { text: companyRespondentText[scLanguage], options: { breakLine: true } },
            { text: sideBarInfo_conducted[scLanguage] + surveyperiod, options: { breakLine: true, bullet: { indent: 10 } } },
            { text: page6Result_1[scLanguage], options: { bullet: { indent: 10 } } },
            { text: page6Result_2[scLanguage], options: { bold: true } }
            ]
                , {
                    x: 0,
                    y: 3.2,
                    h: 1.5,
                    w: 2.6,
                    color: "000000",
                    fontSize: 12,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addText(sideBarInfo_contact[scLanguage], {
                x: 0,
                y: 4.7,
                h: 0.8,
                w: 2.6,
                color: COLOR_BLUELAGOON,
                fontSize: 11,
                fontFace: 'Calibri',
                italic: true,
                align: pres.AlignH.justify,
            });
            slide.addText([{ text: "(+84 28) 6268 2222", options: { breakLine: true, bullet: { indent: 10 } } },
            { text: "clientsolution@anphabe.com", options: { bullet: { indent: 10 } } },
            ]
                , {
                    x: 0,
                    y: 5.3,
                    h: 0.8,
                    w: 2.6,
                    color: COLOR_BLUELAGOON,
                    fontSize: 12,
                    bold: true,
                    italic: true,
                    fontFace: 'Calibri',
                    align: pres.AlignH.left,
                });
            slide.addImage({ path: survey_logo, x: 0.1, y: 6.3, h: 1.6, w: 2.5, sizing: { type: "contain", h: 1.6, w: 2.5 } });

            // SIDE BAR END //
            ///// TITLE 
            let page6_sc_title = {};
            page6_sc_title["VN"] = "THƯƠNG HIỆU NTD & TRẢI NGHIỆM TUYỂN DỤNG – Đánh giá bởi nhân viên";
            page6_sc_title["EN"] = "RECRUITMENT ACTIVITIES EVALUATION – By Employees";
            // ######### HEADER START #############
            slide.addText(sc_subTitle[scLanguage], {
                x: 2.9,
                y: 0.05,
                h: 0.2,
                color: "363636",
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            slide.addText(page6_sc_title[scLanguage], {
                x: 2.9,
                y: 0.25,
                h: 0.6,
                bold: true,
                color: "363636",
                fontSize: 23,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            //// PAGE 6 BODY
            const employeeagreegoodcompany = data_employeeagreegoodcompany;
            const sharingwithfriend = data_sharingwithfriend;
            const employeeagreegoodcompanyInt = parseInt(data_employeeagreegoodcompany);
            const sharingwithfriendInt = parseInt(data_sharingwithfriend);
            const agreeChartPieStat = [
                {
                    name: "Project Status",
                    labels: ["Amber", "White"],
                    values: [employeeagreegoodcompanyInt, 100 - employeeagreegoodcompanyInt],
                },
            ];
            let agreeOptsChartPie = {
                x: 3.2, y: 0.9, h: 1.616, w: 1.616,
                chartColors: ["ffc000", "92d050"],
                dataBorder: { pt: 2.5, color: "F1F1F1" },
                dataLabelColor: "FFFFFF",
                dataLabelFontSize: 14,

                // legendPos: "r",

                showLabel: false,
                showValue: false,
                showPercent: false,
                showLegend: false,
                showTitle: false,

                holeSize: 80,

                title: "Project Status",
                titleColor: "33CF22",
                titleFontFace: "Helvetica Neue",
                titleFontSize: 24,
            };
            slide.addChart(pres.ChartType.doughnut, agreeChartPieStat, agreeOptsChartPie);
            const shareChartPieStat = [
                {
                    name: "Project Status",
                    labels: ["Amber", "White"],
                    values: [sharingwithfriendInt, 100 - sharingwithfriendInt],
                },
            ];
            let shareOptsChartPie = {
                x: 8.75, y: 0.9, h: 1.616, w: 1.616,
                chartColors: ["ed7d31", "92d050"],
                dataBorder: { pt: 2.5, color: "F1F1F1" },
                dataLabelColor: "FFFFFF",
                dataLabelFontSize: 14,

                // legendPos: "r",

                showLabel: false,
                showValue: false,
                showPercent: false,
                showLegend: false,
                showTitle: false,

                holeSize: 80,

                title: "Project Status",
                titleColor: "33CF22",
                titleFontFace: "Helvetica Neue",
                titleFontSize: 24,
            };
            slide.addChart(pres.ChartType.doughnut, shareChartPieStat, shareOptsChartPie);
            // slide.addImage({ path: percentageChart, x: 3.2, y: 1.1, h: 1.416, w: 1.416 });
            // slide.addImage({ path: percentageChart, x: 8.55, y: 1.1, h: 1.416, w: 1.416 });
            slide.addText(employeeagreegoodcompany, {
                x: 3.3, y: 0.9, h: 1.616, w: 1.616,
                color: COLOR_BLACK,
                fontSize: 24,
                bold: true,
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            slide.addText(sharingwithfriend, {
                x: 8.75,
                y: 0.9,
                h: 1.616,
                w: 1.616,
                color: COLOR_BLACK,
                fontSize: 24,
                bold: true,
                fontFace: 'Calibri',
                align: pres.AlignH.center,
            });
            let page6AgreeGood = {};
            page6AgreeGood["VN"] = companyName + " nổi tiếng là một nơi làm việc tốt";
            page6AgreeGood["EN"] = "Employees agree that " + companyName + " is well-known as a good place to work";
            slide.addText(page6AgreeGood[scLanguage], {
                x: 4.92,
                y: 1.3,
                h: 1,
                w: 2.62,
                color: COLOR_BLACK,
                fontSize: 18,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            let page6Share = {};
            page6Share["VN"] = "Tôi có thể chia sẻ rõ ràng với bạn bè/người thân những nét hấp dẫn của " + companyName + " dưới góc độ một nơi làm việc";
            page6Share["EN"] = "Employees can clearly share with friends/relatives about the attractiveness of " + companyName + " as a workplace";
            slide.addText(page6Share[scLanguage], {
                x: 10.6,
                y: 1.1,
                h: 1.5,
                w: 3.8,
                color: COLOR_BLACK,
                fontSize: 18,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
            });
            slide.addText("", {
                x: 2.9,
                y: 3.3,
                h: 4.5,
                w: 11.3,
                fill: { color: COLOR_LIGHTGRAY }
            });
            let page6ManagerEva = {};
            page6ManagerEva["VN"] = "ĐÁNH GIÁ CỦA QUẢN LÝ";
            page6ManagerEva["EN"] = "HIRING MANAGER’S EVALUATION";
            slide.addText(page6ManagerEva[scLanguage], {
                x: 3.2,
                y: 3.4,
                h: 0.5,
                w: 5.2,
                color: COLOR_WHITE,
                fontSize: 18,
                fontFace: 'Calibri',
                align: pres.AlignH.center,
                bold: true,
                fill: { color: COLOR_BLACK }
            });
            const maxManagerRatingBar = 1.7;
            const cvquantity = parseFloat(data_cvquantity);
            const timehire = parseFloat(data_timehire);
            const hrsupport = parseFloat(data_hrsupport);
            const hirequality = parseFloat(data_hirequality);
            let page6ManagerEva_1 = {};
            page6ManagerEva_1["VN"] = "Số lượng hồ sơ ứng viên phù hợp với yêu cầu tuyển dụng";
            page6ManagerEva_1["EN"] = "Quantity of CVs that match hiring requirements";
            slide.addText(page6ManagerEva_1[scLanguage], {
                x: 3.2,
                y: 4.3,
                h: 0.5,
                w: 2.9,
                color: COLOR_DARKGRAY,
                fontSize: 13,
                fontFace: 'Calibri',
                align: pres.AlignH.right,
            });
            const cvquantityBarLg = (cvquantity / 6) * maxManagerRatingBar;
            slide.addText("", {
                x: 6.1,
                y: 4.4,
                h: 0.3,
                w: cvquantityBarLg,
                fill: { color: "5b9bd5" }
            });
            slide.addText(cvquantity.toString(), {
                x: 6.1 + cvquantityBarLg,
                y: 4.4,
                h: 0.3,
                w: 0.8,
                color: COLOR_BLACK,
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                bold: true,
            });
            //
            let page6ManagerEva_2 = {};
            page6ManagerEva_2["VN"] = "Thời gian tuyển dụng đúng hạn";
            page6ManagerEva_2["EN"] = "Time to hire";
            slide.addText(page6ManagerEva_2[scLanguage], {
                x: 3.2,
                y: 5.15,
                h: 0.5,
                w: 2.9,
                color: COLOR_DARKGRAY,
                fontSize: 13,
                fontFace: 'Calibri',
                align: pres.AlignH.right,
            });
            const timehireBarLg = (timehire / 6) * maxManagerRatingBar;
            slide.addText("", {
                x: 6.1,
                y: 5.25,
                h: 0.3,
                w: timehireBarLg,
                fill: { color: "5b9bd5" }
            });
            slide.addText(timehire.toString(), {
                x: 6.1 + timehireBarLg,
                y: 5.25,
                h: 0.3,
                w: 0.8,
                color: COLOR_BLACK,
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                bold: true,
            });
            //
            let page6ManagerEva_3 = {};
            page6ManagerEva_3["VN"] = "Sự hỗ trợ của phòng nhân sự với cả ứng viên và phòng ban";
            page6ManagerEva_3["EN"] = "HR’s support for candidates and the department during hiring process";
            slide.addText(page6ManagerEva_3[scLanguage], {
                x: 3.2,
                y: 6,
                h: 0.5,
                w: 2.9,
                color: COLOR_DARKGRAY,
                fontSize: 13,
                fontFace: 'Calibri',
                align: pres.AlignH.right,
            });
            const hrsupportBarLg = (hrsupport / 6) * maxManagerRatingBar;
            slide.addText("", {
                x: 6.1,
                y: 6.1,
                h: 0.3,
                w: hrsupportBarLg,
                fill: { color: "5b9bd5" }
            });
            slide.addText(hrsupport.toString(), {
                x: 6.1 + hrsupportBarLg,
                y: 6.1,
                h: 0.3,
                w: 0.8,
                color: COLOR_BLACK,
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                bold: true,
            });
            //
            let page6ManagerEva_4 = {};
            page6ManagerEva_4["VN"] = "Chất lượng ứng viên";
            page6ManagerEva_4["EN"] = "Quality of hire";
            slide.addText(page6ManagerEva_4[scLanguage], {
                x: 3.2,
                y: 6.9,
                h: 0.5,
                w: 2.9,
                color: COLOR_DARKGRAY,
                fontSize: 13,
                fontFace: 'Calibri',
                align: pres.AlignH.right,
            });
            const hirequalityBarLg = (hirequality / 6) * maxManagerRatingBar;
            slide.addText("", {
                x: 6.1,
                y: 7,
                h: 0.3,
                w: hirequalityBarLg,
                fill: { color: "5b9bd5" }
            });
            slide.addText(hirequality.toString(), {
                x: 6.1 + hirequalityBarLg,
                y: 7,
                h: 0.3,
                w: 0.8,
                color: COLOR_BLACK,
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                bold: true,
            });
            slide.addText("", {
                x: 6.1,
                y: 4.2,
                h: 3.25,
                w: 0.01,
                fill: { color: "A9A9A9" }
            });
            let page6ManagerEva_5 = {};
            page6ManagerEva_5["VN"] = "*Mức độ hài lòng dựa trên thang điểm từ 1-6, với 1 là “Rất tệ” và 6 là “Xuất sắc”";
            page6ManagerEva_5["EN"] = "*Satisfaction based on scale from 1-6, with 1 is “Very bad” and 6 is “Exceptional”";
            slide.addText(page6ManagerEva_5[scLanguage], {
                x: 2.9,
                y: 7.4,
                h: 0.5,
                color: COLOR_BLACK,
                fontSize: 12,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                italic: true,
            });
            const maxNewbieRatingBar = 1.3;
            const interestedworkhere = parseInt(data_interestedworkhere);
            const recruitmentprocess = parseInt(data_recruitmentprocess);
            const onboarding = parseInt(data_onboarding);
            const leadershipinspiration = parseInt(data_leadershipinspiration);
            let page6NewHire = {};
            page6NewHire["VN"] = "ĐÁNH GIÁ CỦA NHÂN VIÊN MỚI";
            page6NewHire["EN"] = "NEW HIRE’S EVALUATION";
            slide.addText(page6NewHire[scLanguage], {
                x: 8.8,
                y: 3.4,
                h: 0.5,
                w: 5.2,
                color: COLOR_WHITE,
                fontSize: 18,
                fontFace: 'Calibri',
                align: pres.AlignH.center,
                bold: true,
                fill: { color: COLOR_BLACK }
            });
            //
            let page6NewHire_1 = {};
            page6NewHire_1["VN"] = "Những gì tôi biết khiến tôi quan tâm về cơ hội làm việc tại " + companyName;
            page6NewHire_1["EN"] = "What I knew about " + companyName + " interested me to work here";
            slide.addText(page6NewHire_1[scLanguage], {
                x: 8.8,
                y: 4.3,
                h: 0.5,
                w: 3.7,
                color: COLOR_DARKGRAY,
                fontSize: 13,
                fontFace: 'Calibri',
                align: pres.AlignH.right,
            });
            const interestedworkhereBarLg = (interestedworkhere / 100) * maxNewbieRatingBar;
            slide.addText("", {
                x: 12.5,
                y: 4.4,
                h: 0.3,
                w: interestedworkhereBarLg,
                fill: { color: "92d050" }
            });
            slide.addText(interestedworkhere.toString() + "%", {
                x: 12.5 + interestedworkhereBarLg,
                y: 4.4,
                h: 0.3,
                w: 0.8,
                color: COLOR_BLACK,
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                bold: true,
            });
            //
            let page6NewHire_2 = {};
            page6NewHire_2["VN"] = "Tôi hài lòng với toàn bộ quy trình tuyển dụng bao gồm cả quá trình phỏng vấn";
            page6NewHire_2["EN"] = "I am satisfied with the whole recruitment process including interview process";
            slide.addText(page6NewHire_2[scLanguage], {
                x: 8.8,
                y: 5.15,
                h: 0.5,
                w: 3.7,
                color: COLOR_DARKGRAY,
                fontSize: 13,
                fontFace: 'Calibri',
                align: pres.AlignH.right,
            });
            const recruitmentprocessBarLg = (recruitmentprocess / 100) * maxNewbieRatingBar;
            slide.addText("", {
                x: 12.5,
                y: 5.25,
                h: 0.3,
                w: recruitmentprocessBarLg,
                fill: { color: "92d050" }
            });
            slide.addText(recruitmentprocess.toString() + "%", {
                x: 12.5 + recruitmentprocessBarLg,
                y: 5.25,
                h: 0.3,
                w: 0.8,
                color: COLOR_BLACK,
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                bold: true,
            });
            //
            let page6NewHire_3 = {};
            page6NewHire_3["VN"] = "Tôi được quan tâm và được định hướng công việc rõ ràng trong suốt quá trình onboarding";
            page6NewHire_3["EN"] = "During the onboarding process, I am well cared and guided to have a clear view about my position";
            slide.addText(page6NewHire_3[scLanguage], {
                x: 8.8,
                y: 6,
                h: 0.5,
                w: 3.7,
                color: COLOR_DARKGRAY,
                fontSize: 13,
                fontFace: 'Calibri',
                align: pres.AlignH.right,
            });
            const onboardingBarLg = (onboarding / 100) * maxNewbieRatingBar;
            slide.addText("", {
                x: 12.5,
                y: 6.1,
                h: 0.3,
                w: onboardingBarLg,
                fill: { color: "92d050" }
            });
            slide.addText(onboarding.toString() + "%", {
                x: 12.5 + onboardingBarLg,
                y: 6.1,
                h: 0.3,
                w: 0.8,
                color: COLOR_BLACK,
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                bold: true,
            });
            //
            let page6NewHire_4 = {};
            page6NewHire_4["VN"] = "Sự tham gia & chia sẻ của các cấp lãnh đạo là nguồn cảm hứng cho tôi gia nhập " + companyName;
            page6NewHire_4["EN"] = "The participation and sharing of the Leadership team are inspiration for me to join the company";
            slide.addText(page6NewHire_4[scLanguage], {
                x: 8.8,
                y: 6.9,
                h: 0.5,
                w: 3.7,
                color: COLOR_DARKGRAY,
                fontSize: 13,
                fontFace: 'Calibri',
                align: pres.AlignH.right,
            });
            const leadershipinspirationBarLg = (leadershipinspiration / 100) * maxNewbieRatingBar;
            slide.addText("", {
                x: 12.5,
                y: 7,
                h: 0.3,
                w: leadershipinspirationBarLg,
                fill: { color: "92d050" }
            });
            slide.addText(leadershipinspiration.toString() + "%", {
                x: 12.5 + leadershipinspirationBarLg,
                y: 7,
                h: 0.3,
                w: 0.8,
                color: COLOR_BLACK,
                fontSize: 14,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                bold: true,
            });
            slide.addText("", {
                x: 12.5,
                y: 4.2,
                h: 3.25,
                w: 0.01,
                fill: { color: "A9A9A9" }
            });
            let page6NewHire_5 = {};
            page6NewHire_5["VN"] = "";
            page6NewHire_5["EN"] = "*% of new hires who agree with above statements in their evaluation";
            slide.addText(page6NewHire_5[scLanguage], {
                x: 8.8,
                y: 7.4,
                h: 0.5,
                color: COLOR_BLACK,
                fontSize: 12,
                fontFace: 'Calibri',
                align: pres.AlignH.left,
                italic: true,
            });
            slide.addText(copyRightText[scLanguage], {
                x: 2.85,
                y: 7.9,
                h: 0.35,
                w: 11.45,
                color: COLOR_DARKGRAY,
                fontFace: 'Calibri',
                fontSize: 11,
                italic: true
            });
            /// ##############  PAGE 6 END ##################///

            ///// Export /////
            const timeStamp = Date.now();
            pres.writeFile({ fileName: timeStamp + "2021_internalscorecard.pptx" });
        };
        return (
            <div className={classes.main}>
                <div>
                    <img src={cologo} id={"companylogo"} width={"150px"} alt={""} />
                </div>
                <div className={classes.title}>{data_companyname}</div>
                <div className={classes.title}>Báo cáo: {data_survey_name}</div>
                <div className={classes.title}>Năm: {data_year}</div>
                <div className={classes.title}>Ngôn ngữ : {data_language}</div>
                <div className={classes.title}>
                    <button onClick={() => doExport()}> Export PPTX</button>
                </div>
            </div>
        )
    };

export default VNBPTWScoreCardExport;