import * as React from 'react';
import {FC} from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';

const MediaCatCreate: FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm redirect={"list"}>
            <TextInput source="category_title"  label = "Category" validate={[required()]}  style={{width:"100%"}}/>
        </SimpleForm>
    </Create>
);

export default MediaCatCreate;