import * as React from 'react';
import {FC} from 'react';
import UserEditToolbar from '../restrictedelement/UserEditToolbar';
import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';
import {Award} from "../types";


// const UserEditToolbar = props => (
//     <Toolbar {...props} >
//         <SaveButton />
//     </Toolbar>
// ); toolbar={<UserEditToolbar  roles={roles} />}

export const AwardName: FC<FieldProps<Award>> = ({record}) => {
    return <span> Award {record ? `"${record.name}"` : ''}</span>
}
const roles = localStorage.getItem('role') || '';
const AwardEdit: FC<EditProps> = props => (
    <Edit title={<AwardName/>} {...props} mutationMode="optimistic">
        <SimpleForm toolbar={<UserEditToolbar props = {roles}/>}>
            <TextInput disabled source="id"/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="codeName" validate={[required()]}/>
            {/* <RestrictedDeleteButton roles={roles}/> */}
        </SimpleForm>

    </Edit>
)

export default AwardEdit;
