import * as React from "react";
import {FC, useState} from 'react';

import {
    Edit,
    TextInput,
    required,
    EditProps,
    FieldProps,
    SimpleForm,
    ReferenceArrayInput,
    AutocompleteArrayInput
} from 'react-admin';
import {Survey} from "../types";
import {onHandleTextInput} from "../utils/textHandling";

const SalesPackageTitle: FC<FieldProps<Survey>> = ({record}) => {
    return (<span> Sales Package {record ? `"${record.name}"` : ''}</span>);
};
const redirect = (basePath, id, data) => `/surveys/${data.survey_id}/sales-package`;
const SalesPackageEdit: FC<EditProps> = props => {
    const [benefits, setBenefits] = useState('');
    return (
        <Edit  title={<SalesPackageTitle/>} {...props}>
            <SimpleForm  redirect={redirect}>
                <TextInput disabled source="id"/>
                <TextInput source="name" fullWidth validate={[required()]}/>
                <ReferenceArrayInput label="Benefits" source="benefits" reference="salesbenefits"
                                     filter={{benefits: benefits}} >
                    <AutocompleteArrayInput optionText="name" source="benefits" onChange={(event) => {
                        setBenefits(onHandleTextInput(event))}}/>
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    );
};

export default SalesPackageEdit;