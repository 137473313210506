import * as React from "react";
import {FC} from 'react';

import {
    Edit,
    TextInput,
    EditProps,
    FieldProps,
    SimpleForm,
    ReferenceArrayInput,
    SelectArrayInput
} from 'react-admin';
import {Survey} from "../types";

const SalesPackageTitle: FC<FieldProps<Survey>> = ({record}) => {
    return (<span> Sales Package {record ? `"${record.name}"` : ''}</span>);
};
const redirect = (basePath, id, data) => `/surveys/${data.survey_id}/sales-package`;
const SalesPackageShow: FC<EditProps> = props => {
    return (
        <Edit  title={<SalesPackageTitle/>} {...props}>
            <SimpleForm  redirect={redirect}>
                <TextInput disabled source="id" />
                <TextInput source="name" fullWidth  disabled/>
                <ReferenceArrayInput label="Benefits" source="benefits" reference="salesbenefits" disabled>
                    <SelectArrayInput optionText="name"/>
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    );
};

export default SalesPackageShow;