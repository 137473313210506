import * as React from 'react';
import { FC } from 'react';

import {
    EditButton,
    List,
    ListProps,
    FilterProps,
    Datagrid,
    TextField,
    Filter,
    SearchInput, usePermissions
} from 'react-admin';

const AwardFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <SearchInput source="report_name" alwaysOn />
    </Filter>
);


const MediaCommunicationList: FC<ListProps> = props => {
    const { loaded, permissions } = usePermissions();

    if (!loaded) {
        return null; // Đợi quyền truy cập được tải
    }

    const isAdmin = permissions.includes('ROLE_ADMIN');
    return (
        <List title="All Awards" {...props} filters={<AwardFilter />} hasCreate = {isAdmin}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="title" />
                <TextField source="publisher" />
                {isAdmin && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default MediaCommunicationList;