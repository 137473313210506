import SurveyCreate from './SurveyCreate';
import SurveyEdit from './SurveyEdit';
import SurveyList from './SurveyList';


export default {
    create: SurveyCreate,
    edit: SurveyEdit,
    list: SurveyList,
};
