import * as React from "react";
import {FC} from 'react';
import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';

const IndustryCreate: FC<CreateProps> = props => {
    return (
        <Create {...props}>
            <SimpleForm redirect={"list"}>
                <TextInput source="name" validate={[required()]} fullWidth/>
            </SimpleForm>
        </Create>
    );
}

export default IndustryCreate;