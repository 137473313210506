import * as React from 'react';
import UserIcon from '@material-ui/icons/Group';
import { Admin, Resource} from 'react-admin';

import customRoutes from './routes';
import companies from './companies';
import industries from './industries';
import salesbenefits from './SalesBenefits';
import salespackage from './salespackage';
import surveys from "./surveys";
import users from './users';
import awards from './awards';
import companyaward from './companyaward';
import Dashboard from './dashboard/Dashboard';
import authProvider from './auth_provider/authProvider';
import dataProvider from "./data_provider/dataProvider";
import scorecardtemplate from "./scorecardtemplate";
import companyscorecard from "./companyscorecard";
import hawscorecardtemplate from "./hawscorecardtemplate";
import hawscorecard from "./hawscorecard";
import participants from "./participants";
import internalscorecard2021 from './internalscorecard2021';
import internalscorecard2022 from './internalscorecard2022';
import internalscorecard2023 from './internalscorecard2023';
import mediacategory from './mediacategory';
import mediacommunication from './mediacommunication';
import benefitsetting from './benefitsetting';

const App = () => (
    <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        customRoutes={customRoutes}
        dashboard={Dashboard}
    >
        <Resource name="surveys"  {...surveys} icon={UserIcon} />
        <Resource name="salespackages" {...salespackage} />
        <Resource name="participants" {...participants} options={{ label: 'Participants' }} />
        <Resource name="companies"  {...companies} icon={UserIcon} />
        <Resource name="salesbenefits" {...salesbenefits} options={{ label: 'Sales Benefits' }} />
        <Resource name="benefitsetting" {...benefitsetting} options={{ label: 'Benefit Setting' }} />
        <Resource name="industries" {...industries} />
        <Resource name="users" {...users} icon={UserIcon} />
        <Resource name="awardscategory" {...awards} options={{ label: "Awards Category" }} icon={UserIcon} />
        <Resource name="companyaward" {...companyaward} options={{ label: "Company Awards" }} icon={UserIcon} />
        <Resource name="scorecardtemplate" {...scorecardtemplate} options={{ label: "Ext S.Card Template" }} icon={UserIcon} />
        <Resource name="companyscorecard" {...companyscorecard} options={{ label: "Ext ScoreCard " }} icon={UserIcon} />
        <Resource name="hawscorecardtemplate" {...hawscorecardtemplate} options={{ label: "HAWS.Card2020 Template" }} icon={UserIcon} />
        <Resource name="hawscorecard" {...hawscorecard} options={{ label: "Int S.Card 2020" }} icon={UserIcon} />
        <Resource name="internalscorecard2021" {...internalscorecard2021} options={{ label: "Int S.Card 2021" }} icon={UserIcon} />
        <Resource name="internalscorecard2022" {...internalscorecard2022} options={{ label: "Int S.Card 2022" }} icon={UserIcon} />
        <Resource name="internalscorecard2023" {...internalscorecard2023} options={{ label: "Int S.Card 2023" }} icon={UserIcon} />
        <Resource name="mediacategory" {...mediacategory} options={{ label: "Category Báo chí" }} icon={UserIcon} />
        <Resource name="mediacommunication" {...mediacommunication} options={{ label: "Báo chí" }} icon={UserIcon} />
    </Admin>
);
export default App;
