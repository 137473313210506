import BenefitSettingCreate from './BenefitSettingCreate';
import BenefitSettingEdit from './BenefitSettingEdit';
import BenefitSettingList from './BenefitSettingList';


export default {
    create: BenefitSettingCreate,
    edit: BenefitSettingEdit,
    list: BenefitSettingList,
};
