import * as React from "react";
import { FC } from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    required
} from 'react-admin';
import httpClient from "../data_provider/http_client";

const validate_unique_name = async (values: any ) => {
    return new Promise(async (resolve, reject) => {
        if (values.name) {
            const res = await httpClient('salesbenefits/name', {
                method: 'POST',
                body: JSON.stringify({name: values.name})
            });

            if(res.json && res.json.errors){
                resolve(res.json.errors);
            }
        }
        resolve({});
    });
}


const SalesBenefitCreate: FC<CreateProps> = props => {
    return (
        <Create {...props} >
            <SimpleForm redirect={"list"} validate={validate_unique_name}>
                <TextInput source="name"  fullWidth validate={[required()]} />
                <TextInput source="setting_keys"  fullWidth/>
            </SimpleForm>
        </Create>
    );
}

export default SalesBenefitCreate;