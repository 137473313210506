// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { unregister } from './registerServiceWorker';


ReactDOM.render(<App />, document.getElementById('root'));

unregister();
