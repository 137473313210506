import * as React from "react";
import { FC } from 'react';

import {
    Create,
    SimpleForm,
    TextInput,
    CreateProps,
    required
} from 'react-admin';
import httpClient from "../data_provider/http_client";

const validate_unique_code = async (values: any) => {
    return new Promise(async (resolve, reject) => {
        if (values.code) {
            const res = await httpClient('surveys/code', {
                method: 'POST',
                body: JSON.stringify({code: values.code})
            });

            if (res.json.errors) {
                resolve(res.json.errors);
            }
        }
        resolve({});
    });
}


const SurveyCreate: FC<CreateProps> = props => {
    return (
        <Create {...props}>
            <SimpleForm redirect={"list"}>
                <TextInput source="code" validate={[required()]}/>
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="officialUrl"/>
                <TextInput source="demoUrl"/>
            </SimpleForm>
        </Create>
    );
}

export default SurveyCreate;