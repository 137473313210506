import * as React from "react";
import { FC } from 'react';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    required,
    ReferenceManyField,
    Datagrid,
    EditButton,
    TextField,
    EditProps,
    FieldProps,
    Pagination,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    TopToolbar,
    ReferenceField
} from 'react-admin';
import { Survey } from "../types";

const AddSalesPackagesButton: FC<FieldProps<Survey>> = ({ record, resource }) => {
    return record ? (<Button
        component={Link}
        to={{
            pathname: `/${resource}/${record.id}/add-salespackages`
        }}
    >
        Add Sales Packages
    </Button>) : null;
};
const AddParticipantButton: FC<FieldProps<Survey>> = ({ record, resource }) => {
    return record ? (<Button
        component={Link}
        to={{
            pathname: `/${resource}/${record.id}/add-participant`
        }}
    >
        Add Participants
    </Button>) : null;
};
const AddBenefitSettingButton: FC<FieldProps<Survey>> = ({ record, resource }) => {
    return record ? (<Button
        component={Link}
        to={{
            pathname: `/${resource}/${record.id}/add-benefit`
        }}
    >
        Add Participant Benefit
    </Button>) : null;
};
const SurveyTitle: FC<FieldProps<Survey>> = ({ record }) => {
    return (<span> Survey {record ? `"${record.name}"` : ''}</span>);
};
const ParticipantJoin: FC<FieldProps<Survey>> = ({ record }) => {
    return (<span> {record && record.participated === 1 ? 'Có' : 'Không'}</span>);
};
const SurveyEditActions: FC<any> = ({ data, resource }) => (
    <TopToolbar>
        <AddSalesPackagesButton record={data} resource={resource} />
        <AddParticipantButton record={data} resource={resource} />
        <AddBenefitSettingButton record={data} resource={resource} />
    </TopToolbar>
);

const SurveyEdit: FC<EditProps> = props => {
    return (
        <Edit title={<SurveyTitle />} {...props} actions={<SurveyEditActions />}>
            <TabbedForm>
                <FormTab
                    label="Details"
                >
                    <TextInput disabled source="id" />
                    <TextInput source="code" validate={[required()]} />
                    <TextInput source="name" validate={[required()]} />
                    <TextInput source="officialUrl" />
                    <TextInput source="demoUrl" />
                </FormTab>
                <FormTab
                    label="Sales Package"
                    path="sales-package"
                >
                    <ReferenceManyField
                        reference="salespackages"
                        target="survey_id"
                        pagination={<Pagination />}
                        addLabel={false}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="name" />
                            <ReferenceArrayField
                                label="Sales Benefits"
                                reference="salesbenefits"
                                source="benefits"
                                sortBy="benefits.name"

                                sort={{ field: 'name', order: 'ASC' }}
                            >
                                <SingleFieldList>
                                    <ChipField source="name" size="small" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <EditButton />

                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>

                <FormTab
                    label="Participants"
                    path="participants"
                >
                    <ReferenceManyField
                        reference="participants"
                        target="survey_id"
                        pagination={<Pagination />}
                        addLabel={false}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <ReferenceField source="company" reference="companies">
                                <TextField source="commercialName" />
                            </ReferenceField>
                            <ParticipantJoin source="participated" label="Tham gia" />
                            <ReferenceArrayField
                                label="Industries"
                                reference="industries"
                                source="industries"
                                sortBy="industries.name"

                                sort={{ field: 'name', order: 'ASC' }}
                            >
                                <SingleFieldList>
                                    <ChipField source="name" size="small" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField
                                label="Sale Pack"
                                reference="salespackages"
                                source="packages"
                            >
                                <SingleFieldList>
                                    <ChipField source="name" size="small" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab
                    label="Benefit Settinng"
                    path="benefitsetting"
                >
                    <ReferenceManyField
                        reference="benefitsetting"
                        target="survey_id"
                        pagination={<Pagination />}
                        addLabel={false}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <ReferenceField label="Survey"
                                reference="surveys"
                                source="survey"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField label="Benefit"
                                reference="salesbenefits"
                                source="benefit"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField label="Participant"
                                reference="participants"
                                source="participant"
                            >
                                <TextField source="company_name" />
                            </ReferenceField>
                            <ReferenceField label="Setting Keys"
                                reference="salesbenefits"
                                source="benefit"
                            >
                                <TextField source="setting_keys" />
                            </ReferenceField>
                            <TextField source="value" />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default SurveyEdit;