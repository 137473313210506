import * as React from 'react';
import {FC} from 'react';

import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    AutocompleteInput,
    ImageInput,
    ImageField,
    DateInput 
} from 'react-admin';
import {Award} from "../types";


export const ScoreCardTemplate: FC<FieldProps<Award>> = ({record}) => {
    return <span> Award {record ? `"${record.name}"` : ''}</span>
}
const MediaCommunicationEdit: FC<EditProps> = props => (
    <Edit title={<ScoreCardTemplate/>} {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput source="publisher"  label = "Tòa soạn báo" validate={[required()]}/>
            <ReferenceInput
                label="Category"
                source="media_category_id"
                reference="mediacategory"
                filterToQuery={searchText => ({ category_title: searchText })}
                validate={[required()]}>
                <AutocompleteInput optionText="category_title" />
             </ReferenceInput>
            <ImageInput source="logo_url" label="Hình minh họa" accept="image/*">
                <ImageField source="medium"/>
            </ImageInput>
            <TextInput source="title"  label = "Tiêu đề" validate={[required()]}  style={{width:"100%"}}/>
            <TextInput source="description"  label = "Mô tả" validate={[required()]}  style={{width:"100%"}}/>
            <TextInput source="link"  label = "Link nguồn" validate={[required()]}  style={{width:"100%"}}/>
            <DateInput  source="post_date"  label = "Ngày đăng" validate={[required()]}  style={{width:"50%"}}/>
        </SimpleForm>
    </Edit>
)

export default MediaCommunicationEdit;
