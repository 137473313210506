import * as React from 'react';
import { FC } from 'react';

import {
    List,
    ListProps,
    FilterProps,
    Datagrid,
    TextField,
    ReferenceField,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    required,
    FieldProps,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    EditButton,
    BooleanInput,
    usePermissions
} from 'react-admin';
import { Survey } from "../types";
const ParticipantCompanyFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <ReferenceInput label="Survey"
            reference="surveys"
            source="survey"
        >
            <AutocompleteInput optionText="code" alwaysOn />
        </ReferenceInput>
        <ReferenceInput
            label="Company"
            source="company"
            reference="companies"
            filterToQuery={searchText => ({ commercialName: searchText })}
            validate={[required()]}>
            <AutocompleteInput optionText="commercialName" alwaysOn />
        </ReferenceInput>
        <ReferenceInput
            label="Industry"
            source="industries"
            reference="industries"
            filterToQuery={searchText => ({ name: searchText })}
            validate={[required()]}>
            <AutocompleteInput optionText="name" alwaysOn />
        </ReferenceInput>
        <ReferenceInput
            label="Majors"
            source="majors"
            reference="industries"
            filterToQuery={searchText => ({ name: searchText })}
            validate={[required()]}>
            <AutocompleteInput optionText="name" alwaysOn />
        </ReferenceInput>
        <BooleanInput source="participated" label="Tham gia" />
        <BooleanInput source="potential" label="potential" />
    </Filter>
);
const ParticipantJoin: FC<FieldProps<Survey>> = ({ record }) => {
    return (<span> {record && record.participated === 1 ? 'Có' : 'Không'}</span>);
};
const PotentialJoin: FC<FieldProps<Survey>> = ({ record }) => {
    return (<span> {record && record.potential === 1 ? 'Có' : 'Không'}</span>);
};
const ParticipantsList: FC<ListProps> = props => {
    const { loaded, permissions } = usePermissions();

    if (!loaded) {
        return null; // Đợi quyền truy cập được tải
    }

    const isAdmin = permissions.includes('ROLE_ADMIN');
    const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
    const isSurvey = permissions.includes('ROLE_SURVEY');
    return (
        <List title="All Companies" {...props} filters={<ParticipantCompanyFilter />} hasCreate={isAdmin || isSaleAdmin}>
            <Datagrid>
                <TextField source="id" />
                <ReferenceField label="Company"
                    reference="companies"
                    source="company"
                >
                    <TextField source="commercialName" />
                </ReferenceField>
                <ReferenceField label="Survey"
                    reference="surveys"
                    source="survey"
                >
                    <TextField source="code" />
                </ReferenceField>
                <ParticipantJoin source="participated" label="Tham gia" />
                <PotentialJoin source="potential" label="Potential" />
                <ReferenceArrayField
                    label="Industries"
                    reference="industries"
                    source="industries"
                    sortBy="industries.name"

                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <SingleFieldList>
                        <ChipField source="name" size="small" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField
                    label="Majors"
                    reference="industries"
                    source="majors"
                    sortBy="industries.name"

                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <SingleFieldList>
                        <ChipField source="name" size="small" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField
                    label="Sale Pack"
                    reference="salespackages"
                    source="packages"
                >
                    <SingleFieldList>
                        <ChipField source="name" size="small" />
                    </SingleFieldList>
                </ReferenceArrayField>
                {(isAdmin || isSaleAdmin) && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default ParticipantsList;