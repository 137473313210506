import React, {
    FC,
} from 'react';
import {makeStyles} from "@material-ui/styles";
import ArrowUp from "./images/white-triangle-up.png";
import ArrowDown from "./images/white-triangle-down.png";
import ArrowLeft from "./images/white-triangle-left.png";
import ArrowRight from "./images/white-triangle-right.png";
import StayEmployee from "./images/stay-employee_3169.png";
import LeaveEmployee from "./images/leave-employee_3169.png";

interface Props {
    language: string;
    survey_tag: string;
    company_short_name: string,
    company_core: number;
    vietnam_core: number;
    company_regret: number;
    vietnam_regret: number;
    company_zombie: number;
    vietnam_zombie: number;
    company_quitter: number;
    vietnam_quitter: number;
    base_surveyee: number;
    respondents_amount: number;
    employee_leave: number;
    employee_stay: number;
}

const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "100%",
        font: "bold",
        textAlign: "center",
    }),
    title: {
        backgroundColor: "#7f7f7f",
        color: "#ffffff",
        fontWeight: "bold",
        margin: "auto",
        padding: "10px"
    },
    chartBox: {
        width: "100%"
    },
    percentRound: {
        height: "75px",
        width: "75px",
        lineHeight: "75px",
        borderRadius: "50%",
        display: "inline-block",
        verticalAlign: "middle",
        fontWeight: "bold",
        color: "#ffffff"
    },
    coordinateTextUp: {
        display: "inline-block",
        transform: "rotate(-90deg)",
        color: "#ffffff",
        position: "absolute",
        left: "-25px",
        top: "50px",
        width: "111px",
        fontWeight: "bold",
    },
    coordinateArrowUp: {
        position: "absolute",
        left: "23px",
        top: "126px",
        width: "15px"
    },
    coordinateTextLeft: {
        display: "inline-block",
        color: "#ffffff",
        position: "absolute",
        left: "28px",
        top: "23px",
        width: "175px",
        fontWeight: "bold",
        backgroundColor: "#595959"
    },
    leftLine: {
        width: "490px",
        borderBottom: "1px solid whitesmoke",
        position: "absolute",
        left: "4px",
        top: "31px"
    },
    coordinateArrowLeft: {
        position: "absolute",
        left: "490px",
        top: "24px",
        width: "15px"
    },
    coordinateTextRight: {
        display: "inline-block",
        color: "#ffffff",
        position: "absolute",
        right: "28px",
        top: "24px",
        width: "180px",
        fontWeight: "bold",
        backgroundColor: "#595959"
    },
    rightLine: {
        width: "490px",
        borderBottom: "1px solid whitesmoke",
        position: "absolute",
        right: "8px",
        top: "31px"
    },
    coordinateArrowRight: {
        position: "absolute",
        left: "9px",
        top: "24px",
        width: "14px",
        backgroundColor: "#595959"
    },
    coordinateTextDown: {
        display: "inline-block",
        transform: "rotate(-90deg)",
        color: "#ffffff",
        position: "absolute",
        left: "-31px",
        top: "83px",
        width: "124px",
        fontWeight: "bold",
    },
    coordinateArrowDown: {
        position: "absolute",
        left: "23px",
        top: "15px",
        width: "15px"
    },
    crossCircle: {
        height: "59px",
        width: "59px",
        borderRadius: "50%",
        display: "inline-block",
        verticalAlign: "middle",
        fontWeight: "bold",
        color: "#ffffff",
        backgroundColor: "#ffffff"
    },
    arrowBotBefore: {
        content: "",
        position: "absolute",
        right: "-20px",
        bottom: 0,
        /* width: 0; */
        height: 0,
        borderLeft: "20px solid #c55a11",
        borderTop: "15px solid transparent",
        borderBottom: "15px solid transparent"
    },
    arrowBot: {
        width: "435px",
        lineHeight: "30px",
        verticalAlign: "middle",
        height: "30px",
        position: "relative",
        background: "#c55a11",
        fontWeight: "bold",
        color: "whitesmoke",
        textAlign: "left"
    },
    workForceText: {
        fontWeight: 700,
        lineHeight: "25px",
    },
    average: {
        fontStyle: "italic",
        color: "#7f7f7f",
        paddingTop: "3px"
    }
}));
const infoText = {
    en: {
        "haw": {
            title: "WORKFORCE ALLOCATION",
            vsVNRate: "Vs. Avg. VN",
            coreTeam: {
                title: "CORE TEAM",
                description: "HIGH EFFORT &<br/> LOYALTY"
            },
            regretLoss: {
                title: "REGRET LOSS",
                description: "HIGH EFFORT yet still<br/> LEAVE"
            },
            zombie: {
                title: "\"Zombie\"",
                description: "LOW EFFORT & STAY"
            },
            quitter: {
                title: "QUITTER",
                description: "LOW EFFORT & LEAVE"
            },
            highEffort: "HIGH EFFORT",
            intentionToLeave: "INTENTION TO LEAVE",
            lowEffort: "LOW EFFORT",
            intentionToStay: "INTENTION TO LEAVE",
            turnOverRate: "TURNOVER RATE",
            percentToLeave: "% Employees with intention to leave",
            percentToLeaveDescription: "(Employees choosing “Totally disagree”, “Disagree”, “Fairly disagree”<br/> for Commitment statements)",
            vn: "Viet Nam"
        }
    },
    vn: {
        "haw": {
            title: "PHÂN BỔ NGUỒN NHÂN LỰC",
            vsVNRate: "Vs. TB Việt Nam",
            coreTeam: {
                title: "ĐỘI NGŨ NÒNG CỐT",
                description: "Nhân viên NỖ LỰC và<br/> CAM KẾT GẮN BÓ"
            },
            regretLoss: {
                title: "THẤT THOÁT ĐÁNG TIẾC",
                description: "Nhân viên NỖ LỰC, nhưng<br/> vẫn CÓ Ý ĐỊNH NGHỈ VIỆC"
            },
            zombie: {
                title: "NHÓM \"Zombie\"",
                description: "Nhân viên KHÔNG NỖ LỰC,<br/> nhưng KHÔNG CÓ Ý ĐỊNH<br/> NGHỈ VIỆC"
            },
            quitter: {
                title: "NHÓM TỪ BỎ",
                description: "Nhân viên KHÔNG NỖ LỰC, và<br/> CÓ Ý ĐỊNH NGHỈ VIỆC"
            },
            highEffort: "NỖ LỰC CAO",
            intentionToLeave: "CÓ Ý ĐỊNH NGHỈ VIỆC",
            lowEffort: "NỖ LỰC THẤP",
            intentionToStay: "CAM KẾT GẮN BÓ",
            turnOverRate: "DỰ ĐOÁN TỈ LỆ NGHỈ VIỆC",
            percentToLeave: "% Nhân viên KHÔNG cam kết gắn bó",
            percentToLeaveDescription: "(Nhân viên chọn Rất KHÔNG đồng ý/ KHÔNG Đồng ý/ Hơi KHÔNG<br/> đồng ý với các nhận định “Cam kết gắn bó”)",
            vn: "Việt Nam"
        }
    }
};
const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

const HAWIndexTitle: FC<Props> = ({
                                      language = "vn", survey_tag = "haw",
                                      company_short_name = "",
                                      company_core = 0,
                                      vietnam_core = 0,
                                      company_regret = 0,
                                      vietnam_regret = 0,
                                      company_zombie = 0,
                                      vietnam_zombie = 0,
                                      company_quitter = 0,
                                      vietnam_quitter = 0,
                                      base_surveyee = 0,
                                      respondents_amount = 0,
                                      employee_leave = 0,
                                      employee_stay = 0
                                  }) => {
    const classes = useStyles();
    const market_respondent_amount = numberWithCommas((respondents_amount) ? respondents_amount : 0);
    const base_respondent_amount = numberWithCommas((base_surveyee) ? base_surveyee : 0);
    let employee_list: string[] = [];
    if (employee_stay > 0) {
        for (let i = 0; i < employee_stay; i++) {
            employee_list.push(StayEmployee);
        }
    }
    if (employee_leave > 0) {
        for (let i = 0; i < employee_leave; i++) {
            employee_list.push(LeaveEmployee);
        }
    }
    return (<div className={classes.main}>
            <p className={classes.title}>{infoText[language][survey_tag].title}</p>
            <div style={{paddingTop: "5px"}}>
                <div className={classes.chartBox}>
                    <div style={{border: "1px solid #7f7f7f", width: "1094px", display: "flex", borderBottom:"1px solid #595959"}}>
                        <div style={{width: "531px", display: "flex"}}>
                            <div style={{width: "60%", margin: "auto"}}>
                                <div className={classes.workForceText} style={{paddingLeft: "50px", textAlign: "left"}}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].coreTeam.title}}
                                        style={{color: "#a5d054"}}></div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].coreTeam.description}}></div>
                                </div>
                            </div>
                            <div style={{width: "40%", margin: "auto"}}>
                                <div>
                                    <span className={classes.percentRound}
                                          style={{backgroundColor: "#a5d054"}}>{company_core}%</span>
                                </div>
                                <div
                                    className={classes.average}>{infoText[language][survey_tag].vsVNRate} {vietnam_core}%
                                </div>
                            </div>
                        </div>
                        <div style={{
                            width: "60px",
                            height: "150px",
                            backgroundColor: "#595959",
                            border: "1px solid #595959"
                        }}>
                            <div style={{position: "relative", border: "1px solid #595959"}}>
                                <div className={classes.coordinateTextUp}>
                                    {infoText[language][survey_tag].highEffort}
                                </div>
                            </div>
                            <div style={{position: "relative"}}>
                                <div className={classes.coordinateArrowUp}>
                                    <img src={ArrowUp} alt ="Arrow"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "531px", display: "flex"}}>
                            <div style={{width: "40%", margin: "auto"}}>
                                <div>
                                    <span className={classes.percentRound}
                                          style={{backgroundColor: "#c81f1f"}}>{company_regret}%</span>
                                </div>
                                <div
                                    className={classes.average}>{infoText[language][survey_tag].vsVNRate} {vietnam_regret}%
                                </div>
                            </div>
                            <div style={{width: "60%", margin: "auto"}}>
                                <div className={classes.workForceText}
                                     style={{paddingRight: "50px", textAlign: "right"}}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].regretLoss.title}}
                                        style={{color: "red"}}></div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].regretLoss.description}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        border: "1px solid #595959",
                        width: "1094px",
                        height: "60px",
                        display: "flex",
                        backgroundColor: "#595959"
                    }}>
                        <div style={{width: "531px"}}>
                            <div style={{position: "relative"}}>
                                <div className={classes.leftLine}></div>
                            </div>
                            <div style={{position: "relative"}}>
                                <div
                                    className={classes.coordinateTextLeft}>{infoText[language][survey_tag].intentionToStay}
                                </div>
                            </div>
                            <div style={{position: "relative"}}>
                                <div className={classes.coordinateArrowLeft}>
                                    <img src={ArrowLeft} alt = "Arrow"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "60px", border: "1px solid #595959"}}>
                            <span className={classes.crossCircle}/>
                        </div>
                        <div style={{width: "531px"}}>
                            <div style={{position: "relative"}}>
                                <div className={classes.rightLine}></div>
                            </div>
                            <div style={{position: "relative"}}>
                                <div
                                    className={classes.coordinateTextRight}>{infoText[language][survey_tag].intentionToLeave}
                                </div>
                            </div>
                            <div style={{position: "relative"}}>
                                <div className={classes.coordinateArrowRight}>
                                    <img src={ArrowRight} alt="Arrow"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        border: "1px solid #7f7f7f",
                        borderBottom: "1px solid #595959",
                        width: "1093px",
                        display: "flex",
                        borderTop:"1px solid #595959"
                    }}>
                        <div style={{width: "531px", display: "flex"}}>
                            <div style={{width: "60%", margin: "auto"}}>
                                <div className={classes.workForceText} style={{paddingLeft: "50px", textAlign: "left"}}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].zombie.title}}
                                        style={{color: "#fabb0b"}}></div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].zombie.description}}></div>
                                </div>
                            </div>
                            <div style={{width: "40%", margin: "auto"}}>
                                <div>
                                    <span className={classes.percentRound}
                                          style={{backgroundColor: "#fabb0b"}}>{company_zombie}%</span>
                                </div>
                                <div
                                    className={classes.average}>{infoText[language][survey_tag].vsVNRate} {vietnam_zombie}%
                                </div>
                            </div>
                        </div>
                        <div style={{
                            width: "60px",
                            height: "150px",
                            backgroundColor: "#595959",
                            border: "1px solid #595959"
                        }}>
                            <div style={{position: "relative"}}>
                                <div style={{position: "relative"}}>
                                    <div className={classes.coordinateArrowDown}>
                                        <img src={ArrowDown} alt="arrow"/>
                                    </div>
                                </div>
                                <div className={classes.coordinateTextDown}>
                                    {infoText[language][survey_tag].lowEffort}
                                </div>
                            </div>

                        </div>
                        <div style={{width: "531px", display: "flex"}}>
                            <div style={{width: "40%", margin: "auto"}}>
                                <div>
                                    <span className={classes.percentRound}
                                          style={{backgroundColor: "#a5d054"}}>{company_quitter}%</span>
                                </div>
                                <div
                                    className={classes.average}>{infoText[language][survey_tag].vsVNRate} {vietnam_quitter}%
                                </div>
                            </div>
                            <div style={{width: "60%", margin: "auto"}}>
                                <div className={classes.workForceText}
                                     style={{paddingRight: "50px", textAlign: "right"}}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].quitter.title}}
                                        style={{color: "#a5d054"}}></div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].quitter.description}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{paddingTop: "5px"}}>
                <div style={{width: "1094px"}}>
                    <p className={classes.title}>{infoText[language][survey_tag].turnOverRate}</p>
                </div>
                <div style={{width: "1092px", display: "flex", border: "1px solid #7f7f7f", paddingBottom: "5px"}}>
                    <div style={{width: "50%"}}>
                        <div style={{textAlign: "center", width: "450px", paddingTop: "15px", paddingLeft: "30px"}}>
                            {employee_list.map(employee=>(
                                <img src={employee} alt="list"/>
                            ))}
                        </div>
                        <div style={{display: "flex", paddingLeft: "30px", width: "435px"}}>
                            <div className={classes.arrowBot} style={{paddingLeft: "15px"}}>
                                {infoText[language][survey_tag].percentToLeave}
                            </div>
                            <div style={{position: "relative"}}>
                                <div className={classes.arrowBotBefore}>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            paddingTop: "2px",
                            fontSize: "80%",
                            color: "#7f7f7f",
                            fontStyle: "italic",
                            textAlign: "center",
                            marginRight: "40px"
                        }} dangerouslySetInnerHTML={{__html: infoText[language][survey_tag].percentToLeaveDescription}}>
                        </div>
                    </div>
                    <div style={{width: "25%"}}>
                        <div style={{paddingTop: "15px"}}>
                            <div style={{
                                width: "150px",
                                border: "2px solid black",
                                height: "65px",
                                margin: "auto"
                            }}>
                                <div style={{paddingTop: "20px", fontWeight: "bold"}}>{company_short_name}</div>
                            </div>
                        </div>
                        <div style={{color: "#7f7f7f", fontStyle: "italic", paddingTop: "5px"}}>(n
                            = {base_respondent_amount})
                        </div>
                        <div style={{fontWeight: "bold"}}>{company_regret + company_quitter}%</div>
                    </div>
                    <div style={{width: "25%"}}>
                        <div style={{paddingTop: "15px"}}>
                            <div style={{
                                width: "150px",
                                border: "2px solid black",
                                height: "65px",
                                margin: "auto"
                            }}>
                                <div style={{
                                    paddingTop: "20px",
                                    fontWeight: "bold"
                                }}>{infoText[language][survey_tag].vn}</div>
                            </div>
                        </div>
                        <div style={{color: "#7f7f7f", fontStyle: "italic", paddingTop: "5px"}}>(n
                            = {market_respondent_amount})
                        </div>
                        <div style={{fontWeight: "bold"}}>{vietnam_regret + vietnam_quitter}%</div>
                    </div>
                </div>
            </div>

        </div>
    )
};

export default HAWIndexTitle;