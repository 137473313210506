import {stringify} from 'query-string';
import {
    fetchUtils, GetListParams,
    GetListResult, GetOneParams, GetOneResult, GetManyParams,
    GetManyResult, GetManyReferenceParams, GetManyReferenceResult,
    UpdateParams, UpdateResult, UpdateManyParams,
    CreateParams, CreateResult, DeleteParams, DeleteResult,
    DeleteManyParams

} from 'ra-core';
import { Record} from "ra-core/esm/types";


const isValidObject = (value: any) => {
    if (!value) {
        return false;
    }

    const isArray = Array.isArray(value);
    const isBuffer = typeof Buffer !== 'undefined' && Buffer.isBuffer(value);
    const isObject =
        Object.prototype.toString.call(value) === '[object Object]';
    const hasKeys = !!Object.keys(value).length;

    return !isArray && !isBuffer && isObject && hasKeys;
};

const convert2MultiValues = (value: any, path: any = []): any => {

    if (isValidObject(value)) {
        return Object.assign(
            {},
            ...Object.keys(value).map(key =>
                convert2MultiValues(value[key], path.concat([key]))
            )
        );
    } else {
        if (path.length) {
            if (Array.isArray(value)) {
                value = value.join('-');
            }
            return {[path.join('.')]: value};
        } else {
            return value;
        }
    }
};

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */



export default (function (httpClient: any) {
    if (httpClient === void 0) {
        httpClient = fetchUtils.fetchJson;
    }
    return ({
        getList: function (resource: string, params: GetListParams) {
            let _a = params.pagination, page = _a.page, perPage = _a.perPage;
            let _b = params.sort, field = _b.field, order = _b.order;
            let _filter_query_params = convert2MultiValues(params.filter);
            if (Object.keys(_filter_query_params).length !== 0) {
                _filter_query_params.filter = 1;
            }
            let query = Object.assign(_filter_query_params, {
                _sort: field,
                _order: order,
                page: page,
                perPage: perPage
            });
            let url = resource + "?" + stringify(query);
            return httpClient(url).then(function (_a: GetListResult<Record>) {
                return _a;
            });
        },
        getOne: function (resource: string, params: GetOneParams) {
            return httpClient(resource + "/" + params.id).then(function (_a: GetOneResult) {
                return _a;
            });
        },
        getMany: function (resource: string, params: GetManyParams) {
            let ids: string[] = [];
            if (Array.isArray(params.ids)) {
                params.ids.forEach((element) => ids.push(element as string));
            } else {
                let ids_string: string = params.ids as string;
                ids.push(ids_string.replace(",", "-"));
            }

            let query = {
                id: ids.join("-")
            };

            let url = resource + "/get-many?" + stringify(query);
            return httpClient(url).then(function (_a: GetManyResult) {
                return _a;
            });
        },
        getManyReference: function (resource: string, params: GetManyReferenceParams) {
            let page = params.pagination.page, perPage = params.pagination.perPage;
            let field = params.sort.field, order = params.sort.order;
            let _a = Object.assign({}, {
                [params.target]: params.id,
                _sort: field,
                _order: order,
                page: page,
                perPage: perPage
            });
            let query = Object.assign(convert2MultiValues(params.filter), _a);

            let url = resource + "/get-many-ref?" + stringify(query);
            return httpClient(url).then(function (_a: GetManyReferenceResult) {
                return _a;
            });
        },
        update: function (resource: string, params: UpdateParams) {
            return httpClient(resource + "/" + params.id, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(function (_a: UpdateResult) {
                return _a;
            });
        },
        // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        updateMany: function (resource: string, params: UpdateManyParams) {
            return Promise.all(params.ids.map(function (id) {
                return httpClient(resource + "/" + id, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                });
            })).then( function (responses) {
                return responses;
            });
        },
        create: function (resource: string, params: CreateParams) {
            return httpClient(resource, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(function (_a: CreateResult) {
                return _a;
            });
        },
        delete: function (resource: string, params: DeleteParams) {
            return httpClient(resource + "/" + params.id, {
                method: 'DELETE',
            }).then(function (_a: DeleteResult) {
                return _a;
            });
        },
        // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: function (resource: string, params: DeleteManyParams) {
            return Promise.all(params.ids.map(function (id) {
                return httpClient(resource + "/" + id, {
                    method: 'DELETE',
                });
            })).then(function (responses) {
                return responses;
            });
        },
    });
});
