import * as React from "react";
import { FC } from 'react';

import {
    EditButton,
    List,
    Datagrid,
    TextField,
    ReferenceField,
    FilterProps,
    Filter,
    SearchInput,
    usePermissions
} from 'react-admin';


const BenefitSettingFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <SearchInput source="participant" />
        <SearchInput source="benefit" />
    </Filter>
);

const BenefitSettingList: FC<any> = props => {
    const { loaded, permissions } = usePermissions();

    if (!loaded) {
        return null; // Đợi quyền truy cập được tải
    }

    const isAdmin = permissions.includes('ROLE_ADMIN');
    const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
    const isSurvey = permissions.includes('ROLE_SURVEY');
    return (
        <List label="All Sales Benefits" {...props} filters={<BenefitSettingFilter />} hasCreate={false} title={"Để add Benefit mới vui lòng vào từ Survey chọn Edit "}>
            <Datagrid>
                <TextField source="id" />
                <ReferenceField label="Benefit"
                    reference="salesbenefits"
                    source="benefit"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Participant"
                    reference="participants"
                    source="participant"
                >
                    <TextField source="company_name" />
                </ReferenceField>
                <ReferenceField label="Setting Keys"
                    reference="salesbenefits"
                    source="benefit"
                >
                    <TextField source="setting_keys" />
                </ReferenceField>
                <TextField source="value" />
                {(isAdmin || isSaleAdmin) && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default BenefitSettingList;