import React, {
    FC,
} from 'react';
import {makeStyles} from "@material-ui/styles";

interface Props {
    language: string,
    survey_tag: string,
    company_short_name: string,
    base_surveyee: number,
    company_index: number,
    industry_index: number,
    vietnam_index: number
}

const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "100%",
        textAlign: "center",
        height: "42px",
        backgroundColor: "#7f7f7f",
        color: "#ffffff",
        fontWeight: "bold",
        lineHeight: "42px",
        display: "flex"
    }),
    companyBenchmark: {
        display: "flex",
        width: "50%",
        border: "1px #ffffff solid"
    },
    industryBenchmark: {
        display: "flex",
        width: "25%",
        border: "1px #ffffff solid"
    },
    vietNamBenchmark: {
        display: "flex",
        width: "25%",
        border: "1px #ffffff solid"
    }
}));
const infoText = {
    en: {
        "haw": {
            title: {
                hawIndex: "Happiness at work index",
                industry: "Industry",
                vn: "VietNam"
            }
        }
    },
    vn: {
        "haw": {
            title: {
                hawIndex: "Chỉ số Hạnh Phúc",
                industry: "Ngành",
                vn: "Việt Nam"
            }
        }
    }
};
const HAWIndexBenchmark: FC<Props> = ({
                                          language = "vn", survey_tag = "haw", company_short_name = "",
                                          base_surveyee = 0, company_index = 0, industry_index = 0, vietnam_index = 0
                                      }) => {
    const classes = useStyles();
    return (
        <div style={{paddingTop: "25px"}}>
            <div className={classes.main}>
                <div className={classes.companyBenchmark}>
                    <div style={{width: "90%"}}>
                        {infoText[language][survey_tag].title.hawIndex} - {company_short_name} (n={base_surveyee})
                    </div>
                    <div style={{width: "10%", color: "#ffba37", fontSize: "150%"}}>
                        {company_index}
                    </div>
                </div>

                <div className={classes.industryBenchmark}>
                    <div style={{width: "70%"}}>
                        {infoText[language][survey_tag].title.industry}
                    </div>
                    <div style={{width: "30%", color: "#ffba37", fontSize: "150%"}}>
                        {industry_index}
                    </div>
                </div>

                <div className={classes.vietNamBenchmark}>
                    <div style={{width: "70%"}}>
                        {infoText[language][survey_tag].title.vn}
                    </div>
                    <div style={{width: "30%", color: "#ffba37", fontSize: "150%"}}>
                        {vietnam_index}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default HAWIndexBenchmark;