import React, {
    FC,
} from 'react';
import { makeStyles } from "@material-ui/styles";

interface Props {
    language: string;
    survey_tag: string;
}
const useStyles = makeStyles(theme => ({
    main: () => ({
        width: "100%",
        font: "bold",
        textAlign: "center",
        // height: "42px",
    }),
    title: {
        backgroundColor: "#7f7f7f",
        color: "#ffffff",
        fontWeight: "bold",
        margin: "auto",
        padding: "10px"
    },
}));
const infoText = {
    en: {
        "haw": {
            title: {
                part1: "HAPPINESS AT WORK INDEX"
            }
        }
    },
    vn: {
        "haw": {
            title: {
                part1: "CHỈ SỐ NGUỒN NHÂN LỰC HẠNH PHÚC"
            }
        }
    }
};
const HAWIndexTitle: FC<Props> = ({language = "vn", survey_tag = "haw"})=> {
    const classes = useStyles();
    return (<div className={classes.main}>
            <p className={classes.title}>{infoText[language][survey_tag].title.part1}</p>
        </div>
    )
};

export default HAWIndexTitle;