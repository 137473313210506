import * as React from "react";
import { FC, useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput
} from 'react-admin';
import { onHandleTextInput } from "../utils/textHandling";

const BenefitSettingCreate: FC<any> = props => {
    const [filterParticipant, setFilterParticipant] = useState('');
    return (
        <Create {...props} >
            <SimpleForm redirect={"list"} >
                <h2>Participant Benefit Setting</h2>
                <ReferenceInput
                    source="survey"
                    reference="surveys"
                    initialValue={props.surveyid} disabled
                >
                    <SelectInput source="name" />
                </ReferenceInput>
                <ReferenceInput label="Participant" source="participant" reference="participants"
                    filter={{ 'q': filterParticipant, commercialName: filterParticipant, survey: props.surveyid }}
                >
                    <AutocompleteInput optionText="company_name" source="participant" onChange={(event) => {
                        setFilterParticipant(onHandleTextInput(event))
                    }} />
                </ReferenceInput>
                <ReferenceInput
                    source="benefit"
                    reference="salesbenefits"

                >
                    <AutocompleteInput source="name" />
                </ReferenceInput>
                <TextInput source="value" label="Value" />
            </SimpleForm>
        </Create>
    );
}

export default BenefitSettingCreate;