import ScoreCardTemplateCreate from './ScoreCardTemplateCreate';
import ScoreCardTemplateEdit from './ScoreCardTemplateEdit';
import ScoreCardTemplateList from './ScoreCardTemplateList';


export default {
    create: ScoreCardTemplateCreate,
    edit: ScoreCardTemplateEdit,
    list: ScoreCardTemplateList,
};
