import * as React from 'react';
import { FC } from 'react';

import {
    EditButton,
    BooleanField,
    List,
    ListProps,
    FilterProps,
    Datagrid,
    ImageField,
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    SearchInput,
    ReferenceInput,
    SelectArrayInput,
    BooleanInput,
    usePermissions
} from 'react-admin';

const CompanyFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <SearchInput source="commercialName" alwaysOn />
        <ReferenceInput label="Industries"
            reference="industries"
            source="industries"

        >
            <SelectArrayInput source="name" />
        </ReferenceInput>
        <BooleanInput source="valid" />
    </Filter>
);


const CompanyList: FC<ListProps> = props => {

    const { loaded, permissions } = usePermissions();

    if (!loaded) {
        return null; // Đợi quyền truy cập được tải
    }

    const isAdmin = permissions.includes('ROLE_ADMIN');
    const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
    return (
        <List title="All Companies" {...props} filters={<CompanyFilter />} hasCreate = {isAdmin||isSaleAdmin}>
            <Datagrid>
                <TextField source="id" />
                <ImageField source="logo_url.medium" label="Logo" />
                <TextField source="commercialName" />
                <TextField source="standardizedName" />
                <ReferenceArrayField
                    label="Industries"
                    reference="industries"
                    source="industries"
                    sortBy="industries.name"
                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <SingleFieldList>
                        <ChipField source="name" size="small" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <BooleanField source="valid" title="Valid" />
                {(isAdmin||isSaleAdmin) && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default CompanyList;