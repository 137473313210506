import MediaCommunicationCreate from './MediaCommunicationCreate';
import MediaCommunicationEdit from './MediaCommunicationEdit';
import MediaCommunicationList from './MediaCommunicationList';


export default {
    create: MediaCommunicationCreate,
    edit: MediaCommunicationEdit,
    list: MediaCommunicationList,
};
