import React from 'react';
import InternalScoreCard2023Export from "./VNBPTWScoreCardPPTExport/InternalScoreCard2023Export";
import { makeStyles } from '@material-ui/core/styles';

interface Props {
    props: any;
}

const useStyles = makeStyles(theme => ({
    main: (props: Props) => ({
        overflow: 'inherit',
        display: 'flex',
    }),
    title: {},
}));


const InternalScoreCard2023 = ({ props }) => {
    const classes = useStyles(props);
    return (<div className={classes.main}>
        <InternalScoreCard2023Export {...props} />
    </div>)
};

export default InternalScoreCard2023;