/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadCapabilities = dataProvider => ({
    ...dataProvider,
    update: (resource, params) => {
        if (!params.data.logo_url) {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }
        // The posts edition form uses a file upload widget for the pictures field.
        // Freshly dropped pictures are File objects
        // and must be converted to base64 strings

        const newLogo = params.data.logo_url.rawFile instanceof File? [params.data.logo_url]:[];
        const formerLogo = !(params.data.logo_url.rawFile instanceof File)? [params.data.logo_url]:[];

        return Promise.all(newLogo.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64
                }))
            )
            .then(transformedNewLogo =>
                dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        logo_url: [
                            ...transformedNewLogo,
                            ...formerLogo,
                        ],
                    },
                })
            );
    }
});

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

export default addUploadCapabilities;
