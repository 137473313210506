import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScoreCardInfoBlock from "./SurveyInfoBlock/ScoreCardInfoBlock";
import SurveyTitle from "./ScoreCardBlock/SurveyTitle";
import HawScoreCardBlock from "./ScoreCardBlock/HawScoreCardBlock";
import HAWIndexTitle from "./ScoreCardBlock/HAWIndexTitle";
import HAWIndexMainChart from "./ScoreCardBlock/HAWIndexMainChart";
import HAWIndexBenchmark from "./ScoreCardBlock/HAWIndexBenchmark";
import LegalCopyRight from "./ScoreCardBlock/LegalCopyRight";
interface Props {
    props: any;
}

const useStyles = makeStyles(theme => ({
    main: (props: Props) => ({
        overflow: 'inherit',
        display: 'flex'
    }),
    title: {},
}));
const HawIndex = ({props}) => {
    const classes = useStyles(props);
    return (<div className={classes.main}>
        <ScoreCardInfoBlock value = {props}/>
        <HawScoreCardBlock {...props}>
            <SurveyTitle {...props}/>
            <HAWIndexTitle {...props}/>
            <HAWIndexMainChart {...props}/>
			<HAWIndexBenchmark {...props}/>
			<LegalCopyRight {...props}/>
        </HawScoreCardBlock>

    </div>)
};

export default HawIndex;