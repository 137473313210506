import jsonServerProvider from './ra-data-json-server';
import addUploadFeature from "./addUploadFeature";
import { cacheDataProviderProxy } from 'react-admin';
import httpClient from "./http_client";

//http://company.symfony.mio/api
//https://jsonplaceholder.typicode.com
const dataProvider =  jsonServerProvider(httpClient);

const uploadCapableDataProvider = addUploadFeature(dataProvider);

const sometimesFailsDataProvider = new Proxy(uploadCapableDataProvider, {
    get: (target, name) => (resource:string, params:any) => {
        // add rejection by type or resource here for tests, e.g.
        // if (name === 'delete' && resource === 'posts') {
        //     return Promise.reject(new Error('deletion error'));
        // }
        // if (
        //     resource === 'posts' &&
        //     params.data &&
        //     params.data.title === 'f00bar'
        // ) {
        //     return Promise.reject(new Error('this title cannot be used'));
        // }
        return uploadCapableDataProvider[name](resource, params);
    },
});
const delayedDataProvider = new Proxy(sometimesFailsDataProvider, {
    get: (target, name) => (resource:string, params:any) =>
        new Promise(resolve =>
            setTimeout(
                () =>
                    resolve(sometimesFailsDataProvider[name](resource, params)),
                300
            )
        ),
});

export default cacheDataProviderProxy(delayedDataProvider);
