import CompanyScoreCardCreate from './CompanyScoreCardCreate';
import CompanyScoreCardEdit from './CompanyScoreCardEdit';
import CompanyScoreCardList from './CompanyScoreCardList';
import CompanyScoreCardShow from './CompanyScoreCardShow';


export default {
    create: CompanyScoreCardCreate,
    edit: CompanyScoreCardEdit,
    list: CompanyScoreCardList,
    show: CompanyScoreCardShow
};
